import React from "react";
import Header from "../Components/Header";
import Footer from "./Footer";
import Leaseproposal from "../Components/Leaseproposal"
const Proposalpage = () => {
  return (
    <div>
      {/* <Header/> */}
      <Leaseproposal/>
      {/* <Footer/> */}
    </div>
  );
};

export default Proposalpage;
