// import React from "react";
// import "./Submitted.css";
// import Success from "../Images/success icon component.png";
// import { GrNext } from "react-icons/gr";
// import Close from "../Images/close (2).png";
// // import { Link } from "react-router-dom";
// import { useNavigate } from "react-router-dom";

// const Submitted = ({ closeModal }) => {
//   const navigate = useNavigate();
//   return (
//     <>
//       <div className=" modalBackground ">
//         <div className="success-container">
//           <div className="h-div p-3 ">
//             <h1 className="submit-h">
//               Fleet request Form Submitted Successfully!
//             </h1>
//             {/* <button className='closebtn' onClick={()=>closeModal(false)} type="button">X</button> */}
//             <img
//               src={Close}
//               onClick={() => closeModal(false)}
//               alt="close"
//               style={{ width: "24px", height: "24px" }}
//             />
//           </div>
//           <div className=" text-center img-cont">
//             <img
//               src={Success}
//               alt="success"
//               className="img-responsive success-image"
//             />
//           </div>
//           <div className="h-p-b text-center ">
//             <h1>Details Sent Successfully!</h1>
//             <p className="text-center">
//               Our team will reach out to you about your requirements soon!
//             </p>
//             {/* <Link to="/enquire"> */}
//             <button
//               href="#one"
//               onClick={() => {
//                 navigate("/enquire");
//                 closeModal(false);
//                 window.location.reload();
//               }}
//               type="button"
//               class="btn-success "
//             >
//               Fill Out Other Form
//               <span>
//                 <GrNext />
//               </span>
//             </button>
//             {/* </Link> */}
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Submitted;
import React from "react";
import "./Submitted.css";
import Success from "../Images/success icon component.png";
import { GrNext } from "react-icons/gr";
import Close from "../Images/close (2).png";
import { useNavigate } from "react-router-dom";

const Submitted = ({ closeModal }) => {
  const navigate = useNavigate();

  return (
    <>
      <div className="modalBackground">
        <div className="success-container">
          <div className="h-div p-3">
            <h1 className="submit-h">
              Fleet request Form Submitted Successfully!
            </h1>
            <img
              src={Close}
              onClick={() => closeModal(false)} // Close the modal when the close icon is clicked
              alt="close"
              style={{ width: "24px", height: "24px" }}
            />
          </div>
          <div className="text-center img-cont">
            <img
              src={Success}
              alt="success"
              className="img-responsive success-image"
            />
          </div>
          <div className="h-p-b text-center">
            <h1>Details Sent Successfully!</h1>
            <p className="text-center">
              Our team will reach out to you about your requirements soon!
            </p>
            <button
              // href="#one"

              onClick={() => {
                navigate("/fleet_leasing"); // Navigate to another page
                closeModal(false); // Close the modal
                window.location.reload(); // Reload the window if necessary
              }}
              type="button"
              className="btn-success"
            >
              {/* Fill Out Other Form */}
              View more fleet
              <span>
                <GrNext />
              </span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Submitted;
