import React, { useState, useEffect } from 'react';
import { BASE_URL } from '../repository/api';
import "./BlogCard.css";
import ClockIcon from '../Images/Frame.svg';
import { Link } from 'react-router-dom';



const BlogCard = () => {

    const [item, setItem] = useState([]);


    const fetchData = async () => {
        try {
            const res = await fetch(`${BASE_URL}/blog/?ordering=id`).then((response) => response.json());
            const data = res;
            // console.log(data);

            setItem(data);
        } catch (e) {
            console.error(e);
        }

    };
    useEffect(() => {
        fetchData(`${BASE_URL}/blog/?ordering=id`);
    }, []);

    console.log(item)

    const options = { month: 'short', day: 'numeric', year: 'numeric' };
    const formattedDate = new Date(item.created_at).toLocaleDateString('en-US', options);

    console.log(formattedDate);


    return (
        <div >
            <div className="row mt-5">

                {item.results && item.results.map((elem, id) => (

                    <div className="col-md-4 col-sm-12 mb-5" key={id}>
                        <Link to={`/blog_details?id=${elem.id}`} onClick={()=>{
                            window.scrollTo(0,0)
                        }} className="remove-decoration" key={id}>
                            <div className="blog-page-card">
                                <div className="card ">
                                    <div className="card-body">
                                        <div className="blog-card-img w-100">
                                            <img src={elem.image} alt="" className="img-responsive" />
                                        </div>
                                        <div className="blog-card-heading mt-3">
                                            <h5>{elem.title.split(' ').slice(0, 8).join(' ')}{elem.title.split(' ').length > 8 ? '...' : ''}</h5>
                                        </div>

                                        <div className="blog-card-para text-start mt-3">
                                            <p>{elem.top_content.split(' ').slice(0, 25).join(' ')}{elem.top_content.split(' ').length > 25 ? '...' : ''}</p>
                                        </div>
                                        <div className="read-more-text mt-3">
                                            <p>Read more</p>
                                        </div>
                                        <hr />
                                        <div className="author-details mt-2 d-flex justify-content-between">
                                            <div className="d-flex">
                                                <div className="author-image">
                                                    {/* <img src={elem.author_pic} alt="" width="48px" /> */}
                                                    {/* <h6>-</h6> */}
                                                </div>
                                                <div className="blog-card-para ms-2">
                                                    <p>-{elem.author}</p>
                                                </div>
                                            </div>
                                            <div className="d-flex">
                                                <div className="blog-clock-icon">
                                                    <img src={ClockIcon} alt="" width="20px" />
                                                </div>
                                                <div className="blog-current-date ms-2 blog-card-para">
                                                    <p>{new Date(elem.created_at).toLocaleDateString('en-US', options)}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>

                ))}
            </div>
        </div>
    )
}

export default BlogCard
