import React from "react";
import "./SideCard.css";
import { MdOutlineNavigateNext } from "react-icons/md";
import SdgCard from "./SdgCard";
import sdg1 from "../Images/SDG1.png";
import sdg2 from "../Images/SDG2.png";
import sdg7 from "../Images/SDG7.png";
import sdg9 from "../Images/SDG9.png";
import sdg11 from "../Images/SDG11.png";
import sdg13 from "../Images/SDG13.png";

const SideCard = () => {
  return (
    <>
    <div className="w-100 pt-md-5  pb-md-5 paddingsmall position-relative side-container">
      <div className=" container w-100 pt-md-5 pb-md-5 paddingsmall position-relative">
        <div className="text-center ev-lease-text">
          <h1 className="ev-lease">
            And making a positive impact for a better future
          </h1>
        </div>

        <div className="row mt-5 ">
          <div
            className="sdg-1-full col-md-4 col-sm-12 nav nav-pills"
            id="pills-tab"
            role="tablist"
          >
            <div
              className="side-bar w-100 d-flex justify-content-between p-3 mb-2 nav-link active"
              id="pills-ten-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-ten"
              type="button"
              role="tab"
              aria-controls="pills-ten"
              aria-selected="true"
            >
              <h1 className="side-bar-txt m-0">
                {" "}
                SDG 1: Generating Employment
              </h1>
              <p>
                <MdOutlineNavigateNext size="25" />
              </p>
            </div>

            <div
              className="side-bar w-100 d-flex justify-content-between p-3 mb-2 nav-link"
              id="pills-twenty-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-twenty"
              type="button"
              role="tab"
              aria-controls="pills-twenty"
              aria-selected="false"
            >
              <h1 className="side-bar-txt m-0"> SDG 5: Gender Equality</h1>
              <p>
                <MdOutlineNavigateNext size="25" />
              </p>
            </div>

            <div
              className="side-bar w-100 h-auto d-flex justify-content-between p-3 mb-2 nav-link"
              id="pills-thirty-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-thirty"
              type="button"
              role="tab"
              aria-controls="pills-thirty"
              aria-selected="false"
            >
              <h1 className="side-bar-txt m-0">
                {" "}
                SDG 7: Clean Energy to Power our Fleets{" "}
              </h1>
              <p>
                <MdOutlineNavigateNext size="25" />
              </p>
            </div>

            <div
              className="side-bar w-100 h-auto d-flex justify-content-between p-3 mb-2 nav-link"
              id="pills-fourty-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-fourty"
              type="button"
              role="tab"
              aria-controls="pills-fourty"
              aria-selected="false"
            >
              <h1 className="side-bar-txt m-0">
                {" "}
                SDG 9: Promoting Innovating & Manufacturing
              </h1>
              <p>
                <MdOutlineNavigateNext size="25" />
              </p>
            </div>

            <div
              className="side-bar w-100 d-flex justify-content-between p-3 mb-2 nav-link"
              id="pills-fifty-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-fifty"
              type="button"
              role="tab"
              aria-controls="pills-fifty"
              aria-selected="false"
            >
              <h1 className="side-bar-txt m-0">
                {" "}
                SDG 11: Sustainable Transportation{" "}
              </h1>
              <p>
                <MdOutlineNavigateNext size="25" />
              </p>
            </div>

            <div
              className="side-bar w-100 d-flex justify-content-between p-3 mb-2 nav-link"
              id="pills-sixty-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-sixty"
              type="button"
              role="tab"
              aria-controls="pills-sixty"
              aria-selected="false"
            >
              <h1 className="side-bar-txt m-0"> SDG 13: Climate Action </h1>
              <p>
                <MdOutlineNavigateNext size="25" />
              </p>
            </div>
          </div>
          <div className="sdg-2-half col-md-5 col-sm-12 p-3 ">
            <div
              className="sdg2-scroll d-flex w-100 gap-3 nav nav-pills"
              id="pills-tab"
              role="tablist"
            >
              <button
                className="gray-btn nav-link active"
                id="pills-ten-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-ten"
                type="button"
                role="tab"
                aria-controls="pills-ten"
                aria-selected="true"
                style={{ width: "80px", height: "55px" }}
              >
                SDG1
              </button>

              <button
                className="gray-btn nav-link"
                id="pills-twenty-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-twenty"
                type="button"
                role="tab"
                aria-controls="pills-twenty"
                aria-selected="false"
                style={{ width: "80px", height: "55px" }}
              >
                SDG5
              </button>

              <button
                className="gray-btn nav-link"
                id="pills-thirty-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-thirty"
                type="button"
                role="tab"
                aria-controls="pills-thirty"
                aria-selected="false"
                style={{ width: "80px", height: "55px" }}
              >
                SDG7
              </button>

              <button
                className="gray-btn nav-link"
                id="pills-fourty-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-fourty"
                type="button"
                role="tab"
                aria-controls="pills-fourty"
                aria-selected="false"
                style={{ width: "80px", height: "55px" }}
              >
                SDG9
              </button>

              <button
                className="gray-btn nav-link"
                id="pills-fifty-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-fifty"
                type="button"
                role="tab"
                aria-controls="pills-fifty"
                aria-selected="false"
                style={{ width: "80px", height: "55px" }}
              >
                SDG11
              </button>

              <button
                className="gray-btn nav-link"
                id="pills-sixty-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-sixty"
                type="button"
                role="tab"
                aria-controls="pills-sixty"
                aria-selected="false"
                style={{ width: "80px", height: "55px" }}
              >
                SDG13
              </button>
            </div>
          </div>

          <div className="col-md-8 col-sm-12 tab-content" id="pills-tabContent">
            <div
              className="tab-pane fade show active"
              id="pills-ten"
              role="tabpanel"
              aria-labelledby="pills-ten-tab"
            >
              <SdgCard
                text1="Generating Employment"
                para1="We provide job opportunities for underserved, unbanked gig workers and improve their credit profiles through our driver training programs and partnerships with fleet operators, contributing to India's logistics revolution."
                text2="1000+"
                para2="Jobs Generated by 2025"
                img1={
                  <img
                    src={sdg1}
                    alt="sdg1"
                    className="sdg-1 img-responsive w-100"
                  />
                }
              />
            </div>

            <div
              className="tab-pane fade "
              id="pills-twenty"
              role="tabpanel"
              aria-labelledby="pills-twenty-tab"
            >
              <SdgCard
                text1="Gender Equality"
                para1="We encourage women's participation and access to credit in the workforce. Incentives are provided to our fleet partners with female drivers operating fleets, promoting gender equality in the industry."
                text2="30,000+"
                para2="Female Roles Jobs Generated by 2025"
                img1={
                  <img
                    src={sdg2}
                    alt="sdg1"
                    className="sdg-1 img-responsive w-100"
                  />
                }
              />
            </div>

            <div
              className="tab-pane fade"
              id="pills-thirty"
              role="tabpanel"
              aria-labelledby="pills-thirty-tab"
            >
              <SdgCard
                text1="Powering Fleets with Clean Energy"
                para1="We aim to achieve net zero decarbonisation of our fleets by 2025 by generating 100% of their energy from renewable sources. By 2025, we plan to generate and consume 20MW of renewable energy."
                text2="20 MW"
                para2="Renewable Energy Generated by 2025"
                img1={
                  <img
                    src={sdg7}
                    alt="sdg1"
                    className="sdg-1 img-responsive w-100"
                  />
                }
              />
            </div>

            <div
              className="tab-pane fade"
              id="pills-fourty"
              role="tabpanel"
              aria-labelledby="pills-fourty-tab"
            >
              <SdgCard
                text1="Promoting Innovation & Manufacturing"
                para1="We help new age OEMs accelerate their sales by offering innovative financing models that rewards innovation in product and technology. Our ecosystem enablement approach towards electrification works across stakeholders in the EV ecosystem."
                text2="$100MN +"
                para2="Financing Unlocked by 2025"
                img1={
                  <img
                    src={sdg9}
                    alt="sdg1"
                    className="sdg-1 img-responsive w-100"
                  />
                }
              />
            </div>

            <div
              className="tab-pane fade"
              id="pills-fifty"
              role="tabpanel"
              aria-labelledby="pills-fifty-tab"
            >
              <SdgCard
                text1="Sustainable Transportation"
                para1="Our all-electric vehicles decrease air pollution and counteract carbon emissions from traditional combustion engine fleets. We offer one-stop solution to drive the electrification of India's last-mile transportation."
                text2="100,000+"
                para2="EV Fleet by 2025"
                img1={
                  <img
                    src={sdg11}
                    alt="sdg1"
                    className="sdg-1 img-responsive w-100"
                  />
                }
              />
            </div>

            <div
              className="tab-pane fade"
              id="pills-sixty"
              role="tabpanel"
              aria-labelledby="pills-sixty-tab"
            >
              <SdgCard
                text1="Climate Action"
                para1="Switching from a gasoline-powered vehicle to an electric LCV reduces average CO2 by 10.35 tonnes throughout its lifespan, equivalent to planting 500 trees yearly. Our fleet will offset 6 MT of lifetime CO2 by 2025."
                text2="100,000+"
                para2="EV Fleet by 2025"
                img1={
                  <img
                    src={sdg13}
                    alt="sdg1"
                    className="sdg-1 img-responsive w-100"
                  />
                }
              />
            </div>
          </div>
        </div>
      </div>
      </div>
    </>
  );
};

export default SideCard;
