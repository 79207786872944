import React from "react";
import  { useState, useEffect } from "react";
import {  Link, useNavigate, useLocation } from "react-router-dom";
import "./styles/bootstrap.min.css";
import { Route, Routes,Navigate } from "react-router-dom";
import About from "./Pages/About";
import EnquireForm from "./Pages/EnquireForm";
import FleetLeasing from "./Pages/FleetLeasing";
import Fleet from "./Pages/FleetOs";
import Home from "./Pages/Home";
import IndividualImg from "./Pages/IndividualImg";
import MarketingVehicle from "./Pages/MarketingComp/MarketingVehicle";
import Partners from "./Pages/Partners";
import VehicleIndividual from "./Pages/VehicleListComp/Indiviual/VehicleIndividual";
import LeaseOption from "./Pages/VehicleListComp/LeaseOption/LeaseOption";
import Footer from "./Components/Footer";
import Header from "./Components/Header";
import Proposalpage from "./Components/Proposalpage";
import Request from "./Components/Request";
import { BLOG_URL } from "./Components/URL";
import Blog from "./Pages/Blog";
import BlogDetails from "./Pages/BlogDetails";
import FaqsPage from "./Pages/FaqsPage";
import StepperForm from "./Pages/StepperForm/StepperComponent"
import EditStepper from "./Pages/StepperForm/EditStepper"
import Fleetprotect from "./Pages/Fleetprotect";

import LeasingFinance from "./Pages/LeasingFinance";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Login from "./Components/Login";
import ForgotPasswordModal from "./Components/ForgotPasswordModal";
import PasswordReset from "./Components/PasswordReset";
import AddContact from "./Pages/AddContact";
import Refund from "./Pages/Refund";
import PressReleasePage from "./Pages/PressReleasePage";
import FinancialDisclosures from "./Pages/FinancialDisclosures";

const App = () => {
  const [activeLink, setActiveLink] = useState("/");
  const navigate = useNavigate();
  const location = useLocation();

  const handleLinkClick = (link) => {
    setActiveLink(link);
    navigate(link);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location.pathname]);

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleLogin = () => {
   
    setIsLoggedIn(true);
    navigate("/home");
  };
  const hideHeaderAndFooter = location.pathname === "/login" || location.pathname === "/PasswordReset";
  return (
    <>

      
      {!hideHeaderAndFooter &&<Header activeLink={activeLink} handleLinkClick={handleLinkClick} />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/individual" element={<IndividualImg />} />
        <Route path="/vehicleIndividual" element={<VehicleIndividual />} />
        <Route path="/enquire" element={<EnquireForm />} />
        <Route path={BLOG_URL} element={<Blog />} />
        <Route path="/leasing" element={<LeasingFinance/>} />
        <Route path="/partners" element={<Partners />} />
        <Route path="/blog_details" element={<BlogDetails />} />
        <Route path="/fleet" element={<Fleet />} />
        <Route path="/fleet_leasing" element={<FleetLeasing />} />
        <Route path="/fleetprotect" element={<Fleetprotect />} />
        <Route path="/faqs" element={<FaqsPage />} />
        <Route path="/marketingVehicle" element={<MarketingVehicle />} />
        <Route path="/Request" element={<Request />} />
        <Route path="/Contact-us" element={<AddContact/>}/>
        <Route path="/cancelandrefund" element={<Refund/>}/>
        <Route path="/LeaseOption" element={<LeaseOption />} />
        <Route path="/proposalpage" element={<Proposalpage />} />
        <Route path="/leaseapplicationform" element={<StepperForm />} />
        <Route path="/login" element={<Login/>} /> 
         <Route path="/ForgotPasswordModal" element={<ForgotPasswordModal/>}/>
        <Route path="/PasswordReset" element={<PasswordReset />} />
        <Route path="/shell_eurazeo_ev2_twynam_invest" element={<PressReleasePage />} />
        <Route path="/financial-disclosures" element={<FinancialDisclosures/>}/>


        <Route path="/editleaseapplicationform/:id" element={<EditStepper />} />
      </Routes>
      <ToastContainer position="bottom-center" autoClose={5000} />

      {!hideHeaderAndFooter &&<Footer  activeLink={activeLink} handleLinkClick={handleLinkClick}/>}
   
    </>
  );
};

export default App;
