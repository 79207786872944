import React from "react";
import "./FaqComponent.css";
import { useState } from "react";

const FaqComponent = (props) => {
  console.log("props", props);
  
  const [activeIndex, setActiveIndex] = useState(0);

  const handleClick = (index) => {
    setActiveIndex(index);
  };
  return (
    <>
      <div>
        <div className="row p-md-5 paddingsmall mt-5  w-100 container">
          <div className="faq-heading col-md-4 col-sm-12 mt-5">
            <h1 className="ev-lease">Frequently Asked Questions</h1>
            <p className="main">
              Have any queries on our Leasing or Financing process? Find them
              here.
            </p>
            {/* <button className="gray-btn mt-4">Visit help center</button> */}
          </div>

          <div className="col-md-8 col-sm-12">
            <div
              class="accordion w-100 mt-5"
              id="accordionPanelsStayOpenExample"
            >
              <div class="accordion" id="accordionExample">
                {props.faq?.map((item, index) => (
                  <div
                    class="accordion-item"
                    key={index}
                    onClick={() => handleClick(index)}
                  >
                    <h2
                      class="accordion-header collapsed"
                      id="panelsStayOpen-headingOne"
                    >
                      <button
                        className={`accordion-button ${
                          activeIndex === index ? "" : "collapsed"
                        }`}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#panelsStayOpen-collapse-${index}`}
                        aria-expanded={activeIndex === index ? "true" : "false"}
                        aria-controls={`panelsStayOpen-collapse-${index}`}
                      >
                        <p className="main">{item.question}</p>
                      </button>
                    </h2>

                    <div
                      id={`panelsStayOpen-collapse-${index}`}
                      className={`accordion-collapse collapse ${
                        activeIndex === index ? "show" : ""
                      }`}
                      aria-labelledby={`panelsStayOpen-heading-${index}`}
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <p className="main">{item.answer[0].content}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FaqComponent;
