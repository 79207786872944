import axios from "axios";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { BASE_URL_FLEETOS } from "../../repository/api";
import Button from "../CustomComponent/Button";
import FileUpload from "../CustomComponent/UploadFile";
import "./Form.css";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const validationSchema = Yup.object().shape({
  certificate_of_incorporate: Yup.string().required("Required"),
  msme_certificate: Yup.string().required("Required"),
  moa_docuemnt: Yup.string().required("Required"),
  aoa_document: Yup.string().required("Required"),
  gst_certificate: Yup.string().required("Required"),
  company_pan_card: Yup.string().required("Required"),
  form_26as: Yup.string().required("Required"),
  list_of_shareholders: Yup.string().required("Required"),
  group_company_structure: Yup.string().required("Required"),
  list_of_directors: Yup.string().required("Required"),
  // Add more validation as needed
});

export default function SecretarialDocuments({ handleBack, handleNext }) {
  const { id } = useParams();

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchDataFromAPI = async () => {
    try {
      
      const response = await axios.get(
        `${BASE_URL_FLEETOS}/v1/lease-application/${id}`
      );
      setData(response.data.lease_application_secretarial_documents);
    } catch (error) {
      setError(error);
    } finally {
      
    }
  };

  useEffect(() => {
    fetchDataFromAPI();
  }, [id]);

  function extractNameAfterFirstUnderscore(inputString) {
    if (typeof inputString === "string") {
      let parts = inputString?.split("/");
      let lastPart = parts[parts?.length - 1];
      let firstUnderscoreIndex = lastPart?.indexOf("_");
      if (firstUnderscoreIndex !== -1) {
        return lastPart?.substring(firstUnderscoreIndex + 1);
      } else {
        return lastPart;
      }
    }
  }

  const initialValues = {
    certificate_of_incorporate: data?.certificate_of_incorporate ?? "",
    msme_certificate: data?.msme_certificate ?? "",
    moa_docuemnt: data?.moa_docuemnt ?? "",
    aoa_document: data?.aoa_document ?? "",
    gst_certificate: data?.gst_certificate ?? "",
    company_pan_card: data?.company_pan_card ?? "",
    form_26as: data?.form_26as ?? "",
    list_of_shareholders: data?.list_of_shareholders ?? "",
    group_company_structure: data?.group_company_structure ?? "",
    list_of_directors: data?.list_of_directors ?? "",
  };

  const handleErrorResponse = (error) => {
    if (
      error.response &&
      error.response.data &&
      error.response.data.error &&
      error.response.data.error.issues
    ) {
      error.response.data.error.issues.forEach((issue) => {
        toast.error(`${issue.path[0]} is ${issue.message}`);
      });
    } else {
      toast.error("An error occurred. Please try again.");
    }
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        console.log(values);
        let allPromisesUrl = [
          uploadAndCommitFile(values.certificate_of_incorporate),
          uploadAndCommitFile(values.msme_certificate),
          uploadAndCommitFile(values.moa_docuemnt),
          uploadAndCommitFile(values.aoa_document),
          uploadAndCommitFile(values.gst_certificate),
          uploadAndCommitFile(values.company_pan_card),
          uploadAndCommitFile(values.form_26as),
          uploadAndCommitFile(values.list_of_shareholders),
          uploadAndCommitFile(values.group_company_structure),
          uploadAndCommitFile(values.list_of_directors),
        ];

        let allPromisesUrl1 = await Promise.all(allPromisesUrl).then(
          (values) => values
        );
        // console.log("DocValue", extractNameAfterFirstUnderscore(DocValue?.lease_agreement));

        console.log(allPromisesUrl1);
        const Form1Data = {
          ...(values.certificate_of_incorporate !== "" && {
            certificate_of_incorporate: values.certificate_of_incorporate?.type
              ? allPromisesUrl1[0]
              : values.certificate_of_incorporate,
          }),

          ...(values.msme_certificate !== "" && {
            msme_certificate: values.msme_certificate?.type
              ? allPromisesUrl1[1]
              : values.msme_certificate,
          }),

          ...(values.moa_docuemnt !== "" && {
            moa_docuemnt: values.moa_docuemnt?.type
              ? allPromisesUrl1[2]
              : values.moa_docuemnt,
          }),

          ...(values.aoa_document !== "" && {
            aoa_document: values.aoa_document?.type
              ? allPromisesUrl1[3]
              : values.aoa_document,
          }),

          ...(values.gst_certificate !== "" && {
            gst_certificate: values.gst_certificate?.type
              ? allPromisesUrl1[4]
              : values.gst_certificate,
          }),

          ...(values.company_pan_card !== "" && {
            company_pan_card: values.company_pan_card?.type
              ? allPromisesUrl1[5]
              : values.company_pan_card,
          }),

          ...(values.form_26as !== "" && {
            form_26as: values.form_26as?.type
              ? allPromisesUrl1[6]
              : values.form_26as,
          }),

          ...(values.list_of_shareholders !== "" && {
            list_of_shareholders: values.list_of_shareholders?.type
              ? allPromisesUrl1[7]
              : values.list_of_shareholders,
          }),

          ...(values.group_company_structure !== "" && {
            group_company_structure: values.group_company_structure?.type
              ? allPromisesUrl1[8]
              : values.group_company_structure,
          }),
          ...(values.list_of_directors !== "" && {
            list_of_directors: values.list_of_directors?.type
              ? allPromisesUrl1[9]
              : values.list_of_directors,
          }),

          lease_application_id: id,
        };
        const response = await axios.post(
          `${BASE_URL_FLEETOS}/v1/lease-application/secretarial-documents`,
          Form1Data
        );
        // Handle the response as needed
        console.log("Response:", response.data);
       
        handleNext();
        setLoading(false);
      } catch (error) {
        console.log("error", error);
        handleErrorResponse(error);
        setLoading(false);
      }
    },
  });
  const uploadAndCommitFile = (file) => {
    if (file && file.name !== "") {
      return new Promise(async (resolve, reject) => {
        const testData = await axios.post(
          `${BASE_URL_FLEETOS}/v1/shared.getSignedUrlForUploadPublic`,
          {
            fileName: file.name ?? file,
            type: "lease_application_documents",
          }
        );
        const headers = new Headers();
        headers.append("Content-Type", "application/octet-stream");
        // let resp = await new Promise((resolve, reject) => {
        //   const xhr = new window.XMLHttpRequest();
        //   xhr.open("PUT", testData?.data?.result?.data?.url);
        //   xhr.onload = resolve;
        //   xhr.onerror = reject;
        //   xhr.setRequestHeader("Content-Type", "application/octet-stream");
        //   xhr.send(file);
        // });

        resolve(testData?.data?.result?.data?.path);
      });
    } else {
      return Promise.resolve("");
    }
  };

  const handleFileUpload = async (name, file) => {
    formik.setFieldValue(`${name}`, file);
  };

  function extractFilename(filePath) {
    if (typeof filePath === "string") {
      const pathSegments = filePath.split("/");
      const filename = pathSegments[pathSegments.length - 1];
      return filename;
    } else {
      return "";
    }
  }

  console.log(data?.certificate_of_incorporate);
  console.log(formik.values);
  return (
    <>
      <form onSubmit={formik.handleSubmit} className="d-flex flex-column gap-5">
        <h3>Secretarial Documents</h3>
        <div className="d-flex  flex-column flex-md-row justify-content-between gap-4">
          <FileUpload
            labelTitle="Certificate of Incorporate"
            required={true}
            onFileSelect={(files) =>
              handleFileUpload("certificate_of_incorporate", files[0])
            }
            error={
              formik.touched.certificate_of_incorporate &&
              formik.errors.certificate_of_incorporate
            }
            selectedFile={formik.values.certificate_of_incorporate}
            value={extractFilename(formik.values.certificate_of_incorporate)}
            id={"certificate_of_incorporate"}
            title=" Click to upload "
            iconStyle={{
              width: 51,
              height: 51,
              color: "#828282",
              opacity: 0.3,
            }}
            // fileType={fileTypes}
            className="file-upload-customer w-100"
          />
          <FileUpload
            labelTitle="MSME Certificate"
            required={true}
            onFileSelect={(files) =>
              handleFileUpload("msme_certificate", files[0])
            }
            error={
              formik.touched.msme_certificate &&
              formik.errors.msme_certificate
            }
            selectedFile={formik.values.msme_certificate}
            value={extractNameAfterFirstUnderscore(
              formik.values.msme_certificate
            )}
            id={"msme_certificate"}
            title=" Click to upload "
            iconStyle={{
              width: 51,
              height: 51,
              color: "#828282",
              opacity: 0.3,
            }}
            // fileType={fileTypes}
            className="file-upload-customer w-100"
          />
        </div>
        <div className="d-flex flex-column flex-md-row justify-content-between gap-4">
          <FileUpload
            labelTitle="MOA Document"
            required={true}
            error={
              formik.touched.moa_docuemnt &&
              formik.errors.moa_docuemnt
            }
            onFileSelect={(files) => handleFileUpload("moa_docuemnt", files[0])}
            selectedFile={formik.values.moa_docuemnt}
            value={extractNameAfterFirstUnderscore(formik.values.moa_docuemnt)}
            id={"moa_docuemnt"}
            title=" Click to upload "
            iconStyle={{
              width: 51,
              height: 51,
              color: "#828282",
              opacity: 0.3,
            }}
            // fileType={fileTypes}
            className="file-upload-customer w-100"
          />
          <FileUpload
            labelTitle="AOA Document"
            required={true}
            error={
              formik.touched.aoa_document &&
              formik.errors.aoa_document
            }
            onFileSelect={(files) => handleFileUpload("aoa_document", files[0])}
            selectedFile={formik.values.aoa_document}
            value={extractNameAfterFirstUnderscore(formik.values.aoa_document)}
            id={"aoa_document"}
            title=" Click to upload "
            iconStyle={{
              width: 51,
              height: 51,
              color: "#828282",
              opacity: 0.3,
            }}
            // fileType={fileTypes}
            className="file-upload-customer w-100"
          />
        </div>
        <div className="d-flex flex-column flex-md-row justify-content-between gap-4">
          <FileUpload
            labelTitle="GST Certificate "
            required={true}
            error={
              formik.touched.gst_certificate &&
              formik.errors.gst_certificate
            }
            onFileSelect={(files) =>
              handleFileUpload("gst_certificate", files[0])
            }
            selectedFile={formik.values.gst_certificate}
            value={extractNameAfterFirstUnderscore(
              formik.values.gst_certificate
            )}
            id={"gst_certificate"}
            title=" Click to upload "
            iconStyle={{
              width: 51,
              height: 51,
              color: "#828282",
              opacity: 0.3,
            }}
            // fileType={fileTypes}
            className="file-upload-customer w-100"
          />

          <FileUpload
            labelTitle="Company PAN Card"
            required={true}
            onFileSelect={(files) =>
              handleFileUpload("company_pan_card", files[0])
            }
            error={
              formik.touched.company_pan_card &&
              formik.errors.company_pan_card
            }
            selectedFile={formik.values.company_pan_card}
            value={extractNameAfterFirstUnderscore(
              formik.values.company_pan_card
            )}
            id={"company_pan_card"}
            title=" Click to upload "
            iconStyle={{
              width: 51,
              height: 51,
              color: "#828282",
              opacity: 0.3,
            }}
            // fileType={fileTypes}
            className="file-upload-customer w-100"
          />
        </div>
        <div className="d-flex flex-column flex-md-row justify-content-between gap-4">
          <FileUpload
            labelTitle="Form 26AS"
            required={true}
            onFileSelect={(files) => handleFileUpload("form_26as", files[0])}
            selectedFile={formik.values.form_26as}
            value={extractNameAfterFirstUnderscore(formik.values.form_26as)}
            id={"form_26as"}
            error={
              formik.touched.form_26as &&
              formik.errors.form_26as
            }
            title=" Click to upload "
            iconStyle={{
              width: 51,
              height: 51,
              color: "#828282",
              opacity: 0.3,
            }}
            // fileType={fileTypes}
            className="file-upload-customer w-100"
          />

          <FileUpload
            labelTitle="List of Shareholders as on Date"
            required={true}
            onFileSelect={(files) =>
              handleFileUpload("list_of_shareholders", files[0])
            }
            error={
              formik.touched.list_of_shareholders &&
              formik.errors.list_of_shareholders
            }
            selectedFile={formik.values.list_of_shareholders}
            value={extractNameAfterFirstUnderscore(
              formik.values.list_of_shareholders
            )}
            id={"list_of_shareholders"}
            title=" Click to upload "
            iconStyle={{
              width: 51,
              height: 51,
              color: "#828282",
              opacity: 0.3,
            }}
            // fileType={fileTypes}
            className="file-upload-customer w-100"
          />
        </div>
        <div className="d-flex flex-column flex-md-row justify-content-between gap-4">
          <FileUpload
            labelTitle="Group Company Structure and Cross Holding Structure"
            required={true}
            onFileSelect={(files) =>
              handleFileUpload("group_company_structure", files[0])
            }
            error={
              formik.touched.group_company_structure &&
              formik.errors.group_company_structure
            }
            selectedFile={formik.values.group_company_structure}
            value={extractNameAfterFirstUnderscore(
              formik.values.group_company_structure
            )}
            id={"group_company_structure"}
            title=" Click to upload "
            iconStyle={{
              width: 51,
              height: 51,
              color: "#828282",
              opacity: 0.3,
            }}
            // fileType={fileTypes}
            className="file-upload-customer w-100"
          />

          <FileUpload
            labelTitle="List of Directors as on Date"
            required={true}
            onFileSelect={(files) =>
              handleFileUpload("list_of_directors", files[0])
            }
            error={
              formik.touched.list_of_directors &&
              formik.errors.list_of_directors
            }
            selectedFile={formik.values.list_of_directors}
            value={extractNameAfterFirstUnderscore(
              formik.values.list_of_directors
            )}
            id={"list_of_directors"}
            title=" Click to upload "
            iconStyle={{
              width: 51,
              height: 51,
              color: "#828282",
              opacity: 0.3,
            }}
            // fileType={fileTypes}
            className="file-upload-customer w-100"
          />
        </div>

        <div className="d-flex gap-3 justify-content-center">
          <Button label="Back" onClick={handleBack} />
          <Button label="Next" onClick={formik.handleSubmit} disabled={!formik.isValid} loading={loading}/>
        </div>
      </form>
    </>
  );
}
