import React from 'react'
import Carousel from "react-multi-carousel";
import f9 from "../../Images/nsrcel.png";
import f10 from "../../Images/thirdderivative.png";
import f11 from "../../Images/iimb.png";
import f12 from "../../Images/startups.png";
import f13 from "../../Images/iitdelhi.png";
import { useMediaQuery } from "react-responsive";
const Accelerators = () => {
    const isMobile = useMediaQuery({ maxWidth: 827 });
  const responsive = {
    tablet: {
      breakpoint: { max: 823, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 3,
      slidesToSlide: 3,
    },
  };
  const isMobileOrTablet = window.innerWidth <= 824;
  const responsive2 = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      showDots: false,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      showDots: false,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };
  const carouselStyle = {
    padding: "0 5px",
    margin: "0 -5px",
  };
  const itemStyle = {
    margin: "0 10px",
  };
  return (
   <>
     <div className="mb-5 mt-5">
        <div className="acce text-center">
          <h1>Know Our Accelerators</h1>
        </div>
        <div className="nsr-main">
          {isMobileOrTablet ? (
            <Carousel
              responsive={responsive}
              showDots={isMobile}
              arrows={false}
              className=" nsr-container d-flex w-100 pe-md-5 ps-md-5 paddingsmall mt-5 text-center ms-0 pb-4"
              autoPlay={isMobile}
              autoPlaySpeed={3000}
              infinite={true}
              style={carouselStyle}
            >
              <div style={itemStyle}>
                <img
                  src={f9}
                  alt="f9"
                  style={{ width: "4rem", height: "3rem" }}
                />
              </div>
              <div style={itemStyle}>
                <img
                  src={f10}
                  alt="f10"
                  style={{ width: "4rem", height: "3rem" }}
                />
              </div>
              <div style={itemStyle}>
                <img
                  src={f11}
                  alt="f11"
                  style={{ width: "4rem", height: "3rem" }}
                />
              </div>
              <div style={itemStyle}>
                <img
                  src={f12}
                  alt="f12"
                  style={{ width: "4rem", height: "3rem" }}
                />
              </div>
              <div style={itemStyle} className="pe-5">
                <img
                  src={f13}
                  alt="f13"
                  style={{ width: "6.5rem", height: "3rem" }}
                />
              </div>
            </Carousel>
          ) : (
            <div className=" nsr-container w-100 d-flex pe-md-5 ps-md-5 paddingsmall mt-5 text-center">
              <div>
                <img
                  src={f9}
                  alt="f9"
                  style={{ width: "6rem", height: "4rem" }}
                />
              </div>
              <div>
                <img
                  src={f10}
                  alt="f10"
                  style={{ width: "6rem", height: "4rem" }}
                />
              </div>
              <div>
                {" "}
                <img
                  src={f11}
                  alt="f11"
                  style={{ width: "6rem", height: "4rem" }}
                />
              </div>
              <div>
                {" "}
                <img
                  src={f12}
                  alt="f12"
                  style={{ width: "6rem", height: "4rem" }}
                />
              </div>
              <div>
                {" "}
                <img
                  src={f13}
                  alt="f13"
                  style={{ width: "8.5rem", height: "4rem" }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
   </>
  )
}
export default Accelerators