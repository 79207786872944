import React, { useEffect, useState } from "react";
import { MdElectricBolt } from "react-icons/md";
import { GoDash } from "react-icons/go";
import { CgCross } from "react-icons/cg";
import { FiChevronsRight } from "react-icons/fi";
import Moneybag from "../../Images/Moneybag.png";
import Dots from "../../Images/Dots.svg";
import { distanceArray, fuelPrice, mileageArray, noOfOperatingDays, otherFuelCost } from "../Home/constants";
import { numberWithCommas } from "../../utils";
import "./MobileMonthlySavingsCalculator.css";
import MobileButton from "../../Components/CustomComponent/MobileButton";

const MobileMonthlySavingsCalculator = ({ handleViewOwnershipCostComparison }) => {
    const [distanceRange, setDistanceRange] = useState(60);
    const [mileage, setMileage] = useState(30);

    // index
    let i = 0;

    useEffect(() => {
        const rangeInput = document.getElementById("distanceRange");
        // calculates the percentage to be marked as covered distance
        const value = (rangeInput.value-rangeInput.min)/(rangeInput.max-rangeInput.min)*100;
        // cystom styling the range backgound 
        rangeInput.style.background = 'linear-gradient(to right, #F6E859 0%, #F6E859 ' + value + '%, #5B93FF ' + value + '%, #5B93FF 100%)'
    }, [distanceRange]);

    useEffect(() => {
        const rangeInput = document.getElementById("mileage");
        // calculates the percentage to be marked as marked mileage
        const value = (rangeInput.value-rangeInput.min)/(rangeInput.max-rangeInput.min)*100;
        // cystom styling the range backgound 
        rangeInput.style.background = 'linear-gradient(to right, #F6E859 0%, #F6E859 ' + value + '%, #5B93FF ' + value + '%, #5B93FF 100%)'
    }, [mileage]);

    const fuelCost = {
        "3W": {
          Petrol:
            (fuelPrice["Petrol"] / mileage) *
            distanceRange *
            noOfOperatingDays,
          Diesel:
            (fuelPrice["Diesel"] / mileage) *
            distanceRange *
            noOfOperatingDays,
          CNG:
            (fuelPrice["CNG"] / mileage) *
            distanceRange *
            noOfOperatingDays,
        },
        "2W": {
          Petrol:
            (fuelPrice["Petrol"] / mileage) *
            distanceRange *
            noOfOperatingDays,
        },
    };

    // calculate the EV cost
    const calculateEvCost = () => {
        // Your EV cost calculation logic here
        // Example: Calculate total cost based on input values
        const totalCost = 13296.2882366206 + 1500 + 515.034722222222;
        const unitCost = 10;
        const batteryCapacity = 10;
        const fuelCost = (unitCost * batteryCapacity) / 120;
        const noOfOperatingDays = 26;
        const totalCostEv = fuelCost * noOfOperatingDays * distanceRange + totalCost;
        return totalCostEv;
    };
    const evCost = calculateEvCost();

    // calculate the other fuel cost
    const calculateTotalCost3W = (type) => {
        const total3WCost =
          fuelCost["3W"][type] +
          otherFuelCost["lease"] +
          otherFuelCost["maintenance"] +
          otherFuelCost["insurance"];
        return total3WCost;
    }
    // petrol cost
    const petrolCost = calculateTotalCost3W("Petrol");
    //cng cost
    const cngCost = calculateTotalCost3W("CNG");

    return (
        <div>
            {/* header */}
            <div className="mobile-monthly-calculator-header mb-3">
                <img src={Moneybag} alt="savings" />
                Aapki Savings ki Jholi
            </div>
            <div className="monthly-savings-calculator mt-3">
                {/* label */}
                <div className="monthly-savings-calculator-label d-flex justify-content-between align-items-center">
                    <span>
                        Enter daily km
                    </span>
                    <div className="monthly-savings-calculator-distance d-flex justify-content-start align-items-center">
                        <div>{distanceRange}</div>
                        <div className="monthly-savings-calculator-distance-unit">km</div>
                    </div>
                </div>
                {/* distance range slider */}
                <div className="mt-3">
                    <input 
                        type="range" 
                        className="monthly-savings-calculator-range"
                        value={distanceRange} 
                        onChange={(e) => setDistanceRange(e.target.value)}
                        min={0} 
                        max={120} 
                        id="distanceRange"
                    />
                </div>
                {/* distance range marker */}
                <div className="mobile-range-marker-container d-flex justify-content-between align-items-center">
                    <div className="d-flex justify-content-between align-items-center">
                        <CgCross />
                        <GoDash />
                        <GoDash />
                        <GoDash />
                        <GoDash />
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                        <CgCross />
                        <GoDash />
                        <GoDash />
                        <GoDash />
                        <GoDash />
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                        <CgCross />
                        <GoDash />
                        <GoDash />
                        <GoDash />
                        <GoDash />
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                        <CgCross />
                        <GoDash />
                        <GoDash />
                        <GoDash />
                        <GoDash />
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                        <CgCross />
                        <GoDash />
                        <GoDash />
                        <GoDash />
                        <GoDash />
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                        <CgCross />
                        <GoDash />
                        <GoDash />
                        <GoDash />
                        <GoDash />
                        <CgCross />
                    </div>
                </div>
                <div className="mobile-range-marker-marks d-flex justify-content-between align-items-center">
                    {distanceArray.map((distance) => (
                        <div>
                            {distance}
                        </div>
                    ))}
                </div>
                {/* label */}
                <div className="monthly-savings-calculator-label d-flex justify-content-between align-items-center mt-5">
                    <span>
                        Mileage
                    </span>
                </div>
                {/* mileage range slider */}
                <div className="mt-3">
                    <input 
                        type="range" 
                        className="monthly-savings-calculator-range"
                        value={mileage} 
                        onChange={(e) => setMileage(e.target.value)}
                        min={0} 
                        max={60} 
                        id="mileage"
                    />
                </div>
                {/* mileage range marker */}
                <div className="mobile-range-marker-container d-flex justify-content-between align-items-center">
                    <div className="d-flex justify-content-between align-items-center">
                        <CgCross />
                        <GoDash />
                        <GoDash />
                        <GoDash />
                        <GoDash />
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                        <CgCross />
                        <GoDash />
                        <GoDash />
                        <GoDash />
                        <GoDash />
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                        <CgCross />
                        <GoDash />
                        <GoDash />
                        <GoDash />
                        <GoDash />
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                        <CgCross />
                        <GoDash />
                        <GoDash />
                        <GoDash />
                        <GoDash />
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                        <CgCross />
                        <GoDash />
                        <GoDash />
                        <GoDash />
                        <GoDash />
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                        <CgCross />
                        <GoDash />
                        <GoDash />
                        <GoDash />
                        <GoDash />
                        <CgCross />
                    </div>
                </div>
                <div className="mobile-range-marker-marks d-flex justify-content-between align-items-center">
                    {mileageArray.map((mileage) => (
                        <div>
                            {mileage}
                        </div>
                    ))}
                </div>
                {/* monthly savings section */}
                {/* header */}
                <div className="mobile-monthly-savings-header w-100 d-flex justify-content-between align-items-center mt-5">
                    <img src={Dots} alt="dot" />
                    <span>Monthly Savings</span>
                    <img src={Dots} alt="dot" />
                </div>
                {/* body */}
                <div className="mobile-monthly-savings-body w-100 d-flex justify-content-between align-items-center">
                    <div className="text-center">
                        <div className="heading w-100">
                            Petrol
                        </div>
                        <div className="body w-100">
                            ₹&nbsp;{numberWithCommas(petrolCost.toFixed(0))}
                        </div>
                    </div>
                    <div className="text-center middle-child">
                        <div className="heading w-100">
                            EV <MdElectricBolt style={{ color: "#F6E859" }} />
                        </div>
                        <div className="body w-100">
                            ₹&nbsp;{numberWithCommas(evCost.toFixed(0))}
                        </div>
                    </div>
                    <div className="text-center">
                        <div className="heading w-100">
                            CNG
                        </div>
                        <div className="body w-100">
                            ₹&nbsp;{numberWithCommas(cngCost.toFixed(0))}
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-5">
                <MobileButton
                    text="Compare Ownership Cost"
                    icon={<FiChevronsRight />}
                    iconPosition="end"
                    handleClick={handleViewOwnershipCostComparison}
                />
            </div>
        </div>
    )
};

export default MobileMonthlySavingsCalculator;