import axios from "axios";
import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link, useLocation } from "react-router-dom";
import Ecosystem from "../../Components/Ecosystem";
import Parameter from "../../Components/Parameter";
import EVCalculator from "../HomeComp/EVCalculator";
import Process from "../VehicleListComp/Indiviual/IndividualComp/Process";
import SmartWaySlider from "../VehicleListComp/Indiviual/IndividualComp/SmartWaySlider";
import "../VehicleListComp/Indiviual/VehicleIndividual.css";
import "./Market.css";
import { BASE_URL } from "../../repository/api";


export default function MarketingVehicle() {
  const location = useLocation();
  const sectionStyle = {
    background: "linear-gradient(129.14deg, #67c2a5 -5.53%, #1fb486 117.63%)",
    // padding: "72px",
  };
  // const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes("marketingVehicle")) {
      document.querySelector(".header").style.display = "none";
      document.querySelector(".footer-color").style.display = "none";

    }
  }, [location.pathname]);
  const [data, setData] = useState(null);
  const [selectedImage, setSelectedImage] = useState(0);
  console.log("vv", selectedImage);
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const rresponsive = {
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 2,
      slidesToSlide: 2,
    },
  };

  const searchParams = new URLSearchParams(location.search);
  const oemParam = searchParams.get("oem");

  const id = searchParams.get("id");
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (oemParam) {
          const response = await axios.get(
            `${BASE_URL}/marketing-data/?oem=${oemParam}`
          );
          setData(response.data.data);
        } else {
          console.error("OEM parameter not found in the URL");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [location.search]);

  if (!data) {
    return <p>Loading...</p>;
  }

  const maxLength = 25;

  let truncatedSentence =
    data?.vehicle?.thermalManagement &&
    data?.vehicle?.thermalManagement.slice(0, maxLength) + "...";
  const htmlString = `<h4 class="view-space" style="font-size:12px!important;">
      ${truncatedSentence}
      <span class="tooltiptext">${data?.vehicle?.thermalManagement}</span>
    </h4>`;

  const handleImageClick = (index) => {
    setSelectedImage(index);
    console.log(index);
  };

  const handleCarouselBeforeChange = (oldIndex, newIndex) => {
    const numPhotos = data?.vehicle?.photos?.length;

    const direction = oldIndex > newIndex.currentSlide ? 1 : -1;
    let adjustedIndex = selectedImage + direction;

    if (adjustedIndex < 0) {
      adjustedIndex = numPhotos - 1;
    } else if (adjustedIndex >= numPhotos) {
      adjustedIndex = 0;
    }

    setSelectedImage(adjustedIndex);
  };

  const whatsapp_url =
    "https://api.whatsapp.com/send/?phone=919971007201&text&type=phone_number&app_absent=0";
  return (
    <div className="markk" style={{ background: "white" }}>
      <div
        className="blue_top_nav market-nav sticky-top mb-4"
        style={{ color: data?.data?.oem?.colorcode }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-lg-8 col-8 col-sm-8 left-div align justify-content-start flex-column align-items-start">
              <div>
                <img
                  src={data?.oem?.picture}
                  alt=""
                  width="120px"
                  height="30px"
                  className="img-fluid"
                />
              </div>
              <p>FleetLease powered by Alt Mobility.</p>
            </div>
            <div className="col-md-4 col-lg-4 col-4 col-sm-4 right-div align justify-content-end">
              <a href={`/enquire/?oem=${oemParam}`}>
                <button className="green-btn">Lease Now</button>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="Indivi-page" style={{ background: "white" }}>
        <div>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <nav
                  aria-label="breadcrumb d-lg-none d-block d-md-none d-sm-block"
                  style={{ "--bs-breadcrumb-divider": "'>'" }}
                >
                  <ol className="breadcrumb justify-content-lg-start justify-content-center mb-lg-auto mb-0">
                    <li className="breadcrumb-item">
                      <Link to="/fleet_leasing">FleetLease</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      {/* {data?.oem?.name} */}
                      {data?.vehicle?.vehicle?.oem}
                    </li>
                  </ol>
                </nav>
              </div>
            </div>

            <div className="row three d-flex justify-content-between  pt-4">
              <div
                className=" first ncol-lg-3 d-flex flex-column gap-2"
                style={{
                  transform: "translate3d(0px, 0px, 0px)",
                  transition: "all 0s ease 0s",
                }}
              >
                {data?.vehicle?.photos?.map((photo, index) => (
                  <div
                    className={`owl-item item-active ${
                      selectedImage === index ? "item-active-st" : ""
                    }`}
                    key={index}
                    onClick={() => handleImageClick(index)}
                  >
                    <div className="item separate-vehicle">
                      <img
                        key={index}
                        src={photo.photo}
                        alt=""
                        style={{
                          width: "100%",
                          height: "75px",
                          objectFit: "contain",
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>

              <div className=" second col-lg-3 specific-vehicle">
                <Carousel
                  swipeable={false}
                  draggable={false}
                  showDots={false}
                  responsive={responsive}
                  ssr={true}
                  infinite={true}
                  autoPlay={false}
                  autoPlaySpeed={1000}
                  arrows={true}
                  beforeChange={(oldIndex, newIndex) =>
                    handleCarouselBeforeChange(oldIndex, newIndex)
                  }



                >
                  {data?.vehicle?.photos?.map((photo, index) => (
                    <img
                      // key={index}
                      // src={photo.photo}
                      src={data?.vehicle?.photos?.[selectedImage]?.photo}
                      alt=""
                      style={{
                        width: "100%",
                        height: "auto",
                        objectFit: "contain",
                      }}
                    />
                  ))}
                </Carousel>
              </div>

              <div className=" third col-lg-6">
                <div className="box-warp">
                  <span className="vehicle-model  oem-name">
                    {/* <img
                      className="image-adjust"
                      src={data?.oem?.picture}
                      width="30px"
                      height="px"
                      alt="OEM Logo"
                    /> */}
                    {data?.oem?.name}
                  </span>
                  <h2 className="h2-heading ">
                    {data?.vehicle?.vehicle?.vehicleModel}
                  </h2>
                  <ul className="align list-unstyled mb-0 justify-content-lg-start justify-content-md-start justify-content-md-start justify-content-start">
                    <li className="box-warp-li active ">
                      <div className="ps-0" style={{fontSize:"14px"}}>
                        Available in 15 days
                      </div>
                    </li>
                    <li className="box-warp-li">
                      <div className="ms-2 m-lg-0" style={{fontSize:"14px"}}>
                        Launched in 2018
                      </div>
                    </li>
                    <li className="box-warp-li ps-0 ">
                      <div style={{fontSize:"14px"}}>ICAT Approved</div>
                    </li>
                    <li className="box-warp-li">
                      <div className = "border-end-0 ms-2 m-lg-0" style={{fontSize:"14px"}}>
                        AIS156 Certified
                      </div>
                    </li>
                  </ul>

                  <div className="h5-heading active-fleet mt-4">
                    <p>
                      100+ <span>active fleets pan India.</span>
                    </p>
                  </div>
                  <div className="align price-box justify-content-between mt-4">
                    <div className="d-inline-block float-start">
                      <p
                        className="caption-fs mb-1"
                        style={{ fontWeight: "500" }}
                      >
                        Monthly Lease{" "}
                        <span className="d-lg-inline-block d-none">
                          starting
                        </span>{" "}
                        from
                      </p>
                      <a
                        href={whatsapp_url}
                        target="blank"
                        className="blue-color contact-button-lease"
                        style={{
                          fontWeight: "500",
                          background:
                            "linear-gradient(129.14deg, rgb(103, 194, 165) -5.53%, rgb(31, 180, 134) 117.63%)",
                          borderRadius: "8px",
                          color: "#fff",
                          padding: "0px 8px",
                        }}
                      >
                        Contact Us
                      </a>
                    </div>
                    <div className="float-end text-end ps-2">
                      <a href={`/Request/?id=${id}`} className="green-btn">
                        Request To Lease{" "}
                        <img
                          className="tp"
                          src="./images/chevron-right.svg"
                          height="13"
                          alt="Chevron Right"
                        />
                      </a>
                    </div>
                  </div>

                  <div
                    className="container"
                    style={{ padding: "0px", marginTop: "2rem" }}
                  >
                    <div className="laptop d-lg-block d-none d-sm-none d-md-none">
                      <ul
                        className="d-flex   list-unstyled mb-0 last-li "
                        style={{
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <li className="box-warp-li ps-0 ">
                          <span className="green-circle"></span>
                          <span className="smail-caption">
                            Loading capacity
                          </span>
                          <h4>
                            {data.vehicle.vehicle.loadingCapacity > 10
                              ? `${data.vehicle.vehicle.loadingCapacity} Cu feet`
                              : `${
                                  data.vehicle.vehicle.loadingCapacity || 0
                                } Seated`}
                          </h4>
                        </li>
                        <li className="box-warp-li ps-0 ">
                          <span className="green-circle"></span>
                          <span className="smail-caption">
                            Battery Capacity
                          </span>
                          <h4>{data.vehicle.batteryCapacity || 0}</h4>
                        </li>
                        <li className="box-warp-li ps-0 ">
                          <span className="green-circle"></span>
                          <span className="smail-caption">Fast Charging</span>
                          <h4>
                            {data.vehicle.fastChargingTime !== null &&
                            data.vehicle.fastChargingTime !== 0
                              ? `${data.vehicle.fastChargingTime} mins`
                              : "-"}
                          </h4>
                        </li>

                        <li className="box-warp-li ps-0 ">
                          <span className="green-circle"></span>
                          <span className="smail-caption">
                            Thermal Management
                          </span>
                          <div
                            dangerouslySetInnerHTML={{ __html: htmlString }}
                          />
                        </li>
                      </ul>
                      <hr />
                      <ul
                        className="d-flex list-unstyled mb-0 last-li pt-0"
                        style={{ justifyContent: "space-between" }}
                      >
                        <li className="box-warp-li ps-0 ">
                          <span className="green-circle"></span>
                          <span className="smail-caption">Payload</span>
                          <h4>{data.vehicle.vehicle.payload || 0} kgs</h4>
                        </li>
                        <li className="box-warp-li ps-0 ">
                          <span className="green-circle"></span>
                          <span className="smail-caption">Max Speed</span>
                          <h4>{data.vehicle.maxSpeed || 0} kmph</h4>
                        </li>
                        <li className="box-warp-li ps-0 ">
                          <span className="green-circle"></span>
                          <span className="smail-caption">
                            Range W/ Payload
                          </span>
                          <h4>{data.vehicle.rangeWPayload || 0} kgs</h4>
                        </li>
                        <li className="box-warp-li ps-0 ">
                          <span className="green-circle"></span>
                          <span className="smail-caption">
                            Range W/O Payload
                          </span>
                          <h4>
                            {data.vehicle.vehicle.rangeWOPayload !==
                              undefined &&
                            data.vehicle.vehicle.rangeWOPayload !== 0
                              ? `${data.vehicle.vehicle.rangeWOPayload} kgs`
                              : "-"}
                          </h4>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mobile-slider5 mobile mt-4 owl-carousel owl-theme owl-loaded owl-drag">
              <div className="owl-stage-outer">
                <div className="owl-stage gap-5">
                  <div className="owl-item">
                    <div className="item">
                      <Carousel
                        swipeable={false}
                        draggable={false}
                        showDots={true}
                        responsive={rresponsive}
                        ssr={true}
                        infinite={false}
                        autoPlay={false}
                        arrows={false}
                      >
                        <div className="box-warp-li  mobile-li-in">
                          <span className="green-circle"></span>
                          <span className="smail-caption">
                            Seating Capacity
                          </span>
                          <h4>{data.seatingCapacity || 0} Seated</h4>
                        </div>
                        <div className="box-warp-li  mobile-li-in">
                          <span className="green-circle"></span>
                          <span className="smail-caption">
                            Battery Capacity
                          </span>
                          <h4>{data.batteryCapacity || 0}</h4>
                        </div>
                        <div className="box-warp-li  mobile-li-in">
                          <span className="green-circle"></span>
                          <span className="smail-caption">Fast Charging</span>
                          <h4>
                            {data.fastChargingTime !== null &&
                            data.fastChargingTime !== 0
                              ? `${data.fastChargingTime} mins`
                              : "-"}
                          </h4>
                        </div>
                        <div className="box-warp-l mobile-li-in">
                          <span className="green-circle"></span>
                          <span className="smail-caption">
                            Thermal Management
                          </span>
                          <div
                            dangerouslySetInnerHTML={{ __html: htmlString }}
                          />
                        </div>
                        <div className="box-warp-li mobile-li-in ">
                          <span className="green-circle"></span>
                          <span className="smail-caption">Payload</span>
                          <h4>{data.vehicle.payload || 0} kgs</h4>
                        </div>
                        <div className="box-warp-li mobile-li-in">
                          <span className="green-circle"></span>
                          <span className="smail-caption">Max Speed</span>
                          <h4>{data.maxSpeed || 0} kmph</h4>
                        </div>
                        <div className="box-warp-li mobile-li-in ">
                          <span className="green-circle"></span>
                          <span className="smail-caption">
                            Range W/ Payload
                          </span>
                          <h4>{data.rangeWPayload || 0} kgs</h4>
                        </div>
                        <div className="box-warp-li mobile-li-in ">
                          <span className="green-circle"></span>
                          <span className="smail-caption">
                            Range W/O Payload
                          </span>
                          <h4>
                            {data.vehicle.rangeWOPayload !== undefined &&
                            data.vehicle.rangeWOPayload !== 0
                              ? `${data.vehicle.rangeWOPayload} kgs`
                              : "-"}
                          </h4>
                        </div>
                      </Carousel>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div className="container">
                <div
                  className="row mt-5 d-flex "
                  style={{ justifyContent: "end" }}
                >
                  <div
                    className=" dimensions
        mt-5 tabs"
                  >
                    <h3 className="h3-heading">
                      {data.vehicle.oem || "Vehicle"} Specifications
                    </h3>
                    <div className="scroll-frame">
                      <div className="scroll-tab">
                        <ul
                          className="nav nav-pills gap-3 flex-nowrap"
                          id="pills-tab"
                          role="tablist"
                        >
                          <li className=" pb-lg-3 mb-0" role="presentation">
                            <button
                              className="nav-link active"
                              id="pills-1-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#pills-1"
                              type="button"
                              role="tab"
                              aria-controls="pills-1"
                              aria-selected="true"
                            >
                              Dimensions
                            </button>
                          </li>
                          <li className=" pb-lg-3 mb-0" role="presentation">
                            <button
                              className="nav-link"
                              id="pills-2-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#pills-2"
                              type="button"
                              role="tab"
                              aria-controls="pills-2"
                              aria-selected="false"
                              tabindex="-1"
                            >
                              Drivetrain
                            </button>
                          </li>
                          <li className=" pb-lg-3 mb-0" role="presentation">
                            <button
                              className="nav-link"
                              id="pills-3-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#pills-3"
                              type="button"
                              role="tab"
                              aria-controls="pills-3"
                              aria-selected="false"
                              tabindex="-1"
                            >
                              Charging
                            </button>
                          </li>
                          <li className=" pb-lg-3 mb-0" role="presentation">
                            <button
                              className="nav-link"
                              id="pills-4-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#pills-4"
                              type="button"
                              role="tab"
                              aria-controls="pills-4"
                              aria-selected="false"
                              tabindex="-1"
                            >
                              Vehicle Warranty
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div
                      className="tab-content mt-lg-4 mt-2"
                      id="pills-tabContent"
                    >
                      <div
                        className="tab-pane fade show active"
                        id="pills-1"
                        role="tabpanel"
                        aria-labelledby="pills-home-tab"
                      >
                        <ul className="list-unstyled">
                          <li className="tab-list">
                            <div className="row">
                              <div className="col-8">
                                <h5 className="h5-heading fw-light">
                                  Kerb Weight
                                </h5>
                              </div>
                              <div className="col-4 align justify-content-end">
                                <h5 className="h5-heading value">
                                  {data.vehicle.kerbWeight || 0} kgs
                                </h5>
                              </div>
                            </div>
                          </li>
                          <li className="tab-list">
                            <div className="row">
                              <div className="col-8">
                                <h5 className="h5-heading fw-light">
                                  Overall Width
                                </h5>
                              </div>
                              <div className="col-4 align justify-content-end">
                                <h5 className="h5-heading value">
                                  {data.vehicle.overallWidth || 0} ft
                                </h5>
                              </div>
                            </div>
                          </li>
                          <li className="tab-list">
                            <div className="row">
                              <div className="col-8">
                                <h5 className="h5-heading fw-light">
                                  Overall Height
                                </h5>
                              </div>
                              <div className="col-4 align justify-content-end">
                                <h5 className="h5-heading value">
                                  {data.vehicle.overallHeight || 0} ft
                                </h5>
                              </div>
                            </div>
                          </li>
                          <li className="tab-list">
                            <div className="row">
                              <div className="col-8">
                                <h5 className="h5-heading fw-light">
                                  Overall Length
                                </h5>
                              </div>
                              <div className="col-4 align justify-content-end">
                                <h5 className="h5-heading value">
                                  {data.vehicle.overallLength || 0} ft
                                </h5>
                              </div>
                            </div>
                          </li>
                          <li className="tab-list">
                            <div className="row">
                              <div className="col-8">
                                <h5 className="h5-heading fw-light">
                                  Gross Vehicle Weight
                                </h5>
                              </div>
                              <div className="col-4 align justify-content-end">
                                <h5 className="h5-heading value">
                                  {data.vehicle.grossVehicleWeight || 0} kgs
                                </h5>
                              </div>
                            </div>
                          </li>
                          <li className="tab-list">
                            <div className="row">
                              <div className="col-8">
                                <h5 className="h5-heading fw-light">
                                  Container Dimension (lxbxh)ft
                                </h5>
                              </div>
                              <div className="col-4 align justify-content-end">
                                <h5 className="h5-heading value">
                                  {data.vehicle.containerDimension || 0}
                                </h5>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="pills-2"
                        role="tabpanel"
                        aria-labelledby="pills-profile-tab"
                      >
                        <span className="tab-list-head">Motor Information</span>
                        <ul className="list-unstyled">
                          <li className="tab-list">
                            <div className="row">
                              <div className="col-8">
                                <h5 className="h5-heading fw-light">
                                  Max Speed
                                </h5>
                              </div>
                              <div className="col-4 align justify-content-end">
                                <h5 className="h5-heading">
                                  {data.vehicle.maxSpeed || 0} secs
                                </h5>
                              </div>
                            </div>
                          </li>
                          <li className="tab-list">
                            <div className="row">
                              <div className="col-8">
                                <h5 className="h5-heading fw-light">
                                  Power(peak)
                                </h5>
                              </div>
                              <div className="col-4 align justify-content-end">
                                <h5 className="h5-heading">
                                  {data.vehicle.powerPeak || 0} km
                                </h5>
                              </div>
                            </div>
                          </li>
                          <li className="tab-list">
                            <div className="row">
                              <div className="col-8">
                                <h5 className="h5-heading fw-light">
                                  Max Gradability
                                </h5>
                              </div>
                              <div className="col-4 align justify-content-end">
                                <h5 className="h5-heading">
                                  {data.vehicle.maxGradability || 0} km
                                </h5>
                              </div>
                            </div>
                          </li>
                          <li className="tab-list">
                            <div className="row">
                              <div className="col-8">
                                <h5 className="h5-heading fw-light">
                                  Motor Power
                                </h5>
                              </div>
                              <div className="col-4 align justify-content-end">
                                <h5 className="h5-heading">
                                  {data.vehicle.motorPower || 0} watts
                                </h5>
                              </div>
                            </div>
                          </li>
                          <li className="tab-list">
                            <div className="row">
                              <div className="col-8">
                                <h5 className="h5-heading fw-light">
                                  Transmission
                                </h5>
                              </div>
                              <div className="col-4 align justify-content-end">
                                <h5 className="h5-heading">
                                  {data.vehicle.transmission || 0}
                                </h5>
                              </div>
                            </div>
                          </li>
                          <li className="tab-list">
                            <div className="row">
                              <div className="col-8">
                                <h5 className="h5-heading fw-light">
                                  Torque (Peak)
                                </h5>
                              </div>
                              <div className="col-4 align justify-content-end">
                                <h5 className="h5-heading">
                                  {data.vehicle.peakTorque || 0} km
                                </h5>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="pills-3"
                        role="tabpanel"
                        aria-labelledby="pills-contact-tab"
                      >
                        <span className="tab-list-head">
                          Charger Information
                        </span>
                        <ul className="list-unstyled">
                          <li className="tab-list">
                            <div className="row">
                              <div className="col-8">
                                <h5 className="h5-heading fw-light">
                                  Charging Time
                                </h5>
                              </div>
                              <div className="col-4 align justify-content-end">
                                <h5 className="h5-heading">
                                  {data.vehicle.chargingTime || 0} mins
                                </h5>
                              </div>
                            </div>
                          </li>
                          <li className="tab-list">
                            <div className="row">
                              <div className="col-8">
                                <h5 className="h5-heading fw-light">
                                  Charging Compatibility
                                </h5>
                              </div>
                              <div className="col-4 align justify-content-end">
                                <h5 className="h5-heading text-nowrap">
                                  {data.vehicle.chargingCompatibility || 0}
                                </h5>
                              </div>
                            </div>
                          </li>
                          <li className="tab-list">
                            <div className="row">
                              <div className="col-8">
                                <h5 className="h5-heading fw-light">
                                  Fast Charging
                                </h5>
                              </div>
                              <div className="col-4 align justify-content-end">
                                <h5 className="h5-heading">
                                  {data.vehicle.fastChargingTime || 0} mins
                                </h5>
                              </div>
                            </div>
                          </li>
                          <li className="tab-list">
                            <div className="row">
                              <div className="col-8">
                                <h5 className="h5-heading fw-light">
                                  On-board Charger
                                </h5>
                              </div>
                              <div className="col-4 align justify-content-end">
                                <h5 className="h5-heading">
                                  {data.vehicle.onBoardACCharger || false}
                                </h5>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="pills-4"
                        role="tabpanel"
                        aria-labelledby="pills-contact-tab"
                      >
                        <span className="tab-list-head">Warranty Details</span>
                        <ul className="list-unstyled">
                          <li className="tab-list">
                            <div className="row">
                              <div className="col-8">
                                <h5 className="h5-heading fw-light">Cycles</h5>
                              </div>
                              <div className="col-4 align justify-content-end">
                                <h5 className="h5-heading">
                                  {data.vehicle.warrentyCycles || 0} secs
                                </h5>
                              </div>
                            </div>
                          </li>
                          <li className="tab-list">
                            <div className="row">
                              <div className="col-8">
                                <h5 className="h5-heading fw-light">KM</h5>
                              </div>
                              <div className="col-4 align justify-content-end">
                                <h5 className="h5-heading">
                                  {data.vehicle.warrentyKMS || 0}
                                </h5>
                              </div>
                            </div>
                          </li>
                          <li className="tab-list">
                            <div className="row">
                              <div className="col-8">
                                <h5 className="h5-heading fw-light">Years</h5>
                              </div>
                              <div className="col-4 align justify-content-end">
                                <h5 className="h5-heading">
                                  {data.vehicle.warrentyYears || 0}
                                </h5>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <EVCalculator />
        <Parameter />
        <Ecosystem />
        <SmartWaySlider />
        <Process />
        <section className="mt-72 pb-max pt-max market-green green" style={sectionStyle}>
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-12 col-sm-12 col-12 text-sm-center text-lg-start text-center d-flex flex-column gap-2">
                <h3
                  className="h3-heading  explore text-lg-start text-center"
                  style={{ color: "#fff", fontWeight: "600" }}
                >
                  Explore more about leasing or financing with Alt Mobility
                </h3>
                <p className="para text-lg-start text-center text-white mt-lg-4">
                  To know more about the Alt Mobility X Hero Electric
                  collaboration and more about our services, contact us and our
                  experts will get back to you with the best offers!
                </p>
                <div className="market-button d-flex">
                  <button
                    className="white-btns narrow text-start"
                    style={{
                      background: "#fff",
                      padding: "16px",
                      borderRadius: "4px",
                      border: "none",
                      width: "max-content",
                    }}
                  >
                    Submit Details &gt;
                  </button>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                <a href="https://alt.codeaxia.in/marketing-landing-page.html">
                  <img
                    src="./markting_files/image 2.png"
                    alt=""
                    className="img-fluid"
                  />
                </a>
              </div>
            </div>
            <p className="para text-center fw-normal text-white">
              Fleet Lease powered by Alt Mobility
            </p>
          </div>
        </section>
      </div>
    </div>
  );
}

