// PasswordReset.js

import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import "./Login.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URL_UAT } from "../repository/api";

const PasswordReset = () => {
  const [loading, setLoading] = useState(false);
  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email address").required("Required"),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .required("Required"),
    new_password: Yup.string()
      .min(8, "New password must be at least 8 characters")
      .required("Required"),
  });
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      new_password: "",
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setLoading(true);
      try {
        const response = await axios.post(
          `${BASE_URL_UAT}/v2/auth/customer/reset-password`,
          values
        );

        if (response.status === 200) {
          toast.success(response?.data?.message);
          console.log("Password reset successful");
          setLoading(false);
        } else {
          console.error("Password reset failed");
        }
      } catch (error) {
        console.error("API request error:", error);
        setLoading(false);
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <div>
      {/* <h2>Password Reset</h2>
      <form onSubmit={formik.handleSubmit}>
        <label>Email:
          <input type="email" name="email" value={formik.values.email} onChange={formik.handleChange} />
        </label>
        <label>Current Password:
          <input type="password" name="password" value={formik.values.password} onChange={formik.handleChange} />
        </label>
        <label>New Password:
          <input type="password" name="new_password" value={formik.values.new_password} onChange={formik.handleChange} />
        </label>
        <button type="submit" disabled={formik.isSubmitting}>Reset Password</button>
      </form> */}
      <div
        style={{
          maxWidth: "400px",
          margin: "auto",
          padding: "20px",
          border: "1px solid #ccc",
          borderRadius: "8px",
          marginTop: "8rem",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <h2 style={{ textAlign: "center", marginBottom: "20px" }}>
          {" "}
          Reset password
        </h2>
        <form
          style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
          onSubmit={formik.handleSubmit}
        >
          <div className="d-flex flex-column  ">
            <label>
              Email<span style={{ color: "red" }}>*</span>{" "}
            </label>
            <input
              type="email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              style={{
                padding: "8px",
                borderRadius: "4px",
                border: "1px solid #ccc",
                width: "100%",
              }}
            />
            <p style={{ fontSize: "12px", color: "red" }}>
              {formik.touched.email && formik.errors.email}
            </p>
          </div>
          <div className="d-flex flex-column ">
            <label>
              Current Password<span style={{ color: "red" }}>*</span>
            </label>
            <input
              type="password"
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              style={{
                padding: "8px",

                borderRadius: "4px",
                border: "1px solid #ccc",
                width: "100%",
              }}
            />
            <p style={{ fontSize: "12px", color: "red" }}>
              {formik.touched.password && formik.errors.password}
            </p>
          </div>
          <div className="d-flex flex-column ">
            <label>
              New Password<span style={{ color: "red" }}>*</span>
            </label>
            <input
              type="password"
              name="new_password"
              value={formik.values.new_password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              style={{
                padding: "8px",
                borderRadius: "4px",
                border: "1px solid #ccc",
                width: "100%",
              }}
            />
            <p style={{ fontSize: "12px", color: "red" }}>
              {formik.touched.new_password && formik.errors.new_password}
            </p>
          </div>

          <button
            className="login-btn"
            disabled={formik.isSubmitting}
            onClick={formik.handleSubmit}
            type="submit"
            style={{
              color: "white",
              padding: "10px",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            {loading && (
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />
            )}{" "}
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default PasswordReset;
