// import React from 'react';

// const Button = ({ label, onClick, style , disable}) => {
//   return (
//     <button
//       onClick={onClick}
//       style={{
//         borderRadius: '4px',
//         padding: '0.5rem 1.5rem',
//         color: '#fff',
//         background: 'linear-gradient(129.14deg, #67c2a5 -5.53%, #1fb486 117.63%)',
//         border: '1px solid transparent',
//         ...style,
//       }}
//     >
//       {label}
//     </button>
//   );
// };

// export default Button;

import React, { useState } from "react";
import "./Button.css";
const Button = ({ label, onClick, style, disabled, loading }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const buttonStyle = {
    borderRadius: "4px",
    padding: "0.5rem 1.5rem",
    color: "#fff",
    background: isHovered
      ? "  #0a2439 "
      : "linear-gradient(129.14deg, #67c2a5 -5.53%, #1fb486 117.63%)",
    border: "1px solid transparent",
    transition: "background 0.3s ease-in-out",
    ...style,
  };

  return (
    <div className="Custom-button">
    <button
      onClick={onClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={buttonStyle}
      disabled={loading ? true : disabled}
    >
      {label} {loading && <i class="fa fa-spinner fa-spin"></i>}
    </button>
    </div>
  );
};

export default Button;
