import React, { useState } from "react";
import "./Altecosystem.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const Altecosystem = () => {
  const [activeTab, setActiveTab] = useState("oem");

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 6,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  };

  const tabImages = {
    oem: [
      "./images/Euler.jpg",
      "./images/Altigreen-Drive-Electric-logo-01-1.png",
      "./images/Hero-Electric-Bike-Logo-PNG.png",
      "./images/Piaggio.png",
      "./images/Grevol.png",
      "./home_files/electricmahindra-removebg-preview.png",
      "./home_files/Okaya-EV-logo (2).jpg",
      "./images/exponent.png",
      "./images/one-electric-logo-400x400-text-2.png",
      "./images/bgauss-electric-scooters-kranti-chowk-aurangabad-maharashtra-battery-operated-scooter-dealers-sigimrdvsy.webp",

      // Add more images for the OEMs tab
    ],
    fleet: [
      "./home_files/entoo-removebg-preview.png",
      "./home_files/letstransport.jpg",
      "./home_files/Magentalogo.svg",
      "./home_files/evv.png",
      "./home_files/lithium.jpg",
      "./home_files/cabt.jpg",
      "./home_files/zypp-electric-removebg-preview.png",
      "./home_files/fyn.jpg",
      "./home_files/fullfily.jpg",
      // Add more images for the Fleet Operators tab
    ],
    charging: [
      "./home_files/kazam.png",
      "./home_files/exicom.png",
      "./home_files/statiq.png",
      "./home_files/exponent.png",
      "./home_files/kazam.png",
      "./home_files/exicom.png",
      "./home_files/statiq.png",
      "./home_files/exponent.png",
      // Add more images for the Charging Point Providers tab
    ],
    finance: [
      "./home_files/Muffin Green Finance.png",
      "./home_files/Clime Finance.jpg",
      "./home_files/IDFC Bank.png",
      "./home_files/Deutsche Bank.png",
      "./home_files/MBSI.jpg",
      "./home_files/AMU Leasing.png",
      "./home_files/Mahindra Finance.png",
      "./home_files/automotiev.jpg",
      "./home_files/EcoFi.png",
      // Add more images for the Financial Institutions tab
    ],
  };

  // Function to handle tab click and update the active tab
  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  return (
    <>
      <section className="" style={{ background: "#F3F7F6" }}>
        <div className="container pt-5 eco-box text-center pb-5">
          <h2 className="h2-heading pb-max">The Alt Ecosystem</h2>
          <div className="scroll-frame">
            <button
              className={`eco-tabs ${activeTab === "oem" ? "active" : ""}`}
              onClick={() => handleTabClick("oem")}
            >
              <span>
                <img src="./home_files/oem.svg" alt="OEMs" />
              </span>
              &nbsp; OEMs
            </button>
            <button
              className={`eco-tabs ${activeTab === "fleet" ? "active" : ""}`}
              onClick={() => handleTabClick("fleet")}
            >
              <span>
                <img
                  src="./home_files/fleet_management.svg"
                  alt="Fleet Operators"
                />
              </span>
              &nbsp; Fleet Operators
            </button>
            <button
              className={`eco-tabs ${activeTab === "charging" ? "active" : ""}`}
              onClick={() => handleTabClick("charging")}
            >
              <span>
                <img
                  src="./home_files/charging_stations.svg"
                  alt="Charging Point Providers"
                />
              </span>
              &nbsp; Charging Point Providers
            </button>
            <button
              className={`eco-tabs ${activeTab === "finance" ? "active" : ""}`}
              onClick={() => handleTabClick("finance")}
            >
              <span>
                <img
                  src="./home_files/finance1.svg"
                  alt="Financial Institutions"
                />
              </span>
              &nbsp; Financial Institutions
            </button>
          </div>
          <div
            className="tab-content mw-100 oem-gap"
            id="ecopills-tabContent"
            style={{ marginTop: "2rem" }}
          >
            <div
              className={`tab-pane alt-tab-pane fade mw-100 ${
                activeTab === "oem" ? "active show" : ""
              }`}
              style={{ border: "none" }}
              id="ecotab1"
              role="tabpanel"
            >
              <div>
                <div className="swiper container">
                  <Carousel
                    swipeable={false}
                    draggable={false}
                    showDots={false}
                    responsive={responsive}
                    // ssr={true} // means to render carousel on server-side.
                    infinite={true}
                    autoPlay={true}
                    autoPlaySpeed={1000}
                    arrows={false}
                  >
                    {tabImages.oem.map((image, index) => (
                      <div key={index}>
                        <img
                          src={image}
                          alt={`OEM Image ${index + 1}`}
                          className="sponsor-img"
                        />
                      </div>
                    ))}
                  </Carousel>
                </div>
              </div>
            </div>
            <div
              className={`tab-pane alt-tab-pane fade mw-100 ${
                activeTab === "fleet" ? "active show" : ""
              }`}
              id="ecotab2"
              role="tabpanel"
              style={{ border: "none" }}
            >
              <div>
                <div className="swiper container">
                  <Carousel
                    swipeable={false}
                    draggable={false}
                    showDots={false}
                    responsive={responsive}
                    ssr={true} // means to render carousel on server-side.
                    infinite={true}
                    autoPlay={true}
                    autoPlaySpeed={1000}
                    arrows={false}
                  >
                    {tabImages.fleet.map((image, index) => (
                      <div key={index}>
                        <img
                          src={image}
                          alt={`Fleet Image ${index + 1}`}
                          className="sponsor-img"
                        />
                      </div>
                    ))}
                  </Carousel>
                </div>
              </div>
            </div>
            <div
              className={`tab-pane  alt-tab-pane fade mw-100 ${
                activeTab === "charging" ? "active show" : ""
              }`}
              style={{ border: "none" }}
              id="ecotab3"
              role="tabpanel"
            >
              <div>
                <div className="swiper container">
                  <Carousel
                    swipeable={false}
                    draggable={false}
                    showDots={false}
                    responsive={responsive}
                    ssr={true} // means to render carousel on server-side.
                    infinite={true}
                    autoPlay={true}
                    autoPlaySpeed={1000}
                    arrows={false}
                  >
                    {tabImages.charging.map((image, index) => (
                      <div key={index}>
                        <img
                          src={image}
                          alt={`Charging Image ${index + 1}`}
                          className="sponsor-img"
                        />
                      </div>
                    ))}
                  </Carousel>
                </div>
              </div>
            </div>
            <div
              className={`tab-pane alt-tab-pane fade mw-100 ${
                activeTab === "finance" ? "active show" : ""
              }`}
              id="ecotab4"
              role="tabpanel"
            >
              <div>
                <div className="swiper container">
                  <Carousel
                    swipeable={false}
                    draggable={false}
                    showDots={false}
                    responsive={responsive}
                    ssr={true} // means to render carousel on server-side.
                    infinite={true}
                    autoPlay={true}
                    autoPlaySpeed={1000}
                    arrows={false}
                  >
                    {tabImages.finance.map((image, index) => (
                      <div key={index}>
                        <img
                          src={image}
                          alt={`Finance Image ${index + 1}`}
                          className="sponsor-img"
                        />
                      </div>
                    ))}
                  </Carousel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Altecosystem;
