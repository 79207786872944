import React from "react";
import Accordion from 'react-bootstrap/Accordion';
import "./MobileFAQ.css";

const MobileFAQ = ({ title, titleIcon, faqs }) => {
    return (
        <div className="mobile-faq-container">
            <div className="mobile-faq-header">
                <img src={titleIcon} alt="salary" />
                {title}
            </div>
            <div className="mobile-faq-body mt-3">
                <Accordion defaultActiveKey="0">
                    {faqs.map((faq, i) => (
                        <Accordion.Item className="mobile-accordion-item" eventKey={`${i}`}>
                            <Accordion.Header className="mobile-faq-item-header">
                                {faq.question}
                            </Accordion.Header>
                            <Accordion.Body className="mobile-faq-item-body">
                                <div 
                                    dangerouslySetInnerHTML={{__html: faq?.answer}}
                                />
                            </Accordion.Body>
                        </Accordion.Item>
                    ))}
                </Accordion>
            </div>
        </div>
    );
};

export default MobileFAQ;