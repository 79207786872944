import React, { useEffect, useState } from "react";
import { MdOutlineNavigateNext } from "react-icons/md";
import "./Fleetprotect.css";
import { BASE_URL } from "../repository/api";
// import Hassle from'../Images/hassle.png';
import hassle from "../Images/hassle.png";
import Super from "../Images/super.png";

import Third_party from "../Images/3rd_party.png";
import Claim from "../Images/claim_initmation.png";
import Commercial from "../Images/commercial_insurance.png";
import Driver from "../Images/driver_cover.png";
import Fleet_manage from "../Images/fleet_manage.png";
import Low from "../Images/low.png";
import Onestop from "../Images/onestop.png";
import Periodic from "../Images/periodic.png";
import Red_auto from "../Images/protect.png";
import Roadside_assist from "../Images/roadside_assistance.png";
// import Coming from "../Images/coming-soon-badge-icon-flat-260nw-1095543101.jpg";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import Battery_pro from "../Images/battery_pro.png";
import Cashless_claim from "../Images/cashless_claim.png";
import Charge_protect from "../Images/charge_protect.png";
import Emi from "../Images/emi_protect.png";
import Extended_warranty from "../Images/extended_warranty.png";
import Hydro_static from "../Images/hydrostatic_protect.png";
import Inspection from "../Images/inspection.png";
import Setting_claim from "../Images/oem.png";
import Report from "../Images/report.png";
import Road_assist from "../Images/road_assist.png";
// import { GrFormNext } from "react-icons/gr";
import axios from "axios";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import FaqComponent from "../Components/FaqComponent";
const Fleetprotect = () => {
  const [faq, setfaq] = useState([]);

  useEffect(() => {
    axios
      .get(`${BASE_URL}/faq`)
      .then((response) => {
        console.log(response);
        console.log(
          "os",
          response.data.results.filter((o) => o.faqType === "Fleet Protect")
        );
        setfaq(
          response.data.results.filter((o) => o.faqType === "Fleet Protect")
        );
        console.log(response.data.results);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const arr = faq;
  console.log("arr", arr);
  const isMobile = useMediaQuery({ maxWidth: 827 });

  const responsive = {
    tablet: {
      breakpoint: { max: 823, min: 464 },
      items: 2,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  const responsive2 = {
    tablet: {
      breakpoint: { max: 823, min: 464 },
      items: 2,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  const isMobileOrTablet = window.innerWidth <= 824;

  return (
    <>
   <div className="home-mainss">
      <div className="container-title text-center p-md-5 w-100 mt-md-5 paddingsmall">
        {/* <p className="page-link ms-3">
          Homepage <GrFormNext />
          <span className="span-title">FleetProtect</span>
        </p> */}
        <h1 className="main" id="fleet-comprehensive">
          Most comprehensive & accessible insurance for your electric fleet.
        </h1>
        <p className="main mb-5">
          Fleet Protect insurance offers a one-stop solution for a hassle-free
          <br /> experience for your fleet operations.
        </p>
        <Link to="/enquire">
          <button type="button" class="btn-fleetprotect ">
            Contact Us & Get A Quote{" "}
            <span className="icons1">
              <MdOutlineNavigateNext size={25} />
            </span>
          </button>
        </Link>
      </div>

      {isMobileOrTablet ? (
        <Carousel
          responsive={responsive2}
          showDots={isMobile}
          arrows={false}
          className="divcard w-100 mt-5 mb-5 d-flex flex-wrap   position-relative pb-5"
          autoPlay={isMobile}
          autoPlaySpeed={3000}
          infinite={true}
          // style={carouselStyle}
        >
          <div>
            <div className="card cardbox">
              <div className="card-body">
                <div className="imagecard">
                  <img src={hassle} alt="super" />
                </div>
                <div className="head-para">
                  <h1 className="cardtitle mt-3">Hassle Free Claims</h1>
                  <p className="paracard">
                    Easily file a claim at multiple touch points with the help
                    of a dedicated Claims Manager.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div>
            {" "}
            <div className="card cardbox">
              <div className="card-body">
                <div className="imagecard">
                  <img src={Super} alt="super" />
                </div>
                <div className="head-para">
                  <h1 className="cardtitle mt-3">Super Quick Service</h1>
                  <p className="paracard">
                    Guaranteed survey completion within 24 working hours or the
                    survey fee gets waived up to INR 20,000.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div>
            {" "}
            <div className=" card cardbox">
              <div className="card-body">
                <div className="imagecard">
                  <img src={Onestop} alt="onestop" />
                </div>
                <div className="head-para">
                  <h1 className="cardtitle mt-3">One-stop Shop</h1>
                  <p className="paracard">
                    Protect your vehicle with our innovative products for
                    battery cover, hydrostatic damage, and loss of income
                    coverage.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div>
            {" "}
            <div className=" card cardbox">
              <div className="card-body">
                <div className="imagecard">
                  <img src={Low} alt="low" />
                </div>
                <div className="head-para">
                  <h1 className="cardtitle mt-3">Low Premiums</h1>
                  <p className="paracard">
                    Obtain fast and easy cashless claims with our streamlined
                    process.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div>
            {" "}
            <div className=" card cardbox">
              <div className="card-body">
                <div className="imagecard">
                  <img src={Periodic} alt="low" />
                </div>
                <div className="head-para">
                  <h1 className="cardtitle mt-3">Periodic Maintenance</h1>
                  <p className="paracard">
                    From insurers to OEMs, manage the entire claim process with
                    a single help desk.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div>
            {" "}
            <div className=" card cardbox">
              <div className="card-body">
                <div className="imagecard">
                  <img src={Fleet_manage} alt="fleet-manage" />
                </div>
                <div className="head-para">
                  <h1 className="cardtitle mt-3">Fleet Management</h1>
                  <p className="paracard">
                    Lower your monthly insurance costs and increase your
                    financial protection options.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Carousel>
      ) : (
        <>
          <div className="container  divcard w-90 mt-4 justify-content-center p-md-5 paddingsmall ">
            <div className="d-flex position-relative">
              <div className="card cardbox first-left">
                <div className="card-body">
                  <div className="imagecard">
                    <img src={hassle} alt="super" />
                  </div>
                  <div className="head-para">
                    <h1 className="cardtitle mt-3">Hassle Free Claims</h1>
                    <p className="paracard">
                      Lower your monthly insurance costs and increase your
                      financial protection options.
                    </p>
                  </div>
                </div>
              </div>

              <div className="card cardbox first-middle">
                <div className="card-body">
                  <div className="imagecard">
                    <img src={Super} alt="super" />
                  </div>
                  <div className="head-para">
                    <h1 className="cardtitle mt-3">Super Quick Service</h1>
                    <p className="paracard">
                      Guaranteed survey completion within 24 working hours or
                      the survey fee gets waived up to INR 20,000.
                    </p>
                  </div>
                </div>
              </div>

              <div className=" card cardbox first-end">
                <div className="card-body">
                  <div className="imagecard">
                    <img src={Onestop} alt="onestop" />
                  </div>
                  <div className="head-para">
                    <h1 className="cardtitle mt-3">One-stop Shop</h1>
                    <p className="paracard">
                      Protect your vehicle with our innovative products for
                      battery cover, hydrostatic damage, and loss of income
                      coverage.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex position-relative">
              <div className=" card cardbox second-left">
                <div className="card-body">
                  <div className="imagecard">
                    <img src={Low} alt="low" />
                  </div>
                  <div className="head-para">
                    <h1 className="cardtitle mt-3">Low Premiums</h1>
                    <p className="paracard">
                      Obtain fast and easy cashless claims with our streamlined
                      process.
                    </p>
                  </div>
                </div>
              </div>

              <div className=" card cardbox second-middle">
                <div className="card-body">
                  <div className="imagecard">
                    <img src={Periodic} alt="periodic" />
                  </div>
                  <div className="head-para">
                    <h1 className="cardtitle mt-3">Periodic Maintenance</h1>
                    <p className="paracard">
                      From insurers to OEMs, manage the entire claim process
                      with a single help desk.
                    </p>
                  </div>
                </div>
              </div>

              <div className=" card cardbox second-right">
                <div className="card-body">
                  <div className="imagecard">
                    <img src={Fleet_manage} alt="fleet-manage" />
                  </div>
                  <div className="head-para">
                    <h1 className="cardtitle mt-3">Fleet Management</h1>
                    <p className="paracard">
                      Lower your monthly insurance costs and increase your
                      financial protection options.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <div className="container p-md-5 paddingsmall mt-4 text-center w-100">
        <div className="container-head w-100">
          <h1 className="main head-fleet">
            Fleet protect basic takes care of <br /> everything for you.
          </h1>
          <p className="main para-fleet">
            Critical insurance needs for your fleet.
          </p>
          <Link to="/enquire">
            <button type="button" class="btn-claim mt-5">
              Contact Us & Get A Quote{" "}
              <span>
                <MdOutlineNavigateNext size={25} />
              </span>
            </button>
          </Link>
        </div>
      </div>
      <div className="car-container pt-md-5 pb-md-5 paddingsmall ">
        <div className="container">
        <div className="row w-100 ms-0">
          <div className="col-md-6 col-sm-12  order-sm-2 order-md-1">
            <div className="card red-auto-card ">
              <div className="card-body d-flex">
                <div className="opposite-red-auto">
                  <img src={Commercial} alt="commercial" />
                </div>
                <div className="ms-3">
                  <h1 className="red-auto-head">
                    Complete Insurance for Commercial Vehicles
                  </h1>
                  <p className="para-fleet">
                    Full coverage for events like damage, fire, theft, robbery,
                    and natural disasters.
                  </p>
                </div>
              </div>
            </div>
            <div className="card red-auto-card mt-3">
              <div className="card-body d-flex">
                <div className="opposite-red-auto">
                  <img src={Driver} alt="driver" />
                </div>
                <div className="ms-3">
                  <h1 className="red-auto-head">
                    Driver and Personal Accident Cover
                  </h1>
                  <p className="para-fleet">
                    Protection for personal injuries or driver's death in the
                    event of a vehicle accident.
                  </p>
                </div>
              </div>
            </div>
            <div className="card red-auto-card mt-3">
              <div className="card-body d-flex">
                <div className="opposite-red-auto">
                  <img src={Third_party} alt="third_party" />
                </div>
                <div className="ms-3">
                  <h1 className="red-auto-head">Third Party Losses</h1>
                  <p className="para-fleet">
                    Covers losses and damages caused to a third-party vehicle or
                    person's property due to a vehicle accident or collision.
                  </p>
                </div>
              </div>
            </div>
            <div className="card red-auto-card mt-3">
              <div className="card-body d-flex">
                <div className="opposite-red-auto ">
                  <img src={Roadside_assist} alt="assist" />
                </div>
                <div className="ms-3">
                  <h1 className="red-auto-head">Road Side Assistance</h1>
                  <p className="para-fleet">
                    Covers breakdown assistance, flat tire, towing facility to
                    the nearest authorized service garage, and minor repairs
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-sm-12  red-auto-div order-sm-1 order-md-2">
            <img
              src={Red_auto}
              alt="red_auto"
              className="red-auto-img-full img-responsive w-100"
            />
          </div>
        </div>
        </div>
      </div>

      <div className="comphrensive-cont ">
        <div className="text-center pt-md-5 pb-md-5 paddingsmall mt-5 container">
        <div className=" p-4">
          <h1 className="ev-lease">
            But Fleet Protect Comprehensive
            <br /> handles so much more.
          </h1>
          <p className="main">
            Insurance products designed to protect your fleet.
          </p>
          <Link to="/enquire">
            {" "}
            <button type="button" class="btn-claim mt-4  ">
              Contact Us & Get A Quote{" "}
              <span>
                <MdOutlineNavigateNext size={25} />
              </span>
            </button>
          </Link>
        </div>

        {isMobileOrTablet ? (
          <Carousel
            responsive={responsive}
            showDots={isMobile}
            arrows={false}
            className=" nsr-container d-flex w-100 pe-md-5 ps-md-5 paddingsmall mt-5 text-center ms-0 pb-4"
            autoPlay={isMobile}
            autoPlaySpeed={3000}
            infinite={true}
            // style={carouselStyle}
          >
            <div>
              <div className="emi-box comprehensive-card pt-3 ps-3 position-relative w-100">
                <div className="card-body">
                  <div className="h-p">
                    <h1 className="head-comprehensive">Road Side Assistance</h1>
                    <p className="para-comprehensive">
                      Covers breakdown assistance, flat tyre, towing facility to
                      nearest authorised service garage and minor repairs
                    </p>
                    <img
                      className="position-absolute bottom-0 end-0"
                      src={Road_assist}
                      alt="road_assist"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div className="emi-box comprehensive-card pt-3 ps-3 position-relative w-100">
                <div className="card-body">
                  <div className="">
                    <h1 className="head-comprehensive">Hydrostatic Protect</h1>
                    <p className="para-comprehensive">
                      Protection against motor damage caused by water ingress in
                      the vehicle.
                    </p>
                    <img
                      className="position-absolute bottom-0 end-0"
                      src={Hydro_static}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div className="emi-box comprehensive-card pt-3 ps-3 position-relative w-100">
                <div className="card-body">
                  <div className="">
                    <h1 className="head-comprehensive">Battery Protect</h1>
                    <p className="para-comprehensive">
                      Covers out-of-manufacturer warranty claims, such as water
                      damage, fires, non-performance, and theft.
                    </p>
                    <img
                      className="position-absolute bottom-0 end-0"
                      src={Battery_pro}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div className="emi-box comprehensive-card pt-3 ps-3 position-relative w-100">
                <div className="card-body">
                  <div className="">
                    <h1 className="head-comprehensive">Extended Warranty</h1>
                    <p className="para-comprehensive">
                      Covers beyond OEM warranty period up to additional 2
                      years.
                    </p>
                    <img
                      className="position-absolute bottom-0 end-0"
                      src={Extended_warranty}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div className="emi-box comprehensive-card pt-3 ps-3 position-relative w-100">
                <div className="card-body">
                  <div className="h-p">
                    <h1 className="head-comprehensive">EMI Protect</h1>
                    <p className="para-comprehensive text-wrap">
                      Fleet operators receive a compensation of INR 2,000 per
                      day for lost income if the vehicle spends more than 3 days
                      in the OEM garage.
                    </p>
                    <img
                      className="position-absolute bottom-0 end-0"
                      src={Emi}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div>
              {" "}
              <div className="emi-box comprehensive-card pt-3 ps-3 position-relative w-100">
                <div className="card-body">
                  <div className="h-p">
                    <h1 className="head-comprehensive">
                      Charge Protect &nbsp;
                      <span>
                        <img
                          // src={Coming}
                          style={{ width: "50px", height: "25px" }}
                          alt=""
                        />
                      </span>
                    </h1>
                    <p className="para-comprehensive">
                      Mobile charging station support to recharge vehicle at the
                      location of breakdown.
                    </p>
                    <img
                      className="position-absolute bottom-0 end-0"
                      src={Charge_protect}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </Carousel>
        ) : (
          <div className="  comp-container ">
            <div className="row">
              <div className="col-md-4 col-sm-12">
                <div className="emi-box comprehensive-card pt-3 ps-3 position-relative w-100">
                  <div className="card-body">
                    <div className="h-p">
                      <h1 className="head-comprehensive">
                        Road Side Assistance
                      </h1>
                      <p className="para-comprehensive">
                        Covers breakdown assistance, flat tyre, towing facility
                        to nearest authorised service garage and minor repairs
                      </p>
                      <img
                        className="position-absolute bottom-0 end-0"
                        src={Road_assist}
                        alt="road_assist"
                        style={{
                          width: "100px",
                          height: "96px",
                          objectFit: "contain",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4 col-sm-12">
                <div className="emi-box comprehensive-card pt-3 ps-3 position-relative w-100">
                  <div className="card-body">
                    <div className="">
                      <h1 className="head-comprehensive">
                        Hydrostatic Protect
                      </h1>
                      <p className="para-comprehensive">
                        Protection against motor damage caused by water ingress
                        in the vehicle.
                      </p>
                      <img
                        className="position-absolute bottom-0 end-0"
                        src={Hydro_static}
                        alt=""
                        style={{
                          width: "92px",
                          height: "100px",
                          objectFit: "contain",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4 col-sm-12">
                <div className="emi-box comprehensive-card pt-3 ps-3 position-relative w-100">
                  <div className="card-body">
                    <div className="">
                      <h1 className="head-comprehensive">Battery Protect</h1>
                      <p className="para-comprehensive">
                        Covers out-of-manufacturer warranty claims, such as
                        water damage, fires, non-performance, and theft.
                      </p>
                      <img
                        className="position-absolute bottom-0 end-0"
                        src={Battery_pro}
                        alt=""
                        style={{
                          width: "105px",
                          height: "105px",
                          objectFit: "contain",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className=" mt-4">
              <div className="row">
                <div className="col-md-4 col-sm-12">
                  <div className="emi-box comprehensive-card pt-3 ps-3 position-relative w-100">
                    <div className="card-body">
                      <div className="">
                        <h1 className="head-comprehensive">
                          {" "}
                          Extended Warranty
                        </h1>
                        <p className="para-comprehensive">
                          Covers beyond OEM warranty period up to additional 2
                          years.
                        </p>
                        <img
                          className="position-absolute bottom-0 end-0"
                          src={Extended_warranty}
                          alt=""
                          style={{
                            width: "105px",
                            height: "100px",
                            objectFit: "contain",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 col-sm-12">
                  <div className="emi-box comprehensive-card pt-3 ps-3 position-relative w-100">
                    <div className="card-body">
                      <div className="">
                        <h1 className="head-comprehensive">EMI Protect</h1>
                        <p className="para-comprehensive">
                          Fleet operators receive a compensation of INR 2,000
                          per day for lost income if the vehicle spends more
                          than 3 days in the OEM garage.
                        </p>
                        <img
                          className="position-absolute bottom-0 end-0"
                          src={Emi}
                          alt=""
                          style={{
                            width: "100px",
                            height: "100px",
                            objectFit: "contain",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-sm-12">
                  <div className="emi-box comprehensive-card pt-3 ps-3 position-relative w-100">
                    <div className="card-body">
                      <div className="">
                        <h1 className="head-comprehensive">
                          Charge Protect &nbsp;
                          <span>
                            <img
                              // src={Coming}
                              style={{ width: "50px", height: "25px" }}
                              alt=""
                            />
                          </span>
                        </h1>
                        <p className="para-comprehensive">
                          Mobile charging station support to recharge vehicle at
                          the location of breakdown.
                        </p>
                        <img
                          className="position-absolute bottom-0 end-0"
                          src={Charge_protect}
                          alt=""
                          style={{
                            width: "105px",
                            height: "100px",
                            objectFit: "contain",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        </div>
      </div>

      <div className="claim-process container text-center p-md-5 paddingsmall w-100 mt-5">
        <div>
          <h1 className="claim-process-head">Claim Process</h1>
          <Link to="/enquire">
            <button type="button" class="btn-claim ">
              Contact Us & Get A Quote{" "}
              <span>
                {" "}
                <MdOutlineNavigateNext size={25} />
              </span>
            </button>
          </Link>
        </div>

        <div class="row first-row ms-0">
          <div className="col-sm-12 col-lg-4">
            <div className="claim p-3 mb-4 w-100">
              <div className="card-body ">
                <div className="opposite-red-auto text-start mb-4">
                  <img src={Claim} alt="claim" />
                </div>
                <div className="text-start mt-2">
                  <h1 className="claim-head">
                    <span>1.</span> Claim Intimation
                  </h1>
                  <p className="claim-para text-start">
                    In case of an accident or breakdown, following channels:
                    reach out to the Alt Helpline through the
                    <br />
                    a. +911141168587
                    <br />
                    b.. claims@alt-mobility.com
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-sm-12 col-lg-4">
            <div className="claim p-3 mb-4 w-100">
              <div className="card-body">
                <div className="opposite-red-auto text-start  mb-4">
                  <img src={Inspection} alt="ins" />
                </div>
                <div className="text-start mt-2">
                  <h1 className="claim-head">
                    <span>2.</span> Inspection
                  </h1>
                  <p className="claim-para text-start">
                    The insurance company will send a <br />
                    surveyor to evaluate the damage within 24 hours of filing
                    the claim. If there's a delay, an Alt Claims Support Team
                    will expedite the survey.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-sm-12 col-lg-4">
            <div className="claim p-3 mb-4 w-100">
              <div className="card-body">
                <div className="opposite-red-auto text-start mb-4 ">
                  <img src={Report} alt="report" />
                </div>
                <div className="text-start mt-2">
                  <h1 className="claim-head">
                    <span>3.</span> Surveyors Report
                  </h1>
                  <p className="claim-para text-start">
                    The surveyor will prepare a report with an estimate of the
                    claim amount.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-4 ms-0 d-flex">
          <div className="col-sm-12 col-lg-4">
            <div className="claim p-3 mb-4 w-100">
              <div className="card-body">
                <div className="opposite-red-auto text-start mb-4">
                  <img src={Cashless_claim} alt="claim" />
                </div>
                <div className="text-start mt-2">
                  <h1 className="claim-head">
                    <span>4.</span> Cashless Claim
                  </h1>
                  <p className="text-start">
                    The insurance company will approve the cashless claim within
                    24 hours.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-sm-12 col-lg-4">
            <div className="claim p-3 mb-4 w-100">
              <div className="card-body">
                <div className="opposite-red-auto text-start mb-4">
                  <img src={Setting_claim} alt="setting" />
                </div>
                <div className="text-start mt-2">
                  <h1 className="claim-head">
                    <span>5.</span> Repair Work
                  </h1>
                  <p className="text-start">
                    Vehicle to be taken to the nearest authorised service centre{" "}
                    <br />
                    designated by OEM for repairs.
                    <br />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FaqComponent faq={[...arr]} />
      {/* <Footer /> */}
      </div>
    </>
  );
};

export default Fleetprotect;
