import { ErrorMessage } from "@hookform/error-message";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { BASE_URL_FLEETOS } from "../repository/api";

import "./ContactDetails.css";

const validationSchema = Yup.object().shape({
  fullName: Yup.string()
    .required("Full Name is required")
    .matches(
      /^[a-zA-Z\s]*$/,
      "Full Name should only contain alphabets and spaces"
    )
    .min(3, "Full Name should be at least 3 characters")
    .max(35, "Full Name can't exceed 50 characters"),

  organizationName: Yup.string()
    .required("Organization Name is required")
    .min(4, "Organisation name should be at least 4 characters")
    .max(50, "Organisation name should not exceed 50 characters"),
  // mobileNumber: Yup.string()
  //   .required("Mobile Number is required")
  //   .matches(/^\d{10}$/, "Mobile Number should be a 10-digit number"),
  mobileNumber: Yup.string()
    .required("Mobile Number is required")
    .matches(/^[0-9]{10}$/, "Mobile Number should be a 10-digit number"),

  mobileOtp: Yup.string()
    .required("Mobile OTP is required")
    .matches(/^\d{6}$/, "Mobile OTP should be a 6-digit number"),
  emailAddress: Yup.string()
    .required("Email Address is required")
    .email("Invalid email format"),
  emailOtp: Yup.string().required("Email OTP is required"),
  receiveUpdates: Yup.boolean(),
  termsAndConditions: Yup.boolean().oneOf(
    [true],
    "You must accept the Terms & Conditions"
  ),
});

const ContactDetails = ({ handleTabClick, onData1, onForm1Submit }) => {
  const handleSaveAndNext = (data) => {

    sentFirstForm(data);
  };

  const [check, setCheck] = useState(true);
  const [data, setData] = useState({});
  const [mobile, setMobile] = useState();
  const [emailAddress, setEmailAddress] = useState();

  const handleMobileChange = (event) => {
    setMobile(event.target.value);
  };
  const handleEmailChange = (event) => {
    setEmailAddress(event.target.value);
  };
  console.log("email", emailAddress);
  console.log("mobile", mobile);
  const [isMobileOTPSent, setIsMobileOTPSent] = useState(false);
  const [isEmailOTPSent, setIsEmailOTPSent] = useState(false);

  const sendMobileOTP = async (mobile) => {
    try {

      const response = await axios.post(
        `${BASE_URL_FLEETOS}/v2/phoneOTP`,
        { phone: mobile }
      );
      return response.data.success;
    } catch (error) {
      console.error("Error sending mobile OTP:", error);
      return false;
    }
  };

  const sendEmailOTP = async (emailAddress) => {
    try {

      const response = await axios.post(
        `${BASE_URL_FLEETOS}/v2/emailOTP`,

        { email: emailAddress }
      );
      return response.data.success;
    } catch (error) {

      console.error("Error sending email OTP:", error);
      return false;
    }
  };



  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const sentFirstForm = (data) => {
    onData1(data);
  };
  const onSubmit = async (data) => {
    try {

      await setData(data);
      onForm1Submit(data);
      console.log("neeraj", data);
    }
    catch {
      console.log("error");
    }


  };

  return (
    <div className="form">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="fullname">
          <label>
            Full Name<span className="star">*</span>
          </label>
          <div>
            <Form.Control
              className="style"
              type="text"
              placeholder="Your Name"
              {...register("fullName")}
            />
            <p className="form-error">
              <ErrorMessage errors={errors} name="fullName" />
            </p>
          </div>
        </div>
        <div className="fullname mt-4">
          <label>
            Organization Name<span className="star">*</span>
          </label>
          <div>
            <Form.Control
              className="style"
              type="text"
              placeholder="Organization Name"
              {...register("organizationName")}
            />
            <p className="form-error">
              <ErrorMessage errors={errors} name="organizationName" />
            </p>
          </div>
        </div>
        <div className="row down mt-4">
          <div className="col-lg-6 col-sm-12 input-field">
            <label htmlFor="">
              Mobile Number<span className="star">*</span>
            </label>
            <div>
              <Form.Control
                className="style"
                type="text"
                placeholder=""
                {...register("mobileNumber")}
                onChange={handleMobileChange}
              />

              <p className="form-error">
                <ErrorMessage errors={errors} name="mobileNumber" />
              </p>
            </div>
          </div>
          <div className="col-lg-6 col-sm-12 input-field">
            <label htmlFor="">
              Mobile OTP<span className="star">*</span>
            </label>
            <div>
              <Form.Control
                placeholder="Validate mobile OTP"
                className="style"
                type="text"
                {...register("mobileOtp")}
              />
              <p className="form-error">
                <ErrorMessage errors={errors} name="mobileOtp" />
              </p>
            </div>
          </div>
        </div>
        <div className="btnotp">
          {" "}
          <button
            type="button"
            onClick={() => sendMobileOTP(mobile)}
            disabled={isMobileOTPSent}
          >
            Send OTP
          </button>
        </div>
        <div className="row down mt-4">
          <div className="col-lg-6 col-sm-12 input-field">
            <label htmlFor="">
              Email address<span className="star">*</span>
            </label>
            <div>
              <Form.Control
                className="style"
                type="text"
                placeholder=""
                {...register("emailAddress")}
                onChange={handleEmailChange}
              />
              <p className="form-error">
                <ErrorMessage errors={errors} name="emailAddress" />
              </p>
            </div>
          </div>
          <div className="col-lg-6 col-sm-12 input-field">
            <label htmlFor="">
              Email OTP<span className="star">*</span>
            </label>
            <div>
              <Form.Control
                placeholder="Validate email OTP"
                className="style"
                type="text"
                {...register("emailOtp")}
              />
              <p className="form-error">
                <ErrorMessage errors={errors} name="emailOtp" />
              </p>
            </div>
          </div>
        </div>
        <div className="btnotp">
          <button
            type="button"
            onClick={() => sendEmailOTP(emailAddress)}
            disabled={isEmailOTPSent}
          >
            Send OTP
          </button>
        </div>

        <div className="mt-4">
          <div className="d-flex check">
            <div>
              <input type="checkbox" {...register("receiveUpdates")} />
            </div>
            <div>
              <p>I would like to receive updates and offers via WhatsApp</p>
            </div>
          </div>
          <div className="d-flex check">
            <div>
              <input type="checkbox" {...register("termsAndConditions")} />
            </div>
            <div>
              <p>
                I agree to the Alt Mobility's Terms & Conditions and Privacy
                Policy<span className="star">*</span>
              </p>
              <span className="form-error">
                <ErrorMessage errors={errors} name="termsAndConditions" />
              </span>
            </div>
          </div>
        </div>

        <div className="both-btn d-flex justify-content-between">
          <button
            onClick={(e) => {
              e.preventDefault();
              handleTabClick("tab2");
            }}
            className="back"
          >
            Back
          </button>
          <button
            onClick={sentFirstForm(data)}
            className="save1"
            type="submit"
            disabled={!isValid}
          >
            Save & Next
          </button>
        </div>
      </form>
    </div>
  );
};

export default ContactDetails;
