import axios from "axios";
import { useFormik } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import Fade from "react-reveal/Fade";
import { useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import { BASE_URL_FLEETOS } from "../../repository/api";
import Button from "../CustomComponent/Button";
import CustomDropDown from "../CustomComponent/CustomSelectField";
import InputField from "../CustomComponent/InputField";

const validationSchema = Yup.object().shape({
  Amountoffunds: Yup.string()
    .matches(/^[0-9]+$/, "Must be a number")
    .required("Required"),
  Amountofmoney: Yup.string()
    .matches(/^[0-9]+$/, "Must be a number")
    .required("Required"),
  AmountofLoan: Yup.string()
    .matches(/^[0-9]+$/, "Must be a number")
    .required("Required"),
  hasanydebts: Yup.string().required("Required"),
  NumberofEmployees: Yup.string().matches(/^[0-9]+$/, "Must be a number"),
  Listofcustomers: Yup.string(),
  ev: Yup.boolean(),
  lease_details: Yup.array().of(
    Yup.object().shape({
      lessor_name: Yup.string().optional().nullable(),
      leased_ev_vehicle_make: Yup.string().optional().nullable(),
      average_tenure_of_lease: Yup.string().optional().nullable(),
      monthly_obligation: Yup.string().optional().nullable(),
      monthly_obligation_with_gst: Yup.string().optional().nullable(),
      gst_applied: Yup.string().optional().nullable(),
    })
  ),
  Existing2W: Yup.string()
    .matches(/^[0-9]+$/, "Must be a number")
    .required("Required"),
  Existing3W: Yup.string()
    .matches(/^[0-9]+$/, "Must be a number")
    .required("Required"),
  Existing4W: Yup.string()
    .matches(/^[0-9]+$/, "Must be a number")
    .required("Required"),
  Numberofadditional2W: Yup.string()
    .matches(/^[0-9]+$/, "Must be a number")
    .required("Required"),
  Numberofadditional3W: Yup.string()
    .matches(/^[0-9]+$/, "Must be a number")
    .required("Required"),
  Numberofadditional4W: Yup.string()
    .matches(/^[0-9]+$/, "Must be a number")
    .required("Required"),
  CurrentfleetUtilization: Yup.string()
    .matches(/^[0-9]+$/, "Must be a number")
    .required("Required"),

  // Add more validation as needed
});

const Form2 = ({ handleBack, handleNext }) => {
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  const HasEV = useMemo(
    () => [
      { value: true, label: "Yes" },
      { value: false, label: "No" },
    ],
    []
  );

  const GSTApplied = useMemo(
    () => [
      { value: "5", label: 5 },
      { value: "6", label: 6 },
      { value: "7", label: 7 },
      { value: "8", label: 8 },
      { value: "9", label: 9 },
      { value: "10", label: 10 },
      { value: "11", label: 11 },
      { value: "12", label: 12 },
      { value: "13", label: 13 },
      { value: "14", label: 14 },
      { value: "15", label: 15 },
      { value: "16", label: 16 },
      { value: "17", label: 17 },
      { value: "18", label: 18 },
    ],
    []
  );

  const fetchDataFromAPI = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${BASE_URL_FLEETOS}/v1/lease-application/${id}`
      );
      setData(response.data.lease_application_additional_details);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDataFromAPI();
  }, [id]);

  const [LeaseDetail, setLeaseDetail] = useState([
    {
      lessor_name: "",
      number_of_vehicles_leased: "",
      leased_ev_vehicle_make: "",
      average_tenure_of_lease: "",
      monthly_obligation: "",
      gst_applied: "",
      monthly_obligation_with_gst: "",
    },
  ]);

  const initialValues = {
    Amountoffunds: data?.amount_of_money_infused ?? "",
    Amountofmoney: data?.amount_of_funds_raised ?? "",
    AmountofLoan: data?.amount_of_loan_taken ?? "",
    hasanydebts: data?.outstanding_debts ?? "",
    NumberofEmployees: data?.number_of_employees_on_payroll ?? "",
    Listofcustomers: data?.list_of_customer_signed ?? "",
    ev: data?.ev_lease_status_before ?? false,
    lease_details: data?.lease_application_lease_details ?? LeaseDetail,
    Existing2W: data?.existing_2w_l2_vehicles ?? "",
    Existing3W: data?.existing_3w_l5_vehicles ?? "",
    Existing4W: data?.existing_4w_vehicles ?? "",
    Numberofadditional2W: data?.number_of_additional_2w_l2_required ?? "",
    Numberofadditional3W: data?.number_of_additional_3w_l5_required ?? "",
    Numberofadditional4W: data?.number_of_additional_4w_required ?? "",
    CurrentfleetUtilization: data?.current_fleet_utilization_rate ?? "",
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const Form1Data = {
          lease_application_id: id,
          amount_of_money_infused: parseInt(values.Amountoffunds),
          amount_of_funds_raised: parseInt(values.Amountofmoney),
          amount_of_loan_taken: parseInt(values.AmountofLoan),
          outstanding_debts: values.hasanydebts,
          number_of_employees_on_payroll: parseInt(values.NumberofEmployees),
          list_of_customer_signed: values.Listofcustomers,
          ev_lease_status_before: values.ev,
          ...(values.ev === true && {
            lease_details: Object.values(values.lease_details).map(
              (partner) => ({
                lessor_name: partner.lessor_name,
                number_of_vehicles_leased: parseInt(
                  partner.number_of_vehicles_leased
                ),
                leased_ev_vehicle_make: partner.leased_ev_vehicle_make,
                average_tenure_of_lease: parseInt(
                  partner.average_tenure_of_lease
                ),
                monthly_obligation: parseInt(partner.monthly_obligation),
                monthly_obligation_with_gst: parseInt(
                  partner.monthly_obligation_with_gst
                ),
                gst_applied: parseInt(partner.gst_applied),
              })
            ),
          }),
          existing_2w_l2_vehicles: parseInt(values.Existing2W),
          existing_3w_l5_vehicles: parseInt(values.Existing3W),
          existing_4w_vehicles: parseInt(values.Existing4W),
          number_of_additional_2w_l2_required: parseInt(
            values.Numberofadditional2W
          ),
          number_of_additional_3w_l5_required: parseInt(
            values.Numberofadditional3W
          ),
          number_of_additional_4w_required: parseInt(
            values.Numberofadditional4W
          ),
          current_fleet_utilization_rate: parseInt(
            values.CurrentfleetUtilization
          ),
        };
        const response = await axios.post(
          `${BASE_URL_FLEETOS}/v1/lease-application/additional-details`,
          Form1Data
        );
        handleNext();
        setLoading(false);
      } catch (error) {
        console.error("Error:", error.message);
        setLoading(false);
      }
    },
  });

  function addAdditionalFields() {
    const newPartner = {
      lessor_name: "",
      number_of_vehicles_leased: "",
      leased_ev_vehicle_make: "",
      average_tenure_of_lease: "",
      monthly_obligation: "",
      monthly_obligation_with_gst: "",
      gst_applied: "",
    };

    // Update lease_details directly
    formik.setFieldValue("lease_details", [
      ...formik.values.lease_details,
      newPartner,
    ]);

    // Optionally, update the 'ev' field if needed
    formik.setFieldValue("ev", true);
  }

  return (
    <form onSubmit={formik.handleSubmit} className="d-flex flex-column gap-5">
      <div className="d-flex flex-column flex-md-row justify-content-between gap-4">
        <InputField
          label="Amount of funds raised by the company till date."
          type="text"
          id="Amountoffunds"
          className="w-100"
          required
          name="Amountoffunds"
          placeholder="Enter Amount"
          value={formik.values.Amountoffunds}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.Amountoffunds && formik.errors.Amountoffunds}
        />
        <InputField
          label="Amount of money infused by the promoters till date."
          type="text"
          id="Amountofmoney"
          className="w-100"
          required
          name="Amountofmoney"
          placeholder="Enter Amount"
          value={formik.values.Amountofmoney}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.Amountofmoney && formik.errors.Amountofmoney}
        />
      </div>
      <div className="d-flex flex-column flex-md-row justify-content-between gap-4 ">
        <InputField
          label="Amount of Loan taken by the company till date."
          type="text"
          id="AmountofLoan"
          className="w-100"
          required
          name="AmountofLoan"
          placeholder="Enter Amount"
          value={formik.values.AmountofLoan}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.AmountofLoan && formik.errors.AmountofLoan}
        />
        <div className="w-100 d-none d-md-block"></div>
      </div>
      <div className="d-flex flex-column flex-md-row justify-content-between gap-4 input-width">
        <InputField
          label="Does the company has any debts outstanding at present? If yes, please provide details."
          type="text"
          id="hasanydebts"
          className="w-100"
          required
          name="hasanydebts"
          placeholder="Enter Details"
          value={formik.values.hasanydebts}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.hasanydebts && formik.errors.hasanydebts}
        />
        <div className="w-100 d-none d-md-block"></div>
      </div>

      <div className="d-flex flex-column flex-md-row justify-content-between gap-4 input-width">
        <InputField
          label="Number of Employees on Payroll at present"
          type="text"
          id="NumberofEmployees"
          className="w-100"
          name="NumberofEmployees"
          placeholder="Enter Number of Employees"
          value={formik.values.NumberofEmployees}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.NumberofEmployees && formik.errors.NumberofEmployees
          }
        />
        <div className="w-100 d-none d-md-block"></div>
      </div>
      <div className="d-flex flex-column flex-md-row justify-content-between gap-4 input-width">
        <InputField
          label="List of customers signed till date"
          type="text"
          id="Listofcustomers"
          className="w-100"
          name="Listofcustomers"
          placeholder="Enter Detail"
          value={formik.values.Listofcustomers}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.Listofcustomers && formik.errors.Listofcustomers
          }
        />
        <div className="w-100 d-none d-md-block"></div>
      </div>
      <div className="d-flex flex-column flex-md-row justify-content-between gap-4">
        <CustomDropDown
          options={HasEV}
          className="full-width  w-100"
          required
          value={{
            label: formik.values.ev === true ? "Yes" : "No",
            value: formik.values.ev,
          }}
          title="Has the company taken any EV vehicles on lease before?"
          titleClass="custom-dropdown-style"
          onChange={async (option) => {
            formik.setFieldValue("ev", option?.value);
          }}
          onBlur={formik.handleBlur("ev")}
          placeholder={"Select..."}
        />
        <div className="w-100 d-none d-md-block"></div>
      </div>

      {formik.values.ev && (
        <div>
          {formik.values.lease_details.map((field, index) => (
            <div key={index} className="w-100 d-flex  flex-column gap-4">
              <Fade>
                <div className="d-flex  flex-column flex-lg-row  justify-content-between  gap-4 input-width">
                  <InputField
                    label="Lessor Name"
                    type="text"
                    className="w-100"
                    id={`lease_details[${index}].lessor_name`}
                    required
                    name={`lease_details[${index}].lessor_name`}
                    placeholder="Enter Lessor Name"
                    value={formik.values.lease_details[index].lessor_name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.lessor_name && formik.errors.lessor_name
                    }
                  />
                  <div className="w-100">
                    <InputField
                      label="Number of Vehicles Leased:"
                      type="number"
                      id={`lease_details[${index}].number_of_vehicles_leased`}
                      className="w-100"
                      required
                      name={`lease_details[${index}].number_of_vehicles_leased`}
                      placeholder="Enter Number of Vehicles Leased"
                      value={
                        formik.values.lease_details[index]
                          .number_of_vehicles_leased
                      }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.number_of_vehicles_leased &&
                        formik.errors.number_of_vehicles_leased
                      }
                    />
                  </div>
                  <div className="w-100">
                    <InputField
                      label="Leased EV Vehicle Make"
                      type="text"
                      id={`lease_details[${index}].leased_ev_vehicle_make`}
                      name={`lease_details[${index}].leased_ev_vehicle_make`}
                      className="w-100"
                      required
                      placeholder="Enter Leased EV Vehicle Make"
                      value={
                        formik.values.lease_details[index]
                          .leased_ev_vehicle_make
                      }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.leased_ev_vehicle_make &&
                        formik.errors.leased_ev_vehicle_make
                      }
                    />
                  </div>
                </div>
                <div className="d-flex flex-column flex-lg-row justify-content-between gap-4 ">
                  <InputField
                    label="Average Tenure of the Lease:"
                    type="number"
                    className="w-100"
                    required
                    id={`lease_details[${index}].average_tenure_of_lease`}
                    name={`lease_details[${index}].average_tenure_of_lease`}
                    placeholder="Enter Average Tenure of the Lease"
                    value={
                      formik.values.lease_details[index].average_tenure_of_lease
                    }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.average_tenure_of_lease &&
                      formik.errors.average_tenure_of_lease
                    }
                  />
                  <div className="w-100"></div>
                </div>
                <div className="d-flex flex-column flex-lg-row justify-content-between gap-4 input-width">
                  <InputField
                    label="Monthly Obligation (w/​o GST)"
                    type="number"
                    id={`lease_details[${index}].monthly_obligation`}
                    className="w-100"
                    required
                    name={`lease_details[${index}].monthly_obligation`}
                    placeholder="Enter Monthly Obligation"
                    value={
                      formik.values.lease_details[index].monthly_obligation
                    }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.monthly_obligation &&
                      formik.errors.monthly_obligation
                    }
                  />
                  <div className="w-100">
                    <CustomDropDown
                      options={GSTApplied}
                      className="full-width  w-100"
                      title="GST Applied"
                      required
                      value={{
                        label: formik.values.lease_details[index].gst_applied,
                        value: formik.values.lease_details[index].gst_applied,
                      }}
                      titleClass="custom-dropdown-style"
                      onChange={async (option) => {
                        formik.setFieldValue(
                          `lease_details[${index}].gst_applied`,
                          option?.value
                        );
                      }}
                      onBlur={formik.handleBlur(
                        `lease_details[${index}].gst_applied`
                      )}
                      placeholder={"Select GST Applied"}
                    />
                  </div>
                  <div className="w-100">
                    <InputField
                      label="Monthly Obligation (with GST)"
                      type="number"
                      id={`lease_details[${index}].monthly_obligation_with_gst`}
                      className="w-100"
                      required
                      name={`lease_details[${index}].monthly_obligation_with_gst`}
                      placeholder="Enter Monthly Obligation"
                      value={
                        formik.values.lease_details[index]
                          .monthly_obligation_with_gst
                      }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.monthly_obligation_with_gst &&
                        formik.errors.monthly_obligation_with_gst
                      }
                    />
                  </div>
                </div>
              </Fade>
            </div>
          ))}
        </div>
      )}

      <div className="w-100 " style={{ marginTop: "2.5rem" }}>
        {" "}
        {formik.values.ev && (
          <Button
            type="button"
            label="Add another lease"
            onClick={() => addAdditionalFields()}
          />
        )}
      </div>

      <div className="d-flex flex-column flex-md-row justify-content-between gap-4 input-width">
        <InputField
          label="Existing 2W/​L2 vehicles in operation"
          type="text"
          id="Existing2W"
          className="w-100"
          required
          name="Existing2W"
          placeholder="Enter Existing 2W"
          value={formik.values.Existing2W}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.Existing2W && formik.errors.Existing2W}
        />
        <InputField
          label="Existing 3W/​L5 vehicles in operation"
          type="text"
          id="Existing3W"
          name="Existing3W"
          className="w-100"
          required
          placeholder="Enter Existing 3W"
          value={formik.values.Existing3W}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.Existing3W && formik.errors.Existing3W}
        />
      </div>
      <div className="d-flex flex-column flex-md-row justify-content-between gap-4 input-width">
        <InputField
          label="Existing 4W vehicles in operation"
          type="text"
          id="Existing4W"
          className="w-100"
          required
          name="Existing4W"
          placeholder="Enter Existing 4W"
          value={formik.values.Existing4W}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.Existing4W && formik.errors.Existing4W}
        />
        <div className="w-100 d-none d-md-block"></div>
      </div>
      <div className="d-flex flex-column flex-md-row justify-content-between gap-4 input-width">
        <InputField
          label="Number of additional 2W/​L2 requried"
          type="text"
          id="Numberofadditional2W"
          className="w-100"
          required
          name="Numberofadditional2W"
          placeholder="Enter Number of additional 2W"
          value={formik.values.Numberofadditional2W}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.Numberofadditional2W &&
            formik.errors.Numberofadditional2W
          }
        />
        <InputField
          label="Number of additional 3W/​L5 requried"
          type="text"
          id="Numberofadditional3W"
          name="Numberofadditional3W"
          className="w-100"
          required
          placeholder="Enter Number of additional 3W"
          value={formik.values.Numberofadditional3W}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.Numberofadditional3W &&
            formik.errors.Numberofadditional3W
          }
        />
      </div>
      <div className="d-flex flex-column flex-md-row justify-content-between gap-4 input-width">
        <InputField
          label="Number of additional 4W/​L5 requried"
          type="text"
          id="Numberofadditional4W"
          name="Numberofadditional4W"
          className="w-100"
          required
          placeholder="Enter Number of additional 4W"
          value={formik.values.Numberofadditional4W}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.Numberofadditional4W &&
            formik.errors.Numberofadditional4W
          }
        />
        <div className="w-100 d-none d-md-block"></div>
      </div>
      <div className="d-flex flex-column flex-md-row justify-content-between gap-4 input-width">
        <InputField
          label="Current Fleet Utilization Rate (%)"
          type="text"
          id="CurrentfleetUtilization"
          name="CurrentfleetUtilization"
          className="w-100"
          required
          placeholder="Enter Current fleet Utilization"
          value={formik.values.CurrentfleetUtilization}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.CurrentfleetUtilization &&
            formik.errors.CurrentfleetUtilization
          }
        />
        <div className="w-100 d-none d-md-block"></div>
      </div>

      <div className="d-flex gap-3 justify-content-center">
        <Button type="button" label="Back" onClick={handleBack} />
        <Button
          label="Next"
          onClick={formik.handleSubmit}
          disabled={!formik.isValid}
          loading={loading}
        />
      </div>
    </form>
  );
};

export default Form2;
