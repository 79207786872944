import React, { useEffect, useRef, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import Ecosystem from "../../../Components/Ecosystem";
import Parameter from "../../../Components/Parameter";
import { BASE_URL_DEV } from "../../../repository/api";
import Process from "./IndividualComp/Process";
import SmartWaySlider from "./IndividualComp/SmartWaySlider";
import "./VehicleIndividual.css";
import ReactPlayer from "react-player";
import CloseIcon from "../../../Images/closeicon.png";

export default function VehicleIndividual() {
  const [showdata, setShowdata] = useState(false);
  const [showVideoAd, setShowVideoAd] = useState(true);

  // const location = useLocation();
  // const searchParams = new URLSearchParams(location.search);
  // const id = searchParams.get("id");

  const currentRoute = window.location.search;
  const searchParams = new URLSearchParams(currentRoute);
  const id = searchParams.get("id");

  const wrapperRef = useRef({});

  const isMobile = useMediaQuery({ maxWidth: 990 });

  const Responsive = {
    tablet: {
      breakpoint: { max: 990, min: 768 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1,
    },
  };
  const rresponsive = {
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 2,
      slidesToSlide: 2,
    },
  };

  const carouselStyle = {
    padding: "0 5px",
    margin: "0 -5px",
  };

  const itemStyle = {
    margin: "0 10px",
  };

  const isMobileOrTablet = window.innerWidth <= 990;

  const [data, setData] = useState({});
  const [selectedImage, setSelectedImage] = useState(0);
  const [webpImages, setWebpImages] = useState([]);
  const carouselRef = useRef(null);
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const maxLength = 15;
  let truncatedSentence =
    data.thermalManagement &&
    data.thermalManagement.slice(0, maxLength) + "...";
  const htmlString = `<h4 class="view-space" style="font-size:12px!important;">
    ${truncatedSentence}
    <span class="tooltiptext">${data.thermalManagement}</span>
  </h4>`;

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = async () => {
      try {
        setShowdata(true);
        const response = await fetch(
          `${BASE_URL_DEV}/detailed-vehicle-info/${id}`
        );
        const result = await response.json();

        setData(result);
        setShowdata(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const getQueryParam = (param) => {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get(param);
    };

    fetchData();
  }, []);

  const handleImageClick = (index) => {
    setSelectedImage(index);
    // console.log(index);
  };
  const handleCarouselBeforeChange = (oldIndex, newIndex) => {
    const numPhotos = data?.photos?.length;
    const direction = oldIndex > newIndex.currentSlide ? -1 : 1;
    let adjustedIndex = selectedImage - direction;

    if (adjustedIndex < 0) {
      adjustedIndex = numPhotos - 1;
    } else if (adjustedIndex >= numPhotos) {
      adjustedIndex = 0;
    }

    setSelectedImage(adjustedIndex);
  };

  const handleVideoAdClose = () => {
    setShowVideoAd(false);
  };

  const handleVideoEnd = () => {
    handleVideoAdClose();
  };

  const whatsapp_url =
    "https://api.whatsapp.com/send/?phone=919971007201&text&type=phone_number&app_absent=0";
  return (
    <div
      className="Indivi-page "
      style={{ background: "white" }}
      ref={wrapperRef}
    >
      <div>
        <div className="VehicleIndividual container">
          <div className="row">
            <div className="col-12">
              <nav
                aria-label="breadcrumb d-lg-none d-block d-md-none d-sm-block"
                style={{ "--bs-breadcrumb-divider": "'>'" }}
              >
                <ol className="breadcrumb justify-content-lg-start justify-content-center mb-lg-auto mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/fleet_leasing">FleetLease</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {/* {data?.vehicle?.oem} */}
                    {data && data.vehicle && data.vehicle.oem}
                  </li>
                </ol>
              </nav>
            </div>
          </div>

          <div className="row three d-flex justify-content-between pt-4">
            <div
              className=" first ncol-lg-3 d-flex flex-column gap-2"
              style={{
                transform: "translate3d(0px, 0px, 0px)",
                transition: "all 0s ease 0s",
              }}
            >
              {data?.photos?.map((photo, index) => (
                <div
                  className={`owl-item item-active ${
                    selectedImage === index ? "item-active-st" : ""
                  }`}
                  key={index}
                  onClick={() => handleImageClick(index)}
                >
                  <div className="item separate-vehicle">
                    <img
                      key={index}
                      src={photo.photo}
                      alt=""
                      style={{
                        width: "100%",
                        height: "75px",
                        objectFit: "contain",
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>

            <div className="second col-lg-3 specific-vehicle">
              {showVideoAd && data?.videoUrl ? (
                <>
                  {/* Video Ad */}
                  <div style={{ position: "relative" }}>
                    <button
                      onClick={handleVideoAdClose}
                      style={{
                        position: "absolute",
                        top: "0",
                        right: "0",
                        background: "none",
                        border: "none",
                        cursor: "pointer",
                      }}
                    >
                      <span
                        class="d-inline-block h-3 w-3"
                        style={{ backgroundColor: "white", padding: "2px" }}
                      >
                        <img
                          src={CloseIcon}
                          style={{ height: "20px", width: "20px" }}
                          alt=""
                        />
                      </span>
                    </button>
                    <ReactPlayer
                      light={true}
                      muted={true}
                      controls={true}
                      onEnded={handleVideoEnd}
                      autoPlay={true}
                      url={data?.videoUrl}
                      width="100%"
                      height="500px"
                    />
                  </div>
                </>
              ) : // Display the image carousel when showVideoAd is false
              data?.photos &&
                Array.isArray(data.photos) &&
                data.photos.length > 0 ? (
                <Carousel
                  swipeable={false}
                  draggable={false}
                  showDots={false}
                  responsive={responsive}
                  ssr={false}
                  infinite={true}
                  autoPlay={false}
                  autoPlaySpeed={1000}
                  arrows={true}
                  beforeChange={(oldIndex, newIndex) =>
                    handleCarouselBeforeChange(oldIndex, newIndex)
                  }
                >
                  {data?.photos?.map((photo, index) => (
                    <img
                      key={index}
                      src={data?.photos?.[selectedImage]?.photo}
                      alt=""
                      style={{
                        height: "400px",
                        width: "400px",
                        objectFit: "contain",
                      }}
                    />
                  ))}
                </Carousel>
              ) : (
                // If there are no photos available
                <p>No photos available</p>
              )}
            </div>

            <div className=" third col-lg-6">
              <div className="box-warp">
                <span className="vehicle-model  oem-name">
                  <img
                    className="image-adjust"
                    src={data?.oemPic}
                    width="30px"
                    // height="px"
                    alt="OEM Logo"
                  />
                  {/* {data.vehicle.oem} */}
                  {data && data.vehicle && data.vehicle.oem}
                </span>
                <h2 className="h2-heading  model">
                  {/* {data.vehicle.vehicleModel} */}
                  {data?.vehicle?.vehicleModel}
                </h2>
                <ul className="align list-unstyled mb-0 justify-content-lg-start justify-content-md-start justify-content-md-start justify-content-start">
                  <li className="box-warp-li active ">
                    <div className="ps-0" style={{ fontSize: "14px" }}>
                      Available in 15 days
                    </div>
                  </li>
                  <li className="box-warp-li">
                    <div className="ms-2 m-lg-0" style={{ fontSize: "14px" }}>
                      Launched in 2018
                    </div>
                  </li>
                  <li className="box-warp-li ps-0 ">
                    <div style={{ fontSize: "14px" }}>ICAT Approved</div>
                  </li>
                  <li className="box-warp-li">
                    <div
                      className="border-end-0 ms-2 m-lg-0"
                      style={{ fontSize: "14px" }}
                    >
                      AIS156 Certified
                    </div>
                  </li>
                </ul>

                <div className="h5-heading active-fleet mt-4">
                  <p>
                    100+ <span>active fleets pan India.</span>
                  </p>
                </div>
                <div className="align price-box justify-content-between mt-4">
                  <div className="d-inline-block float-start">
                    <p className="caption-fs mb-1">
                      Monthly Lease{" "}
                      <span className="d-lg-inline-block d-none">starting</span>{" "}
                      from
                    </p>
                    {/* <a
                      href={whatsapp_url}
                      target="blank"
                      className="blue-color contact-button-lease"
                      style={{
                        fontWeight: "500",
                        background:
                          "linear-gradient(129.14deg, rgb(103, 194, 165) -5.53%, rgb(31, 180, 134) 117.63%)",
                        borderRadius: "8px",
                        color: "#fff",
                        padding: "0px 8px",
                      }}
                    >
                      Contact Us
                    </a> */}
                    <div style={{ fontWeight: "600" }}>
                      {/* {`₹ ${data?.vehicle?.leaseStartPrice.toLocaleString()}`} */}
                      {data?.vehicle?.leaseStartPrice
                        ? `₹ ${data?.vehicle?.leaseStartPrice?.toLocaleString()}`
                        : "-"}
                    </div>
                  </div>
                  <div className="float-end text-end ps-2">
                    <Link
                      to={`/Request?id=${id}&individual=false`}
                      className="green-btn"
                    >
                      Request To Lease{" "}
                      <img
                        className="tp"
                        src="./images/chevron-right.svg"
                        height="13"
                        alt="Chevron Right"
                      />
                    </Link>
                  </div>
                </div>
                {!isMobileOrTablet ? (
                  <div
                    className="container"
                    style={{ padding: "0px", marginTop: "2rem" }}
                  >
                    <div className="">
                      <ul
                        className="d-flex   list-unstyled mb-0 last-li "
                        style={{
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <li className="box-warp-li ps-0 ">
                          <span className="green-circle"></span>
                          <span className="smail-caption">
                            Seating Capacity
                          </span>
                          <h4>{data.seatingCapacity || 0} Seated</h4>
                        </li>
                        <li className="box-warp-li ps-0 ">
                          <span className="green-circle"></span>
                          <span className="smail-caption">
                            Battery Capacity
                          </span>
                          <h4>{data.batteryCapacity || 0}</h4>
                        </li>
                        <li className="box-warp-li ps-0 ">
                          <span className="green-circle"></span>
                          <span className="smail-caption">Fast Charging</span>
                          <h4>
                            {data.fastChargingTime !== null &&
                            data.fastChargingTime !== 0
                              ? `${data.fastChargingTime} mins`
                              : "-"}
                          </h4>
                        </li>

                        <li className="box-warp-li ps-0 ">
                          <span className="green-circle"></span>
                          <span className="smail-caption">
                            Thermal Management
                          </span>
                          <div
                            dangerouslySetInnerHTML={{ __html: htmlString }}
                          />
                        </li>
                      </ul>
                      <hr />
                      <ul
                        className="d-flex list-unstyled mb-0 last-li pt-0"
                        style={{ justifyContent: "space-between" }}
                      >
                        <li className="box-warp-li ps-0 ">
                          <span className="green-circle"></span>
                          <span className="smail-caption">Payload</span>
                          <h4>{data?.vehicle?.payload || 0} kgs</h4>
                        </li>
                        <li className="box-warp-li ps-0 ">
                          <span className="green-circle"></span>
                          <span className="smail-caption">Max Speed</span>
                          <h4>{data.maxSpeed || 0} kmph</h4>
                        </li>
                        <li className="box-warp-li ps-0 ">
                          <span className="green-circle"></span>
                          <span className="smail-caption">
                            Range W/ Payload
                          </span>
                          <h4>{data?.rangeWPayload || 0} kgs</h4>
                        </li>
                        <li className="box-warp-li ps-0 ">
                          <span className="green-circle"></span>
                          <span className="smail-caption">
                            Range W/O Payload
                          </span>
                          <h4>
                            {data?.vehicle?.rangeWOPayload !== undefined &&
                            data?.vehicle?.rangeWOPayload !== 0
                              ? `${data?.vehicle?.rangeWOPayload} kgs`
                              : "-"}
                          </h4>
                        </li>
                      </ul>
                    </div>
                  </div>
                ) : (
                  <div className="mobile-slider5 mobile mt-4 owl-carousel owl-theme owl-loaded owl-drag">
                    <div className="owl-stage-outer">
                      <div className="owl-stage gap-5">
                        <div className="owl-item">
                          <div className="item">
                            <Carousel
                              swipeable={false}
                              draggable={false}
                              showDots={true}
                              responsive={rresponsive}
                              ssr={true}
                              infinite={false}
                              autoPlay={false}
                              arrows={false}
                            >
                              <div className="box-warp-li  mobile-li-in">
                                <span className="green-circle"></span>
                                <span className="smail-caption">
                                  Seating Capacity
                                </span>
                                <h4>{data.seatingCapacity || 0} Seated</h4>
                              </div>
                              <div className="box-warp-li  mobile-li-in">
                                <span className="green-circle"></span>
                                <span className="smail-caption">
                                  Battery Capacity
                                </span>
                                <h4>{data.batteryCapacity || 0}</h4>
                              </div>
                              <div className="box-warp-li  mobile-li-in">
                                <span className="green-circle"></span>
                                <span className="smail-caption">
                                  Fast Charging
                                </span>
                                <h4>
                                  {data.fastChargingTime !== null &&
                                  data.fastChargingTime !== 0
                                    ? `${data.fastChargingTime} mins`
                                    : "-"}
                                </h4>
                              </div>
                              <div className="box-warp-l mobile-li-in">
                                <span className="green-circle"></span>
                                <span className="smail-caption">
                                  Thermal Management
                                </span>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: htmlString,
                                  }}
                                />
                              </div>
                              <div className="box-warp-li mobile-li-in ">
                                <span className="green-circle"></span>
                                <span className="smail-caption">Payload</span>
                                <h4>{data?.vehicle?.payload || 0} kgs</h4>
                              </div>
                              <div className="box-warp-li mobile-li-in">
                                <span className="green-circle"></span>
                                <span className="smail-caption">Max Speed</span>
                                <h4>{data.maxSpeed || 0} kmph</h4>
                              </div>
                              <div className="box-warp-li mobile-li-in ">
                                <span className="green-circle"></span>
                                <span className="smail-caption">
                                  Range W/ Payload
                                </span>
                                <h4>{data?.rangeWPayload || 0} kgs</h4>
                              </div>
                              <div className="box-warp-li mobile-li-in ">
                                <span className="green-circle"></span>
                                <span className="smail-caption">
                                  Range W/O Payload
                                </span>
                                <h4>
                                  {data?.vehicle?.rangeWOPayload !==
                                    undefined &&
                                  data?.vehicle?.rangeWOPayload !== 0
                                    ? `${data?.vehicle?.rangeWOPayload} kgs`
                                    : "-"}
                                </h4>
                              </div>
                            </Carousel>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="container">
          <div className="row mt-5 d-flex " style={{ justifyContent: "end" }}>
            <div
              className=" dimensions
        mt-5 tabs"
            >
              <h3 className="h3-heading">
                {data?.vehicle?.oem || "Vehicle"}
                Specifications
              </h3>
              <div className="scroll-frame">
                <div className="scroll-tab">
                  <ul
                    className="nav nav-pills gap-3 flex-nowrap"
                    id="pills-tab"
                    role="tablist"
                  >
                    <li className=" pb-lg-3 mb-0" role="presentation">
                      <button
                        className="nav-link active"
                        id="pills-1-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-1"
                        type="button"
                        role="tab"
                        aria-controls="pills-1"
                        aria-selected="true"
                      >
                        Dimensions
                      </button>
                    </li>
                    <li className=" pb-lg-3 mb-0" role="presentation">
                      <button
                        className="nav-link"
                        id="pills-2-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-2"
                        type="button"
                        role="tab"
                        aria-controls="pills-2"
                        aria-selected="false"
                        tabindex="-1"
                      >
                        Drivetrain
                      </button>
                    </li>
                    <li className=" pb-lg-3 mb-0" role="presentation">
                      <button
                        className="nav-link"
                        id="pills-3-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-3"
                        type="button"
                        role="tab"
                        aria-controls="pills-3"
                        aria-selected="false"
                        tabindex="-1"
                      >
                        Charging
                      </button>
                    </li>
                    <li className=" pb-lg-3 mb-0" role="presentation">
                      <button
                        className="nav-link"
                        id="pills-4-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-4"
                        type="button"
                        role="tab"
                        aria-controls="pills-4"
                        aria-selected="false"
                        tabindex="-1"
                      >
                        Vehicle Warranty
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="tab-content mt-lg-4 mt-2" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="pills-1"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                >
                  <ul className="list-unstyled">
                    <li className="tab-list">
                      <div className="row">
                        <div className="col-8">
                          <h5 className="h5-heading fw-light">Kerb Weight</h5>
                        </div>
                        <div className="col-4 align justify-content-end">
                          <h5 className="h5-heading value">
                            {data.kerbWeight || 0} kgs
                          </h5>
                        </div>
                      </div>
                    </li>
                    <li className="tab-list">
                      <div className="row">
                        <div className="col-8">
                          <h5 className="h5-heading fw-light">Overall Width</h5>
                        </div>
                        <div className="col-4 align justify-content-end">
                          <h5 className="h5-heading value">
                            {data.overallWidth || 0} ft
                          </h5>
                        </div>
                      </div>
                    </li>
                    <li className="tab-list">
                      <div className="row">
                        <div className="col-8">
                          <h5 className="h5-heading fw-light">
                            Overall Height
                          </h5>
                        </div>
                        <div className="col-4 align justify-content-end">
                          <h5 className="h5-heading value">
                            {data.overallHeight || 0} ft
                          </h5>
                        </div>
                      </div>
                    </li>
                    <li className="tab-list">
                      <div className="row">
                        <div className="col-8">
                          <h5 className="h5-heading fw-light">
                            Overall Length
                          </h5>
                        </div>
                        <div className="col-4 align justify-content-end">
                          <h5 className="h5-heading value">
                            {data?.overallLength || 0} ft
                          </h5>
                        </div>
                      </div>
                    </li>
                    <li className="tab-list">
                      <div className="row">
                        <div className="col-8">
                          <h5 className="h5-heading fw-light">
                            Gross Vehicle Weight
                          </h5>
                        </div>
                        <div className="col-4 align justify-content-end">
                          <h5 className="h5-heading value">
                            {data.grossVehicleWeight || 0} kgs
                          </h5>
                        </div>
                      </div>
                    </li>
                    <li className="tab-list">
                      <div className="row">
                        <div className="col-8">
                          <h5 className="h5-heading fw-light">
                            Container Dimension (lxbxh)ft
                          </h5>
                        </div>
                        <div className="col-4 align justify-content-end">
                          <h5 className="h5-heading value">
                            {data.containerDimension || 0}
                          </h5>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div
                  className="tab-pane fade"
                  id="pills-2"
                  role="tabpanel"
                  aria-labelledby="pills-profile-tab"
                >
                  <span className="tab-list-head">Motor Information</span>
                  <ul className="list-unstyled">
                    <li className="tab-list">
                      <div className="row">
                        <div className="col-8">
                          <h5 className="h5-heading fw-light">Max Speed</h5>
                        </div>
                        <div className="col-4 align justify-content-end">
                          <h5 className="h5-heading">
                            {data.maxSpeed || 0} secs
                          </h5>
                        </div>
                      </div>
                    </li>
                    <li className="tab-list">
                      <div className="row">
                        <div className="col-8">
                          <h5 className="h5-heading fw-light">Power(peak)</h5>
                        </div>
                        <div className="col-4 align justify-content-end">
                          <h5 className="h5-heading">
                            {data.powerPeak || 0} km
                          </h5>
                        </div>
                      </div>
                    </li>
                    <li className="tab-list">
                      <div className="row">
                        <div className="col-8">
                          <h5 className="h5-heading fw-light">
                            Max Gradability
                          </h5>
                        </div>
                        <div className="col-4 align justify-content-end">
                          <h5 className="h5-heading">
                            {data.maxGradability || 0} km
                          </h5>
                        </div>
                      </div>
                    </li>
                    <li className="tab-list">
                      <div className="row">
                        <div className="col-8">
                          <h5 className="h5-heading fw-light">Motor Power</h5>
                        </div>
                        <div className="col-4 align justify-content-end">
                          <h5 className="h5-heading">
                            {data.motorPower || 0} watts
                          </h5>
                        </div>
                      </div>
                    </li>
                    <li className="tab-list">
                      <div className="row">
                        <div className="col-8">
                          <h5 className="h5-heading fw-light">Transmission</h5>
                        </div>
                        <div className="col-4 align justify-content-end">
                          <h5 className="h5-heading">
                            {data.transmission || 0}
                          </h5>
                        </div>
                      </div>
                    </li>
                    <li className="tab-list">
                      <div className="row">
                        <div className="col-8">
                          <h5 className="h5-heading fw-light">Torque (Peak)</h5>
                        </div>
                        <div className="col-4 align justify-content-end">
                          <h5 className="h5-heading">
                            {data.peakTorque || 0} km
                          </h5>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div
                  className="tab-pane fade"
                  id="pills-3"
                  role="tabpanel"
                  aria-labelledby="pills-contact-tab"
                >
                  <span className="tab-list-head">Charger Information</span>
                  <ul className="list-unstyled">
                    <li className="tab-list">
                      <div className="row">
                        <div className="col-8">
                          <h5 className="h5-heading fw-light">Charging Time</h5>
                        </div>
                        <div className="col-4 align justify-content-end">
                          <h5 className="h5-heading">
                            {data?.vehicle?.chargingTime || 0} mins
                          </h5>
                        </div>
                      </div>
                    </li>
                    <li className="tab-list">
                      <div className="row">
                        <div className="col-8">
                          <h5 className="h5-heading fw-light">
                            Charging Compatibility
                          </h5>
                        </div>
                        <div className="col-4 align justify-content-end">
                          <h5 className="h5-heading text-nowrap">
                            {data.chargingCompatibility || 0}
                          </h5>
                        </div>
                      </div>
                    </li>
                    <li className="tab-list">
                      <div className="row">
                        <div className="col-8">
                          <h5 className="h5-heading fw-light">Fast Charging</h5>
                        </div>
                        <div className="col-4 align justify-content-end">
                          <h5 className="h5-heading">
                            {data.fastChargingTime || 0} mins
                          </h5>
                        </div>
                      </div>
                    </li>
                    <li className="tab-list">
                      <div className="row">
                        <div className="col-8">
                          <h5 className="h5-heading fw-light">
                            On-board Charger
                          </h5>
                        </div>
                        <div className="col-4 align justify-content-end">
                          <h5 className="h5-heading">
                            {data.onBoardACCharger || false}
                          </h5>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div
                  className="tab-pane fade"
                  id="pills-4"
                  role="tabpanel"
                  aria-labelledby="pills-contact-tab"
                >
                  <span className="tab-list-head">Warranty Details</span>
                  <ul className="list-unstyled">
                    <li className="tab-list">
                      <div className="row">
                        <div className="col-8">
                          <h5 className="h5-heading fw-light">Cycles</h5>
                        </div>
                        <div className="col-4 align justify-content-end">
                          <h5 className="h5-heading">
                            {data.warrentyCycles || 0} secs
                          </h5>
                        </div>
                      </div>
                    </li>
                    <li className="tab-list">
                      <div className="row">
                        <div className="col-8">
                          <h5 className="h5-heading fw-light">KM</h5>
                        </div>
                        <div className="col-4 align justify-content-end">
                          <h5 className="h5-heading">
                            {data.warrentyKMS || 0}
                          </h5>
                        </div>
                      </div>
                    </li>
                    <li className="tab-list">
                      <div className="row">
                        <div className="col-8">
                          <h5 className="h5-heading fw-light">Years</h5>
                        </div>
                        <div className="col-4 align justify-content-end">
                          <h5 className="h5-heading">
                            {data.warrentyYears || 0}
                          </h5>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Parameter />
        <Ecosystem />
        <SmartWaySlider />
        <Process />
      </div>
    </div>
  );
}
