import React, { useState, useEffect } from "react";
import "./EnquireFormComponents.css";
import EnquireFormImage from "../Images/enquireformimage.png";
import FleetIcons from "../Images/fleeticon.png";
import Driverwhite from "../Images/driverwhite.png";
import Fleetwhite from "../Images/fleetwhite.png";
import Partnerwhite from "../Images/partnerwhite.png";
import PartnerIcons from "../Images/partnericons.png";
import DriverIcons from "../Images/drivericons.png";
import EnquiryDriver from "../Components/EnquiryDriver";
import EnquiryFleet from "../Components/EnquiryFleet";
import EnquiryPartner from "../Components/EnquiryPartner";
// import Chatting_box from "../Images/chatting_box.png";
// import ChatUi from "./ChatUi";
import { useLocation, useNavigate } from "react-router-dom";
import { BASE_URL } from "../repository/api";

function getQueryParam(param) {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(param);
}
const id = getQueryParam("oem");

// let API = `https://alt-backend.alt-mobility.com/api/vehicle/marketing-data/?oem=${id}`;
let API = `${BASE_URL}/marketing-data/?oem=${id}`;

const EnquireFormComponents = () => {
  const location = useLocation();
  const navigation = useNavigate();
  const [enquiryProfile, setEnquiryProfile] = useState("");

  const [toggle, settoggle] = useState({
    Driver:
      location.hash.split("#")[1] === "Driver" || location.hash === "" || false,
    Fleet: location.hash.split("#")[1] === "Fleet" || false,
    Partner: location.hash.split("#")[1] === "Partner" || false,
  });

  const imgChange = (e) => {
    if (
      location.hash.split("#")[1] === "Partner" ||
      location.hash.split("#")[1] === "Driver"
    )
      navigation("/enquire");
    settoggle({
      Driver: false,
      Fleet: false,
      Partner: false,
      [e.target.getAttribute("name")]: true,
    });
  };
  const arr = [
    {
      name: "Driver",
      activeimage: Driverwhite,
      inactiveimage: DriverIcons,
    },
    {
      name: "Fleet",
      activeimage: Fleetwhite,
      inactiveimage: FleetIcons,
    },
    {
      name: "Partner",
      activeimage: Partnerwhite,
      inactiveimage: PartnerIcons,
    },
  ];

  useEffect(() => {
    // navigation("/enquire#Driver");
    const partnerElement = document.getElementById("scrollInto");
    partnerElement.scrollIntoView({
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    fetch(API)
      .then((response) => response.json())
      .then((data) => {
        if (window.location.search.includes(`oem=${id}`)) {
          setEnquiryProfile(data.data.vehicle.photos[0]?.photo);
        } else if (window.location.search.includes("/enquire")) {
          setEnquiryProfile(EnquireFormImage);
        } else {
          setEnquiryProfile(EnquireFormImage);
        }

        console.log(data.data.vehicle.photos[0]?.photo);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <div className="home-mainss">
      <div className="container">
        <div
          className="joinIndias row paddingsmall ms-0 w-100 mt-md-5 pt-md-5"
          id="one"
        >
          <div className="enquire-form-title col-md-12 col-lg-6 col-sm-12 mt-5">
            <h1 className="main">Join India's largest EV Ecosystem</h1>
            <p className="main">
              Select your role and fill out our enquiry form to help us connect
              you to the right Alt Mobility team.
            </p>
          </div>
          {enquiryProfile && (
            <div className="col-md-12 col-lg-6 col-sm-12" id="formimage">
              <img
                src={enquiryProfile}
                alt=""
                className="img-responsive w-100"
              />
            </div>
          )}
        </div>

        <div
          className="form-selext-box mt-5 justify-content-center gap-4 d-flex nav nav forScroll "
          role="tablist"
          id="scrollInto"
        >
          {arr.map((element, index) => (
            <button
              style={{
                backgroundColor: toggle[element.name] ? "#67C2A5" : "#fff",
              }}
              key={index}
              name={element.name}
              className="enquire-form-btn p-2 text-center alt-card nav-link active"
              data-bs-target=""
              type="button"
              role="tab"
              aria-controls=""
              aria-selected="true"
              onClick={imgChange}
            >
              <img
                src={
                  toggle[element.name]
                    ? element.activeimage
                    : element.inactiveimage
                }
                alt="abc"
                name={element.name}
              />
              <h5
                className={toggle[element.name] ? "text-white" : "text-dark"}
                name={element.name}
              >
                {element.name}
              </h5>
            </button>
          ))}
        </div>

        <div className="tab-content">
          {toggle.Driver && (
            <div
              className="active p-3"
              id="pills-profile"
              role="tabpanel"
              aria-labelledby="pills-profile-tab"
            >
              <EnquiryDriver />
            </div>
          )}
          {toggle.Fleet && (
            <div
              className="p-3"
              id="pills-profile"
              role="tabpanel"
              aria-labelledby="pills-profile-tab"
            >
              <EnquiryFleet />
            </div>
          )}
          {toggle.Partner && (
            <div
              className="p-3"
              id="pills-profile"
              role="tabpanel"
              aria-labelledby="pills-profile-tab"
            >
              <EnquiryPartner />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EnquireFormComponents;
