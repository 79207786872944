import { ErrorMessage } from "@hookform/error-message";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";
import Correct from "../Images/Vector.svg";
import "./VehicleRequirement.css";

const validationSchema = Yup.object().shape({
  city: Yup.string()

    .required("City is required")
    .required("City is required")
    .matches(/^[a-zA-Z\s]*$/, "City should not contain digits")
    .min(3, "City should be at least 3 characters")
    .max(50, "City can't exceed 50 characters"),

  pincode: Yup.string()

    .required("Pin Code is required")
    .matches(/^\d{6}$/, "Pin Code should be a 6-digit number"),

  vehiclesnumber: Yup.number()

    .required("Number of vehicles is required")
    .typeError("Please enter a valid number")
    .positive("Please enter a positive number")
    .integer("Please enter a whole number"),

  tenure: Yup.number().required("Tenure is required"),

  estimated: Yup.date().nullable().typeError("Please enter a valid date"),
});

const VehicleRequirement = ({
  onData,
  handleTabClick,
  firstTab,
  firstForm,
  cost,
  onForm1Submit,
}) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  let id = searchParams.get("id");
  const [addonId, setAddonId] = useState([]);
  const [data, setData] = useState({});
  const currentDate = new Date();
  const currentDateString = currentDate.toISOString().split("T")[0];
  const [selectedAddons, setSelectedAddons] = useState([]);
  const [addonOptions, setAddonOptions] = useState([]);

  const isValidNumber = (value) => !isNaN(value) && typeof value === 'number';

  useEffect(() => {
    localStorage.setItem("cost", cost);
  }, [cost]);

  const baseCost = parseInt(localStorage.getItem("cost"));
  const [totalcost, setTotalcost] = useState(baseCost);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await axios.get(`${BASE_URL_FLEETOS}/v2/vehicleAddOn`);
  //       setAddonOptions(response.data.data);
  //     } catch (error) {
  //       console.log("error");
  //     }
  //   };

  //   fetchData();
  // }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      estimated: null,
    },
  });

  const handleAddonCheckboxChange = (aid, addonName, addonPrice) => {
    const price = Number(addonPrice);
    if (!isValidNumber(price)) return;

    const isSelected = selectedAddons.some(addon => addon.name === addonName);
    let updatedTotalCost = totalcost;

    if (isSelected) {
      updatedTotalCost -= price;
      setAddonId((prevIds) => prevIds.filter((id) => id !== Number(aid)));

    } else {
      updatedTotalCost += price;
      setAddonId((prevIds) => [...prevIds, Number(aid)]);
    }

    if (isValidNumber(updatedTotalCost)) {
      setTotalcost(updatedTotalCost);
    } else {
      console.error('Invalid updatedTotalCost:', updatedTotalCost);
    }

    if (isSelected) {
      setSelectedAddons(prevAddons => prevAddons.filter(addon => addon.name !== addonName));
    } else {
      setSelectedAddons(prevAddons => [...prevAddons, { name: addonName, cost: price }]);
    }
  };

  // const handleAddonCheckboxChange = (aid, addonName, addonPrice) => {
  //   const isSelected = selectedAddons.some((addon) => addon.name === addonName);

  //   if (isSelected) {
  //     const updatedTotalCost = totalcost - addonPrice;
  //     setTotalcost(updatedTotalCost);
  //     setAddonId((prevIds) => prevIds.filter((id) => id !== aid));
  //   } else {
  //     const updatedTotalCost = totalcost + addonPrice;
  //     setTotalcost(updatedTotalCost);
  //     setAddonId((prevIds) => [...prevIds, aid]);
  //   }

  //   // Update the selectedAddons state
  //   if (isSelected) {
  //     setSelectedAddons((prevAddons) =>
  //       prevAddons.filter((addon) => addon.name !== addonName)
  //     );
  //   } else {
  //     setSelectedAddons((prevAddons) => [
  //       ...prevAddons,
  //       { name: addonName, cost: addonPrice },
  //     ]);
  //   }
  // };

  const sentSecondForm = (data, addonId, totalcost) => {
    onData(data, addonId, totalcost);
  };

  const onSubmit = async (data) => {
    try {
      firstForm();
      await handleTabClick("tab2");
      firstTab();
      // onForm1Submit(data);

      setData(data);
      sentSecondForm(data, addonId, totalcost);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
    console.log(data);
  };
  const handleKeyDown = (e) => {
    e.preventDefault();
  };

  return (
    <div className="form-container">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row down mt-4">
          <div className="col-lg-6 col-sm-12 input-field">
            <label htmlFor="city">
              City<span className="star">*</span>
            </label>
            <div>
              <Form.Control
                className="style"
                type="text"
                id="city"
                placeholder="Enter City"
                {...register("city")}
              />
              <p className="form-error">
                <ErrorMessage errors={errors} name="city" />
              </p>
            </div>
          </div>
          <div className="col-lg-6 col-sm-12 input-field">
            <label htmlFor="pincode">
              Pin Code<span className="star">*</span>
            </label>
            <div>
              <Form.Control
                className="style"
                type="number"
                id="pincode"
                placeholder="Enter Pin Code"
                {...register("pincode")}
              />
              <p className="form-error">
                <ErrorMessage errors={errors} name="pincode" />
              </p>
            </div>
          </div>
        </div>

        <div className="row down mt-4">
          <div className="col-lg-6 col-sm-12 input-field">
            <label htmlFor="vehiclesnumber">
              No. of vehicles to be leased<span className="star">*</span>
            </label>
            <div>
              <Form.Control
                className="style"
                type="text"
                id="vehiclesnumber"
                placeholder="Enter Number of Vehicles"
                {...register("vehiclesnumber")}
              />
              <p className="form-error">
                <ErrorMessage errors={errors} name="vehiclesnumber" />
              </p>
            </div>
          </div>
          <div className="col-lg-6 col-sm-12 input-field">
            <label htmlFor="tenure">
              Tenure(months)<span className="star">*</span>
            </label>
            <div>
              <Form.Select
                size="lg"
                className="style"
                id="tenure"
                {...register("tenure")}
              >
                <option>24 </option>
                <option>60 </option>
              </Form.Select>
              <p className="form-error">
                <ErrorMessage errors={errors} name="tenure" />
              </p>
            </div>
          </div>
        </div>
        <div className="row down mt-4">
          <div className="col-lg-6 col-sm-12 input-field">
            <label htmlFor="estimated">Estimated Date of Delivery</label>
            <div>
              <Form.Control
                className="style"
                type="date"
                id="estimated"
                min={currentDateString}
                {...register("estimated", {
                  valueAsDate: true,
                })}
                onKeyDown={handleKeyDown}
              />

              <p className="form-error">
                <ErrorMessage errors={errors} name="estimated" />
              </p>
            </div>
          </div>
        </div>

        <div className="inclusive-container">
          <div>
            <h1
              style={{
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "21px",
                color: "#262728",
              }}
            >
              Inclusive:
            </h1>
          </div>
          <div className="insurance d-flex gap-4">
            <div>
              <div className="d-flex zero">
                <img src={Correct} alt="" />
                <p>Zero Deprisiation Vehicle Insurance</p>
              </div>
              <div className="d-flex zero">
                <img src={Correct} alt="" />
                <p>Roadside & Breakdown assistance</p>
              </div>
            </div>
            <div>
              <div className="d-flex zero">
                <img src={Correct} alt="" />
                <p>FleetOS</p>
              </div>
              <div className="d-flex zero">
                <img src={Correct} alt="" />
                <p>Extended warranty</p>
              </div>
            </div>
          </div>
        </div>
        <div className="add-ons mt-4">
          <h3
            style={{
              fontSize: "14px",
              fontWeight: "600",
              lineHeight: "21px",
              color: "#262728",
            }}
          >
            Add ons:
          </h3>

          <div className="checkbox-container">
            {addonOptions?.map((addon) => (
              <div
                key={addon.id}
                className="addon-checkbox d-flex justify-content-between"
              >
                <div className="d-flex inp-p">
                  <input
                    type="checkbox"
                    onChange={() =>
                      handleAddonCheckboxChange(
                        addon.id,
                        addon.add_on_name,
                        addon.price
                      )
                    }
                  />
                  <p>{addon.add_on_name}</p>
                </div>
                <div className="d-flex align-center rupees">
                  <h5 className="mb-0">₹ {addon.price}</h5>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="lease-cost inp-p d-flex justify-content-between">
          <p>
            Total Lease Cost:{" "}
            <span style={{ fontSize: "18px", fontWeight: "700" }}>
              ₹ {totalcost}
            </span>
          </p>
          <button
            onClick={sentSecondForm(data, addonId, totalcost)}
            type="submit"
            className="save"
          >
            Save & Next
          </button>
        </div>
      </form>
    </div>
  );
};

export default VehicleRequirement;
