import React from 'react'
import "./Parameter.css";
import wallet from "../Images/wallet.png";
import tax from "../Images/tax_benefits.png";
import oem from "../Images/oem.png";
import fleet from "../Images/fleet_management.png";
import affordable from "../Images/affordable.png";
import daily_distance from "../Images/daily_distance.png";
import warranty from "../Images/warranty.png"
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useMediaQuery } from "react-responsive";


const Loanmeter = () => {
  const isMobile = useMediaQuery({ maxWidth: 990 });

  const responsive = {
    tablet: {
      breakpoint: { max: 990, min: 450 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 450, min: 0 },
      items: 1,
    },
  };

  const carouselStyle = {
    padding: "0 5px",
    margin: "0 -5px",
  };

  const itemStyle = {
    margin: "0 10px",
  };

  const isMobileOrTablet = window.innerWidth <= 990;
  return (
    <>
    <div className='container'>    
          {isMobileOrTablet ? (<div className='row mt-5' >
    <Carousel
                responsive={responsive}
                arrows={false}
                // className="text-start mt-4 mb-4 ms-0 pb-5"
                className="text-start mt-4 mb-4 ms-0 pb-5 mx-auto" 
                // autoPlay={isMobile}
                showDots={isMobile}
                // autoPlaySpeed={3000}
                infinite={true}
                style={carouselStyle}
              >
       <div className=' col mt-4 ms-4'>
       <div className='tab1-parameters'>
            <div>
                <h3 className='key-h'>Key Parameters</h3>
            </div>
            <h5 className='key-title'><img src={wallet} alt='f1' width={"20px"} height={"16px"}/>&nbsp; &nbsp;EMI (per month)</h5>
            <hr/>
            <h5 className='key-title'><img src={tax} alt='f1' width={"20px"} height={"16px"}/>&nbsp; &nbsp;Insurance (per month)</h5>
            <hr/>
            <h5 className='key-title'> <img src={oem} alt='f1' width={"20px"} height={"16px"}/>&nbsp; &nbsp;Service & Maintenance</h5>
            <hr/>
            <h5 className='key-title'><img src={warranty} alt='f1' width={"20px"} height={"16px"}/>&nbsp; &nbsp;Warranty Management</h5>
            <hr/>
            <h5 className='key-title'><img src={daily_distance} alt='f1' width={"20px"} height={"16px"}/>&nbsp; &nbsp;Road Side Assistance</h5>
            <hr/>
            <h5 className='key-title'><img src={fleet} alt='f1' width={"20px"} height={"16px"}/>&nbsp; &nbsp;Advanced Telematics</h5>
            <hr/>
            <h5 className='key-title'><img src={affordable} alt='f1' width={"20px"} height={"16px"}/>&nbsp; &nbsp;Monthly Operating Cost</h5>
            
       </div>
        
       </div>
       <div className='  col mt-4 ms-4'>
       <div className='tab1-parameters'>
       <div>
                <h3 className='loan-h'>Loan</h3>
                <h5 className='loan-title '>₹ 14,618</h5>
            <hr/>
            <h5 className='loan-title'>₹ 600</h5>
            <hr/>
            <h5 className='loan-title'>₹ 1,500</h5>
            <hr/>
            <h5 className='loan-title'>-</h5>
            <hr/>
            <h5 className='loan-title'>₹ 100</h5>
            <hr/>
            <h5 className='loan-title'>₹ 200</h5>
            <hr/>
            <h5 className='loan-title'>₹ 17,001.33</h5>
            
            </div>
            </div>
            </div>
       
       <div className='  col mt-4 ms-4'>
       <div className='tab1-parameters'>
       <div>
                <h3 className='fleet-h'>FleetLease</h3>
                <h5 className='loan-title'>₹ 13,900</h5>
            <hr/>
            <h5 className='loan-title'>Included</h5>
            <hr/>
            <h5 className='loan-title'>Included</h5>
            <hr/>
            <h5 className='loan-title'>Included</h5>
            <hr/>
            <h5 className='loan-title'>Included</h5>
            <hr/>
            <h5 className='loan-title'>Included</h5>
            <hr/>
            <h5 className='loan-title'>₹ 13,900.01</h5>
            
            </div>
            </div>
            
            </div>
            
            </Carousel>
            </div>
             ) : (<div className='row mt-5'>
              
       <div className=' col mt-4 '>
       <div className='tab1-parameters'>
            <div>
                <h3 className='key-h'>Key Parameters</h3>
            </div>
            <h5 className='key-title'><img src={wallet} alt='f1' width={"20px"} height={"16px"}/>&nbsp; &nbsp;EMI (per month)</h5>
            <hr/>
            <h5 className='key-title'><img src={tax} alt='f1' width={"20px"} height={"16px"}/>&nbsp; &nbsp;Insurance (per month)</h5>
            <hr/>
            <h5 className='key-title'> <img src={oem} alt='f1' width={"20px"} height={"16px"}/>&nbsp; &nbsp;Service & Maintenance</h5>
            <hr/>
            <h5 className='key-title'><img src={warranty} alt='f1' width={"20px"} height={"16px"}/>&nbsp; &nbsp;Warranty Management</h5>
            <hr/>
            <h5 className='key-title'><img src={daily_distance} alt='f1' width={"20px"} height={"16px"}/>&nbsp; &nbsp;Road Side Assistance</h5>
            <hr/>
            <h5 className='key-title'><img src={fleet} alt='f1' width={"20px"} height={"16px"}/>&nbsp; &nbsp;Advanced Telematics</h5>
            <hr/>
            <h5 className='key-title'><img src={affordable} alt='f1' width={"20px"} height={"16px"}/>&nbsp; &nbsp;Monthly Operating Cost</h5>
            
       </div>
        
       </div>
       <div className='  col mt-4 '>
       <div className='tab1-parameters'>
       <div>
                <h3 className='loan-h'>Loan</h3>
                <h5 className='loan-title '>₹ 14,618</h5>
            <hr/>
            <h5 className='loan-title'>₹ 600</h5>
            <hr/>
            <h5 className='loan-title'>₹ 1,500</h5>
            <hr/>
            <h5 className='loan-title'>-</h5>
            <hr/>
            <h5 className='loan-title'>₹ 100</h5>
            <hr/>
            <h5 className='loan-title'>₹ 200</h5>
            <hr/>
            <h5 className='loan-title'>₹ 17,001.33</h5>
            
            </div>
            </div>
            </div>
       
       <div className='  col mt-4 '>
       <div className='tab1-parameters'>
       <div>
                <h3 className='fleet-h'>FleetLease</h3>
                <h5 className='loan-title'>₹ 13,900</h5>
            <hr/>
            <h5 className='loan-title'>Included</h5>
            <hr/>
            <h5 className='loan-title'>Included</h5>
            <hr/>
            <h5 className='loan-title'>Included</h5>
            <hr/>
            <h5 className='loan-title'>Included</h5>
            <hr/>
            <h5 className='loan-title'>Included</h5>
            <hr/>
            <h5 className='loan-title'>₹ 13,900.01</h5>
            
            </div>
            </div>
            
            </div>
             </div>)}
            </div>


            <p className='parameter-para mt-4'>*This calculation is based on 3 Year Loan Tenure, 18% interest, and 4 Year FleetLease with 2,600 km monthly annual mileage.</p>
       
    
    </>
      
    
  )
}

export default Loanmeter;
