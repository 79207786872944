import React, { useState } from "react";
import DesktopView from "./DesktopView";
import MobileView from "./MobileView";

const Home = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth > 340 && window.innerWidth < 450);

    return isMobile
        ? <MobileView />
        : <DesktopView />
};

export default Home;
