import React, { useState } from "react";
import DeskTopHeader from "./DeskTopHeader";
import MobileHeader from "./MobileHeader";

const Header = ({ activeLink, handleLinkClick }) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth > 340 && window.innerWidth < 450);

    return isMobile
        ? <MobileHeader activeLink={activeLink} handleLinkClick={handleLinkClick} />
        : <DeskTopHeader activeLink={activeLink} handleLinkClick={handleLinkClick} />;
};

export default Header;