// import React from "react";
import React, { useEffect, useRef } from "react";
import "./TermsForLease.css";
const StandardTerms = ({ updateSubmenu, removeSubmenu}) => {
 

  const headingsRef = useRef([]);
  const observerRef = useRef(null);

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };

    observerRef.current = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (entry.target.tagName === "H3") {
            updateSubmenu(entry.target.textContent);
          }
        } else {
          // If the element is not intersecting, remove it from the parent's submenu
          if (entry.target.tagName === "H3") {
            removeSubmenu(entry.target.textContent);
          }
        }
      });
    }, observerOptions);

    headingsRef.current.forEach((heading) => {
      if (heading) {
        observerRef.current.observe(heading);
      }
    });

    return () => {
      // Cleanup the observer when the component unmounts
      if (observerRef.current) {
        headingsRef.current.forEach((heading) => {
          if (heading) {
            observerRef.current.unobserve(heading);
          }
        });
      }
    };
  }, [updateSubmenu, removeSubmenu]);
  return (
    <div className="">
      <h2 className="standard-lease-heading">Standard terms</h2>
      <h3 className="standard_h3" ref={(el) => headingsRef.current.push(el)}>Request Form</h3>
      <p className="standard_p">
        1. To Order Vehicles, Customer shall submit Request Forms (to be filled
        online at [www.alt-mobility.com] or submitted in a format provided) as
        required by the EV Management Company.
      </p>
      <p className="standard_p">
        2. Cost on account of cancellation or modification of Request Form once
        issued shall be on account of the Customer.
      </p>
      <h3 className="standard_h3" ref={(el) => headingsRef.current.push(el)}>Asset Ownership</h3>
      <p className="standard_p">
        Ownership of the vehicle shall at all times during the tenure vest with,
        or to order of, the Lessor. Registered and Legal Ownership of the EV’s
        shall be transferred to ALT NRG Private Limited upon termination of
        lease if not opted for ownership transfer by the Customer. Customer may
        request for transfer of ownership upon payment of Termination
        Charges/Foreclosure Charges.
      </p>
      <h3 className="standard_h3" ref={(el) => headingsRef.current.push(el)}>Option at the expiry of Lease</h3>
      <p className="standard_p">
        1. Upon expiry of lease period, the Customer shall have option to
        purchase the EVs after payment of Termination Charges/Foreclosure Value.{" "}
      </p>
      <p className="standard_p">
        {" "}
        2. In the event Customer opts not to purchase the EVs, EV Management
        Company shall repossess (customer shall deliver within 20 km range from
        Delivery Location) the EVs and inspect for damages/ errors/ structural
        changes in the EVs and refurbishment/repair/rectification required in
        that regard. All costs for refurbishment/repair/rectification shall be
        borne by Customer and adjusted from balance Security Deposit available
        or recovered from Customer (as the case may be).
      </p>
      <p className="standard_p">
        3. In case of pre-mature termination of lease for any reason whatsoever,
        the Customer shall pay Termination Charges/Foreclosure Charges
        irrespective of whether Customer purchases the EVs or not.
      </p>
      <h3 className="standard_h3" ref={(el) => headingsRef.current.push(el)}>Penalty for non-compliances/delay in payment </h3>
      <p className="standard_p">
        1. Delay in payment of Monthly Rental – 2% per month on defaulted
        amount.
      </p>
      <p className="standard_p">
        2. Delay in Activation NACH mandate or Failure of payment through NACH –
        2% per week on the default/unpaid amounts, and all other charges as may
        be imposed by NACH financial institutions.
      </p>
      <p className="standard_p">
        3. Delay in AMC services as per OEM Manual or advised by the EV
        Management company – 2% per week of the monthly lease rental amount.
      </p>
      <p className="standard_p">
        4. Failure/delay in submission of quarterly maintenance report and
        financial data – 2% per month of the monthly lease rental amount.
      </p>
      <p className="standard_p">
        5. Failure in installation and/or non-maintenance of IoT Devices – 2%
        per week of the monthly lease rental amount. (if applicable)
      </p>
      <p className="standard_p">
        6. Delay in providing Security Deposit – 2% per month on the amount of
        Security Deposit.
      </p>
      <p className="standard_p">
        7. Delay in payment of Add on Charges and Processing Fee - 2% per month
        on the defaulted amount.{" "}
      </p>
      <p className="standard_p">
        8. Non-payment or delay in payment of fines/penalties imposed by
        Government Authorities – 2% per week on the fines/ penalties amount.
      </p>
      <h3 className="standard_h3" ref={(el) => headingsRef.current.push(el)}>Payment Mode</h3>
      <p className="standard_p">
        Monthly Rentals shall be paid in advance through NACH. Customer shall
        provide NACH mandate in favour of EV Management Company at the time of
        order placement and/or before delivery of EVs.{" "}
      </p>
      <p className="standard_p">
        All Add on Charges, Processing Fee and other cost shall be paid upfront
        by Customer directly to the account of the EV Management Company.
      </p>
      <h3 className="standard_h3" ref={(el) => headingsRef.current.push(el)}>Battery Capacity</h3>
      <p className="standard_p">As per specifications provided by OEM and warranty policy of OEM.</p>
      <p className="standard_p">
        EV Management Company shall not be responsible for performance parameter
        and battery capacity.
      </p>
      <h3 className="standard_h3" ref={(el) => headingsRef.current.push(el)}>Warranty</h3>
      <p className="standard_p">As per OEM Documents.</p>
      <h3 className="standard_h3" ref={(el) => headingsRef.current.push(el)}>Post Delivery Inspection (PDI)</h3>
      <p className="standard_p">
        1. Delivery will be made at the place/premises informed by the Lessor.
      </p>
      <p className="standard_p">
        2. Customer may carry a post-delivery inspection within 3 days of PDI
        notice.{" "}
      </p>
      <p className="standard_p">
        3. Failure to carry out Post Delivery Inspection will be deemed
        acceptance.
      </p>
      <h3 className="standard_h3" ref={(el) => headingsRef.current.push(el)}>
        Cost for Electricity for Vehicle Charging and other Infrastructure
      </h3>
      <p className="standard_p">
        To borne by the Customer as per actuals or as per the plan opted in the
        add-on services.
      </p>
      <h3 className="standard_h3" ref={(el) => headingsRef.current.push(el)}>Stamp Duty Charges</h3>
      <p className="standard_p">To be borne by customer at actuals.</p>
      <h3 className="standard_h3" ref={(el) => headingsRef.current.push(el)}>Registration Cost</h3>
      <p className="standard_p">To be borne by Customer at actuals.</p>
      <h3 className="standard_h3" ref={(el) => headingsRef.current.push(el)}>Insurance</h3>
      <p className="standard_p">
        1. To be borne by Customer. Cost of first insurance is included in the
        Monthly Rental.
      </p>
      <p className="standard_p">2. Customer shall be responsible for timely insurance renewal.</p>
      <p className="standard_p">
        3. EV Management Company shall facilitate insurance renewals and claims
        through Mobile App/dashboard/platform.
      </p>
      <p className="standard_p">
        4. Customer shall bear the shortfall amounts if insurance proceeds are
        not insufficient to meet repair cost, theft, damages or any event not
        covered under insurance policy.
      </p>
      <p className="standard_p">
        5. Customer to indemnify for losses on account of inadequacy of
        insurance proceeds.{" "}
      </p>
      <p className="standard_p">
        6. Damage on account of claim amount below INR 10,000 to be borne by the
        Customer
      </p>
      <h3 className="standard_h3" ref={(el) => headingsRef.current.push(el)}>Taxes</h3>
      <p className="standard_p">
        All fees/charges mentioned in this Term Sheet are exclusive of taxes,
        which shall be payable by the Customer (even in case of change in law)
        at actuals. All payments shall be made without any deduction or
        withholding.
      </p>
      <h3 className="standard_h3" ref={(el) => headingsRef.current.push(el)} >Variation in Add on Charges</h3>
      <p className="standard_p">
        All Add-on services charges are subject to change on annual basis. EV
        Management company has right to revise the same on annual basis.
      </p>
      <h3 className="standard_h3"ref={(el) => headingsRef.current.push(el)}>II. Superdari Situation </h3>
      <p className="standard_p">In case the vehicle is impounded by a Government Instrumentality, the Customer shall be liable for all payment on such account to any Government Instrumentality. Customer shall pay Termination Charges in case Superdari situation continues or likely to continue beyond the Term of Lease.</p>
      <h3 className="standard_h3"ref={(el) => headingsRef.current.push(el)}>III. AMC</h3>
      <p className="standard_p">Customer shall be responsible for the following:</p>
      <p className="standard_p">(a) Vehicle is serviced and maintained as per the maintenance schedule, manual and OEM documents. Cost will be borne by Customer. </p>
      <p className="standard_p">(b) Cost of spares, labor cost and major components replacement (battery, motor and motor controller etc.) and other components not covered under warranty.</p>
      <p className="standard_p">(c) Service details along with job card.</p>
      <p className="standard_p">(d) Replacement/repair as per requirement/opinion/instruction of OEM or EV Management Company. Cost will be borne by Customer or adjusted from Security Deposit in case of non-payment.</p>
      <p className="standard_p">(e) Any loss due to poor service standards or non-maintenance or non-compliance of specifications/stipulations provided by Lessor/OEM shall be on account of the Customer.</p>
      <p className="standard_p">(f) Malfunctioning not covered in warranty.</p>
      <h3 className="standard_h3"ref={(el) => headingsRef.current.push(el)}>IV. IOT Device</h3>
      <p className="standard_p">(a) Customer shall be responsible for installation and maintenance of IOT/Bluetooth devices.</p>
      <p className="standard_p">(b) IOT Hardware related cost shall be on account of Customer and charged as part of Monthly Lease Rental.</p>
      <p className="standard_p">(c) IOT/Bluetooth Device to be installed to each EV at the cost of Customer before delivery.</p>
      <p className="standard_p">(d)Ownership rights to vehicle data with the EV Management Company. </p>
      <p className="standard_p">(e) All Data (generated by IOT/Bluetooth Devices) shall be exclusive property of the EV Management Company and it shall have unrestricted access.</p>
      <h3 className="standard_h3"ref={(el) => headingsRef.current.push(el)}>V. Loss and Damage</h3>
      <p className="standard_p">Customer shall be responsible for the following:</p>
      <p className="standard_p">(a) Any liability arising out of proceedings by any third party on account of failure, non-adherence to applicable law, breach of terms, breach of safety standards/tests or OEM Documents.</p>
      <p className="standard_p">(b) Customer to inform the Lessor within 24 hours of any accident-causing loss or damage to the vehicle.</p>
      <p className="standard_p">(c) Claims by third party for breach of intellectual property rights.</p>
      <p className="standard_p">(d) Accidental claims, rash and negligent driving, violation of traffic rules etc.</p>
      <p className="standard_p">(e) Unauthorized use of vehicles.</p>
      <p className="standard_p">(f) Failure to maintain or operate EVs as per OEM Documents/manuals etc.</p>
      <p className="standard_p">(g) Superdari situation.</p>
      <p className="standard_p">Customer shall keep EV Management Company/Lessor indemnified at all times against any direct or consequential losses, damage, cost, charges expenses (including attorney’s fee).</p>
      <h3 className="standard_h3"ref={(el) => headingsRef.current.push(el)}>VI. Conditions Precedent</h3>
      <p className="standard_p">(a) Signing of the Lease and security document.</p>
      <p className="standard_p">(b) Payment of all amounts/Security Deposit/Processing Fee.</p>
      <p className="standard_p">(c) NACH mandate.</p>
      <p className="standard_p">(d) KYC</p>
      <p className="standard_p">(e) RTO Kit (including form no 21, 26, 29, 30, 34, 35,36 or any other relevant RTO Form).</p>
      <p className="standard_p">(f) Credit reports, credit rating and ROC Search Report.</p>
      <p className="standard_p">(g) Background check and police verification of all the users of the vehicle.</p>
      <p className="standard_p">(h) Credit/financial due diligence of Customer.</p>
      <h3 className="standard_h3"ref={(el) => headingsRef.current.push(el)}>VII. Customer Covenants </h3>
      <p className="standard_p">(a) Ensure compliance with applicable laws (including data protection laws).</p>
      <p className="standard_p">(b) Not claim any right, title or interest in the vehicle or any data generated from IOT.</p>
      <p className="standard_p">(c) Use, operate and maintain the vehicle in conformity with the OEM Documents.</p>
      <p className="standard_p">(d) To comply with such financial covenants/ratios and other stipulations as may be prescribed.</p>
      <p className="standard_p">(e) Obtain all the permits which are necessary in order for the operation/branding of its business and EVs.</p>
      <p className="standard_p">(f) Ensure that the vehicles are used within the territory as defined under respective Request Form(s).</p>
      <p className="standard_p">(g) Not claim any relief by way of any deduction, allowance, subsidy or grant to the Lessor as the owner of the vehicle.</p>
      <p className="standard_p">(h) Comply with all the terms and conditions of the Insurance Policies, warranty documents, OEM documents, User manual and permits/approvals, as modified/varied from time to time.</p>
      <p className="standard_p">(i) Present (within [·] days) the vehicle for statutorily mandated technical/fitness tests and inspection upon intimation or as per inspection schedule.</p>
      <p className="standard_p">(j) Directly pay (within [·] days) all fines, damages, or legal claims with respect to EVs and any violation of laws. </p>
      <p className="standard_p">(k) Submit the relevant Transport Authority forms or RTO Kit/documents as required. </p>
      <p className="standard_p">(l) User/drivers of EVs shall sign up on Mobile App/Website.</p>
      <p className="standard_p">(m) Insurance Claims, Roadside/Breakdown Assistance, and requests to be raised through Mobile App/Website EV Management Company.</p>
      <p className="standard_p">(n) Obtain and safekeep duplicate keys of the vehicle.</p>
      <p className="standard_p">(o) Customer shall maintain confidentiality and no use intellectual property of EV Management Company without prior permission.</p>




        <h3 className="standard_h3" ref={(el) => headingsRef.current.push(el)}>
        IX.Indemnity 
      </h3>

        <p className="standard_p">The Customer shall defend, indemnify and hold the Lessor and/or EV Management Company harmless from and against all claims, damages, Losses, third party claims, expenses, suits, proceedings, actions, demands and costs (including reasonable attorneys' fees) arising out of/resulting from:</p>
        <p className="standard_p">(a) Breach of any applicable laws.</p>
        <p className="standard_p">(b) Superdari situation.</p>
        <p className="standard_p">(c) Non-maintenance of the vehicles in accordance with the OEM Documents.</p>
        <p className="standard_p">(d) Civil or criminal proceedings initiated by any third party against the Customer or the EVs.</p>
        <p className="standard_p">(e) Suspension or revocation of license/permits. </p>
        <p className="standard_p">(f) Levy of fines or conviction in relation to any traffic violation.</p>
        <p className="standard_p">(g) Use of EV for illegal activities.</p>
        <p className="standard_p">(h) Breach of terms of definitive agreement or this Term Sheet.</p>
        <p className="standard_p">(i) Misappropriation or infringement of any third party’s intellectual property rights; or </p>
        <p className="standard_p">(j) Accident, personal injury(death), tangible or intangible damage (including loss of use of the vehicle), negligent driving etc.</p>
        <p className="standard_p" >(k) Premature termination of lease.</p>
       
        <h3 className="standard_h3" ref={(el) => headingsRef.current.push(el)}>
        X. Negative Covenants
      </h3>
        <p className="standard_p">(a) Disposal of the EVs;</p>
        <p className="standard_p">(b) Encumbrance on or unauthorized usage of EVs; </p>
        <p className="standard_p">(c) Negative covenants as required by financier/lessor;</p>
        <p className="standard_p">(d) In case of guarantee, negative covenants as applicable for guarantors;</p>
        <p className="standard_p">(e) Change in constitution/shareholding;</p>
        <p className="standard_p">(f) Change in name; and</p>
        <p className="standard_p">(g) Change of key and lock/obtaining duplicate key.</p>
        
        <h3 className="standard_h3" ref={(el) => headingsRef.current.push(el)}>
        XI. Events of Default
      </h3>
        <p className="standard_p">Occurrence of any of the following event shall constitute an event of default by Customer:</p>
        <p className="standard_p">(a) Default in payment of any amount due under the Lease agreement;</p>
        <p className="standard_p">(b) Misrepresentation or Fraud;</p>
        <p className="standard_p">(c) Failure of the Customer to furnish/replenish the Security Deposit;</p>
        <p className="standard_p">(d) Breach of any covenant by the Customer/Affiliate thereof under the Definitive Agreements (including guarantee related documentation);</p>
        <p className="standard_p">(e) Use of the Vehicle for any illegal activity not permitted under the Applicable Law;</p>
        <p className="standard_p">(f) Legal proceedings against the Customer;</p>
        <p className="standard_p">(g) Material Adverse Effect;</p>
        <p className="standard_p">(h) Termination or expiry of any mandatory concession/licenses or permits;</p>
        <p className="standard_p">(i) Breach of negative covenants;</p>
        <p className="standard_p">(j) Cross default under any other vehicle leasing contract signed with any other leasing company or ALT NRG Private Limited. Default under any facility obtained from any bank or financial institution.</p>
        <p className="standard_p">(k) Change in the lock and Keys of the vehicle without the consent of the Lessor.</p>
        <p className="standard_p">(l) The EV is rendered not fit to use pursuant to the inspective by the Lessor or otherwise.</p>
        <h3  className="standard_h3"ref={(el) => headingsRef.current.push(el)}>XII. Consequences of Event of Default</h3>
        <p className="standard_p">Upon the occurrence of any event of default, the Lessor or EV Management Company shall be entitled to enforce any or all of the following rights with a 15 days’ notice:</p>
        <p className="standard_p">(a) Terminate the lease and recover Termination Charges;</p>
        <p className="standard_p">(b) Remote immobilization and repossession of the Vehicle from the Customer;</p>
        <p className="standard_p">(c) Claim all the cost and charges incurred by the Lessor in the repossession of the vehicle over and above the penalty which shall be charged from the Customer;</p>
        <p className="standard_p">(d) Claim and take over from the Customer all the relevant documents, keys, key fobs and all other accessories fitted as original equipment on the vehicle;</p>
        <p className="standard_p">(e) Levy a penal interest of 2% per month on all the amounts due and payable by the Customer including balance Monthly Rentals, Termination Charges, Add on Charges, fines/levies/penalties etc.;</p>
        <p className="standard_p">(f) Adjust/set off outstanding from Security Deposit.</p>
        <p className="standard_p">(g) Termination Charges are payable in all cases of premature termination or in case EV is rendered not fit for use; and</p>
        <p className="standard_p">(h) Recourse and remedies available under laws.</p>
        <p className="standard_p">The Customer acknowledges that EV Management Company is entitled to declare default and undertake all actions and steps whether on its own or on behalf of the Lessor under the definitive agreement.</p>
        <h3  className="standard_h3"ref={(el) => headingsRef.current.push(el)}>XIII.Force Majeure</h3>
        <p className="standard_p">Any payment obligations of the Customer shall not be affected due to force majeure event. EV Management Company/Lessor shall not be responsible if EVs are rendered unusable due to force majeure event. All such situation shall be covered as per warranty documents and Customer to bear all cost not covered under warranty/insurance.</p>
        <h3 className="standard_h3"ref={(el) => headingsRef.current.push(el)}>XIV. Assignment</h3>
        <p className="standard_p">Lessor/EV Management Company can transfer/assign its rights and obligations under the Definitive Agreements without the consent of the Customer. Customer shall not assign/novate its rights/obligations without prior permission of Lessor.</p>
        <h3 className="standard_h3"ref={(el) => headingsRef.current.push(el)}>XV. Dispute Resolution</h3>
        <p className="standard_p">Arbitration in accordance with the provisions of the Arbitration and Conciliation Act, 1996 by one Arbitrator to be mutually appointed by parties to definitive agreement.</p>
        <h3 className="standard_h3"ref={(el) => headingsRef.current.push(el)}>XVI. Lease Documents, OEM Documents and KYC</h3>
        <h3 className="standard_h3"ref={(el) => headingsRef.current.push(el)}>A. Lease Documents</h3>
        <p>Following documents will constitute the Lease Documents:</p>
        <p className="standard_p">(a) Master Lease Agreement</p>
        <p className="standard_p">(b) Term Sheet</p>
        <p className="standard_p">(c) Security Cheques for lease payment and Residual Value (If applicable). Cheques needs to be issued from the main operating account.</p>
        <p className="standard_p">(d) NACH Mandate</p>
        <p className="standard_p">(e) Board Resolution, other corporate authorizations and approvals from the Customer</p>
        <p className="standard_p">(f) Other standard documents</p>
        <p className="standard_p">(g) RTO Forms/kit</p>
        <p className="standard_p">(h) Guarantee</p>
        <p className="standard_p">(i) OEM Warranties</p>
        <h3 className="standard_h3"ref={(el) => headingsRef.current.push(el)}>B. KYC Documents</h3><span> <p className="standard_p">- As per the requirement of the Lessor in terms of its policy.</p></span>
        <h3 className="standard_h3"ref={(el) => headingsRef.current.push(el)}>C. Original Equipment Manufacturer (OEM) documents</h3>
        <p className="standard_p">Customer shall comply with and adhere to the following.</p>
        <p className="standard_p">(a) Driver’s Manual</p>
        <p className="standard_p">(b) Service Schedule</p>
        <p className="standard_p">(c) Warranty Policy relating to the vehicle.</p>
        <p className="standard_p">(d) Maintenance Policy and instructions, if any, provided or made available/accessible by the Lessor.</p>
        <h3 className="standard_h3"ref={(el) => headingsRef.current.push(el)}>XVII. Other Terms as applicable for transaction of this nature shall be incorporated in the definitive agreements.</h3>
    

      
      
    </div>
  );
};

export default StandardTerms;
