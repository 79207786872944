import React from "react";
import { MdOutlineNavigateNext } from "react-icons/md";
import { Link } from "react-router-dom";
import Alt_team from "../../Images/Alt-team.JPEG";
const PressRelease = () => {
  const handleClick = () => {
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: "smooth",
    })
  };
  return (
    <>
      <div className="ecosystem-container container mt-5 w-100 p-5">
        <h2 className=" mb-4 mt-3 md-mt-0" style={{ fontSize: "40px" }}>
          Press Release
        </h2>
        <div className="row">
          <div className="col-md-6 col-sm-12 mt-4">
            <div className="eco-h">
              <h3>
                Alt Mobility raises $6mn in Funding Co-led by Shell Ventures,
                Eurazeo, EV2 and Twynam{" "}
              </h3>
            </div>
            <div className="eco-p">
              <p className="">
                Aims to enhance its EV FleetOS platform, expand market for EV
                leasing and accelerate its growth plans
              </p>
            </div>
            <div className="eco-btn mt-4">
              <Link onClick={handleClick} to="/shell_eurazeo_ev2_twynam_invest">
                <button className="apply_btn">
                  Read More
                  <MdOutlineNavigateNext color="white" size={25} />
                </button>
              </Link>
            </div>
          </div>
          <div className="col-md-6 col-sm-12">
            <div className="eco-img mt-4 w-100 ">
              <img
                src={Alt_team}
                alt="ecosystem"
                className="img-responsive"
                style={{ maxHeight: "300px", objectFit: "cover" }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PressRelease;
