// import React, { useEffect, useState } from "react";
// import Button from "react-bootstrap/Button";
// import Container from "react-bootstrap/Container";
// import Nav from "react-bootstrap/Nav";
// import Navbar from "react-bootstrap/Navbar";
// import { Link, useLocation } from "react-router-dom";
// import Logo from "../Images/altlogo.png";
// import "./Header.css";
// import { BLOG_URL } from "./URL";

// const Header = () => {
//   const [click, setClick] = useState(false);
//   const handleClick = () => setClick(!click);
//   const location = useLocation();
//   const currentPath = location.pathname;
//   const [activeLink, setActiveLink] = useState(currentPath);
//   console.log(currentPath);



//   const handleLinkClick = (link) => {
//     setActiveLink(link);
//     setClick(false);
//     // handleClick();
//   };

//   console.log(click);
//   useEffect(() => {
//     if (click) {
//       document.body.style.overflow = "hidden";
//     } else {
//       document.body.style.overflow = "auto";
//     }
//   }, [click]);

//   const [color, setColor] = useState(false);
//   const changeColor = () => {
//     if (window.scrollY >= 100) {
//       setColor(true);
//     } else {
//       setColor(false);
//     }
//   };
//   window.addEventListener("scroll", changeColor);

//   let prevScrollPos = window.pageYOffset;

//   window.onscroll = function () {
//     let currentScrollPos = window.pageYOffset;
//     if (prevScrollPos > currentScrollPos) {
//       document.querySelector(".header").style.top = "0";
//     } else {
//       document.querySelector(".header").style.top = "-80px";
//     }
//     prevScrollPos = currentScrollPos;
//   };

//   return (
//     <div>
//       <Navbar
//         expanded={click}
//         expand="lg"
//         sticky="top"
//         className={color ? "header  header-bg p-0" : "header p-0"}
//       >
//         <Container>
//           <div className="header-logo">
//             <Link to="/" onClick={() => handleLinkClick(null)}>
//               <img src={Logo} alt="" />
//             </Link>
//           </div>
//           <Navbar.Toggle
//             aria-controls="basic-navbar-nav"
//             onClick={handleClick}
//           />
//           <Navbar.Collapse id="basic-navbar-nav">
//             <div className={click ? "nav-menu active" : "nav-menu"}>
//               <Nav className="gap-5" aria-current="page">
//                 <Link
//                   className={`nav-link link-color ${
//                     activeLink === "/fleet_leasing" ? "active" : ""
//                   }`}
//                   to="/fleet_leasing"
//                   onClick={() => handleLinkClick("/fleet_leasing")}
//                 >
//                   Fleet Lease
//                 </Link>
//                 <Link
//                   className={`nav-link link-color ${
//                     activeLink === "/fleet" ? "active" : ""
//                   }`}
//                   to="/fleet"
//                   onClick={() => handleLinkClick("/fleet")}
//                 >
//                   FleetOS
//                 </Link>
//                 <Link
//                   className={`nav-link link-color ${
//                     activeLink === "/about" ? "active" : ""
//                   }`}
//                   to="/about"
//                   onClick={() => handleLinkClick("/about")}
//                 >
//                   About Us
//                 </Link>
//                 <Link
//                   className={`nav-link link-color ${
//                     activeLink === "/partners" ? "active" : ""
//                   }`}
//                   to="/partners"
//                   onClick={() => handleLinkClick("/partners")}
//                 >
//                   Partners
//                 </Link>
//                 <Link
//                   className={`nav-link link-color ${
//                     activeLink === BLOG_URL ? "active" : ""
//                   }`}
//                   to={BLOG_URL}
//                   onClick={() => handleLinkClick(BLOG_URL)}
//                 >
//                   Blog
//                 </Link>

//               </Nav>
//             </div>
//             <Navbar.Collapse className=" justify-content-end nav-button">
//               <Nav.Link eventKey={2} href="#memes">
//                 <Link
//                   to="https://fleetos.alt-mobility.com/signin"
//                   style={{ textDecoration: "none" }}
//                 >
//                   <Button variant="outline-success" className="gray-btns me-4">
//                     Sign In
//                   </Button>
//                 </Link>
//                 <Link to="/enquire">
//                   {" "}
//                   <Button variant="outline-success" className="green-btns">
//                     {" "}
//                     Contact Us{" "}
//                   </Button>
//                 </Link>
//               </Nav.Link>
//             </Navbar.Collapse>
//           </Navbar.Collapse>
//         </Container>
//       </Navbar>
//     </div>
//   );
// };

// export default Header;






// import React, { useEffect, useState, } from "react";
// import Button from "react-bootstrap/Button";
// import Container from "react-bootstrap/Container";
// import Nav from "react-bootstrap/Nav";
// import Navbar from "react-bootstrap/Navbar";
// import { Link, useLocation } from "react-router-dom";

// import Logo from "../Images/altlogo.png";
// import "./Header.css";
// import { BLOG_URL } from "./URL";

// const Header = ({ activeLink, handleLinkClick }) => {
//   const [click, setClick] = useState(false);
//   const handleClick = () => setClick(!click);
//   const location = useLocation();
//   const currentPath = location.pathname;

//   useEffect(() => {
//     setClick(false);
//   }, [currentPath]);

//   useEffect(() => {
//     if (click) {
//       document.body.style.overflow = "hidden";
//     } else {
//       document.body.style.overflow = "auto";
//     }
//   }, [click]);

//   const [color, setColor] = useState(false);
//   const changeColor = () => {
//     if (window.scrollY >= 100) {
//       setColor(true);
//     } else {
//       setColor(false);
//     }
//   };

//   window.addEventListener("scroll", changeColor);

//   let prevScrollPos = window.pageYOffset;

//   window.onscroll = function () {
//     let currentScrollPos = window.pageYOffset;
//     if (prevScrollPos > currentScrollPos) {
//       document.querySelector(".header").style.top = "0";
//     } else {
//       document.querySelector(".header").style.top = "-80px";
//     }
//     prevScrollPos = currentScrollPos;
//   };





//   const whatsapp_url =
//     "https://api.whatsapp.com/send/?phone=919971007201&text&type=phone_number&app_absent=0";
//   return (
//     <div>
//       <Navbar
//         expanded={click}
//         expand="lg"
//         sticky="top"
//         className={color ? "header  header-bg p-0" : "header p-0"}
//         // className={`header ${visible ? "header-visible" : "header-hidden"}`}
//       >
//         <Container>
//           <div className="header-logo">
//             <Link to="/" onClick={() => handleLinkClick("/")}>
//               <img src={Logo} alt="" />
//             </Link>
//           </div>
//           <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={handleClick} />
//           <Navbar.Collapse id="basic-navbar-nav">
//             <div className={click ? "nav-menu active" : "nav-menu"}>
//               <Nav className="gap-md-5 gap-2" aria-current="page">
//                 <Link
//                   className={`nav-link link-color ${activeLink === "/fleet_leasing" ? "active" : ""}`}
//                   to="/fleet_leasing"
//                   onClick={() => handleLinkClick("/fleet_leasing")}
//                 >
//                   Fleet Lease
//                 </Link>
//                 <Link

//                 className={`nav-link link-color ${activeLink === "/fleet" ? "active" : ""}`}
//                 to="/fleet"
//                 onClick={() => handleLinkClick("/fleet")}
//               >
//                 FleetOS
//               </Link>
//               <Link
//                     className={`nav-link link-color ${activeLink === "/about" ? "active" : ""}`}
//                     to="/about"
//                     onClick={() => handleLinkClick("/about")}
//                  >
//                    About Us
//                </Link>
//                <Link
//                     className={`nav-link link-color ${activeLink === "/partners" ? "active" : ""}`}
//                     to="/partners"
//                     onClick={() => handleLinkClick("/partners")}
//                  >
//                    Partners
//                </Link>


//                 <Link
//                   className={`nav-link link-color ${activeLink === BLOG_URL ? "active" : ""}`}
//                   to={BLOG_URL}
//                   onClick={() => handleLinkClick(BLOG_URL)}
//                 >
//                   Blog
//                 </Link>
//               </Nav>
//             </div>
//             <Navbar.Collapse className=" justify-content-end nav-button">
//               <Nav.Link eventKey={2} href="#memes" className="contact-signin">

//                 <Link 
//                 to="/enquire"
//                 >
//                   {" "}
//                   <Button variant="outline-success" className="green-btns">
//                     {" "}
//                     Enquire Now{" "}
//                   </Button>
//                 </Link>
//               </Nav.Link>
//             </Navbar.Collapse>
//           </Navbar.Collapse>
//         </Container>
//       </Navbar>

//     </div>
//   );
// };

// export default Header;


















import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link, useLocation } from "react-router-dom";

import Logo from "../../Images/altlogo.png";
import "./Header.css";
import { BLOG_URL } from "../URL";

const DeskTopHeader = ({ activeLink, handleLinkClick }) => {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const location = useLocation();
  const currentPath = location.pathname;

  useEffect(() => {
    setClick(false);
  }, [currentPath]);

  useEffect(() => {
    if (click) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [click]);

  const [color, setColor] = useState(false);
  const changeColor = () => {
    if (window.scrollY >= 100) {
      setColor(true);
    } else {
      setColor(false);
    }
  };

  window.addEventListener("scroll", changeColor);

  let prevScrollPos = window.pageYOffset;





  window.onscroll = function () {
    let currentScrollPos = window.pageYOffset;
    if (prevScrollPos > currentScrollPos || window.scrollY <= 100) {
      document.querySelector(".header").style.top = "";
    } else {
      document.querySelector(".header").style.top = "-80px";
    }
    prevScrollPos = currentScrollPos;
  };

  return (
    <div>
      <Navbar
        expanded={click}
        expand="lg"
        sticky="top"
        className={color ? "header header-bg p-0" : "header p-0"}
      >
        <Container>
          <div className="header-logo " style={{zIndex:"1"}}>
            <Link to="/" onClick={() => handleLinkClick("/")}>
              <img src={Logo} alt="" />
            </Link>
          </div>
          <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={handleClick} style={{zIndex:"1"}} />
          <Navbar.Collapse id="basic-navbar-nav">
            <div className={click ? "nav-menu active" : "nav-menu"}>
              <Nav className=" gap-3" aria-current="page">
                <Link
                  className={`nav-link link-color ${activeLink === "/fleet_leasing" ? "active" : ""}`}
                  to="/fleet_leasing"
                  onClick={() => handleLinkClick("/fleet_leasing")}
                >
                  Fleet Lease
                </Link>
                <Link
                  className={`nav-link link-color ${activeLink === "/fleet" ? "active" : ""}`}
                  to="/fleet"
                  onClick={() => handleLinkClick("/fleet")}
                >
                  FleetOS
                </Link>
                <Link
                  className={`nav-link link-color ${activeLink === "/about" ? "active" : ""}`}
                  to="/about"
                  onClick={() => handleLinkClick("/about")}
                >
                  About Us
                </Link>
                <Link
                  className={`nav-link link-color ${activeLink === "/partners" ? "active" : ""}`}
                  to="/partners"
                  onClick={() => handleLinkClick("/partners")}
                >
                  Partners
                </Link>
                <Link
                  className={`nav-link link-color ${activeLink === BLOG_URL ? "active" : ""}`}
                  to={BLOG_URL}
                  onClick={() => handleLinkClick(BLOG_URL)}
                >
                  Blog
                </Link>
              </Nav>
            </div>
            <Navbar.Collapse className="justify-content-end nav-button">
              <Nav.Link eventKey={2} href="#memes" className="contact-signin">
                <Link to="/enquire">
                  {" "}
                  <Button variant="outline-success" className="green-btns">
                    {" "}
                    Enquire Now{" "}
                  </Button>
                </Link>
              </Nav.Link>
            </Navbar.Collapse>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default DeskTopHeader;


