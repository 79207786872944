import axios from "axios";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { BASE_URL_FLEETOS } from "../../repository/api";
import Button from "../CustomComponent/Button";
import InputField from "../CustomComponent/InputField";

const Form4 = ({ handleBack, handleNext }) => {
  const { id } = useParams();
  const [data, setData] = useState([
    {
      number_of_states_company_operates_in: "",
      gstin1: "",
      registered_username1: "",
      registered_phone_number1: "",
    },
  ]);
  const [loading, setLoading] = useState(false);

  const fetchDataFromAPI = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL_FLEETOS}/v1/lease-application/${id}`
      );
      response?.data?.lease_application_gst_details?.length > 0 &&
        setData(response.data.lease_application_gst_details);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchDataFromAPI();
  }, [id]);

  // Define the validation schema
  const validationSchema = Yup.array().of(
    Yup.object().shape({
      number_of_states_company_operates_in: Yup.string()
        .matches(/^[0-9]+$/, "Must be a number")
        .required("Required"),
      gstin1: Yup.string().required("Required"),
      registered_username1: Yup.string().required("Required"),
      registered_phone_number1: Yup.string()
        .matches(/^[0-9]{10}$/, "Invalid phone number")
        .required("Registration Number is required"),
    })
  );

  const formik = useFormik({
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: data,
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const formDataArray = Object.values(values).map((partner) => ({
          lease_application_id: id,
          number_of_states_company_operates_in:
            parseInt(partner.number_of_states_company_operates_in),
          gstin1: partner.gstin1,
          registered_username1: partner.registered_username1,
          registered_phone_number1: partner.registered_phone_number1.toString(),
        }));
        let response = await axios.post(
          `${BASE_URL_FLEETOS}/v1/lease-application/gst-details`,
          formDataArray
        );

        handleNext();
        setLoading(false);
      } catch (error) {
        // Handle errors, e.g., validation errors or network issues
        console.error("Error:", error.message);
      }
    },
  });

  // Function to add additional fields
  function addAdditionalFields() {
    const newData = {
      number_of_states_company_operates_in: "",
      gstin1: "",
      registered_username1: "",
      registered_phone_number1: "",
    };
    const updatedValues = [...formik.values, newData];
    formik.setValues(updatedValues);
  }
  return (
    <form onSubmit={formik.handleSubmit} className="d-flex flex-column gap-5">
      {formik.values?.map((_, index) => {
        return (
          <div>
            <div className="d-flex flex-column flex-md-row justify-content-between gap-4 input-width">
              <InputField
                label="Number of States Company operates in"
                type="text"
                id={`${index}.number_of_states_company_operates_in`}
                className="w-100"
                required
                name={`${index}.number_of_states_company_operates_in`}
                placeholder="Enter Number of States Company operates"
                value={
                  formik.values[index]?.number_of_states_company_operates_in
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched[index]?.number_of_states_company_operates_in &&
                  formik.errors[index]?.number_of_states_company_operates_in
                }
              />
              <div className="w-100 d-none d-md-block"></div>
            </div>
            <div className="d-flex flex-column flex-md-row justify-content-between gap-4 input-width">
              <InputField
                label="GSTIN 1"
                type="text"
                className="w-100"
                required
                id={`${index}.gstin1`}
                name={`${index}.gstin1`}
                placeholder="Enter GSTIN 1"
                value={formik.values[index]?.gstin1}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched[index]?.gstin1 && formik.errors[index]?.gstin1
                }
              />
              <div className="w-100 d-none d-md-block"></div>
            </div>
            <div className="d-flex flex-column flex-md-row justify-content-between gap-4 input-width">
              <InputField
                label="Registered Username 1"
                type="text"
                id={`${index}.registered_username1`}
                name={`${index}.registered_username1`}
                className="w-100"
                required
                placeholder="Enter Registered Username"
                value={formik.values[index]?.registered_username1}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched[index]?.registered_username1 &&
                  formik.errors[index]?.registered_username1
                }
              />

              <InputField
                label="Registered Phone Number 1 (for OTP)"
                type="text"
                className="w-100"
                required
                id={`${index}.registered_phone_number1`}
                name={`${index}.registered_phone_number1`}
                placeholder="Enter Your phone number"
                value={formik.values[index]?.registered_phone_number1}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched[index]?.registered_phone_number1 &&
                  formik.errors[index]?.registered_phone_number1
                }
              />
            </div>
          </div>
        );
      })}

      <Button
        type="button"
        label="Add Gst Details"
        onClick={() => addAdditionalFields()}
      />
      <div className="d-flex gap-3 justify-content-center">
        <Button label="Back" onClick={handleBack} />
        <Button
          label="Next"
          onClick={formik.handleSubmit}
          disabled={!formik.isValid}
          loading={loading}
        />
      </div>
    </form>
  );
};

export default Form4;
