import React from 'react'
import LeaseFinance from './LeasingFinanceComp/LeaseFinance'

const LeasingFinance = () => {
  return (
    <>
    <div className='home-mainss'>
      <LeaseFinance/>
    </div>
    </>
  )
}

export default LeasingFinance
