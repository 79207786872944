import React from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import FleetRequest from "./FleetRequest";

const Request = () => {
  return (
    <div>
      {/* <Header /> */}
      <FleetRequest />
      {/* <Footer /> */}
    </div>
  );
};

export default Request;
