import React, { useState } from "react";
import DeskTopFooter from "./DeskTopFooter";

const Footer = ({ activeLink, handleLinkClick }) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth > 340 && window.innerWidth < 450);

    return isMobile 
        ? null
        : <DeskTopFooter activeLink={activeLink} handleLinkClick={handleLinkClick} />;
}

export default Footer;