import React from "react";
import { MdElectricBolt } from "react-icons/md";
import "./MobileButton.css";

const MobileButton = ({
    text,
    handleClick,
    icon,
    iconPosition
}) => {
    // text in sent with | as separator, the "|" will be replace with the electric bolt 
    const textArray = text.split("|")
    return (
        <button 
            className={
                `mobile-form-button 
                ${iconPosition === "start" 
                    ? "text-right" 
                    : iconPosition === "end" 
                    ? "text-left"
                    : ""
                }`
            } 
            onClick={handleClick}
        >
            {iconPosition === "start"
                ? (
                    <span className="mobile-btn-icon-start">{icon}</span>
                )
                : null
            }
            {text?.includes("|")
                ? (
                    <span>
                        {textArray[0]}&nbsp;<MdElectricBolt />&nbsp;{textArray[1]} 
                    </span>
                )
                : `${text}`
            }
            {iconPosition === "end"
                ? (
                    <span className="mobile-btn-icon-end">{icon}</span>
                )
                : null
            }
        </button>
    )
};

export default MobileButton;