import React from "react";
import { useState } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { AiOutlineDownload } from "react-icons/ai";
import { BsWhatsapp } from "react-icons/bs";
import Correct from "../Images/Vector.svg";
import TermsConditionsModal from "../Components/TermsConditionsModal";
import "./Leaseproposal.css";
import Vehicleimg from "../Images/vehicleimage.png";
import Logo from "../Images/mahindralogo.png";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Leaseproposal = () => {
  const [buttoncolor, setbuttoncolor] = useState(false);
  const [buttonbackground, setbuttonbackground] = useState(false);
  const [expanded, setExpanded] = useState([true, true, true, true, true]);
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [acceptProposal, setAcceptProposal] = useState(false);

  const objectsArray = [
    {
      id: 1,
      name: "Lease Details",
      details: [
        { name: "No. of Vehicles", prices: "10" },
        { name: "Lease Rental", prices: "74,617" },
        { name: "GST on lease rental 18%", prices: "35,816" },
        { name: "Fleet Management", prices: "12,523" },
        { name: "Total Lease Rental", prices: "1,10,433" },
        { name: "GST on FMS 18%%", prices: "2,254" },
      ],
    },
    {
      id: 2,
      name: "Vehicle Details",
      details: [
        { name: "Cateogry", prices: "3 WHeeler" },
        { name: "Manufacture", prices: "Mahindra" },
        { name: "Model", prices: "Treo Zor E Xtra" },
        { name: "Vehicle Warranty", prices: "24 months" },
        { name: "Battery Chemistry", prices: "120 kms" },
        { name: "Battery Capacity", prices: "120 kms" },

        { name: "Payload", prices: "450 kg" },
      ],
    },
  ];

  const handleAccordionChange = (index) => {
    const newExpanded = [...expanded];
    newExpanded[index] = !newExpanded[index];
    setExpanded(newExpanded);
  };

  const captureComponentAsImage = () => {
    const container = document.getElementById("capture-container");
    html2canvas(container, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const imgWidth = pdf.internal.pageSize.getWidth();
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
      pdf.save("leaseproposal.pdf");
    });
  };

  const redirectWhatsAppGroup = () => {
    var phoneNumber = "+919971007201";
    var url = "https://wa.me/" + encodeURIComponent(phoneNumber);
    window.open(url, "_blank");
  };
  const handleChildButtonClick = () => {
    setbuttoncolor("#ffffff");
    setbuttonbackground(
      "linear-gradient(129.14deg, #67C2A5 -5.53%, #1FB486 117.63%)"
    );

    setAcceptProposal(true);
  };
  const handleReject = () => {
    setbuttoncolor(false);
    setbuttonbackground(
      // "linear-gradient(129.14deg, #67C2A5 -5.53%, #1FB486 117.63%)"
      false
    );

    setAcceptProposal(false);
  };
  return (
    <div className="" id="pdf-content" style={{ marginTop: "7rem",padding:"0px 1rem" }}>
      <div className="download-section">
        <h3>Alt Lease Proposal</h3>
        <button onClick={captureComponentAsImage}>
          Download PDF{" "}
          <span>
            <AiOutlineDownload size={16} color="#125EAF" />
          </span>
        </button>
      </div>
      <div id="capture-container">
        <div
          className="lessor-text"
          style={{
            display: "grid",
            alignItems: "center",
            gridTemplateColumns: "repeat(4, 1fr)",
            gridGap: "3rem",
          }}
        >
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <div>
              <img
                src={Vehicleimg}
                style={{ height: "91px", width: "97px", objectFit: "contain" }}
                alt="Vehicle"
              />
            </div>
            <div>
              <img
                src={Logo}
                style={{ width: "17px", objectFit: "contain" }}
                alt="Logo"
              />
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#4A5863",
                }}
              >
                Mahindra
              </span>
              <p>Treo Zor E Xtra</p>
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <p>Lessor</p>
            <h5>Alt Mobility</h5>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <p>Lessee Name</p>
            <h5>Michael Scott</h5>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <p>Tenure</p>
            <h5>36 Months</h5>
          </div>
        </div>

        <div
          className="lease-container"
          style={{ background: "", border: "1px solid #E9E9E9" }}
        >
          {objectsArray.map((obj, index) => (
            <Accordion
              style={{ margin: "0px", boxShadow: "none" }}
              key={obj.id}
              expanded={expanded[index]}
              onChange={() => handleAccordionChange(index)}
            >
              <AccordionSummary
                className="header-summary"
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${obj.id}-content`}
                id={`panel${obj.id}-header`}
              >
                <div>
                  <p>{obj.name}</p>
                </div>
              </AccordionSummary>
              <div>
                <AccordionDetails>
                  <div
                  className="accord-list"
                    style={{
                      display: "grid",
                      // gridTemplateColumns: "repeat4, 1fr)",
                      // gridColumnGap: "2rem",
                      // gridRowGap: "1.3rem",
                    }}
                  >
                    {obj.details.map((detail, detailIndex) => (
                      <div className="typo" key={detailIndex}>
                        <Typography className="details">
                          {detail.name}
                        </Typography>
                        <Typography className="price">
                          {detail.prices}
                        </Typography>
                      </div>
                    ))}
                  </div>
                </AccordionDetails>
              </div>
            </Accordion>
          ))}
        </div>
      </div>
      <div className="term-section">
        <h5>Terms & Conditions</h5>
        <p>
          In order to accept the proposal, please read and accept our{" "}
          <a  onClick={() => setShowTermsModal(true)}>
            Terms & Conditions
          </a>
        </p>
      </div>
      {acceptProposal && (
        <div
          style={{
            display: "flex",
            marginTop: "1rem",
            fontSize: "12px",
            fontWeight: "400",
            color: "#828282",
            padding: "0rem 2rem",
            gap: "6px",
          }}
        >
          <img src={Correct} alt="" />
          <p>
            Now that you've agreed to the terms and conditions, you're welcome
            to proceed with accepting the proposal.
          </p>
        </div>
      )}
      <div className="accept-section">
        <button
          className="accept"
          style={{
            background: buttonbackground,
            color: buttoncolor,
            cursor: acceptProposal ? "pointer" : "not-allowed",
          }}
        >
          Accept Proposal
        </button>
        <button className="whatsup" onClick={redirectWhatsAppGroup}>
          <span>
            <BsWhatsapp color="#1EAC5A" />
          </span>
          &nbsp;Chat with us
        </button>
      </div>

      <TermsConditionsModal
        show={showTermsModal}
        onHide={() => setShowTermsModal(false)}
        onClick={handleChildButtonClick}
        onReject={handleReject}
      />
    </div>
  );
};

export default Leaseproposal;
