import React from "react";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import "./style.css";

const InfiniteCarousels = ({ itemsShowing, itemsArray, type }) => {
    // config for mobile
    const responsive = {
        mobile: {
            breakpoint: { max: 464, min: 340 },
            items: itemsShowing
        }
    };

    return (
        <div className="mt-3">
            <Carousel
                swipeable={false}
                draggable={false}
                showDots={false}
                responsive={responsive}
                // ssr={true} // means to render carousel on server-side.
                infinite={true}
                autoPlay={true}
                autoPlaySpeed={1000}
                arrows={false}
            >
                {type === "Images" && itemsArray.map((item, i) => (
                    <img className="mobile-carousel-items" src={item} alt={i} />
                ))}
                {type === "Cards" && itemsArray.map((item,i) => (
                    <div className="mobile-carousel-card" key={i}>
                        <div className="mobile-carousel-card-header">
                            {item.title}
                        </div>
                        <div className="mobile-carousel-card-subtitle">
                            {item.subTitle}
                        </div>
                        <img className="mobile-carousel-card-image" src={item.image} alt={item.title} />
                        <div className="mobile-carousel-card-desc">
                            {item.description}
                        </div>
                        <div className="mobile-carousel-card-price d-flex justify-content-start align-items-center">
                            {item.price}
                            <span className="mobile-carousel-card-suffix">
                                {item.suffix}
                            </span>
                        </div>
                    </div>
                ))}
            </Carousel>
        </div>
    )
};

export default InfiniteCarousels;