import axios from "axios";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import Fade from "react-reveal/Fade";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { BASE_URL_FLEETOS } from "../../repository/api";
import Button from "../CustomComponent/Button";
import FileUpload from "../CustomComponent/UploadFile";
import "./Form.css";

const validationSchema = Yup.object().shape({
  last_two_years_financials_available: Yup.boolean(),
  provisional_financials:Yup.string().required("Required"),
  p_and_l:Yup.string().required("Required"),
  balance_sheet:Yup.string().required("Required"),
  cash_flow_statement:Yup.string().required("Required"),
  sanction_letter:Yup.string().required("Required"),
  gstr_data:Yup.string().required("Required"),
  itr_with_computation:Yup.string().required("Required"),
  unit_economics:Yup.string().required("Required"),
  bank_statement:Yup.string().required("Required"),
  cash_flow_mis:Yup.string().required("Required"),
  fleet_tracking_data:Yup.string().required("Required"),
});

const Form5 = ({ handleBack, handleNext }) => {
  const { id } = useParams();

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchDataFromAPI = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL_FLEETOS}/v1/lease-application/${id}`
      );
      setData(response.data.lease_application_company_financial_documents);
    } catch (error) {
      setError(error);
    } finally {
    }
  };

  useEffect(() => {
    fetchDataFromAPI();
  }, [id]);

  function extractNameAfterFirstUnderscore(inputString) {
    if (typeof inputString === "string") {
      let parts = inputString?.split("/");
      let lastPart = parts[parts?.length - 1];
      let firstUnderscoreIndex = lastPart?.indexOf("_");
      if (firstUnderscoreIndex !== -1) {
        return lastPart?.substring(firstUnderscoreIndex + 1);
      } else {
        return lastPart;
      }
    }
  }

  const initialValues = {
    last_two_years_financials_available:
      data?.last_two_years_financials_available ?? false,
    last_two_years_audited_financials:
      data?.last_two_years_audited_financials ?? "",
    provisional_financials: data?.provisional_financials ?? "",
    audited_financials: data?.audited_financials ?? "",
    p_and_l: data?.p_and_l ?? "",
    balance_sheet: data?.balance_sheet ?? "",
    cash_flow_statement: data?.cash_flow_statement ?? "",
    sanction_letter: data?.sanction_letter ?? "",
    gstr_data: data?.gstr_data ?? "",
    itr_with_computation: data?.itr_with_computation ?? "",
    unit_economics: data?.unit_economics ?? "",
    bank_statement: data?.bank_statement ?? "",
    cash_flow_mis: data?.cash_flow_mis ?? "",
    fleet_tracking_data: data?.fleet_tracking_data ?? "",
  };

  const handleErrorResponse = (error) => {
    if (
      error.response &&
      error.response.data &&
      error.response.data.error &&
      error.response.data.error.issues
    ) {
      error.response.data.error.issues.forEach((issue) => {
        toast.error(`${issue.path[0]} is ${issue.message}`);
      });
    } else {
      toast.error("An error occurred. Please try again.");
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        if (values.last_two_years_financials_available === true && values.audited_financials === "") {
          toast.error("Audited financials is required.");
          setLoading(false);
          return; 
        }
       
        console.log(values);
        let allPromisesUrl = [
          uploadAndCommitFile(values.provisional_financials),
          uploadAndCommitFile(values.audited_financials),
          uploadAndCommitFile(values.p_and_l),
          uploadAndCommitFile(values.balance_sheet),
          uploadAndCommitFile(values.cash_flow_statement),
          uploadAndCommitFile(values.sanction_letter),
          uploadAndCommitFile(values.gstr_data),
          uploadAndCommitFile(values.itr_with_computation),
          uploadAndCommitFile(values.unit_economics),
          uploadAndCommitFile(values.bank_statement),
          uploadAndCommitFile(values.cash_flow_mis),
          uploadAndCommitFile(values.fleet_tracking_data),
          uploadAndCommitFile(values.last_two_years_audited_financials),
        ];

        let allPromisesUrl1 = await Promise.all(allPromisesUrl);

        console.log(allPromisesUrl1);
        const Form1Data = {
          last_two_years_financials_available:
            values.last_two_years_financials_available,

          ...(values.provisional_financials !== "" && {
            provisional_financials: values.provisional_financials.type
              ? allPromisesUrl1[0]
              : values.provisional_financials,
          }),

          audited_financials: values.audited_financials.type
            ? allPromisesUrl1[1]
            : values.audited_financials,

          ...(values.p_and_l !== "" && {
            p_and_l: values.p_and_l.type ? allPromisesUrl1[2] : values.p_and_l,
          }),

          ...(values.balance_sheet !== "" && {
            balance_sheet: values.balance_sheet.type
              ? allPromisesUrl1[3]
              : values.balance_sheet,
          }),

          ...(values.cash_flow_statement !== "" && {
            cash_flow_statement: values.cash_flow_statement.type
              ? allPromisesUrl1[4]
              : values.cash_flow_statement,
          }),

          ...(values.sanction_letter !== "" && {
            sanction_letter: values.sanction_letter.type
              ? allPromisesUrl1[5]
              : values.sanction_letter,
          }),

          ...(values.gstr_data !== "" && {
            gstr_data: values.gstr_data.type
              ? allPromisesUrl1[6]
              : values.gstr_data,
          }),

          ...(values.itr_with_computation !== "" && {
            itr_with_computation: values.itr_with_computation.type
              ? allPromisesUrl1[7]
              : values.itr_with_computation,
          }),

          ...(values.unit_economics !== "" && {
            unit_economics: values.unit_economics.type
              ? allPromisesUrl1[8]
              : values.unit_economics,
          }),

          ...(values.bank_statement !== "" && {
            bank_statement: values.bank_statement.type
              ? allPromisesUrl1[9]
              : values.bank_statement,
          }),

          ...(values.cash_flow_mis !== "" && {
            cash_flow_mis: values.cash_flow_mis.type
              ? allPromisesUrl1[10]
              : values.cash_flow_mis,
          }),
          ...(values.fleet_tracking_data !== "" && {
            fleet_tracking_data: values.fleet_tracking_data.type
              ? allPromisesUrl1[11]
              : values.fleet_tracking_data,
          }),

          ...(values.last_two_years_financials_available === true  && {
            last_two_years_audited_financials: values
              .last_two_years_audited_financials.type
              ? allPromisesUrl1[12]
              : values.last_two_years_audited_financials,
          }),

          lease_application_id: id,
        };
       
          const response = await axios.post(
            `${BASE_URL_FLEETOS}/v1/lease-application/company-financial-documents`,
            Form1Data
          );
          console.log("Response:", response.data);
          handleNext()
          setLoading(false);
         
         
      
      } catch (error) {
        console.log("error", error);
       
        handleErrorResponse(error);
        setLoading(false);
      }
    },
  });
  const uploadAndCommitFile = (file) => {
    if (file && file.name !== "") {
      return new Promise(async (resolve, reject) => {
        const testData = await axios.post(
          `${BASE_URL_FLEETOS}/v1/shared.getSignedUrlForUploadPublic`,
          {
            fileName: file.name ?? file,
            type: "lease_application_documents",
          }
        );
        // let testData = await getUrl.mutateAsync({
        //   fileName: file.name ?? file,
        //   type: "fleet_request_documents",
        // });
        const headers = new Headers();
        headers.append("Content-Type", "application/octet-stream");
        // let resp = await new Promise((resolve, reject) => {
        //   const xhr = new window.XMLHttpRequest();
        //   xhr.open("PUT", testData?.data?.result?.data?.url);
        //   xhr.onload = resolve;
        //   xhr.onerror = reject;
        //   xhr.setRequestHeader("Content-Type", "application/octet-stream");
        //   xhr.send(file);
        // });

        resolve(testData?.data?.result?.data?.path);
      });
    } else {
      return Promise.resolve("");
      // return null;
    }
  };

  const handleFileUpload = async (name, file) => {
    formik.setFieldValue(`${name}`, file);
  };

  function extractFilename(filePath) {
    if (typeof filePath === "string") {
      const pathSegments = filePath.split("/");
      const filename = pathSegments[pathSegments.length - 1];
      return filename;
    } else {
      return "";
    }
  }

  return (
    <form onSubmit={formik.handleSubmit} className="d-flex flex-column gap-5">
      <h3>Company Financials</h3>
      <div className="d-flex flex-column flex-md-row justify-content-between gap-4">
        <div style={{ width: "100%" }}>
          <label>Last 2 years audited Financials available</label>
          <div className="d-flex gap-3">
            <input
              type="radio"
              name="last2yr"
              value="true"
              onChange={() => {
                formik.setValues({
                  ...formik.values,
                  last_two_years_financials_available: true,
                });
              }}
              onBlur={formik.handleBlur}
              checked={
                formik.values.last_two_years_financials_available === true
              }
            />
            <label htmlFor="Yes">Yes</label>
            <input
              type="radio"
              name="last2yr"
              value="false"
              onChange={() => {
                formik.setValues({
                  ...formik.values,
                  last_two_years_financials_available: false,
                });
              }}
              onBlur={formik.handleBlur}
              checked={
                formik.values.last_two_years_financials_available === false
              }
            />
            <label htmlFor="No">No</label>
          </div>
        </div>
        <div className="w-100 d-none d-md-block"></div>
      </div>
      <div className="d-flex flex-column flex-md-row justify-content-between gap-4 ">
        {formik.values.last_two_years_financials_available && (
          <div className="w-100  ">
            <Fade top>
              <FileUpload
                labelTitle="Last 2 years audited financials with audit reports"
                required={true}
                onFileSelect={(files) =>
                  handleFileUpload(
                    "last_two_years_audited_financials",
                    files[0]
                  )
                }
                selectedFile={formik.values.last_two_years_audited_financials}
                value={extractNameAfterFirstUnderscore(
                  formik.values.last_two_years_audited_financials
                )}
                id={"last_two_years_audited_financials"}
                title=" Click to upload "
                iconStyle={{
                  width: 51,
                  height: 51,
                  color: "#828282",
                  opacity: 0.3,
                }}
                // fileType={fileTypes}
                className="file-upload-customer w-100"
              />
            </Fade>
          </div>
        )}
        <FileUpload
          labelTitle="FY 23-24 Provisional Financials (Singed and Stamped)"
          required={true}
          onFileSelect={(files) =>
            handleFileUpload("provisional_financials", files[0])
          }
          selectedFile={formik.values.provisional_financials}
          value={extractNameAfterFirstUnderscore(
            formik.values.provisional_financials
          )}
          id={"provisional_financials"}
          title=" Click to upload "
          iconStyle={{
            width: 51,
            height: 51,
            color: "#828282",
            opacity: 0.3,
          }}
          error={
            formik.touched.provisional_financials &&
            formik.errors.provisional_financials
          }
          // fileType={fileTypes}
          className="file-upload-customer w-100"
        />
        {!formik.values.last_two_years_financials_available && (
          <div className="w-100 d-none d-md-block"></div>
        )}
      </div>

      <div className="d-flex flex-column flex-md-row justify-content-between gap-4">
        <FileUpload
          labelTitle="Audited financials of Holding or Subsidiary Companies in case Lessee entity is Special Purpose Vehicle"
          required={false}
          onFileSelect={(files) =>
            handleFileUpload("audited_financials", files[0])
          }
          selectedFile={formik.values.audited_financials}
          value={extractNameAfterFirstUnderscore(
            formik.values.audited_financials
          )}
          id={"audited_financials"}
          title=" Click to upload "
          iconStyle={{
            width: 51,
            height: 51,
            color: "#828282",
            opacity: 0.3,
          }}
          
          className="file-upload-customer w-100"
        />
        <FileUpload
          required={true}
          onFileSelect={(files) => handleFileUpload("p_and_l", files[0])}
          selectedFile={formik.values.p_and_l}
          value={extractNameAfterFirstUnderscore(formik.values.p_and_l)}
          id={"p_and_l"}
          title=" Click to upload "
          iconStyle={{
            width: 51,
            height: 51,
            color: "#828282",
            opacity: 0.3,
          }}
          error={
            formik.touched.p_and_l &&
            formik.errors.p_and_l
          }
          // fileType={fileTypes}
          labelTitle="Financial Projections of at least next 1 year with P&L BalanceSheet and Cashflow Statements"
        />
      </div>
      <div className="d-flex flex-column flex-md-row justify-content-between gap-4">
        <FileUpload
          labelTitle=" BalanceSheet "
          selectedFile={formik.values.balance_sheet}
          className="file-upload-customer w-100"
          required={true}
          onFileSelect={(files) => handleFileUpload("balance_sheet", files[0])}
          value={extractNameAfterFirstUnderscore(formik.values.balance_sheet)}
          id={"balance_sheet"}
          title=" Click to upload "
          error={
            formik.touched.balance_sheet &&
            formik.errors.balance_sheet
          }
          iconStyle={{
            width: 51,
            height: 51,
            color: "#828282",
            opacity: 0.3,
          }}
        />
        <FileUpload
          labelTitle=" Cashflow Statements"
          selectedFile={formik.values.cash_flow_statement}
          className="file-upload-customer w-100"
          required={true}
          error={
            formik.touched.cash_flow_statement &&
            formik.errors.cash_flow_statement
          }
          onFileSelect={(files) =>
            handleFileUpload("cash_flow_statement", files[0])
          }
          value={extractNameAfterFirstUnderscore(
            formik.values.cash_flow_statement
          )}
          id={"cash_flow_statement"}
          title=" Click to upload "
          iconStyle={{
            width: 51,
            height: 51,
            color: "#828282",
            opacity: 0.3,
          }}
        />
      </div>
      <div className="d-flex flex-column flex-md-row justify-content-between gap-4">
        <FileUpload
          selectedFile={formik.values.sanction_letter}
          labelTitle="Sanction Letter of all the loans/​leases taken by the Company"
          className="file-upload-customer w-100"
          required={true}
          onFileSelect={(files) =>
            handleFileUpload("sanction_letter", files[0])
          }
          error={
            formik.touched.sanction_letter &&
            formik.errors.sanction_letter
          }
          value={extractNameAfterFirstUnderscore(formik.values.sanction_letter)}
          id={"sanction_letter"}
          title=" Click to upload "
          iconStyle={{
            width: 51,
            height: 51,
            color: "#828282",
            opacity: 0.3,
          }}
        />
        <FileUpload
          selectedFile={formik.values.gstr_data}
          labelTitle="Last 12 months GSTR Data"
          className="file-upload-customer w-100"
          required={true}
          onFileSelect={(files) => handleFileUpload("gstr_data", files[0])}
          value={extractNameAfterFirstUnderscore(formik.values.gstr_data)}
          id={"gstr_data"}
          error={
            formik.touched.gstr_data &&
            formik.errors.gstr_data
          }
          title=" Click to upload "
          iconStyle={{
            width: 51,
            height: 51,
            color: "#828282",
            opacity: 0.3,
          }}
        />
      </div>
      <div className="d-flex flex-column flex-md-row justify-content-between gap-4">
        <FileUpload
          labelTitle="Last 2 year ITR with computation"
          selectedFile={formik.values.itr_with_computation}
          className="file-upload-customer w-100"
          required={true}
          onFileSelect={(files) =>
            handleFileUpload("itr_with_computation", files[0])
          }
          value={extractNameAfterFirstUnderscore(
            formik.values.itr_with_computation
          )}
          id={"itr_with_computation"}
          error={
            formik.touched.itr_with_computation &&
            formik.errors.itr_with_computation
          }
          title=" Click to upload "
          iconStyle={{
            width: 51,
            height: 51,
            color: "#828282",
            opacity: 0.3,
          }}
        />
        <FileUpload
          labelTitle="Unit Economics"
          selectedFile={formik.values.unit_economics}
          className="file-upload-customer w-100"
          required={true}
          onFileSelect={(files) => handleFileUpload("unit_economics", files[0])}
          value={extractNameAfterFirstUnderscore(formik.values.unit_economics)}
          id={"unit_economics"}
          error={
            formik.touched.unit_economics &&
            formik.errors.unit_economics
          }
          title=" Click to upload "
          iconStyle={{
            width: 51,
            height: 51,
            color: "#828282",
            opacity: 0.3,
          }}
        />
      </div>
      <div className="d-flex flex-column flex-md-row justify-content-between gap-4">
        <FileUpload
          labelTitle="Last 12 months Bank Statements"
          selectedFile={formik.values.bank_statement}
          className="file-upload-customer w-100"
          required={true}
          onFileSelect={(files) => handleFileUpload("bank_statement", files[0])}
          value={extractNameAfterFirstUnderscore(formik.values.bank_statement)}
          id={"bank_statement"}
          error={
            formik.touched.bank_statement &&
            formik.errors.bank_statement
          }
          title=" Click to upload "
          iconStyle={{
            width: 51,
            height: 51,
            color: "#828282",
            opacity: 0.3,
          }}
        />
        <FileUpload
          labelTitle="Last 12 months cash flow MIS"
          selectedFile={formik.values.cash_flow_mis}
          className="file-upload-customer w-100"
          required={true}
          onFileSelect={(files) => handleFileUpload("cash_flow_mis", files[0])}
          value={extractNameAfterFirstUnderscore(formik.values.cash_flow_mis)}
          id={"cash_flow_mis"}
          error={
            formik.touched.cash_flow_mis &&
            formik.errors.cash_flow_mis
          }
          title=" Click to upload "
          iconStyle={{
            width: 51,
            height: 51,
            color: "#828282",
            opacity: 0.3,
          }}
        />
      </div>
      <div className="d-flex flex-column flex-md-row justify-content-between gap-4">
        <FileUpload
          labelTitle="Last 3 months current Fleet Tracking Data "
          selectedFile={formik.values.fleet_tracking_data}
          className="file-upload-customer w-100"
          required={true}
          onFileSelect={(files) =>
            handleFileUpload("fleet_tracking_data", files[0])
          }
          error={
            formik.touched.fleet_tracking_data &&
            formik.errors.fleet_tracking_data
          }
          value={extractNameAfterFirstUnderscore(
            formik.values.fleet_tracking_data
          )}
          id={"fleet_tracking_data"}
          title=" Click to upload "
          iconStyle={{
            width: 51,
            height: 51,
            color: "#828282",
            opacity: 0.3,
          }}
        />
        <div className="w-100 d-none d-md-block"></div>
      </div>

      <div className="d-flex gap-3 justify-content-center">
        <Button label="Back" onClick={handleBack} />
        <Button label="Next" onClick={formik.handleSubmit} disabled={!formik.isValid} loading={loading} />
      </div>
      {/* <Button label="Submit" onClick={formik.handleSubmit} /> */}
    </form>
  );
};

export default Form5;
