import React from 'react';
import "./BlogPage.css";
import BlogCard from './BlogCard';

const BlogPage = () => {
    return (
        <>
            <div className=''>
                <div className="container ">
                    <div className="blog-main-heading text-center">
                        <h1>Electric Insights: Unleashing the Power of EVs </h1>
                    </div>
                    <div className="blog-main-para text-center mt-3">
                        <p>Discover the Latest in Electric Vehicles on Our Blog</p>
                    </div>
                </div>
            </div>
            <div className="container mt-5">
                <BlogCard />
            </div>

        </>
    )
}

export default BlogPage
