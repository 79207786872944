import React, { useEffect } from "react";
import "./AboutMain.css";
import Accelerating from "../Images/Team-_3_.webp";
import Fleet_depot from "../Images/fleet_depot.png";
import Fleet_lease from "../Images/fleet_lease.png";
import Fleet_os from "../Images/fleet_os.png";
import Fleet_protect from "../Images/fleet_protect.png";
import Card from "./Card";
import FounderCard from "./FounderCard";
import SideCard from "./SideCard";

import Applynow from "./Applynow";
import FeaturedIn from "./FeaturedIn";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { BASE_URL } from "../repository/api";

const API=`${BASE_URL}/marketing-data/?oem=mahindra`

const AboutMain = () => {
  const responsive = {
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  // const [openModal,setOpenModal]= useState(false);

  // const carouselStyle = {
  //   padding: "0 5px",
  //   margin: "0 -5px"
  // };

  // const itemStyle = {
  //   margin: "0 10px"
  // };

  const fetchApiData = async(url) =>{
    try{
      const res=await fetch(url);
      const data = await res.json();
      console.log("individual page api data",data);
    }catch(error){
      console.log(error);
    }
  }

  useEffect(()=>{
    fetchApiData(API)
  })

  return (
    <>
      <div className="container">
        <div className="accelerating-india row mt-md-5 w-100 heading-mg w-100 ms-0">
          <div className="col-lg-6 col-12 col-md-12 col-sm-12 heading-main  mt-5 mb-4">
            <h1 className="main mb-3">
              Accelerating India towards sustainable mobility
            </h1>
            <p>
              Our mission is to offer cost-effective, dependable, and
              emission-free transportation solutions and full-stack services for
              businesses.
            </p>
          </div>
          <div
            className="col-sm-12 col-md-12 col-lg-6 picture-main mt-5"
            style={{ marginBottom: "2.3rem!important" }}
          >
            <img
              src={Accelerating}
              alt=""
              className="first-pic img-responsive w-100"
              style={{ borderRadius: "13px" }}
            />
          </div>
        </div>
      </div>

      <div className="next-fullhidden mt-5 ">
        <div>
          <Carousel
            responsive={responsive}
            autoPlay={true}
            showDots={true}
            autoPlaySpeed={4000}
            infinite={true}
            arrows={false}
            className=" container fleet_containers pb-5 pt-5 pe-3 ms-0"
          >
            <div>
              {" "}
              <div className="container ms-1 ">
                <div className="card fleet ">
                  <div className="card-body">
                    <div className="fleet_image mb-4">
                      <img src={Fleet_depot} alt="super" />
                    </div>
                    <div className="head-para">
                      <h1 className="fleet_heading mb-3">Fleet Depot</h1>
                      <p className="fleet_paragraph">
                        An affordable, full-stack leasing service with no
                        upfront costs, smoothing the transition to EVs.
                      </p>
                    </div>
                  </div>
                </div>
              </div>{" "}
            </div>

            <div>
              {" "}
              <div className="container ms-1">
                <div className="card fleet ">
                  <div className="card-body">
                    <div className="fleet_image mb-4">
                      <img src={Fleet_lease} alt="super" />
                    </div>
                    <div className="head-para">
                      <h1 className="fleet_heading mb-2">Fleet Lease</h1>
                      <p className="fleet_paragraph">
                        An integrated fleet management platform with dashboards
                        to monitor health, performance, and uptime for financing
                        institutions and fleet operators.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              {" "}
              <div className="container ms-1">
                <div className="card fleet  ">
                  <div className="card-body">
                    <div className="fleet_image mb-4">
                      <img src={Fleet_os} alt="super" />
                    </div>
                    <div className="head-para">
                      <h1 className="fleet_heading mb-2">Fleet OS</h1>
                      <p className="fleet_paragraph">
                        Customised insurance plans for commercial fleets, with
                        protection against accidents, breakdowns, warranty
                        claims, and more.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              {" "}
              <div className="container ms-1">
                <div className="card fleet  ">
                  <div className="card-body">
                    <div className="fleet_image mb-4">
                      <img src={Fleet_protect} alt="super" />
                    </div>
                    <div className="head-para">
                      <h1 className="fleet_heading mb-2">Fleet Protect</h1>
                      <p className="fleet_paragraph">
                        Simplifying your fleet expenses with monthly pricing for
                        leasing, servicing, parking, and charging at one
                        location.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Carousel>
        </div>
      </div>

      <div className="next-main-box-1 container">
        <div className="next-main-box ">
          <div className="box-card container ">
            <Card
              icons={
                <img
                  src={Fleet_lease}
                  alt="super"
                  className="fleet-depot-image"
                />
              }
              title="Fleet Lease"
              text="An integrated fleet management platform with dashboards to monitor health, performance, and uptime for financing institutions and fleet operators."
            />

            <Card
              icons={
                <img src={Fleet_os} alt="super" className="fleet-depot-image" />
              }
              title="Fleet OS"
              text="Customised insurance plans for commercial fleets, with protection against accidents, breakdowns, warranty claims, and more."
            />
            <Card
              icons={
                <img
                  src={Fleet_protect}
                  alt="super"
                  className="fleet-depot-image"
                />
              }
              title="Fleet Protect"
              text="Simplifying your fleet expenses with monthly pricing for leasing, servicing, parking, and charging at one location."
            />

            <Card
              icons={
                <img
                  src={Fleet_depot}
                  alt="super"
                  className="fleet-depot-image"
                />
              }
              title="Fleet Depot"
              text="An affordable, full-stack leasing service with no upfront costs, smoothing the transition to EVs."
            />
          </div>
        </div>
      </div>
      {/* <div className=" position-fixed chat-imgs">
                       {!openModal && <img  className="chat-image "  src={Chatting_box} alt="Chat" style={{width:"55px",height:"55px"}}  onClick={()=>{
  setOpenModal(true);
 }}/>}
    { openModal && <ChatUi closeModal={setOpenModal}/>}

 </div> */}

      <FounderCard />
      <SideCard />
      <Applynow />
      <FeaturedIn />
      {/* <Ambitious/> */}
    </>
  );
};

export default AboutMain;
