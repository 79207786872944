import React from "react";
import Alt_team from "../Images/Alt-team.JPEG";
import { Link } from "react-router-dom";
const PressReleasePage = () => {
  return (
    <>
      <div className="home-mainss">
        <div className=" mt-md-5 pt-md-5  mx-auto col-lg-8 col-sm-12 ">
          <div className="container d-flex flex-column gap-4 shadow-sm p-4 mb-5  rounded">
            <h3>
              Alt Mobility raises $6mn in Funding Co-led by Shell Ventures,
              Eurazeo, EV2 and Twynam{" "}
            </h3>
            <img
              src={Alt_team}
              alt="ecosystem"
              className="img-responsive"
              style={{ maxHeight: "500px", objectFit: "cover" }}
            />

            <h5 className="">
              Aims to enhance its EV FleetOS platform, expand market for EV
              leasing and accelerate its growth plans
            </h5>
            <div className="d-flex flex-column gap-4">
              <p className="">
               <strong> National, 18th January 2024:</strong><Link to="/" style={{color:"blue"}}> Alt Mobility</Link>, a technology-enabled
                electric vehicle (EV) leasing and lifecycle management platform,
                has announced that it has raised $6mn in funding co-led by <strong>Shell
                Ventures, Eurazeo, EV2 Ventures and Twynam.</strong> The round also saw
                participation from UC Inclusive, Piper Serica, Pitchright and
                LetsVenture in a mix of equity and venture debt.
              </p>
              <p className="">
                The capital will be used for hiring engineering team to scale
                its EV asset management platform FleetOS. The company would also
                be launching new verticals for fleets including Drive-to-Own
                model, Parametric Insurance, Fleet Depots (parking and
                charging), Battery Refurbishment Unit. The company further
                intends to accelerate its growth plans and embark expanding its
                presence in over 20+ cities aiming to achieve an AUM of $100mn
                in the next two years.
              </p>
              <p>
                Founded in 2021 by <strong>Dev Arora, Anuj Gupta, Manas Arora, Harsh
                Goyal</strong> and <strong>Jayant Gupta</strong> , Alt Mobility has been a catalyst for the
                EV ecosystem, enabling rapid EV fleet adoption through asset
                management for fleets, providing hassle free and integrated
                experience and unlocking lowest total cost of ownership and
                maximum fleet uptime, resulting in business certainty and
                predictability.
              </p>
              <p>
                Sharing the development,<strong> Dev Arora, CEO, Alt Mobility</strong> said,
                “With their faith in Alt Mobility, investors across four
                continents are confident in the growth of the EV ecosystem in
                India and our integrated approach of electrifying commercial
                fleets. The investors joining our board recognize the immense
                growth opportunity in the EV sector in India and will bring in
                their global and India experience in achieving our mission.”{" "}
              </p>
              <p>
                "ALT Mobility's vision to further the electrification of fleets
                in India aligns with our commitment to advance the development
                of sustainable and efficient transportation solutions in the
                region," says <strong>Christine Vincent, Venture Principal at Shell
                Ventures.</strong> "Today, one of the main barriers for fleets to
                transition to EVs is the lack of affordable financing options.
                Alt's model caters to this gap and helps business owners
                accelerate their electrification journey. We are excited to work
                with ALT Mobility towards a more sustainable mobility future for
                India."
              </p>
              <p>
               <strong> Ernest Xue, Director at Eurazeo Venture Smart City</strong>, said, ”The
                electrification of mobility is significant to the
                decarbonization of transportation and improvement of pollution
                in India, all while driving job creation and inclusive economic
                growth across demographics. Our investment into Alt Mobility
                signifies our belief that sustainable products emerging from the
                nexus of mobility, energy and data will be transformative for
                one of the deepest and fastest-growing markets in the world."
              </p>
              <p>
               <strong> Matthieu Bonamy partner at Eurazeo Venture</strong> says “As a global
                investor that has supported the growth of deep EV ecosystems in
                both China and Europe, we are committed to partnering with
                driven entrepreneurs working on unlocking the different
                chokepoints that hinder such progress and enable more
                sustainable cities.”
              </p>
              <p>
                General Partner,<strong> EV2 Ventures – Karan Mittal</strong>, said, “Alt
                Mobility aligns seamlessly with our central thesis by directly
                contributing towards the advancing of the clean mobility
                ecosystem, with its innovative approach of offering affordable
                financing solutions and robust asset lifecycle management,
                addressing a key obstacle for electric vehicle adoption, and
                thus reinforcing our commitment to a sustainable and impactful
                investment in the mobility sector."
              </p>
              <p>
               <strong> Jonathan Green, Investment Director at Twynam Earth Fund </strong>said,
                “The team at Alt have delivered on lower cost operating leases,
                removing friction from the transition to electric for operators.
                Their tenacity has been clear from day one and we at Twynam are
                excited to support their journey toward decarbonising India’s
                massive fleet of vehicles.
              </p>
              <p>
                The company is on track to become the largest proprietary EV
                fleet provider in the country. By 2025, Alt Mobility is aiming
                their fleet's cumulatively will offset 400,000 MT of lifetime
                CO2 emissions, reinforcing their commitment to sustainable
                mobility.<br/>Website: <Link to="/" style={{color:"blue"}}> www.alt-mobility.com</Link>
              </p>
              <p>
                <strong>About Alt Mobility</strong>
                <br /><br/>
                Alt Mobility is a full stack Electric Fleet Leasing platform
                that enables transition of urban logistics fleets to electric
                mobility through leasing. Alt’s Fleet Lease solution combines
                leasing with insurance, roadside service, maintenance, charging
                & parking hubs for complete lifecycle management of electric
                fleets. The company started leasing electric vehicles in April
                2022 and has scaled to AUM of $12 million (Rs 100 cr) comprising
                of leased e2W, e3W, e4W and chargers.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PressReleasePage;
