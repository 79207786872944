import React, { useEffect, useState } from "react";
import Battery from "../Images/batter.png";
import { BASE_URL } from "../repository/api";
// import FleetVehicle from "../Images/fleetosvehicle.png";
import { MdOutlineNavigateNext } from "react-icons/md";
import W3 from "../Images/3w.png";
import Iot from "../Images/Iot.png";
import FleetOperationCenter from "../Images/Turning hardware into intelligent fleets.jpg";
import BatteryHealth from "../Images/battery_health.png";
import current from "../Images/current_voltage.png";
import Excellent from "../Images/excellent.png";
import FleeteOSCard from "../Images/fleet-os-card.png";
import FleetRound from "../Images/fleet-round.png";
import Fleet15 from "../Images/fleet15.png";
import HighVehicle from "../Images/high_vehicle.png";
import Indicator50 from "../Images/indicator-50.png";
import motor_Controller from "../Images/motor_controller.png";
import Motor_Data from "../Images/motor_data.png";
import NagarRoad from "../Images/nagar-road.png";
import OK from "../Images/ok.png";
import RahulJain from "../Images/rahul_jain.png";
import Running from "../Images/running.png";
import Temperature from "../Images/temperature_alerts.png";
import VehicleEfficiency from "../Images/vehicle_efficiency.png";
import "./FleetOs.css";
// import FleetMapFleetOp from "../Images/Turning hardware into intelligent fleets 2.jpg";
import { Link } from "react-router-dom";
import Ecosystem from "../Components/Ecosystem";
import AdvanceFleetManagement from "../Images/Advance fleet management.jpg";
import EvLeaseImage from "../Images/Our EV lease ecosystem.jpg";
import AdvanceFleetManagement2 from "../Images/advancefleetmanagement2.png";
import ClaimIntimation from "../Images/claim_initmation (2).png";
import Diagnostics from "../Images/diagnostics.png";
import EvLeaseFullImage from "../Images/evleasefullimage.png";
import FleetDanger from "../Images/fleet-danger.png";
import FleetTick from "../Images/fleet-tick.png";
import HealthOptimise from "../Images/health_optimise.png";
import Tick from "../Images/tick.png";
import FaqComponent from "./FaqComponent";
// import Chatting_box from "../Images/chatting_box.png";
import axios from "axios";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useMediaQuery } from "react-responsive";

// import ChatUi from "./ChatUi";

const FleetOs = () => {

  // const [user, setUser] = useState({ question: "", answer: [] });
  const [faq, setfaq] = useState([]);

  useEffect(() => {
    axios
      .get(`${BASE_URL}/faq/`)
      .then((response) => {
        console.log(response);
        console.log(
          "os",
          response.data.results.filter((o) => o.faqType === "Fleet OS")
        );
        setfaq(response.data.results.filter((o) => o.faqType === "Fleet OS"));
        console.log(response.data.results);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const arr = faq;
  console.log("arr", arr);
  const isMobile = useMediaQuery({ maxWidth: 827 });

  const responsive = {
    tablet: {
      breakpoint: { max: 1024, min: 781 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 780, min: 0 },
      items: 1,
    },
  };

  const carouselStyle = {
    padding: "0 5px",
    margin: "0 -5px",
  };

  const itemStyle = {
    margin: "0 10px",
  };

  const isMobileOrTablet = window.innerWidth <= 823;

  const handleClick = () => {
    window.scrollTo(0, 0);
  };
  // const [openModal,setOpenModal]= useState(false);

  return (
    <>
      <section className="section mt-md-5 pt-5 container">
        <div className=" p-md-2 p-lg-5 paddingsmall text-center">
          <h1 className="main">
            <div className="unleash-size">
              Unleash the full power of data to run your electric fleet
            </div>
            <div></div>
          </h1>
          <p className="main" style={{ marginTop: "1rem" }}>
            With FleetOS, you can optimize your fleet's telematics, maintenance,
            leasing, charging,
            <br /> parking and lower the total cost of fleet operations.
          </p>
        </div>
      </section>
      <div className="fleet-os-box container gx-0 gap-2 ">
        <div className="d-flex gap-2 ">
          <div className="">
            <div className="card fleet-first-cnt mt-3">
              <div className="card-body d-flex justify-content-around gap-1">
                <div className="">
                  <img src={Battery} alt="Battery" className="flt-tick"></img>
                </div>
                <div className="title ml-auto">
                  <p className="text-start l-h-inherit">
                    Vehicles with high battery temperature
                  </p>
                </div>
              </div>
            </div>

            <div className="card fleet-first-cnt mt-3 ">
              <div className="card-body d-flex justify-content-around gap-1">
                <div className="image">
                  <img src={FleetTick} alt="Battery" className="flt-tick" />
                </div>
                <div className="title ml-auto">
                  <p className="text-start l-h-inherit">
                    Low operating costs for budget-friendly fleets
                  </p>
                </div>
              </div>
            </div>

            <div className="card alt-card fleet-first-cnt">
              <div className="card-body d-flex align-items-center">
                <div className="image">
                  <img src={FleetDanger} alt="Battery"></img>
                </div>
                <div className="title">
                  <p className="l-h-inherit">
                    Vehicles with faults{" "}
                    <span style={{ color: "#2DB78C", marginLeft: "0.5rem" }}>
                      8
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className=" w-100">
            <div className=" mt-3">
              <div className="card">
                <div className="card-body">
                  <div>
                    <div className="d-flex md-hr justify-content-between">
                      <h5>MD-Hr124rgd</h5>
                      <p className="running-img">
                        <img src={Running} alt="" />
                      </p>
                    </div>
                    <div className="range-left text-start">
                      45 Km Range left
                    </div>
                  </div>
                  <img src={Indicator50} alt="" className="indicator-50" />
                  <br></br>
                  <div className="d-flex">
                    <div className="fleet-os-card">
                      <img
                        src={FleeteOSCard}
                        alt="Battery"
                        className="img-responsive w-100"
                      />
                    </div>
                    <div className="ml-auto ms-3 mt-3">
                      <p className="small rahul_jain">
                        <img src={RahulJain} alt="" /> Rahul Jain
                      </p>
                      <p className="small text-start rahul_jain">
                        <img src={OK} alt="" /> Good 7.5
                      </p>
                    </div>
                  </div>
                  <div className="location d-flex justify-content-between mt-2">
                    <p className="rahul_jain gap-1 d-flex mt-1">
                      <img src={NagarRoad} alt="" />
                      <h6 className="text-nowrap">
                        {" "}
                        Nagar Road, Karol Bagh New Delhi
                      </h6>
                    </p>
                    <p className="w3 gap-1 d-flex ms-5">
                      <img src={W3} alt="" />
                      <h6>3w</h6>
                    </p>
                  </div>
                </div>
              </div>
              <div className="card mt-3">
                <div className="card-body">
                  <div className="title">
                    <p className="small d-flex text-start text-nowrap tick-img gap-2">
                      <img src={Tick} alt="" /> 360 services for overall Fleets
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="">
            <div className=" ">
              <div className="card alt-card">
                <div className="card-body">
                  <div className="alt-card">
                    <div className="d-flex align-items-center">
                      <div className="image">
                        <img src={HighVehicle} alt="Battery"></img>
                      </div>
                      <div className="title">
                        <p className="small text-start text-nowrap">
                          High vehicle uptime
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="alt-card mt-3">
                    <div className="veh-efficiency d-flex align-items-center">
                      <div className="image">
                        <img src={VehicleEfficiency} alt="Battery"></img>
                      </div>
                      <div className=" title">
                        <p className="tiny">Vehicle efficiency</p>
                        <p className="tiny text-start mt-1 green-style">
                          85Wh/Km
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="alt-card mt-3">
                    <div className="veh-efficiency d-flex align-items-center">
                      <div className="image">
                        <img src={BatteryHealth} alt="Battery"></img>
                      </div>
                      <div className="title">
                        <p className="tiny">Battery health</p>
                        <p className="tiny text-start mt-1 green-style">+30%</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className=" w-100">
            <div className=" mt-3">
              <div className="card">
                <div className="card-body d-flex ">
                  <div className="roundset mt-2">
                    <img
                      src={Excellent}
                      alt="Battery"
                      className="round-img img-responsive w-100"
                    />
                  </div>
                  <div className="fleet-15 title ml-auto">
                    <img
                      src={Fleet15}
                      alt=""
                      className=" img-responsive w-100"
                    />
                    <h1 className="small mt-3">Resale value comparison</h1>
                  </div>
                </div>
              </div>
              <div className="card mt-3">
                <div className="card-body d-flex ">
                  <div className="roundse mt-">
                    <img
                      src={FleetRound}
                      alt="Battery"
                      className="round-img img-responsive w-100"
                    />
                  </div>
                  <div className="title ml-auto   ">
                    <h3 className="small ms-3">SOH degradation</h3>
                    <ul>
                      <li className="tiny light-green1 mt-2">
                        Natural Aging &nbsp; &nbsp; 67%
                      </li>
                      <li className="tiny light-green  mt-2">
                        Poor Charging &nbsp; &nbsp; 13%
                      </li>
                      <li className="tiny light-green2 mt-2">
                        Harsh Driving &nbsp; &nbsp; 10%
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container p-md-5 paddingsmall text-center mt-5">
        <h4 className="turning-hard" style={{ marginBottom: "1rem " }}>
          Turning hardware into <br /> intelligent fleets
        </h4>
        <p
          className="main"
          style={{ maxWidth: "600px", marginLeft: "auto", marginRight: "auto" }}
        >
          We collect data from these touch points and analyse it constantly to
          provide you with the best fleet services.
        </p>
        <Link to="/enquire" onClick={handleClick}>
          <button className="fleet-button mt-4">
            Looking for more? Enquire Now <MdOutlineNavigateNext size={25} />
          </button>
        </Link>
      </div>
      <div className="container p-2 mt-3">
        <div className="fleet-battery alt-card d-flex ">
          <div className="image d-flex ">
            <img src={current} alt="current" />
            <p>Battery(Current & Voltage)</p>
          </div>

          <div className="d-flex">
            <img src={Temperature} alt="Temperature" />
            <p>Temperature & Alerts</p>
          </div>

          <div className="d-flex">
            <img src={motor_Controller} alt="Motor_Controller" />
            <p>Motor Controller</p>
          </div>

          <div className="d-flex">
            <img src={Motor_Data} alt="Motor_Data" />
            <p>Motor Data Analytics</p>
          </div>

          <div className="d-flex">
            <img src={Iot} alt="Iot" />
            <p>IoT (Location, Accelaration)</p>
          </div>
        </div>
      </div>
      <div className="fleet-operation-container container p-5 p-sm-3 text-center mt-5">
        <img
          src={FleetOperationCenter}
          alt="FleetOperationCenter"
          className="img-responsive"
        />
      </div>
      {/* <div className="fleetosplay mt-5 p-4 w-100">
        <img
          src={FleetMapFleetOp}
          alt="FleetMapFleetOp"
          className="img-responsive mt-2"
          style={{ maxHeight: "750px", objectFit: "contain" }}
        />
      </div> */}
      <div className="fleet-management-bg w-100 mb-5 container">
        <div className="fleet-management">
          <div className="row w-100">
            <div className="advance-management-title col-lg-6 col-md-12  mt-5 ">
              <h1 className="turning-hard text-md-start text-sm-center">
                Advance fleet <br />
                management
              </h1>
              <p className="main mt-3 text-md-start text-sm-center">
                Know more about our services
              </p>
              <div className="advance-fleet-management2 col-sm-12">
                <img
                  src={AdvanceFleetManagement2}
                  alt="AdvanceFleetManagement2"
                  className="img-responsive w-100"
                />
              </div>
              <div className="row fleet-os-tick1 mt-4 w-100">
                <div className="col-lg-6 col-md-6">
                  <div className="d-flex tick gap-2">
                    <img src={Tick} alt="Tick" className="mt-2" />
                    <h5>CO2 Offset</h5>
                  </div>
                  <div className="d-flex tick gap-2">
                    <img src={Tick} alt="Tick" className="mt-2" />
                    <h5>Fuel savings</h5>
                  </div>
                  <div className="d-flex tick gap-2">
                    <img src={Tick} alt="Tick" className="mt-2" />
                    <h5>Battery Analytics</h5>
                  </div>
                  <div className="d-flex tick gap-2">
                    <img src={Tick} alt="Tick" className="mt-2" />
                    <h5>Trip Details</h5>
                  </div>
                  <div className="d-flex tick gap-2">
                    <img src={Tick} alt="Tick" className="mt-2" />
                    <h5>Vehicle Run Time</h5>
                  </div>
                  <div className="d-flex tick gap-2">
                    <img src={Tick} alt="Tick" className="mt-2" />
                    <h5>Warranty Management</h5>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="d-flex tick gap-2">
                    <img src={Tick} alt="Tick" className="mt-2" />
                    <h5>Energy consumption</h5>
                  </div>
                  <div className="d-flex tick gap-2">
                    <img src={Tick} alt="Tick" className="mt-2" />
                    <h5>Driver Behaviour</h5>
                  </div>
                  <div className="d-flex tick gap-2">
                    <img src={Tick} alt="Tick" className="mt-2" />
                    <h5>Service Management</h5>
                  </div>
                  <div className="d-flex tick gap-2">
                    <img src={Tick} alt="Tick" className="mt-2" />
                    <h5>Vehicle Documents</h5>
                  </div>
                  <div className="d-flex tick gap-2">
                    <img src={Tick} alt="Tick" className="mt-2" />
                    <h5>Vehicle Immobilisation</h5>
                  </div>
                  <div className="d-flex tick gap-2">
                    <img src={Tick} alt="Tick" className="mt-2" />
                    <h5>Insurance Management</h5>
                  </div>
                </div>
                {/* <Link to="/enquire">
                  <button className="fleet-button mt-4">
                    Looking for more? Enquire Now{" "}
                    <MdOutlineNavigateNext size={25} />
                  </button>
                </Link> */}
              </div>
            </div>

            <div className="advance-fleet-management1 col-lg-6 col-md-6">
              <img
                src={AdvanceFleetManagement}
                alt="AdvanceFleetManagement"
                className="img-responsive w-100"
              />
            </div>
          </div>
        </div>
        <div className="space" style={{ marginTop: "0px" }}>
          <Link to="/enquire">
            <button className="fleet-button " style={{ marginTop: "0px" }}>
              Looking for more? Enquire Now <MdOutlineNavigateNext size={25} />
            </button>
          </Link>
        </div>
      </div>
      <div className="Ev-Lease-Ecosystem w-100 mt-5 ">
        <div className="container">
          <div className="row">
            <div className="ev-lease-half-image col-lg-6 col-sm-12 pt-4">
              <img
                src={EvLeaseImage}
                alt="EvLeaseImage"
                className="img-responsive w-100"
              />
            </div>

            <div className="ev-lease-fleet-head col-lg-6 col-sm-12 pe-3 mb-4">
              <h1 className="ev-lease">Our EV lease ecosystem</h1>
              <p className="main">
                Designed to help fleets increase their efficiency, uptime, and
                safety. This is what we do -
              </p>
              <div className="ev-lease-full-image col-sm-12 pt-4">
                <img
                  src={EvLeaseFullImage}
                  alt="EvLeaseImage"
                  className="img-responsive w-100"
                />
              </div>
              <div className="d-flex tick gap-2 mt-4">
                <img src={Tick} alt="Tick" className="mt-1" />
                <p>Discover vehicles with Clear pricing.</p>
              </div>
              <div className="d-flex tick gap-2 mt-3">
                <img src={Tick} alt="Tick" className="mt-1" />
                <p>
                  Provide add-on services like FleetProtect insurance,
                  Maintenance, and Roadside assist.
                </p>
              </div>
              <div className="d-flex tick gap-2 mt-3">
                <img src={Tick} alt="Tick" className="mt-1" />
                <p>Provide Charging, Parking hubs, and Service garages.</p>
              </div>
              <div className="mt-4">
                <Link to="/enquire">
                  <button className="fleet-button">
                    Looking for more? Enquire Now{" "}
                    <MdOutlineNavigateNext size={25} />
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="lease-with-us w-100 p-3 mt-5 mb-4 text-center  p-md-5 p-sm-3 pb-5">
        <div className="container">
          <h1 className="ev-lease">Lease with Us</h1>
          <p className="main">
            Alt provides everything you need at one place using zero-emission
            commercial electric <br />
            fleets - leasing, insurance, services, telematics, parking, and
            charging - at a lower cost <br />
            than diesel or CNG operations.
          </p>

          {isMobileOrTablet ? (
            <Carousel
              responsive={responsive}
              arrows={false}
              className="text-start mt-4 mb-4 ms-0 pb-5"
              autoPlay={isMobile}
              showDots={isMobile}
              autoPlaySpeed={3000}
              infinite={true}
              style={carouselStyle}
            >
              <div style={itemStyle}>
                {" "}
                <div className="container">
                  <div className="lease-with-box p-3">
                    <div className="card-body">
                      <h4 className="lease-with-us-title">
                        Predictive maintenance & diagnostics
                      </h4>
                      <p className="lease-with-us-para">
                        Get alerts and recommendations for vehicles through the
                        AI/ML-based technology of FleetOS.
                      </p>
                      <img src={Diagnostics} alt="Diagnostics" />
                    </div>
                  </div>
                </div>
              </div>

              <div style={itemStyle}>
                <div className="container">
                  <div className="p-3 lease-with-box">
                    <div className="card-body">
                      <h4 className="lease-with-us-title">
                        Fleet health optimization
                      </h4>
                      <p className="main">
                        Discover the vehicle's health and battery pack, and get
                        real-time feedback on driving behavior to improve and
                        optimize vehicle life.
                      </p>
                      <img src={HealthOptimise} alt="HealthOptimise" />
                    </div>
                  </div>
                </div>
              </div>

              <div style={itemStyle}>
                <div className="container">
                  <div className="p-3 lease-with-box ">
                    <div className="card-body">
                      <h4 className="lease-with-us-title">
                        Service & warranty claims
                      </h4>
                      <p className="main">
                        Receive service, warranty alerts, and claims through
                        FleetOS App.
                      </p>
                      <img src={ClaimIntimation} alt="ClaimIntimation" />
                    </div>
                  </div>
                </div>
              </div>
            </Carousel>
          ) : (
            <div className="lease-with-us-card d-flex mt-5 mb-5 gap-1 ">
              <div className="container">
                <div className="lease-with-box p-3">
                  <div className="card-body">
                    <h4 className="lease-with-us-title">
                      Predictive maintenance & diagnostics
                    </h4>
                    <p className="lease-with-us-para">
                      Get alerts and recommendations for vehicles through the
                      AI/ML-based technology of FleetOS.
                    </p>
                    <img src={Diagnostics} alt="Diagnostics" />
                  </div>
                </div>
              </div>

              <div className="container">
                <div className="p-3 lease-with-box">
                  <div className="card-body">
                    <h4 className="lease-with-us-title">
                      Fleet health optimization
                    </h4>
                    <p className="main">
                      Discover the vehicle's health and battery pack, and get
                      real-time feedback on driving behavior to improve and
                      optimize vehicle life.
                    </p>
                    <img src={HealthOptimise} alt="HealthOptimise" />
                  </div>
                </div>
              </div>

              <div className="container">
                <div className="p-3 lease-with-box ">
                  <div className="card-body">
                    <h4 className="lease-with-us-title">
                      Service & warranty claims
                    </h4>
                    <p className="main">
                      Receive service, warranty alerts, and claims through FleetOS
                      App.
                    </p>
                    <img src={ClaimIntimation} alt="ClaimIntimation" />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <Ecosystem />
      <FaqComponent faq={[...arr]} />;
    </>
  );
};

export default FleetOs;
