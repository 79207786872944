import React, { CSSProperties, FC, useState, useRef } from "react";
import { v4 as uuid } from "uuid";
import { RiUploadCloudFill } from "react-icons/ri";
import Image from "../../assest/PDF-icon.svg";
import "./CustomerFileUploader.css";



const UploadFile = ({
  title,
  selectedFile = "",
  id = uuid(),
  subTitle,
  error,
  iconStyle,
  className,
  multiple = true,
  onFileSelect,
  value,
  fileType,
  required,
  labelTitle,
}) => {
  // ... (rest of the code remains the same)
  const [dragActive, setDragActive] = useState(false);
  // ref
  const inputRef = useRef(null);

  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = function (e) {
    console.log("handleDrop file uploader");
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length) {
      console.log("files array handleDrop", e.dataTransfer.files);
      // handleFiles(e.dataTransfer.files);

      onFileSelect && onFileSelect(e.dataTransfer.files);
    }
  };
  const [fileName, setFileName] = useState(undefined);

  // triggers when file is selected with click
  const handleChange = function (e) {
    e.preventDefault();
    console.log("handle change file uploader");
    const target = e.target;
    if (target.files && target.files.length) {
      // handleFiles(e.target.files);
      const uploadedFile = target.files[0];
      const uploadedFileName = uploadedFile.name;
      setFileName(uploadedFileName);
      console.log("files array change", target.files);
      onFileSelect && onFileSelect(Array.prototype.slice.call(target.files));
    }
  };

  // triggers the input when the button is clicked
  const onButtonClick = () => {
    inputRef.current && inputRef.current.click();
  };


  return (
    <div className="w-100">
      {labelTitle ? (
        <div className="labelDiv">
          <p className={`font-reg-400-12-15 labelTitle `} style={{fontSize:"14px"}}>
            {labelTitle} <label className="required">{required && "*"}</label>
          </p>
        </div>
      ) : null}
      <form
        id={`form-file-upload_form_${id}`}
        className={`form-file-upload ${className}`}
        onDragEnter={handleDrag}
        onSubmit={(e) => e.preventDefault()}
      >
        <input
          ref={inputRef}
          type="file"
          className={`form-file-upload_input`}
          id={`form-file-upload_input_${id}`}
          multiple={multiple}
          onChange={handleChange}
          accept={fileType ?? ""}
        />

        <label

          id="label-file-upload"
          htmlFor={`form-file-upload_input_${id}`}
          className={dragActive ? "drag-active" : ""}
        >
          {selectedFile ? (
            <div className="d-flex">
              <div style={{ textAlign: "center" }}>
                <img src={Image} style={{ height: "4rem" }} />
              </div>
              <p className="uploaded-file-name text-center align-items-center d-flex " style={{ fontSize: "14px" }}>
                {fileName ?? value}
              </p>
            </div>
          ) : value !== "" ? (
            <p className="uploaded-file-name" style={{ fontSize: "14px" }}>
              {fileName ?? value}
            </p>
          ) : (
            <>
              <RiUploadCloudFill style={iconStyle} />
              {title ? (
                <p className="textfiledrop text-center">{title} </p>
              ) : null}
              {subTitle ? (
                <p className="textfiledrop">{subTitle}</p>
              ) : null}
            </>
          )}

          <button className="upload-button" onClick={onButtonClick}></button>
        </label>

        {dragActive && (
          <div
            id="drag-file-element"
            className={`drag-file-element_${id}`}
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          ></div>
        )}
      </form>
      {error && <div className="error-text">{error}</div>}
    </div>
  );
};

export default UploadFile;
