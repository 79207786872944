import React from "react";
import SwiperCore from "swiper/core";
import "swiper/css/scrollbar"; // Import the scrollbar styles
import { Pagination, Scrollbar } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import IndividualFlexible from "../../../../assest/Individual-Flexible.svg";
import IndividualSmart from "../../../../assest/Individual-Smart.svg";
import IndividualZero from "../../../../assest/Individual-Zero.svg";
import IndividualHigh from "../../../../assest/Individula-high.svg";
import "./SmartWaySlider.css";

SwiperCore.use([Pagination, Scrollbar]);

export default function SmartWaySlider() {
  const isMobileOrTablet = window.innerWidth <= 990;
  return (
    <div className="SmartWaySlider" >
      <section className="mt-max pb-max pt-max suggestion-sec">
        <div className="container text-center offer2">
          <h2 className="h2-heading text-center d-block mb-3">
            The Smartest Way to switch your <br />
            fleet to Electric.
          </h2>
          <p
            className="para text-center"
            style={{ fontSize: "16px", lineHeight: "24px" }}
          >
            FleetLease provides a full stack fleet electrification solution and
            services designed to increase fleet uptime and provide the lowest
            operating costs in the industry, while helping businesses achieve
            sustainability goals.
          </p>
          <a href="/enquire" className="white-btn bg-white bg-lg">
            Contact Us &amp; Know More
            <img
              src="./images/chevron-right_black.svg"
              height="13"
              alt="Chevron Right"
            />
          </a>
          {/* swiper-slider */}
          <div className="slider suggetion-slider">
            <Swiper
              spaceBetween={20}
              slidesPerView={
                window.innerWidth <= 768
                  ? 1
                  : window.innerWidth > 768 && window.innerWidth <= 990
                  ? 2
                  : 3
              }
              navigation={{
                nextEl: null,
                prevEl: null,
              }}
              scrollbar={{ draggable: true }}
            >
              <SwiperSlide>
                <div className="slider-card">
                  {/* Your first slide content goes here */}
                  <div class="thumb-cricle">
                    <img src={IndividualHigh} alt="" />
                  </div>
                  <h5 class="h5-heading" style={{fontWeight:"600"}}>High Quality Vehicles</h5>
                  <p class="para">
                    Our vehicles are vetted on over 100+ data points and
                    inspection tests before delivery.
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="slider-card">
                  <div class="thumb-cricle">
                    <img src={IndividualZero} alt="" />
                  </div>
                  <h5 class="h5-heading"style={{fontWeight:"600"}}>Zero Downpayment</h5>
                  <p class="para">
                    No upfront costs for leasing a brand-new vehicle. You simply
                    pay a small security deposit and one month's advance lease
                    rental to get started.
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="slider-card">
                  <div class="thumb-cricle">
                    <img src={IndividualFlexible} alt="" />
                  </div>
                  <h5 class="h5-heading"style={{fontWeight:"600"}}>Flexible Tenures</h5>
                  <p class="para">
                    Subscribe to a fleet up to 36 months to 60 months tenure.
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="slider-card">
                  <div class="thumb-cricle">
                    <img src={IndividualSmart} alt="" />
                  </div>
                  <h5 class="h5-heading"style={{fontWeight:"600"}}>Smart Ownership</h5>
                  <p class="para">
                    Save up to 20% on monthly costs with FleetLease compared to
                    bank financing, with an option to own the vehicle at the end
                    of the lease.
                  </p>
                </div>
              </SwiperSlide>
              {/* <div class="swiper-scrollbar swiper-scrollbar-horizontal">
              <div
                class="swiper-scrollbar-drag"
                style={{width: "50px", transform: "translate3d(0px, 0px, 0px)"}}
              ></div>
            </div> */}
            </Swiper>
          </div>
        </div>
      </section>
    </div>
  );
}
