import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../repository/api";
import "./VehicleList.css";
function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const whatsapp_url =
  "https://api.whatsapp.com/send/?phone=919971007201&text&type=phone_number&app_absent=0";

function VehicleList({ selectedVehicleType }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();


  useEffect(() => {
    fetchData();
  }, [selectedVehicleType]);

  const fetchData = () => {
    let urlToFetch = `${BASE_URL}/general-vehicle-info/?ordering=id`;
    fetch(urlToFetch)
      .then((response) => response.json())
      .then((data) => {
        const filteredData = data.filter(
          (item) => item.wheelerType === selectedVehicleType
        );
        setData(filteredData);
        setLoading(false);
      })
      .catch((error) => console.error(error));
  };

  const handlecardClick = (item) => {
    console.log("item", item)
    navigate(`/vehicleIndividual?id=${item.detailedvehicleinfo}`)
  }

  return (
    <div className="container   mb-72 tab-content" id="pills-tabContent">
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div
          class="tab-pane fade active show"
          aria-labelledby="pills-home-tab"
          role="tabpanel"
        >
          <div className="row">
            {data.map((item) => (
              <div key={item.id} className="col-lg-4 col-md-6 col-sm-12">

                <div className="t-none">
                  <div className="vichale-card " onClick={() => handlecardClick(item)}>
                    {/* <Link
                      to={`/vehicleIndividual?id=${item.detailedvehicleinfo}`}
                      style={{ textDecoration: "none", color: "inherit" }}
                    > */}
                    <div className=" mb-3 mt-3 cardImg">
                      <img
                        src={item.profilePhoto}
                        alt=""
                        className="img-fluid"
                        width="100%"
                        style={{
                          zIndex: 1,
                          maxHeight: "180px",
                          objectFit: "contain",
                        }}
                      />

                    </div>
                    {/* </Link> */}
                    <div className="align align-items-start flex-column mt-3">
                      {/* <Link
                        to={`/vehicleIndividual?id=${item.detailedvehicleinfo}`}
                        style={{ textDecoration: "none", color: "inherit" }}
                      > */}
                      <span className="vehicle-model Card-Model-logo">
                        <img
                          className="image-fit"
                          src={item.oemPic}
                          width="65px"
                          height="60px"
                        />
                        &nbsp;
                        {item.oem}
                      </span>
                      <span className="align justify-content-start vichal-name h4-heading">
                        {item.vehicleModel}
                      </span>
                      {/* </Link> */}
                      {/* <Link
                        to={`/vehicleIndividual?id=${item.detailedvehicleinfo}`}
                        style={{
                          display: "contents",
                          textDecoration: "none",
                          color: "inherit",
                        }}
                      > */}
                      <div className=" d-flex align justify-content-between w-100">
                        <span className="align justify-content-start formname">
                          <img
                            src="./fleet leasing_files/service_stations.png"
                            alt=""
                            className="img-fluid"
                          />
                          &nbsp;Leasing from
                        </span>
                        <div style={{ fontWeight: "600" }}>
                          {item?.leaseStartPrice
                            ? `₹ ${item?.leaseStartPrice?.toLocaleString()}`
                            : "-"}
                        </div>
                      </div>
                      {/* </Link> */}
                    </div>
                    <hr />
                    {/* <Link
                      to={`/vehicleIndividual?id=${item.detailedvehicleinfo}`}
                      style={{ textDecoration: "none", color: "inherit" }}
                    > */}
                    <div className="align d-flex justify-content-between mb-2">
                      <span className="align para justify-content-start">
                        <img
                          src="./fleet leasing_files/vehicle_mileage.png"
                          alt=""
                          className="img-fluid"
                        />
                        &nbsp;Battery Range
                      </span>
                      <div className="align justify-content-end info-v">
                        {numberWithCommas(item.rangeWOPayload)} kms
                      </div>
                    </div>

                    <div className="align d-flex justify-content-between mb-2">
                      <span className="align para justify-content-start">
                        <img
                          src="./fleet leasing_files/charging_stations.png"
                          alt=""
                          className="img-fluid"
                        />
                        &nbsp;Charging Time
                      </span>
                      <div className="align justify-content-end info-v">
                        {item.chargingTime} mins
                      </div>
                    </div>
                    <div className="align d-flex justify-content-between mb-2">
                      <span className="align para justify-content-start">
                        <img
                          src="./fleet leasing_files/services.png"
                          alt=""
                          className="img-fluid"
                        />
                        &nbsp;Seating Capacity
                      </span>
                      <div className="align justify-content-end info-v">
                        {item.loadingCapacity} Seated
                      </div>
                    </div>
                    {/* </Link> */}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default VehicleList;
