import React from "react";
import "./Ecosystem.css";
import ecosystem from "../Images/ev-ecosystem.png";
import { MdOutlineNavigateNext } from "react-icons/md";
import { Link } from "react-router-dom";

const Ecosystem = () => {
  return (
    <>
      <div className="ecosystem-container container mt-5 w-100  p-5">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <div className="eco-h">
              <h3>Want to become a part of India's largest EV ecosystem?</h3>
            </div>
            <div className="eco-p">
              <p className="">
                Submit your requirement details via our enquiry form and our
                experts will get back to you with the best offers!
              </p>
            </div>
            <div className="eco-btn mt-4">
              <Link to="/enquire">
                <button className="submit-btn" style={{ height: "45.36px" }}>
                  Submit Details
                  <MdOutlineNavigateNext color="white" size={24} />
                </button>
              </Link>
            </div>
          </div>
          <div className="col-md-6 col-sm-12">
            <div className="eco-img mt-4 w-100 ">
              <img src={ecosystem} alt="ecosystem" className="img-responsive" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Ecosystem;
