import React from "react";
import FaqPageComponent from "../Components/FaqPageComponent";

// import ChatUi from "../Components/ChatUi";
// import ResultsNotFound from "../Components/ResultsNotFound";

const FaqsPage = () => {
  return (
    <div className="home-mainss mt-md-5" >
      <FaqPageComponent />


    </div>
  );
};

export default FaqsPage;
