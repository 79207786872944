import React from "react";
import "./FounderCard.css";
import Anuj from "../Images/Anuj (2) (1).webp";
import Dev from "../Images/Dev1 (1) (1).webp";
import Harsh from "../Images/Harsh (1) (1).webp";
import Jayant from "../Images/jayant5 (1).webp";
import Manas from "../Images/Manas (2) (1).webp";
const FounderCard = () => {
  return (
    <>
      <div className="heading-container2 text-center pt-md-5 paddingsmall w-100 ">
        <h1 className="ev-lease"> Backed by a team of second-time founders </h1>
        <p className="main">
          {" "}
          Alt aims to make the switch to EV seamless for all its users.
        </p>
      </div>
      <div className="row founder-container container text-center pt-md-5 pb-md-5 w-100">
        <div className="col-md-12 col-lg-4 col-sm-12 p-4">
          <div className="founder-image-bg mb-3">
            <img
              className="img-responsive w-100 f-image"
              src={Dev}
              alt="second"
              style={{ objectFit: "contain", maxHeight: "330px" }}
            />
          </div>
          <h1 className="founder-h1">Dev Arora</h1>
          <p className="main">
            Co-Founder
            <br />& Chief Executive Officer
          </p>
        </div>
        <div className="col-md-12 col-lg-4 col-sm-12  p-4">
          <div className="founder-image-bg mb-3">
            <img
              className="f-image img-responsive w-100"
              src={Anuj}
              alt="second"
              style={{ objectFit: "contain", maxHeight: "330px" }}
            />
          </div>
          <h1 className="founder-h1">Anuj Gupta</h1>
          <p className="main">
            Co-Founder <br /> & Chief Business Officer
          </p>
        </div>
        <div className="col-md-12 col-lg-4 col-sm-12  p-4">
          <div className="founder-image-bg mb-3">
            <img
              className="f-image img-responsive w-100"
              src={Manas}
              alt="second"
              style={{ objectFit: "contain", maxHeight: "330px" }}
            />
          </div>
          <h1 className="founder-h1">Manas Arora</h1>
          <p className="main">
            Co-Founder <br />& Chief Financial Officer
          </p>
        </div>
      </div>
      <div className="row founder-container container text-center pt-md-5 pb-md-5 w-100">
        <div className="col-md-12 col-lg-4 col-sm-12  p-4">
          <div className="founder-image-bg mb-3">
            <img
              className="f-image img-responsive w-100"
              src={Harsh}
              alt="fifth"
              style={{ objectFit: "contain", maxHeight: "330px" }}
            />
          </div>
          <h1 className="founder-h1">Harsh Goyal</h1>
          <p className="main">
            Co-Founder <br />& Chief Product Officer
          </p>
        </div>
        <div className="col-md-12 col-lg-4 col-sm-12  p-4">
          <div className="founder-image-bg mb-3">
            <img
              className="f-image img-responsive w-100"
              src={Jayant}
              alt="sixth"
              style={{ objectFit: "contain", maxHeight: "330px" }}
            />
          </div>
          <h1 className="founder-h1">Jayant Gupta</h1>
          <p className="main">
            Co-Founder
            <br /> & Chief Commercial Officer
          </p>
        </div>
      </div>
    </>
  );
};
export default FounderCard;
