import React, { useEffect, useState } from "react";
// components from libraries
import axios from "axios";
import { toast } from "react-toastify";
import Select from '@mui/material/Select';
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from "@mui/material/InputAdornment";
// custom components
import InfiniteCarousels from "../../Components/InfiniteCarousels";
import MobileCarouselTab from "../HomeComp/MobileCarouselTab";
import MobileButton from "../../Components/CustomComponent/MobileButton";
import MobileMonthlySavingsCalculator from "../HomeComp/MobileMonthlySavingsCalculator";
import MobileFAQ from "../HomeComp/MobileFAQ";
import MobileFooter from "../../Components/Footer/MobileFooter";
// images
import Banner from "../../Images/mobileBanner.png";
import MoneyBag from "../../Images/salary.png";
import GreenTick from "../../Images/Tick.svg";
import FAQ from "../../Images/FAQ Icon.png";
// utils
import { 
    mapURL, IndianCities, infiniteCarousels, mobileCarouselTabs, 
    vehicleTypes, leaseOfferArray, videoURL, brandNewVehicles, 
    faqs,
    consultaionAPI
} from "./constants";
import { handleEmbedVideoURL, isEmpty } from "../../utils";

const MobileView = () => {
    // states
    const [cities, setCities] = useState("New Delhi");
    const [phone, setPhone] = useState(null);
    const [formError, setFormError] = useState("");
    // state to detemine the type of vehicle shown in the carousel
    const [vehicleTypeShown, setVehicleTypeShown] = useState("2W");

    // function to book a free EV Consultation
    const handleBookConsultation = async () => {
        let payload;
        // phone number is empty, consultation cannot be booked
        if(isEmpty(phone)) {
            setFormError("Please enter phone number.");
        } else {
            payload = {
                contact: {
                    mobile_number: `+91${phone}`,
                    city: cities,
                }
            }
        }
        // booking consultation
        try {
            const response = await axios.post(
                consultaionAPI,
                payload
            )
            if(response.status === 200) {
                toast.success("Consultation booked successfully");
            } else {
                toast.error("Something went wrong! Try Again.");
            }
        } catch(err) {
            toast.error("Something went wrong! Try Again.");
        }
        // console.log("Hi Alt Mobility ", response);
    };

    // redirecting to a page to view more available vehicles
    const handleViewMoreVehicles = () => {
        console.log("Redirect");
    };

    // redirecting to a page to compare ownership cost
    const handleViewOwnershipCostComparison = () => {
        console.log("Redirect");
    }

    useEffect(() => {
        window.scrollTo(0,0);
        /* 
            * On first load, a popup appears to "Know your location" in user's screen
            * if the user ackknowledges, the current location, i.e the city will be used in the consultation form as default value
            * else by default it will show New Delhi
        */
        navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
    }, []);

    const successCallback = async (position) => {
        // get the lattitude and longitude values, if user shares the location
        const lat = position.coords.latitude
        const long = position.coords.longitude

        // fetch user's city
        try {
            const response = await axios.get(`${mapURL}?format=json&lat=${lat}&lon=${long}`);
            IndianCities.forEach((city) => {
                if(response?.data?.address?.city.includes(city)) {
                    setCities(city);
                }
            })
        } catch(err) {
            setCities("New Delhi");
        }
    };
    
    const errorCallback = (error) => {
        // if the user reject to provide the current location
        setCities("New Delhi");
    };
      
    return (
        <div className="mobile-container">
            {/* banner card & book consultation form */}
            <div className="mobile-consultation-card">
                <div className="mobile-banner">
                    {/* banner image */}
                    <img src={Banner} alt="banner" />
                    {/* consultation form */}
                    <div className="mobile-consultaion-form">
                        <Select
                            id="demo-simple-select"
                            value={cities}
                            inputProps={{ 'aria-label': 'Without label' }}
                            onChange={(e) => setCities(e.target.value)}
                            className="mobile-consultaion-input"
                        >
                            {IndianCities.map((city) => (
                                <MenuItem value={city}>{city}</MenuItem>
                            ))}
                        </Select>
                        <OutlinedInput
                            id="outlined-adornment-amount"
                            className="mobile-consultaion-input"
                            type="number"
                            value={phone}
                            onChange={(e) => {
                                setPhone(e.target.value);
                                if(e.target.value?.length !== 10 && e.target.value?.length > 0) {
                                    setFormError("Phone number should be of 10 digits")
                                }
                                else 
                                    setFormError("");
                            }}
                            placeholder="9999999999"
                            startAdornment={<InputAdornment position="start">+91</InputAdornment>}
                        />
                    </div>
                </div>
                {/* form error and button */}
                <div className="mt-4">
                    <div className="mobile-form-error">
                        {formError}
                    </div>
                    <div className={`d-flex justify-content-center align-items-center ${formError ? "mt-3" : "mt-5"}`}>
                        <MobileButton
                            text="Book free EV|Consultation"
                            handleClick={handleBookConsultation}
                        />
                    </div>
                </div>
            </div>
            {/* sponsor carousel */}
            <div className="mobile-sponsor-carousel mt-3">
                <InfiniteCarousels itemsShowing={5} itemsArray={infiniteCarousels} type="Images" />
            </div>
            {/* vehicle types carousel */}
            <div className="mobile-vehicle-carousel mt-3">
                <div className="mobile-vehicle-carousel-container">
                    <MobileCarouselTab 
                        tabs={mobileCarouselTabs} 
                        selectedTab={vehicleTypeShown} 
                        setSelectedTab={(value) => setVehicleTypeShown(value)}
                        itemsShowing={1} 
                        carouselContent={vehicleTypes}
                        handleViewMoreVehicles={handleViewMoreVehicles}
                    />
                </div>
            </div>
            {/* monthly savings calculation */}
            <div className="mobile-monthly-savings-calculator mt-5">
                <MobileMonthlySavingsCalculator handleViewOwnershipCostComparison={handleViewOwnershipCostComparison} />
            </div>
            {/* video testimonial */} 
            <div className="mobile-video-testimonial">
                <div className="mobile-iframe-holder">
                    <iframe src={handleEmbedVideoURL(videoURL)}
                        allow='autoplay; encrypted-media'
                        allowFullScreen
                        title='video'
                        className="mobile-video-iframe"
                    />
                </div>
            </div>
            {/* brand new vehicles */}
            <div className="mobile-vehicle-display-carousel">
                <div className="mobile-vehicle-display-carousel-header">
                    Brand New Vehicles
                </div>
                <InfiniteCarousels itemsShowing={2} itemsArray={brandNewVehicles} type="Cards" />
            </div>
            {/* lease section */}
            <div className="mobile-lease-offer-container mt-4">
                <div className="mobile-lease-offer-header">
                    <img src={MoneyBag} alt="salary" />
                    Lease Aur Laabh Offer
                </div>
                <div className="mobile-lease-offer-body mt-5">
                    {leaseOfferArray.map((leaseOffer, i) => (
                        <div className="mb-3" key={i}>
                            <img src={GreenTick} alt={i} />
                            {leaseOffer}
                        </div>
                    ))}
                </div>
            </div>
            {/* faq section */}
            <MobileFAQ title="FAQs on Leasing" titleIcon={FAQ} faqs={faqs} />
            {/* footer */}
            <MobileFooter />
        </div>
    );
};

export default MobileView;