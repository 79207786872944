

import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { BASE_URL_FLEETOS } from "../repository/api";
import "./Summary.css";

const Summary = ({ data, data1, }) => {
  const loaction = useLocation();
  const searchParams = new URLSearchParams(loaction.search);
  const id = searchParams.get("id");
  // console.log(">>>>>>>", id);
  const [vehicle, setVehicle] = useState({});
  const [contact, setContact] = useState({});

  const [datas, setData] = useState({});

  useEffect(() => {
    leaseData();
    setVehicle(data);
    setContact(data1);
  }, []);
  const fleet_id = localStorage.getItem("fleetRequestId");

  const leaseData = async () => {
    try {
      const response = await axios.get(`${BASE_URL_FLEETOS}/v2/vehicle-lease/${fleet_id}`);
      console.log("response", response);
      setData(response?.data?.data);
      localStorage.removeItem("fleetRequestId");
      console.log(">>>>", datas)
    }
    catch (error) {

    }
  }
  //change date in local formate
  const dateString = datas.created_date;
  const utcDate = new Date(dateString);
  const localDate = utcDate.toLocaleDateString('en-US')

  const capitalizeFirstLetter = (str) => {
    return str.replace(/\b\w/g, (match) => match.toUpperCase());
  };

  // console.log('Details data------------------>',datas.vehicleleasecustomer.full_name)
  return (
    <div className="w-100">
      <div className=" d-flex flex-column align-self-start request-summary">
        <div className="summary-heading">
          <p>Fleet Request Summary</p>
        </div>
        <div className="both-container mt-4 p-4">
          <div className="summary-details ">
            <p className="head-p mb-2"> Vehicle requirement</p>
            <div className="fleet-details d-flex flex-column gap-2">
              <div className="d-flex justify-content-between">
                <p>City</p>
                <p>{datas.city ? capitalizeFirstLetter(datas.city) : ""}</p>
              </div>
              <di className="d-flex justify-content-between" v>
                <p>Pincode</p>
                <p>{datas.pin_code}</p>

              </di>
              <div className="d-flex justify-content-between">
                <p>Vehicles Numbers</p>
                <p>{datas.number_of_vehicles}</p>
              </div>
              <div className="d-flex justify-content-between">
                <p>Tenure</p>
                <p>{datas.tenure}</p>

              </div>
              
              {/* <div className="d-flex justify-content-between">
                <p>Date</p>
                <p>{localDate}</p>

              </div> */}

            </div>


          </div>
          <div className="summary-details mt-4">
            <p className="head-p mb-2"> Contact details</p>
            <div className="fleet-details d-flex flex-column gap-2">

              <div className="d-flex justify-content-between">
                <p>Full Name</p>
                <p>{datas.vehicleleasecustomer?.full_name ? capitalizeFirstLetter(datas.vehicleleasecustomer.full_name) : ""}</p>
              </div>
              <div className="d-flex justify-content-between">
                <p>Organization Name</p>
                <p>{datas.vehicleleasecustomer?.organization_name ? capitalizeFirstLetter(datas.vehicleleasecustomer.organization_name) : ""}</p>
              </div>
              <div className="d-flex justify-content-between">
                <p>Mobile Number</p>
                <p>{datas.vehicleleasecustomer?.phone}</p>

              </div>
              <div className="d-flex justify-content-between">
                <p>Email Address</p>
                <p>{datas.vehicleleasecustomer?.email ? capitalizeFirstLetter(datas.vehicleleasecustomer.email) : ""}</p>

              </div>
            </div>
          </div>
        </div>

        {/* <div className=" reservation-cost inp-p d-flex justify-content-between">
          <p>
            Total Reservation Amount:{" "}
            <span style={{ fontSize: "18px", fontWeight: "600" }}>
              ₹ 1,441523
            </span>
          </p>
          <a href="/proposalpage">
            <button type="submit" className="save">
              Generate Proposal
              Submit
            </button>
          </a>
        </div> */}

      </div>
    </div>
  );
};

export default Summary;
