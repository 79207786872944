import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import InputField from "../CustomComponent/InputField";
import Button from "../CustomComponent/Button";
import FileUpload from "../CustomComponent/UploadFile";
import { BASE_URL_FLEETOS } from "../../repository/api";
import axios from "axios";
import "./Form.css";
import { useParams } from "react-router-dom";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const validationSchema = Yup.object().shape({
  corporate_profile: Yup.string().required("Required"),
  singed_customer_contracts: Yup.string().required("Required"),
  last_two_year_itr: Yup.string().required("Required"),
  pan_card: Yup.string().required("Required"),
  aadhar_card: Yup.string().required("Required"),
  promoters_net_worth_certificate: Yup.string().required("Required"),
  // Add more validation as needed
});

export default function OtherDocuments({ handleBack, handleNext }) {
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [check, setCheck] = useState(null);
  const [data1, setData1] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchDataFromAPI = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL_FLEETOS}/v1/lease-application/${id}`
      );
      console.log(response.data)
      setCheck(response.data)
      setData(response.data.lease_application_business_documents);
      setData1(response.data.lease_application_directors_documents);
    } catch (error) {
      setError(error);
    } finally {
    }
  };

  useEffect(() => {
    fetchDataFromAPI();
  }, [id]);

  function extractNameAfterFirstUnderscore(inputString) {
    if (typeof inputString === "string") {
      let parts = inputString?.split("/");
      let lastPart = parts[parts?.length - 1];
      let firstUnderscoreIndex = lastPart?.indexOf("_");
      if (firstUnderscoreIndex !== -1) {
        return lastPart?.substring(firstUnderscoreIndex + 1);
      } else {
        return lastPart;
      }
    }
  }

  const initialValues = {
    corporate_profile: data?.corporate_profile ?? "",
    singed_customer_contracts: data?.singed_customer_contracts ?? "",
    last_two_year_itr: data1?.last_two_year_itr ?? "",
    pan_card: data1?.pan_card ?? "",
    aadhar_card: data1?.aadhar_card ?? "",
    promoters_net_worth_certificate:
      data1?.promoters_net_worth_certificate ?? "",
  };
  const handleErrorResponse = (error) => {
    if (
      error.response &&
      error.response.data &&
      error.response.data.error &&
      error.response.data.error.issues
    ) {
      error.response.data.error.issues.forEach((issue) => {
        toast.error(`${issue.path[0]} is ${issue.message}`);
      });
    } else if (
      error.response &&
      error.response.data &&
      error.response.data.error &&
      error.response.data.error.message
    ) {
      // Handle the second response structure
      toast.error(error.response.data.error.message);
    } else {
      // Handle other cases or display a generic error message
      toast.error("An error occurred. Please try again.");
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        let allPromisesUrl = [
          uploadAndCommitFile(values.corporate_profile),
          uploadAndCommitFile(values.singed_customer_contracts),
          uploadAndCommitFile(values.last_two_year_itr),
          uploadAndCommitFile(values.pan_card),
          uploadAndCommitFile(values.aadhar_card),
          uploadAndCommitFile(values.promoters_net_worth_certificate),
        ];

        let allPromisesUrl1 = await Promise.all(allPromisesUrl).then(
          (values) => values
        );
        // console.log("DocValue", extractNameAfterFirstUnderscore(DocValue?.lease_agreement));
        const Form1Data = {
          ...(values.corporate_profile !== "" && {
            corporate_profile: values.corporate_profile.type
              ? allPromisesUrl1[0]
              : values.corporate_profile,
          }),

          ...(values.singed_customer_contracts !== "" && {
            singed_customer_contracts: values.singed_customer_contracts.type
              ? allPromisesUrl1[1]
              : values.singed_customer_contracts,
          }),

          lease_application_id: id,
        };
        const Form2Data = {
          ...(values.last_two_year_itr !== "" && {
            last_two_year_itr: values.last_two_year_itr.type
              ? allPromisesUrl1[2]
              : values.last_two_year_itr,
          }),

          ...(values.pan_card !== "" && {
            pan_card: values.pan_card.type
              ? allPromisesUrl1[3]
              : values.pan_card,
          }),
          ...(values.aadhar_card !== "" && {
            aadhar_card: values.aadhar_card.type
              ? allPromisesUrl1[4]
              : values.aadhar_card,
          }),

          ...(values.promoters_net_worth_certificate !== "" && {
            promoters_net_worth_certificate: values
              .promoters_net_worth_certificate.type
              ? allPromisesUrl1[5]
              : values.promoters_net_worth_certificate,
          }),

          lease_application_id: id,
        };
        const response = await axios.post(
          `${BASE_URL_FLEETOS}/v1/lease-application/business-documents`,
          Form1Data
        );
        const response1 = await axios.post(
          `${BASE_URL_FLEETOS}/v1/lease-application/directors-documents`,
          Form2Data
        );
        // Handle the response as needed
        console.log("Response1:", response1.data);
        console.log("Response:", response.data);
        // debugger;

        toast.success("Form submitted successfully!");
        setLoading(false);
      } catch (error) {
        console.log("error", error);
        handleErrorResponse(error);
        setLoading(false);
      }
    },
  });
  const uploadAndCommitFile = (file) => {
    if (file && file.name !== "") {
      return new Promise(async (resolve, reject) => {
        const testData = await axios.post(
          `${BASE_URL_FLEETOS}/v1/shared.getSignedUrlForUploadPublic`,
          {
            fileName: file.name ?? file,
            type: "lease_application_documents",
          }
        );
        // let testData = await getUrl.mutateAsync({
        //   fileName: file.name ?? file,
        //   type: "fleet_request_documents",
        // });
        const headers = new Headers();
        headers.append("Content-Type", "application/octet-stream");
        // let resp = await new Promise((resolve, reject) => {
        //   const xhr = new window.XMLHttpRequest();
        //   xhr.open("PUT", testData?.data?.result?.data?.url);
        //   xhr.onload = resolve;
        //   xhr.onerror = reject;
        //   xhr.setRequestHeader("Content-Type", "application/octet-stream");
        //   xhr.send(file);
        // });

        resolve(testData?.data?.result?.data?.path);
      });
    } else {
      return Promise.resolve("");
      // return null;
    }
  };

  const handleFileUpload = async (name, file) => {
    formik.setFieldValue(`${name}`, file);
    // files.map((eachFile) => formData.append("vehicle_images", eachFile));
  };

  function extractFilename(filePath) {
    if (typeof filePath === "string") {
      const pathSegments = filePath.split("/");
      const filename = pathSegments[pathSegments.length - 1];
      return filename;
    } else {
      return "";
    }
  }
  return (
    <>
      <form onSubmit={formik.handleSubmit} className="d-flex flex-column gap-5">
        <h3>Business Related</h3>
        <div className="d-flex flex-column flex-md-row justify-content-between gap-4">
          <FileUpload
            labelTitle="Corporate Profile/​Pitch Deck"
            required={true}
            error={
              formik.touched.corporate_profile &&
              formik.errors.corporate_profile
            }
            onFileSelect={(files) =>
              handleFileUpload("corporate_profile", files[0])
            }
            selectedFile={formik.values.corporate_profile}
            value={extractNameAfterFirstUnderscore(
              formik.values.corporate_profile
            )}
            id={"corporate_profile"}
            title=" Click to upload "
            iconStyle={{
              width: 51,
              height: 51,
              color: "#828282",
              opacity: 0.3,
            }}
            // fileType={fileTypes}
            className="file-upload-customer w-100"
          />
          <FileUpload
            labelTitle="Signed Customer Contracts ZIP file (max. 5)"
            required={true}
            error={
              formik.touched.singed_customer_contracts &&
              formik.errors.singed_customer_contracts
            }
            onFileSelect={(files) =>
              handleFileUpload("singed_customer_contracts", files[0])
            }
            selectedFile={formik.values.singed_customer_contracts}
            value={extractNameAfterFirstUnderscore(
              formik.values.singed_customer_contracts
            )}
            id={"singed_customer_contracts"}
            title=" Click to upload "
            iconStyle={{
              width: 51,
              height: 51,
              color: "#828282",
              opacity: 0.3,
            }}
            // fileType={fileTypes}
            className="file-upload-customer w-100"
          />
        </div>
        <h3>Directors KYC</h3>
        <div className="d-flex flex-column flex-md-row justify-content-between gap-4">
          <FileUpload
            labelTitle="Last 2 years ITR"
            required={true}
            onFileSelect={(files) =>
              handleFileUpload("last_two_year_itr", files[0])
            }
            error={
              formik.touched.last_two_year_itr &&
              formik.errors.last_two_year_itr
            }
            selectedFile={formik.values.last_two_year_itr}
            value={extractNameAfterFirstUnderscore(
              formik.values.last_two_year_itr
            )}
            id={"last_two_year_itr"}
            title=" Click to upload "
            iconStyle={{
              width: 51,
              height: 51,
              color: "#828282",
              opacity: 0.3,
            }}
            // fileType={fileTypes}
            className="file-upload-customer w-100"
          />
          <FileUpload
            labelTitle="PAN Card"
            required={true}
            onFileSelect={(files) => handleFileUpload("pan_card", files[0])}
            selectedFile={formik.values.pan_card}
            error={formik.touched.pan_card && formik.errors.pan_card}
            value={extractNameAfterFirstUnderscore(formik.values.pan_card)}
            id={"pan_card"}
            title=" Click to upload "
            iconStyle={{
              width: 51,
              height: 51,
              color: "#828282",
              opacity: 0.3,
            }}
            // fileType={fileTypes}
            className="file-upload-customer w-100"
          />
        </div>
        <div className="d-flex flex-column flex-md-row justify-content-between gap-4">
          <FileUpload
            labelTitle="Aadhar Card"
            required={true}
            error={formik.touched.aadhar_card && formik.errors.aadhar_card}
            onFileSelect={(files) => handleFileUpload("aadhar_card", files[0])}
            selectedFile={formik.values.aadhar_card}
            value={extractNameAfterFirstUnderscore(formik.values.aadhar_card)}
            id={"aadhar_card"}
            title=" Click to upload "
            iconStyle={{
              width: 51,
              height: 51,
              color: "#828282",
              opacity: 0.3,
            }}
            // fileType={fileTypes}
            className="file-upload-customer w-100"
          />
          <FileUpload
            labelTitle="Promoters Net Worth Certificate"
            required={true}
            onFileSelect={(files) =>
              handleFileUpload("promoters_net_worth_certificate", files[0])
            }
            error={
              formik.touched.promoters_net_worth_certificate &&
              formik.errors.promoters_net_worth_certificate
            }
            selectedFile={formik.values.promoters_net_worth_certificate}
            value={extractNameAfterFirstUnderscore(
              formik.values.promoters_net_worth_certificate
            )}
            id={"promoters_net_worth_certificate"}
            title=" Click to upload "
            iconStyle={{
              width: 51,
              height: 51,
              color: "#828282",
              opacity: 0.3,
            }}
            // fileType={fileTypes}
            className="file-upload-customer w-100"
          />
        </div>
        <div className="d-flex gap-3 justify-content-center">
          <Button label="Back" onClick={handleBack} />
          <Button
            label="Submit"
            onClick={formik.handleSubmit}
            disabled={
              !formik.isValid ||
              !check?.lease_application_additional_details ||
              Object.keys(check.lease_application_additional_details).length === 0 ||
              !check.lease_application_stakeholder_details ||
              !check.lease_application_secretarial_documents ||
              !check.lease_application_gst_details ||
              !check.lease_application_company_financial_documents
            }
            
            loading={loading}
          />
        </div>
      </form>
    </>
  );
}
