import React from 'react';
import "./IndividualVehicle.css";
import {GrFormNext} from "react-icons/gr";
import image6 from "../Images/vehicleimage.png";
// import image7 from "../Images/vehicleimage2.png";
// import image8 from "../Images/vehicleimage3.png";
// import image9 from "../Images/vehicleimage.png";
// import frame from "../Images/Frame.png";
import mahindralogo from "../Images/mahindralogo.png";

const IndividualVehicle = () => {
  return (
   <>


            <div className="vehicle-container p-5">
           
                <h5  className='xyz '><span className='text-secondary'>Fleet lease</span><GrFormNext className=''/><span className='text-dark'> Treo Zor E xtra</span></h5>
                <div className='row  '>

                  <div className='col-2 small-card '>
                  
                  
                    <div className='card card-size'>
                    <div className='' >
                          <img src={image6} alt="image6" width="75rem" height="70rem"/>
                    </div>
                    </div>
                    <div className='card card-size'>
                    <div className='' >
                          <img src={image6} alt="image6" width="75rem" height="70rem"/>
                    </div>
                    </div>
                    <div className='card card-size'>
                    <div className='' >
                          <img src={image6} alt="image6" width="75rem" height="70rem"/>
                    </div>
                    </div>
                    <div className='card card-size'>
                    <div className='' >
                          <img src={image6} alt="image6" width="75rem" height="70rem"/>
                    </div>
                    </div>
                    <div className='card card-size'>
                    <div className='' >
                          <img src={image6} alt="image6" width="75rem" height="70rem"/>
                    </div>
                    </div>
                  </div>
                  {/* <div className=''> */}
                  <div className='  col-3 mdium-card'>
                  <div className='card card-carousel'>
                {/* <div className='img-cnt'> */}
                {/* <div id="carouselExample" className="carousel slide"> */}
                {/* <div className="card-body"> */}
                  <div className="carousel-item active ">
                    <img src={image6} className="d-block-fluid w-100" alt="..." />
                  </div>
                 
                {/* </div> */}
                {/* <button className="carousel-control-prev " type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
                  <span className="carousel-control-prev-icon bg-dark rounded-circle" aria-hidden="true"></span>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next bg-dark rounded-circle" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
                  <span className="carousel-control-next-icon" aria-hidden="true"></span>
                  <span className="visually-hidden">Next</span>
                </button> */}
                  
                  {/* </div> */}
                  {/* </div> */}
                  {/* </div> */}
                  </div>
                  </div>
                  {/* </div> */}
                   <div className=' col-6 large-card'>
           <div className='card'>
                    <h4><img src={mahindralogo} alt="" width="25rem" height="25rem"/>&nbsp;Mahindra</h4>
                    <h1>Treo Zor E Xtra</h1>
                    <p>Available in 15 days  |  Launcled in 2018  |  ICAT Approved  |  AISI56 Certified</p>
                    <span className='fa fa-star checked '></span>
                    <span className='fa fa-star checked '></span>
                    <span className='fa fa-star checked '></span>
                    <span className='fa fa-star checked '></span>
                    <span className='uncheck fa fa-star  '></span>  <span className='view ms-3'>View Reviews <GrFormNext/></span>

                    <h3 className='active-fleets'><span>450+</span> active fleets pan India.</h3>
                    <div className='monthlylease mt-3 container-fluid'>
                         <div className='row'>
                          <div className='col mt-2 ms-3'>
                          <h5 className='lease'>Monthly Lease starting from</h5>
                          <h2><b>₹ 8,801</b></h2>
                          </div>
                          <div className='col'>
                              <div className='demo mt-4'>
                                  <h5 className='request-btn'><span>Request a demo <GrFormNext/></span> </h5>
                              </div>
                          </div>
                         </div>
                    </div>

                    <div className="loading-capacity row mt-3 ">
                      <div className="col-3"><h6>Loading Capacity</h6><p>50kWh</p></div>
                      <div className="col-3 "><h6>Battery Capacity</h6><p>120kms</p></div>
                      <div className="col-2"><h6>Fast Charging</h6><p>3.5mins</p></div>
                      <div className="col ms-3"><h6>Thermal Management</h6><p>80kms</p></div>
                      <hr/>
                    </div>

                    <div className="loading-capacity row ">
                      <div className="col-2"><h6>Payload</h6><p>50kWh</p></div>
                      <div className="col-3 ms-4"><h6>Max Speed</h6><p>120kms</p></div>
                      <div className="col-3 ms-1"><h6>Range W/Payload</h6><p>3.5mins</p></div>
                      <div className="col ms-3"><h6>Range W/Payload</h6><p>80kms</p></div>
                    </div>

                 <div className="specific-table mt-5">
                  <h3>Treo Zor Specifications</h3>
                  <div className="row mt-2">
                  <div className="col-3"><button className='btn btn-success w-80'>Dimensions</button></div>
                    <div className="col-3"><button className='btn btn-light w-80'>Dimensions</button></div>
                    <div className="col-3"><button className='btn btn-light w-80'>Dimensions</button></div>
                    <div className="col-3"><button className='btn btn-light w-80'>Dimensions</button></div>
                  </div>
                  <div className="row mt-3">
                    <div className="col">
                      <div className="table-cnt container-fluid">
                          <p>Kerb weight  <span className='secs '>5.3secs</span></p>
                          <hr />
                          <p>Overall width  <span className='secs '>627km</span></p>
                          <hr />
                          <p>Overall height  <span className='secs '>627km</span></p>
                          <hr />
                          <p>Overall length  <span className='secs '>1200watts</span></p>
                          <hr />
                          <p>Gross Vehicle weight <span className='secs '>627km</span></p>
                          <hr />
                          <p>Container Dimensions (lxbxh)ft  <span className='secs'>627km</span></p>
                          
                          
                      </div>
                    </div>
                  </div>
                 </div>  
                 </div>
</div> 
                
                  </div>


                  {/* <div className='col-10'>
                  <div className='vehicleimage mt-4 container-fluid '>
                <div className='img-cnt'>
                <div id="carouselExample" className="carousel slide">
                <div className="carousel-inner">
                  <div className="carousel-item active ">
                    <img src={image6} className="d-block-fluid w-100" alt="..." />
                  </div>
                 
                </div>
                <button className="carousel-control-prev " type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
                  <span className="carousel-control-prev-icon bg-dark rounded-circle" aria-hidden="true"></span>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next bg-dark rounded-circle" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
                  <span className="carousel-control-next-icon" aria-hidden="true"></span>
                  <span className="visually-hidden">Next</span>
                </button>
                </div>
                </div>
                </div>
                  </div>

                </div> 
            

            


            <div className='vehicleprice col mt-5 '>
           
                    <h4><img src={mahindralogo} alt="" width="25rem" height="25rem"/>&nbsp;Mahindra</h4>
                    <h1>Treo Zor E Xtra</h1>
                    <p>Available in 15 days  |  Launcled in 2018  |  ICAT Approved  |  AISI56 Certified</p>
                    <span className='fa fa-star checked '></span>
                    <span className='fa fa-star checked '></span>
                    <span className='fa fa-star checked '></span>
                    <span className='fa fa-star checked '></span>
                    <span className='uncheck fa fa-star  '></span>  <span className='view ms-3'>View Reviews <GrFormNext/></span>

                    <h3 className='active-fleets'><span>450+</span> active fleets pan India.</h3>
                    <div className='monthlylease mt-3 container-fluid'>
                         <div className='row'>
                          <div className='col mt-2 ms-3'>
                          <h5 className='lease'>Monthly Lease starting from</h5>
                          <h2><b>₹ 8,801</b></h2>
                          </div>
                          <div className='col'>
                              <div className='demo mt-4'>
                                  <h5 className='request-btn'><span>Request a demo <GrFormNext/></span> </h5>
                              </div>
                          </div>
                         </div>
                    </div>

                    <div className="loading-capacity row mt-3 ">
                      <div className="col-3"><h6>Loading Capacity</h6><p>50kWh</p></div>
                      <div className="col-3 "><h6>Battery Capacity</h6><p>120kms</p></div>
                      <div className="col-2"><h6>Fast Charging</h6><p>3.5mins</p></div>
                      <div className="col ms-3"><h6>Thermal Management</h6><p>80kms</p></div>
                      <hr/>
                    </div>

                    <div className="loading-capacity row ">
                      <div className="col-2"><h6>Payload</h6><p>50kWh</p></div>
                      <div className="col-3 ms-4"><h6>Max Speed</h6><p>120kms</p></div>
                      <div className="col-3 ms-1"><h6>Range W/Payload</h6><p>3.5mins</p></div>
                      <div className="col ms-3"><h6>Range W/Payload</h6><p>80kms</p></div>
                    </div>

                 <div className="specific-table mt-5">
                  <h3>Treo Zor Specifications</h3>
                  <div className="row mt-2">
                  <div className="col-3"><button className='btn btn-success w-80'>Dimensions</button></div>
                    <div className="col-3"><button className='btn btn-light w-80'>Dimensions</button></div>
                    <div className="col-3"><button className='btn btn-light w-80'>Dimensions</button></div>
                    <div className="col-3"><button className='btn btn-light w-80'>Dimensions</button></div>
                  </div>
                  <div className="row mt-3">
                    <div className="col">
                      <div className="table-cnt container-fluid">
                          <p>Kerb weight  <span className='secs '>5.3secs</span></p>
                          <hr />
                          <p>Overall width  <span className='secs '>627km</span></p>
                          <hr />
                          <p>Overall height  <span className='secs '>627km</span></p>
                          <hr />
                          <p>Overall length  <span className='secs '>1200watts</span></p>
                          <hr />
                          <p>Gross Vehicle weight <span className='secs '>627km</span></p>
                          <hr />
                          <p>Container Dimensions (lxbxh)ft  <span className='secs'>627km</span></p>
                          
                          
                      </div>
                    </div>
                  </div>
                 </div>  

                  
            </div> */}
         </div>
    </>
  )
}

export default IndividualVehicle;
