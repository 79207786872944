export const vehicleDropdownData = [
    {
        menu: "2-wheeler",
        icon: require("../../Images/2Wheeler.png"),
        link: "#"
    },{
        menu: "3-wheeler",
        icon: require("../../Images/3Wheeler.png"),
        link: "#"
    },{
        menu: "4-wheeler",
        icon: require("../../Images/4Wheeler.png"),
        link: "#"
    }
];