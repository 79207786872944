import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import "./TermsConditionsModal.css";

import PolicyTerm from "./TermsForLease/PolicyTerm";
import StandardTerms from "./TermsForLease/StandardTerms";

const TermsConditionsModal = ({ show, onHide, onClick, onReject }) => {
  const [key, setKey] = useState("tab1");
  //   const [submenus, setSubmenus] = useState([]);

  //   const updateSubmenu = (submenuText) => {
  //     // Check if the submenuText is not already in the submenus array
  //     if (!submenus.includes(submenuText)) {
  //       setSubmenus([...submenus, submenuText]);
  //     }
  //   };
  //   const removeSubmenu = (submenuText) => {
  //     // Remove the submenuText from the submenus array
  //     setSubmenus(submenus.filter((item) => item !== submenuText));
  //   };
  const [submenuItems, setSubmenuItems] = useState([]);
  const [activeSubmenu, setActiveSubmenu] = useState("");
  const updateSubmenu = (submenu) => {
    setActiveSubmenu(submenu);
    if (!submenuItems.includes(submenu)) {
      setSubmenuItems((prevItems) => [...prevItems, submenu]);
    }
  };

  const removeSubmenu = (submenu) => {
    setActiveSubmenu("");
    setSubmenuItems((prevItems) =>
      prevItems.filter((item) => item !== submenu)
    );
  };

  useEffect(() => {}, [activeSubmenu]);

  const handleAcceptAndContinue = () => {
    onHide();
    if (onClick) {
      onClick();
    }
  };
  const handleReject = () => {
    onHide();
    if (onClick) {
      onReject();
    }
  };
  return (
    <div>
      <Modal show={show} onHide={onHide} size="lg">
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "#555459" }}>
            Terms and Conditions
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Tab.Container
            id="modal-tabs"
            activeKey={key}
            onSelect={(k) => setKey(k)}
          >
            <Row className="TermsCondition-Modal">
              <Col sm={3} style={{ width: "25%" }}>
                <Nav variant="pills" className="flex-column">
                  <Nav.Item className="nav-link-item">
                    <Nav.Link className="link-tabs" eventKey="tab1">
                      Standard Terms
                    </Nav.Link>
                    <ul style={{ paddingLeft: "1rem" }}>
                      {submenuItems.map((submenu, index) => (
                        <li
                          style={{ fontSize: "12px", color: "green" }}
                          key={index}
                        >
                          {submenu}
                        </li>
                      ))}
                    </ul>
                  </Nav.Item>
                  <Nav.Item className="nav-link-item">
                    <Nav.Link className="link-tabs" eventKey="tab2">
                      {" "}
                      Information Covenants
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="nav-link-item">
                    <Nav.Link className="link-tabs" eventKey="tab3">
                      IoT Device
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="nav-link-item">
                    <Nav.Link className="link-tabs" eventKey="tab4">
                      Customer Covenants
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col sm={9} className="tab-content-border">
                <Tab.Content>
                  <Tab.Pane eventKey="tab1">
                    <div style={{ maxHeight: "60vh", overflowY: "auto" }}>
                      <StandardTerms
                        updateSubmenu={updateSubmenu}
                        removeSubmenu={removeSubmenu}
                      />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="tab2">
                    {/* Content for Tab 2 */}
                    <div style={{ maxHeight: "60vh", overflowY: "auto" }}>
                      <PolicyTerm />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="tab3">
                    {/* Content for Tab 1 */}
                    <p>Tab 3 content goes here.</p>
                  </Tab.Pane>
                  <Tab.Pane eventKey="tab4">
                    {/* Content for Tab 1 */}
                    <p>Tab 4 content goes here.</p>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex gap-3">
            <button onClick={handleReject} className="reject">
              Reject Terms & Conditions
            </button>

            <button
              onClick={handleAcceptAndContinue}
              className="accept-continue"
            >
              Accept and Continue
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default TermsConditionsModal;
