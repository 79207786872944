import { BASE_URL_FLEETOS } from "../../repository/api";

// API to get city with user's lattitude and longitude
export const mapURL = "https://nominatim.openstreetmap.org/reverse";

const Euler = require("../../Images/euler.png");
const Piaggio = require("../../Images/mobilePiaggio.png");
const Altigreen = require("../../Images/Altigreen-Drive-Electric-logo-01-1.png");
const HeroElectric = require("../../Images/Hero-Electric-Bike-Logo-PNG.png");
const Grevol = require("../../Images/Grevol (1).png");
const MahindraElectric = require("../../Images/electricmahindra-removebg-preview.png");
const OkayaEV = require("../../Images/Okaya-EV-logo (2).jpg");
const Exponent = require("../../Images/exponent.png");
const OneElectric = require("../../Images/one-electric-logo-400x400-text-2.png");
const BgaussElectric = require("../../Images/bgauss-electric-scooters-kranti-chowk-aurangabad-maharashtra-battery-operated-scooter-dealers-sigimrdvsy.webp");

export const infiniteCarousels = [
    Euler,
    Piaggio,
    Altigreen,
    HeroElectric,
    Grevol,
    MahindraElectric,
    OkayaEV,
    Exponent,
    OneElectric,
    BgaussElectric
];

export const IndianCities = [
    "Agartala", "Agra", "Ahmedabad", "Aizawl", "Aligarh", "Alwar", "Amaravati", "Amla", "Amritsar", "Aurangabad", 
    "Bambora", "BandhavGarh", "Bangalore", "Bharatpur", "Bhopal", "Bhubaneswar", "Bikaner", "Bilaspur",
    "Calicut", "Chail", "Chamba", "Chandigarh", "Chennai", "Chittorgarh", "Coimbatore", "Cuttack",
    "Dalhousie", "Darjeeling", "Dehradun", "Dausa", "Dharamsala", "Dispur", "Durgapur", 
    "Gandhinagar", "Gangtok", "Goa", "Gurgaon", "Guwahati", "Gwalior",
    "Hansi", "Haridwar", "Hyderabad",
    "Imphal", "Indore", "Itanagar",
    "Jabalpur", "Jaipur", "Jaisalmer", "Jalandhar", "Jamshedpur", "Jodhpur",
    "Kalaburagi", "Kanha", "Kanpur", "Khimsar", "Kochin", "Kohima", "Kolkata", "Kota",
    "Leh", "Lucknow", "Ludhiana",
    "Madurai", "Mahabaleshwar", "Manali", "Mandavi", "Mangalore", "Marchula", "Mathura", "Moradabad", "Mount Abu", "Mumbai", "Mussoorie", "Mysore",
    "Nagaur Fort", "Nagpur", "Nainital", "New Delhi",
    "Ooty",
    "Palampur", "Pali", "Panaji", "Panchkula", "Patna", "Pench", "Phalodi", "Port Blair", "Pragpur", "Prayagraj", "Pune", "Pushkar",
    "Rajamahendravaram", "Raipur", "Ram Nagar", "Ramgarh", "Ranakpur", "Ranchi", "Ranthambore", "Rishikesh", "Rohetgarh",
    "Salem", "Sasan Gir", "Sawai Madhopur", "Shillong", "Shimla", "Shirdi", "Siana", "Srinagar", "Surat", 
    "Thanjavur", "Thekkady", "Thiruvananthapuram", "Tiruchirappalli", "Tirupati", 
    "Udaipur",
    "Vadodara", "Vapi", "Vijayawada", "Vijayapura", "Visakhapatnam",
    "Warangal",
];

export const mobileCarouselTabs = [
    {
        name: "2W",
        image: require("../../Images/2Wheeler.png")
    },{
        name: "3W",
        image: require("../../Images/3Wheeler.png")
    },{
        name: "4W",
        image: require("../../Images/4Wheeler.png")
    }
];

export const vehicleTypes = [
    {
        type: "2W",
        image: require("../../Images/2W Hero.png")
    },{
        type: "3W",
        image: require("../../Images/3W Hero.png")
    },{
        type: "4W",
        image: require("../../Images/4W Hero.png")
    }
];

export const distanceArray = [0, 20, 40, 60, 80, 100, 120];
export const mileageArray = [0, 10, 20, 30, 40, 50, 60]

export const fuelPrice = {
    Petrol: 98,
    Diesel: 92,
    CNG: 85,
};

export const noOfOperatingDays = 26;

export const otherFuelCost = {
    lease: 18964,
    maintenance: 1500,
    insurance: 515,
};

// video testimonial url
export const videoURL = "https://www.youtube.com/embed/E7wJTI-1dvQ";

export const brandNewVehicles = [
    {
        title: "neEV High Deck",
        subTitle: "Altigreen",
        image: require("../../Images/neEV High Deck.png"),
        description: "Lease starts from",
        price: "₹ 11,000",
        suffix: "/month"
    },{
        title: "Evaum",
        subTitle: "Grevol",
        image: require("../../Images/Evaum.png"),
        description: "Lease starts from",
        price: "₹ 13,412",
        suffix: "/month"
    },{
        title: "Tez",
        subTitle: "Altigreen",
        image: require("../../Images/Tez.png"),
        description: "Lease starts from",
        price: "₹ 11,000",
        suffix: "/month"
    }
];

export const leaseOfferArray = [
    "No upfront cost",
    "Team of EV experts",
    "Easy insurance claims",
    "Easy ownership option",
    "Cheapest renting or leasing cost",
    "Maintenance and service assistance",
];

export const faqs = [
    {
        question: "What is the security deposit? Is it refundable after the lease period?",
        answer: "The security deposit is a one-time, refundable fee required at the start of your lease to cover any potential damages or outstanding payments at the end of your lease term. For more details contact us at:&nbsp;<b>9999303854</b>&nbsp;for free consultation."
    },{
        question: "After how many years can I own the vehicle?",
        answer: "Depending upon the vehicle category, typically you can opt to purchase the vehicle after a minimum lease period of three years. For more details contact us at:&nbsp;<b>9999303854</b>&nbsp;for free consultation."
    },{
        question: "Do I need to pay anything extra, other than the security deposit and the monthly payments?",
        answer: "Apart from the security deposit and regular monthly lease payments, no additional fees are required under normal circumstances. For more details contact us at:&nbsp;<b>9999303854<b>."
    },{
        question: "⁠What are my options at the end of the lease?",
        answer: "Yes, ALT-Mobility offers comprehensive services including maintenance, RSA, fleet monitoring etc for all leased vehicles directly through our network of certified service centres. For more details contact us at:&nbsp;<b>9999303854</b>&nbsp;for free consultation."
    }
];

export const footerLinks = [
    {
        title: "Compare",
        link: "#",
    },{
        title: "About Us",
        link: "/about",
    },{
        title: "Blog",
        link: "/blog",
    }
]

// url for APIs
export const consultaionAPI = `${BASE_URL_FLEETOS}/v2/freshwork/book`;