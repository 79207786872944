import React, { useEffect, useState } from "react";
import ApexCharts from "react-apexcharts";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useMediaQuery } from "react-responsive";
// import Slider from "react-slider";
import Slider from "@mui/material/Slider";
import "./EVCalculator.css";
const EVCalculator = () => {
  // State for the first Slider
  // const [smallSliderValue, setSmallSliderValue] = useState(70);
  // const [milageSliderValue, setMilageSliderValue] = useState(30);
  const isMobile = useMediaQuery({ maxWidth: 827 });
  const Responsive = {
    tablet: {
      breakpoint: { max: 990, min: 500 },
      items: 2,
      slidesToSlide: 2,
    },
    mobile: {
      breakpoint: { max: 500, min: 0 },
      items: 1,
    },
  };
  const carouselStyle = {
    padding: "0 5px",
    margin: "0 -5px",
  };
  const itemStyle = {
    margin: "0 10px",
  };
  const isMobileOrTablet = window.innerWidth <= 990;
  const [selectedDistance, setSelectedDistance] = useState(70);
  const [vehicleType, setVehicleType] = useState("Diesel");
  const [runningPerDay, setRunningPerDay] = useState(70);
  const [selectedMileage, setSelectedMileage] = useState(30);
  const [chartData, setChartData] = useState([]);
  const updateVehicleType = (type) => {
    setVehicleType(type);
  };
  const updateRunningPerDay = (value) => {
    setRunningPerDay(value);
    setSelectedDistance(value);
  };
  
  const updateSelectedMileage = (value) => {
    setSelectedMileage(value);
  };
  var mileage = {
    "3W": {
      Petrol: 15,
      Diesel: 18,
      CNG: 20,
    },
    "2W": {
      Petrol: 50,
    },
  };
  const calculateEvCost = () => {
    // Your EV cost calculation logic here
    // Example: Calculate total cost based on input values
    const totalCost = 13296.2882366206 + 1500 + 515.034722222222;
    const unitCost = 10;
    const batteryCapacity = 10;
    const fuelCost = (unitCost * batteryCapacity) / 120;
    const noOfOperatingDays = 26;
    const totalCostEv =
      fuelCost * noOfOperatingDays * selectedDistance + totalCost;
    return totalCostEv;
  };
  const otherCosts = {
    "2W": {
      lease: 2559,
      maintenance: 500,
      insurance: 132,
      charging: 832,
    },
    "3W": {
      lease: 13296.2882366206,
      maintenance: 1500,
      insurance: 515.034722222222,
      charging: charging(),
    },
  };
  function charging() {
    return 0.833333 * runningPerDay * 26;
  }
  function calculateTotalCost3W() {
    const total3WCost =
      fuelCost["3W"][vehicleType] +
      otherFuelCost["lease"] +
      otherFuelCost["maintenance"] +
      otherFuelCost["insurance"];
    return total3WCost;
  }
  const noOfOperatingDays = 26;
  const fuelPrice = {
    Petrol: 98,
    Diesel: 92,
    CNG: 85,
  };
  const fuelCost = {
    "3W": {
      Petrol:
        (fuelPrice["Petrol"] / selectedMileage) *
        runningPerDay *
        noOfOperatingDays,
      Diesel:
        (fuelPrice["Diesel"] / selectedMileage) *
        runningPerDay *
        noOfOperatingDays,
      CNG:
        (fuelPrice["CNG"] / selectedMileage) *
        runningPerDay *
        noOfOperatingDays,
    },
    "2W": {
      Petrol:
        (fuelPrice["Petrol"] / selectedMileage) *
        runningPerDay *
        noOfOperatingDays,
    },
  };

  const otherFuelCost = {
    lease: 18964,
    maintenance: 1500,
    insurance: 515,
  };
  const evCost = calculateEvCost();
  const sumOtherFuelCost =
    otherFuelCost.insurance + otherFuelCost.maintenance + otherFuelCost.lease;
  const monthlySaving = fuelCost["3W"][vehicleType] + sumOtherFuelCost - evCost;
  const monthlySavingPercentage = (
    (monthlySaving / (sumOtherFuelCost + fuelCost["3W"][vehicleType])) *
    100
  ).toFixed(0);
  const co2Emission = {
    Petrol: 2.3,
    Diesel: 2.7,
    CNG: 2.1,
  };
  const electric =
    (2.3 * 0.3 * runningPerDay * 26 * 12) / selectedMileage / 1000;
  const co2Offset = (
    (((runningPerDay * 26 * 12) / selectedMileage) * co2Emission[vehicleType]) /
      1000 -
    electric
  ).toFixed(2);
  const getChartData = (vehicleType, otherCosts, fuelCost, otherFuelCost) => {
    // Example logic to calculate chart data based on input values
    const series = [
      // {
      //   name: 'ICE',
      //   data: [3500, 4200],
      // },
      // {
      //   name: 'EV',
      //   data: [calculateEvCost(), 3200], // Use your EV cost calculation function
      // },
      {
        name: "Lease Cost",
        data: [
          otherFuelCost["lease"].toFixed(0),
          otherCosts["3W"]["lease"].toFixed(0),
        ],
      },
      {
        name: "Maintenance",
        data: [
          otherFuelCost["maintenance"].toFixed(0),
          otherCosts["3W"]["maintenance"].toFixed(0),
        ],
      },
      {
        name: "Insurance",
        data: [
          otherFuelCost["insurance"].toFixed(0),
          otherCosts["3W"]["insurance"].toFixed(0),
        ],
      },
      {
        name: "Charging Cost",
        data: [0, otherCosts["3W"]["charging"].toFixed(0)],
      },
      {
        name: "Fuel Cost",
        data: [fuelCost["3W"][vehicleType].toFixed(0), 0],
      },
    ];
    return series;
  };
  useEffect(() => {
    // Calculate chart data when inputs change
    const newData = getChartData(
      vehicleType,
      otherCosts,
      fuelCost,
      otherFuelCost
    );
    setChartData(newData);
  }, [selectedMileage, selectedDistance, vehicleType, otherCosts, fuelCost, otherFuelCost]);
 
  useEffect(() => {});
  const chartOptions = {
    colors: ["#FD9D24", "#292A73", "#5E63AB", "#1EAC5A", "#F66C6C"],
    series: chartData,
    chart: {
      type: "bar",
      height: 420,
      stacked: true,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: "top",
            offsetX: -30,
            offsetY: 0,
            itemMargin: {
              horizontal: 15,
              vertical: 0,
            },
          },
        },
      },
    ],
    yaxis: {
      labels: {
        formatter: function (value) {
          return "₹" + numberWithCommas(value);
        },
        style: {
          fontWeight: "bold",
        },
      },
    },
    xaxis: {
      // categories: ['ICE', 'EV'],
      // labels: {
      //   style: {
      //     fontWeight: 'bold',
      //   },
      // },
      categories: [
        `ICE - ₹${numberWithCommas(calculateTotalCost3W().toFixed(0))}`,
        `EV - ₹${numberWithCommas(
          (
            otherCosts["3W"]["lease"] +
            otherCosts["3W"]["maintenance"] +
            otherCosts["3W"]["insurance"] +
            otherCosts["3W"]["charging"]
          ).toFixed(0)
        )}`,
      ],
      labels: {
        style: {
          fontWeight: "bold",
        },
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
      fontSize: "12px",
      offsetX: 50,
      markers: {
        width: 14,
        height: 14,
        strokeWidth: 0,
        radius: 12,
        offsetX: -2,
        offsetY: 2,
      },
      itemMargin: {
        horizontal: 28,
        vertical: 0,
      },
    },
    fill: {
      opacity: 1,
    },
  };
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  // const monthlySaving = numberWithCommas((calculateTotalCost3W() -
  //     (otherCosts["3W"]["lease"] +
  //     otherCosts["3W"]["maintenance"] +
  //     otherCosts["3W"]["insurance"] +
  //     otherCosts["3W"]["charging"])).toFixed(0))
  return (
    <section>
      <div className="container calculation-map">
        <div className="row mb-5">
          <div className="col-md-12 heading-space px-sm-0  px-0 px-lg-auto">
            <h2 className="section-heading">
              Electrify your fleet and start <br /> saving from day one
            </h2>
            <p className="section-content text-center mb-40">
              We’ve shown you what we do. Now let’s check Leasing or Purchasing
              options for vehicles <br /> with real numbers. We’ve already
              calculated one example for you.
            </p>
          </div>
        </div>
        <div className="saving-card">
          <div className="row">
            <div className="col-lg-6 col-12">
              <h3 className="fw-bolder">Calculate your savings</h3>
              <div className="vehicle-type mt-4">
                <p className="homepage-filter">
                  <span className="sub-heading fw-bold px-2">
                    Type of Vehicle
                  </span>
                </p>
                <div className="form-radio-outline form-radio-success mb-3 mt-4 pt-2 me-3">
                  <div className="row">
                    <div className="col-md-3 pe-2 mb-3 mb-lg-0">
                      <input
                        type="radio"
                        id="349"
                        className="form-check-input me-2 radio-vehicle"
                        name="vehicle-type"
                        value="Petrol"
                        onClick={() => updateVehicleType("Petrol")}
                      />
                      <label htmlFor="349">Petrol</label>
                    </div>
                    <div className="col-md-3 pe-2 mb-3 mb-lg-0">
                      <input
                        type="radio"
                        id="350"
                        className="form-check-input me-2 radio-vehicle"
                        name="vehicle-type"
                        value="Diesel"
                        onClick={() => updateVehicleType("Diesel")}
                        defaultChecked
                      />
                      <label htmlFor="350">Diesel</label>
                    </div>
                    <div className="col-md-3 pe-2 mb-3 mb-lg-0">
                      <input
                        type="radio"
                        id="351"
                        className="form-check-input me-2 radio-vehicle"
                        name="vehicle-type"
                        value="CNG"
                        onClick={() => updateVehicleType("CNG")}
                      />
                      <label htmlFor="351">CNG</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="distance-travel">
                <hr style={{ color: "#2DB78C66" }} />
                <div className="row">
                  <div className="col-lg-1 col-1 homepage-filter">
                    <img src="./home_files/daily_distance.png" alt="" />
                  </div>
                  <div className="col-lg-8 col-11">
                    <p className="homepage-filter">
                      <span className="sub-heading fw-bold px-lg-2 p-0 ps-1">
                        Daily distance traveled (Km)
                      </span>
                    </p>
                  </div>
                  <div className="col-lg-3 col-12">
                    <input
                      type="number"
                      className="form-control text-lg-center blue-color fw-bold"
                      name="range"
                      value={selectedDistance}
                      onChange={(e) => updateRunningPerDay(e.target.value)}
                    />
                  </div>
                </div>
                <Slider
                  value={runningPerDay}
                  min={1}
                  onChange={(event, newValue) => updateRunningPerDay(newValue)}
                  aria-label="Default"
                  valueLabelDisplay="auto"
                  max={200}
                  sx={{
                    color: "#17763d", // Change the color to your desired color
                    "& .MuiSlider-thumb": {
                      backgroundColor: "#17763d", // Change the color of the thumb
                    },
                    "& .MuiSlider-valueLabel": {
                      color: "Black",
                      background: "#fff",
                      boxShadow: "0px 2px 4px 0px #11182714", // Change the color of the value label
                    },
                  }}
                />
              </div>
              <div className="distance-travel mt-4 pt-3">
                <hr
                  style={{ color: "#2DB78C66" }}
                  className="d-lg-block d-none"
                />
                <div className="row">
                  <hr
                    style={{ color: "#2DB78C66" }}
                    className="d-lg-none d-block"
                  />
                  <div className="col-1 homepage-filter">
                    <img src="./home_files/vehicle_mileage.png" alt="" />
                  </div>
                  <div className="col-lg-8 col-11">
                    <p className="homepage-filter">
                      <span
                        id="mileage-label"
                        className="sub-heading fw-bold ps-2"
                      >
                        Vehicle Mileage (km/L)
                      </span>
                    </p>
                  </div>
                  <div className="col-lg-3 col-12">
                    <input
                      type="number"
                      className="form-control text-lg-center blue-color fw-bold"
                      name="range"
                      value={selectedMileage}
                      onChange={(e) => updateSelectedMileage(e.target.value)}
                    />
                  </div>
                </div>
                <Slider
                  value={selectedMileage}
                  min={1}
                  max={60}
                  onChange={(event, newValue) =>
                    updateSelectedMileage(newValue)
                  }
                  aria-label="Default"
                  valueLabelDisplay="auto"
                  sx={{
                    "& .MuiSlider-valueLabel": {
                      color: "Black",
                      background: "#fff",
                      boxShadow: "0px 2px 4px 0px #11182714", // Change the color of the value label
                    },
                  }}
                />
              </div>
            </div>

            <div className="col-lg-6 col-12">
              <ApexCharts
                options={chartOptions}
                series={chartData}
                type="bar"
                height={420}
              />
            </div>
          </div>
        </div>
        {isMobileOrTablet ? (
          <div className="home-chart-car">
            <Carousel
              responsive={Responsive}
              arrows={false}
              className="text-start Green-bottom text-white mt-4 mb-4 ms-0 pb-3 pt-3"
              autoPlay={true}
              showDots={true}
              autoPlaySpeed={3000}
              infinite={true}
              style={carouselStyle}
            >
              <div className="Green-bottom-border d-flex justify-content-between">
                <div className="d-flex">
                  <div>
                    <img src="./images/wallet.png" width="32" />
                  </div>
                  <div className="d-flex flex-column">
                    <h3 className="h3-heading text-white fw-bold mb-0">
                      ₹&nbsp;
                      {numberWithCommas(monthlySaving.toFixed(0))}
                    </h3>
                    <p className="para fw-light text-white">
                      Monthly Savings (EV)
                    </p>
                  </div>
                </div>
                <div className="bck"></div>
              </div>

              <div className="Green-bottom-border d-flex justify-content-between">
                <div className="d-flex">
                  <div>
                    <img src="./images/savings.png" width="32" />
                  </div>
                  <div className="d-flex flex-column">
                    <h3 className="h3-heading text-white fw-bold mb-0">
                      {monthlySavingPercentage}&nbsp;%
                    </h3>
                    <p className="para fw-light text-white">
                      Saved Monthly by EV
                    </p>
                  </div>
                </div>
                <div className="bck"></div>
              </div>

              <div className="Green-bottom-border d-flex justify-content-between">
                <div className="d-flex">
                  <div>
                    <img src="./images/car.png" width="32" />
                  </div>
                  <div className="d-flex flex-column">
                    <h3 className="h3-heading text-white fw-bold mb-0">
                      ₹&nbsp;
                      {numberWithCommas(evCost.toFixed(0))}
                    </h3>
                    <p className="para fw-light text-white">
                      EV Ownership Cost
                    </p>
                  </div>
                </div>
              </div>

              <div className="Green-bottom-border d-flex justify-content-between">
                <div className="d-flex">
                  <div>
                    <img src="./images/trees.png" width="32" />
                  </div>
                  <div className="d-flex flex-column">
                    <h3 className="h3-heading text-white fw-bold mb-0">
                      {co2Offset * 1000}&nbsp;kg/year
                    </h3>
                    <p className="para fw-light text-white">CO2 Offset</p>
                  </div>
                </div>
                <div className="bck"></div>
              </div>
            </Carousel>
          </div>
        ) : (
          <div className="Green-bottom d-flex w-100 mt-3 d-flex justify-content-around text-white">
            {" "}
            <div className="Green-bottom-border d-flex gap-2">
              <div>
                <img src="./images/wallet.png" width="32" />
              </div>
              <div className="d-flex flex-column">
                <h3 className="h3-heading text-white fw-bold mb-0">
                  ₹&nbsp;
                  {numberWithCommas(monthlySaving.toFixed(0))}
                </h3>
                <p className="para fw-light text-white">Monthly Savings (EV)</p>
              </div>
            </div>
            <div className="bck"></div>
            <div className="Green-bottom-border d-flex gap-2">
              <div>
                <img src="./images/savings.png" width="32" />
              </div>
              <div className="d-flex flex-column">
                <h3 className="h3-heading text-white fw-bold mb-0">
                  {monthlySavingPercentage}&nbsp;%
                </h3>
                <p className="para fw-light text-white">Saved Monthly by EV</p>
              </div>
            </div>
            <div className="bck"></div>
            <div className="Green-bottom-border d-flex gap-2">
              <div>
                <img src="./images/car.png" width="32" />
              </div>
              <div className="d-flex flex-column">
                <h3 className="h3-heading text-white fw-bold mb-0">
                  ₹&nbsp;
                  {numberWithCommas(evCost.toFixed(0))}
                </h3>
                <p className="para fw-light text-white">EV Ownership Cost</p>
              </div>
            </div>
            <div className="bck"></div>
            <div className="Green-bottom-border d-flex gap-2">
              <div>
                <img src="./images/trees.png" width="32" />
              </div>
              <div className="d-flex flex-column">
                <h3 className="h3-heading text-white fw-bold mb-0">
                  {co2Offset * 1000}&nbsp;kg/year
                </h3>
                <p className="para fw-light text-white">CO2 Offset</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default EVCalculator;
