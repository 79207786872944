import React from "react";
import EnquireFormComponents from "../Components/EnquireFormComponents";

const EnquireForm = () => {
  return (
    <>
      <EnquireFormComponents />
    </>
  );
};

export default EnquireForm;
