import React from "react";
import Addressicon from "../Images/Address-icon.png";
import Emailicon from "../Images/Email-icon.png";
import Phoneicon from "../Images/phone-icon.png";
import "./CSShtml/Home.css";
// import "../Components/FaqPageComponent.css"
import "./ContactandPolicy.css";
const AddContact = () => {
  return (
    <>
      <div className="home-mainss ">
        <div className=" col-md-5 col-sm-12  mx-auto  mt-md-5 pt-md-5 ">
          <div className=" container shadow-sm p-4 mb-5  rounded d-flex flex-column gap-4 ">
            <h1 className="text-center main">Contact Us</h1>
            <div className="d-flex  gap-2 align-items-md-center  flex-md-row">
              <img
                className="contact-icon"
                src={Addressicon}
                alt="phone"
                style={{ width: "48px" }}
              />
              <div>
                <p className="contact-h">Address:</p>
                <p className="contact-p">
                A1/17, Ground Floor, Safdarjung Enclave, New Delhi - 110029
                </p>
              </div>
            </div>
            <div className="d-flex  gap-2 align-items-md-center flex-md-row">
              <img
                className="contact-icon"
                src={Phoneicon}
                alt="phone"
                style={{ width: "48px" }}
              />
              <div>
                <p className="contact-h">Phone Number:</p>
                <a href="tel:+919999303854" className="contact-p" style={{color:"blue"}}>
                    +91 9999303854
                </a>
              </div>
            </div>
            <div className="d-flex  gap-2 align-items-md-center flex-md-row">
              <img
                className="contact-icon"
                src={Emailicon}
                alt="phone"
                style={{ width: "48px" }}
              />
              <div>
                <p className="contact-h">Email:</p>
                <a href="mailto:sales@alt-mobility.com" className="contact-p" style={{color:"blue"}}>sales@alt-mobility.com</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddContact;
