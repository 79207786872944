import axios from "axios";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import * as Yup from "yup";
import Correct from "../Images/Vector.svg";
import { BASE_URL, BASE_URL_FLEETOS } from "../repository/api";
import ContactDetails from "./ContactDetails";
import "./FleetRequest.css";
import Summary from "./Summary";

import VehicleRequirement from "./VehicleRequirement";
const validationSchema = Yup.object().shape({
  username: Yup.string()
    .required("Full Name is required")
    .matches(
      /^[a-zA-Z\s]*$/,
      "Full Name should only contain alphabets and spaces"
    )
    .min(3, "Full Name should be at least 3 characters")
    .max(35, "Full Name can't exceed 50 characters"),

  phone_no: Yup.string()
    .matches(
      /^[0-9]{10}$/,
      "Invalid phone number it should be a 10-digit number"
    )
    // .matches(/^[0-9]{10}$/, "Phone Number should be a 10-digit number")
    .required("Phone number is required"),

  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
});

const initialValues = {
  username: "",
  phone_no: "",
  email: "",
};

const FleetRequest = () => {
  const location = useLocation();
  const [responsedata, setresponsedata] = useState();

  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  const [isindividual, setIsIndividual] = useState(
    searchParams.get("individual") === "false" ? false : true
  );
  const [radioValue, setRadioValue] = useState(
    isindividual ? "corporate" : "individual"
  );

  const [submitted, setSubmitted] = useState(false);
  const [errorPara, setErrorPara] = useState("");

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      try {
        const apiUrl = `${BASE_URL_FLEETOS}/v2/freshwork/contact`;

        const add_user_data = {
          contact: {
            first_name: values.username.split(" ").slice(0, -1).join(" "),
            last_name:
              values.username.split(" ")[values.username.split(" ").length - 1],
            mobile_number: values.phone_no,
            custom_field: {
              cf_oem: item?.vehicle.oem,
              cf_model: item?.vehicle.vehicleModel,
            },
            email: values.email,
          },
        };
        console.log(add_user_data);
        const response = await axios.post(apiUrl, add_user_data);
        setSubmitted(true);
        setErrorPara("");
      } catch (error) {
        setErrorPara(error.response.data.error.errors.message[0]);
      }
    },
  });
  const [addonId, setAddonId] = useState([]);
  const [totalcost, setTotalcost] = useState();
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  const handleForm2 = (data, addonId, totalcost) => {
    setData(data);
    setAddonId(addonId);
    setTotalcost(totalcost);
  };
  const handleForm1 = (data) => {
    setData1(data);
  };
  const [form2Completed, setForm2Completed] = useState(false);
  const [form1Completed, setForm1Completed] = useState(false);
  const [formDataFromForm2, setFormDataFromForm2] = useState({});
  const [formDataFromForm1, setFormDataFromForm1] = useState({});

  const [activeTab, setActiveTab] = useState("tab1");
  const [item, setItem] = useState();
  const [showSummary, setShowSummary] = useState(false);
  const [img, setImage1] = useState(false);

  const handleForm1Submit = (dataFromForm1) => {
    setForm1Completed(true);
    setFormDataFromForm1(dataFromForm1);
    setActiveTab("tab2");
    setImage1(true);
  };

  const handleForm2Submit = (dataFromForm2) => {
    setForm2Completed(true);
    setFormDataFromForm2(dataFromForm2);
    setShowSummary(true);
    setActiveTab("tab2");
  };
  const areBothNotEmpty =
    Object.keys(data).length > 0 && Object.keys(data1).length > 0;
  const handleFormSubmit = async () => {
    try {
      const mergedData = {
        customer: {
          email_otp: data1.emailOtp,
          phone_otp: data1.mobileOtp,
          email: data1.emailAddress,
          phone: data1.mobileNumber,
          organization_name: data1.organizationName,
          full_name: data1.fullName,
        },
        vehicle: id,
        city: data.city,
        pin_code: data.pincode,
        number_of_vehicles: data.vehiclesnumber,
        tenure: data.tenure,
        total_cost: totalcost,
        vehicle_lease_add_ons: addonId,
      };
      console.log(">>>>>>", mergedData);
      const response = await axios.post(
        `${BASE_URL_FLEETOS}/v2/vehicle-lease`,
        mergedData
      );
      setShowSummary(true);
      console.log("API Response:", response.data);
      setresponsedata(response.data);
      localStorage.setItem("fleetRequestId", response?.data?.data?.id);
    } catch (error) {
      console.error("Error submitting form data:", error);
    }
  };
  // if (areBothNotEmpty) {
  //   console.log(">>>>>1")
  //   handleFormSubmit();
  // }
  useEffect(() => {
    if (areBothNotEmpty) {
      console.log(">>>>>1");
      handleFormSubmit();
    }
  }, [areBothNotEmpty]);

  useEffect(() => {
    fetch(`${BASE_URL}/detailed-vehicle-info/${id}`)
      .then((response) => response.json())
      .then((data) => {
        console.log("data", data);
        setItem(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [id]);

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };
  const firstTab = () => {
    setImage1(true);
  };

  const secondForm = () => {
    setForm2Completed(true);
  };
  const firstForm = () => {
    setForm1Completed(true);
  };
  const handleclose = () => {
    setSubmitted(false);
    formik.resetForm();
  };
  // radio active
  const handleRadioChange = (event) => {
    setRadioValue(event.target.value);

    if (event.target.value === "corporate") {
      setIsIndividual(true);
    } else {
      setIsIndividual(false);
    }
  };
  //go back from summray pages

  return (
    <div className="home-mainss fleetrequest">
      <div className="container">
        <div
          className="d-flex flex-wrap"
          style={{ justifyContent: "space-between " }}
        >
          <div className="col-lg-5 flex-1">
            <div className="request-heading">
              <h1>Fleet Request Form</h1>
              <p>
                Please fill the form to recieve an instant offer for leasing EV
                fleet on your email.
              </p>
            </div>

            <div className="vehicle-details">
              <div className="vehicle">
                <img
                  src={item?.vehicle.profilePhoto}
                  style={{
                    width: "126px",
                    height: "118px",
                    objectFit: "contain",
                  }}
                  alt=""
                />
              </div>
              <div className="details">
                <div>
                  <div className="d-flex " style={{ gap: "0.5rem" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <img
                        src={item?.oemPic}
                        style={{ height: "20px" }}
                        alt=""
                      />
                    </div>

                    <div>
                      <span className="mahindra">{item?.vehicle.oem}</span>
                    </div>
                  </div>
                  <h1>{item?.vehicle.vehicleModel}</h1>
                  {/* <p>
                    <span style={{ fontSize: "18px", fontWeight: "700" }}>
                      ₹ 8,801{" "}
                    </span>
                    <span
                      style={{
                        fontSize: "14px",
                        fontWeight: "400",
                        lineHeight: "24px",
                        letterSpacing: "0.6%",
                        color: "#8F8F8F",
                      }}
                    >
                      (Monthly Lease Cost)
                    </span>
                  </p> */}
                </div>
              </div>
            </div>
            <div className="benefits">
              <div className="benefits-heading">
                <h1>Fleet Lease Benefits</h1>
              </div>
              <div className="list">
                <div>
                  <img src={Correct} style={{ width: "16px" }} alt="" />
                </div>
                <div className="list-text">
                  <h5>Zero Downpayment</h5>
                  <p>No upfront cost for leasing of brand new vehicle</p>
                </div>
              </div>
              <div className="list">
                <div>
                  <img src={Correct} style={{ width: "16px" }} alt="" />
                </div>
                <div className="list-text">
                  <h5>Alt Assured</h5>
                  <p>
                    All vehicles are tested by our experts with a 100+ point
                    checklist
                  </p>
                </div>
              </div>
              <div className="list">
                <div>
                  <img src={Correct} style={{ width: "16px" }} alt="" />
                </div>
                <div className="list-text">
                  <h5>Road Tax and Registrations </h5>
                  <p>Road tax and registration charges are inclusive</p>
                </div>
              </div>
              <div className="list">
                <div>
                  <img src={Correct} style={{ width: "16px" }} alt="" />
                </div>
                <div className="list-text">
                  <h5>Hassle Free Delivery</h5>
                  <p>
                    Vehicle delivered at nearest dealership to your location
                  </p>
                </div>
              </div>
              <div className="list">
                <div>
                  <img src={Correct} style={{ width: "16px" }} alt="" />
                </div>
                <div className="list-text">
                  <h5>Alt Protect Insurance</h5>
                  <p>
                    Zero depreciation vehicle insurance, third party and driver
                    cover
                  </p>
                </div>
              </div>
              <div className="list">
                <div>
                  <img src={Correct} style={{ width: "16px" }} alt="" />
                </div>
                <div className="list-text">
                  <h5>Vehicle Usage</h5>
                  <p>
                    Includes vehicle usage upto fixed km per month and
                    additional charges for vehicles beyond monthly limits
                  </p>
                </div>
              </div>
              <div className="list">
                <div>
                  <img src={Correct} style={{ width: "16px" }} alt="" />
                </div>
                <div className="list-text">
                  <h5>Comprehensive Maintenance</h5>
                  <p>
                    Includes periodic service and part replacements, labor,
                    consumables as per the Comprehensive Maintenance plan.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-md-7 flex-1 fleet-responsive">
            <div className="d-flex flex-column">
              {isindividual ? (
                !showSummary ? (
                  <div className=" col-lg-6 form-part d-flex flex-column align-self-start w-100 ">
                    <div className="">
                      <div className="d-flex " style={{ gap: "0.6rem" }}>
                        <span>{/* <img src={Arrow} alt="" /> */}</span>
                        <div className="d-flex " style={{ gap: "0.5rem" }}>
                          {!img ? (
                            <button
                              className={`one ${
                                activeTab === "tab1" ? "active" : ""
                              }`}
                              onClick={() => handleTabClick("tab1")}
                              id="1"
                            >
                              <span>1</span>
                            </button>
                          ) : (
                            <img
                              src={Correct}
                              alt=""
                              style={{ marginBottom: "0.5rem", width: "17px" }}
                            />
                          )}

                          <h3
                            className={`tab-button ${
                              activeTab === "tab1" ? "active" : ""
                            } ${img ? "img-active" : ""}`}
                            onClick={() => handleTabClick("tab1")}
                          >
                            Contact Details
                          </h3>
                        </div>
                        <div className="d-flex" style={{ gap: "0.5rem" }}>
                          <button
                            className={`two ${
                              activeTab === "tab2" ? "active" : ""
                            }`}
                            onClick={() => handleTabClick("tab2")}
                            id="2"
                          >
                            <span>2</span>
                          </button>

                          <div>
                            <h3
                              className={`tab-button ${
                                activeTab === "tab2" ? "active" : ""
                              }`}
                              onClick={() => handleTabClick("tab2")}
                            >
                              Vehicle Requirement
                            </h3>
                          </div>
                        </div>
                      </div>
                      <hr />

                      <div
                        className={`tab-cont ${
                          activeTab === "tab1" ? "active" : ""
                        }`}
                      >
                        <ContactDetails
                          id="contact_details"
                          handleTabClick={handleTabClick}
                          onForm1Submit={handleForm1Submit}
                          onData1={(data) => {
                            handleForm1(data);
                          }}
                        />
                      </div>
                      <div
                        className={`tab-cont ${
                          activeTab === "tab2" ? "active" : ""
                        }`}
                      >
                        <VehicleRequirement
                          handleTabClick={handleTabClick}
                          firstTab={firstTab}
                          cost={item?.vehicle?.leaseStartPrice}
                          firstForm={firstForm}
                          // onForm1Submit={handleForm1Submit}
                          handleForm2Submit={handleForm2Submit}
                          onData={(data, addonId, totalcost) => {
                            handleForm2(data, addonId, totalcost);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="col-lg-6 col-lg-md-7 w-100">
                    <Summary
                      responsedata={responsedata}
                      data={data}
                      data1={data1}
                    />
                  </div>
                )
              ) : (
                <div className=" col-lg-4 form-part d-flex flex-column align-self-start w-100">
                  {submitted ? (
                    <div style={{ width: "100%" }}>
                      <div
                        className="thanku-firstpart"
                        style={{
                          borderRadius: "4px",
                          height: "130px",
                          background:
                            "linear-gradient(129.14deg, #67c2a5 -5.53%, #1fb486 117.63%)",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="63"
                          height="59"
                          viewBox="0 0 63 59"
                          fill="none"
                        >
                          <g filter="url(#filter0_d_1305_1377)">
                            <path
                              d="M18.0001 29L28.0001 39L44.6667 19"
                              stroke="white"
                              stroke-width="10"
                              stroke-linecap="round"
                            />
                          </g>
                          <defs>
                            <filter
                              id="filter0_d_1305_1377"
                              x="0"
                              y="0"
                              width="62.667"
                              height="58.4072"
                              filterUnits="userSpaceOnUse"
                              color-interpolation-filters="sRGB"
                            >
                              <feFlood
                                flood-opacity="0"
                                result="BackgroundImageFix"
                              />
                              <feColorMatrix
                                in="SourceAlpha"
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                result="hardAlpha"
                              />
                              <feOffset dy="-1" />
                              <feGaussianBlur stdDeviation="6.5" />
                              <feComposite in2="hardAlpha" operator="out" />
                              <feColorMatrix
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.18 0"
                              />
                              <feBlend
                                mode="normal"
                                in2="BackgroundImageFix"
                                result="effect1_dropShadow_1305_1377"
                              />
                              <feBlend
                                mode="normal"
                                in="SourceGraphic"
                                in2="effect1_dropShadow_1305_1377"
                                result="shape"
                              />
                            </filter>
                          </defs>
                        </svg>
                      </div>
                      <div
                        className="thanku-secondpart"
                        style={{ height: "150px", background: "" }}
                      >
                        <h1>Thank you</h1>
                        <p style={{ textAlign: "center" }}>
                          Our team will be reaching out to you soon.
                        </p>
                        <div className="d-flex justify-content-center w-100">
                          <button
                            onClick={handleclose}
                            style={{
                              width: "max-content",
                            }}
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <form
                      onSubmit={formik.handleSubmit}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "1.5rem",
                      }}
                    >
                      <div className="contact-lease">Contact For Lease </div>
                      <div>
                        <label>Full Name:</label>
                        <input
                          type="text"
                          id="username"
                          name="username"
                          placeholder="Enter Your name"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.username}
                          style={{
                            width: "100%",
                            borderRadius: "4px",
                            padding: "6px",
                            border: "1px solid rgba(222, 223, 224, 1)",
                          }}
                        />
                        {formik.touched.username && formik.errors.username && (
                          <div className="error-text">
                            {formik.errors.username}
                          </div>
                        )}
                      </div>

                      <div>
                        <label>Phone Number:</label>

                        <input
                          type="text"
                          id="phone_no"
                          name="phone_no"
                          placeholder="Enter Your phone number"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.phone_no}
                          style={{
                            width: "100%",
                            borderRadius: "4px",
                            padding: "6px",
                            border: "1px solid rgba(222, 223, 224, 1)",
                          }}
                        />
                        {formik.touched.phone_no && formik.errors.phone_no && (
                          <div className="error-text">
                            {formik.errors.phone_no}
                          </div>
                        )}
                      </div>
                      <div>
                        <label>Email:</label>

                        <input
                          type="text"
                          id="email"
                          name="email"
                          placeholder="Enter Your email"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.email}
                          style={{
                            width: "100%",
                            borderRadius: "4px",
                            padding: "6px",
                            border: "1px solid rgba(222, 223, 224, 1)",
                          }}
                        />
                        {formik.touched.email && formik.errors.email && (
                          <div className="error-text">
                            {formik.errors.email}
                          </div>
                        )}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          Lease Cost:{" "}
                          <span>
                            {item?.vehicle?.leaseStartPrice
                              ? `₹ ${item?.vehicle?.leaseStartPrice?.toLocaleString()}`
                              : "-"}
                          </span>
                        </div>

                        <div>
                          <button
                            type="submit"
                            style={{
                              borderRadius: "4px",
                              padding: "6px 10px",
                              color: "#fff",
                              background:
                                "linear-gradient(129.14deg, #67c2a5 -5.53%, #1fb486 117.63%)",
                              border: " 1px solid transparent",
                            }}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                      <p className="error-text" style={{textTransform:"capitalize"}}>
                        {errorPara !== "" ? `${errorPara}*` : null}
                      </p>
                    </form>
                  )}
                </div>
              )}
              {!showSummary ? (
                <div className="d-flex justify-content-start align-items-center gap-14 mt-3">
                  <input
                    type="radio"
                    name="radiogroup1"
                    id="individual"
                    value="individual"
                    checked={radioValue === "individual"}
                    onChange={handleRadioChange}
                    style={{
                      height: "18px",
                      width: "16px",
                      backgroundColor:
                        radioValue === "individual" ? "green" : "transparent",
                      cursor: "pointer",
                    }}
                  />
                  <label
                    htmlFor="individual"
                    style={{ fontSize: "18px", marginLeft: "4px" }}
                  >
                    Individual
                  </label>
                  <input
                    type="radio"
                    name="radiogroup1"
                    id="corporate"
                    value="corporate"
                    checked={radioValue === "corporate"}
                    onChange={handleRadioChange}
                    style={{
                      height: "18px",
                      width: "16px",
                      marginLeft: "8px",
                      backgroundColor:
                        radioValue === "corporate" ? "green" : "transparent",
                      cursor: "pointer",
                    }}
                  />
                  <label
                    htmlFor="corporate"
                    style={{ fontSize: "18px", marginLeft: "4px" }}
                  >
                    Corporate Employee
                  </label>
                </div>
              ) : (
                <Link
                  to="/fleet_leasing"
                  style={{
                    textDecoration: "none",
                    color: "#125EAF",
                    marginTop: "2px",
                    cursor: "pointer",
                    padding: "2px",
                  }}
                >
                  <span style={{ fontSize: "18px", color: "#125EAF" }}>
                    Go Back
                  </span>
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default FleetRequest;
