import React from "react";
import { Link } from "react-router-dom";
import apply from "../Images/apply_now_new.png";
// import "../../node_modules//bootstrap/dist/css/bootstrap.min.css";
import { MdOutlineNavigateNext } from "react-icons/md";
import "./Ecosystem.css";
import "./Applynow.css";

const Applynow = () => {
  const handleClick = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div className="ecosystem-container container mt-5 w-100 p-5">
      <div className="row">
        <div className="col-md-6 col-sm-12 mt-4">
          <div className="eco-h">
            <h3>Join us and be a part of India's top EV service provider</h3>
          </div>
          <div className="eco-p">
            <p className="">
              Submit your requirement details via our enquiry form and our
              experts will get back to you with the best offers!
            </p>
          </div>
          <div className="eco-btn mt-4">
            <Link onClick={handleClick} to="/enquire">
              <button className="apply_btn">
                Apply Now
                <MdOutlineNavigateNext color="white" size={25} />
              </button>
            </Link>
          </div>
        </div>
        <div className="col-md-6 col-sm-12">
          <div className="eco-img mt-4 w-100 ">
            <img src={apply} alt="ecosystem" className="img-responsive" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Applynow;
