import React from "react";
import FleetOs from "../Components/FleetOsPage";

const Fleet = () => {
  return (
    <div className="home-mainss">
     
      <FleetOs />
  
    </div>
  );
};

export default Fleet;
