import React, { useEffect, useState } from "react";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { useLocation, useNavigate } from "react-router-dom";
import Wave from "../../../Images/wave-pattern.png";
import { BASE_URL } from "../../../repository/api";
import "./LeaseOption.css";

export default function LeaseOption() {
  const [item, setItem] = useState();

  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");

  const navigateRequest = (e) => {
    e.preventDefault();
    navigate(`/Request?id=${id}&individual=true`);
  };

  const leaseRequest = (e) => {
    e.preventDefault();
    // navigate("/leaseapplicationform");
    navigate(`/Request?id=${id}&individual=false`);
  };

  useEffect(() => {
    fetch(`${BASE_URL}/detailed-vehicle-info/${id}`)
      .then((response) => response.json())
      .then((data) => {
        console.log("data", data);
        setItem(data);
        window.scrollTo(0, 0);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [id]);

  return (
    <div
      className="VehicleIndividual position-relative"
      style={{
        height: "80vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="container">
        <div className="card card-listing  gap-3 shadow-sm p-3 mb-5 bg-body rounded">
          <img
            src={item?.vehicle.profilePhoto}
            className="card-img-top"
            style={{
              width: "40%",
              objectFit: "contain",
            }}
            alt="..."
          />
          <div className="card-body">
            <h1 className="card-title fs-4">Select User Type</h1>
            <div className="d-flex flex-column pb-4" style={{ gap: "0.5rem" }}>
              <div
                className="card px-4 py-3 leaseOption_button d-flex justify-content-between flex-row"
                onClick={(e) => navigateRequest(e)}
              >
                <p className="mt-0">Individual</p>
                <IoIosInformationCircleOutline style={{ fontSize: "1.5rem" }} />
              </div>
              <div
                className="card px-4 py-3 leaseOption_button d-flex justify-content-between flex-row"
                onClick={(e) => leaseRequest(e)}
              >
                <p className="mt-0">Corporate Employee</p>
                <IoIosInformationCircleOutline style={{ fontSize: "1.5rem" }} />
              </div>
            </div>
            <img
              src={Wave}
              className="position-absolute"
              style={{ bottom: "0", right: "0" }}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
}
