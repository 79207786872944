import React from 'react'
import BlogPage from '../Components/BlogPage'

const Blog = () => {
    return (
        <>        <div className=" home-mainss mt-lg-5 pt-5" >

            <BlogPage className=" mt-lg-4" />

        </div></>

    )
}

export default Blog
