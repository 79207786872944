import "./FeaturedIn.css";

import Express from "../Images/express mobility(3).png";

import image2 from "../Images/Image 2 (1).webp";
import image1 from "../Images/Image 1.jpg";
import { Link } from "react-router-dom";

import { MdOutlineNavigateNext } from "react-icons/md";
import f9 from "../Images/nsrcel.png";
import Whiteeco from "../Images/ET__1_-removebg-preview.png";
import Whitemobility from "../Images/mobility outlook.png";
import Whiteemobility from "../Images/emobility-removebg-preview.png";
import Whiteconstruction from "../Images/Construction_Week-removebg-preview.png";
import Whitezee from "../Images/ZeeBiz-Logo-removebg-preview.png";
import Whitebw from "../Images/BW_Autoworld-removebg-preview.png";

import f10 from "../Images/thirdderivative.png";
import f11 from "../Images/iimb.png";
import f12 from "../Images/startups.png";
import f13 from "../Images/iitdelhi.png";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useMediaQuery } from "react-responsive";

const FeaturedIn = () => {
  const isMobile = useMediaQuery({ maxWidth: 827 });

  const responsive = {
    tablet: {
      breakpoint: { max: 823, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 3,
      slidesToSlide: 3,
    },
  };

  const isMobileOrTablet = window.innerWidth <= 824;

  const responsive2 = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      showDots: false,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      showDots: false,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  const carouselStyle = {
    padding: "0 5px",
    margin: "0 -5px",
  };

  const itemStyle = {
    margin: "0 10px",
  };

  // const [activeButton ,setActiveButton]=useState("btn1")
  return (
    <>
      <div className="main-cnted text-center  paddingsmall ">
       
        <h1 className="feature-heading">Featured In</h1>
        <div
          className=" sev-images flex-nowrap p-md-5  paddingsmall mb-5 nav nav-pills  "
          id="pills-tab"
          role="tablist"
        >
          <button
            // onClick={()=> setActiveButton("btn1")}
            className="nav-link active btn-height"
            id="pills-onee-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-onee"
            type="button"
            role="tab"
            aria-controls="pills-onee"
            aria-selected="true"
            style={{ height: "60px" }}
          >
            <img
              // src={activeButton=== "btn1"? Economic: Whiteeco}
              src={Whiteeco}
              alt="f1"
              style={{ width: "120px", height: "30px", objectFit: "cover" }}
            />
          </button>

          <button
            // onClick={()=> setActiveButton("btn2")}

            className="nav-link btn-height"
            id="pills-twoe-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-twoe"
            type="button"
            role="tab"
            aria-controls="pills-twoe"
            aria-selected="false"
            style={{ height: "60px" }}
          >
            {" "}
            <img
              src={Express}
              alt="f2"
              style={{ width: "129px", height: "30px" }}
            />
          </button>

          <button
            // onClick={()=> setActiveButton("btn3")}

            className="nav-link btn-height"
            id="pills-threee-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-threee"
            type="button"
            role="tab"
            aria-controls="pills-threee"
            aria-selected="false"
            style={{ height: "60px" }}
          >
            <img
              className="img-blr"
              // src={activeButton=== "btn3"? Mobility: Whitemobility}
              src={Whitemobility}
              alt="f3"
              style={{ width: "115px", height: "40px" }}
            />
          </button>

          <button
            // onClick={()=> setActiveButton("btn4")}

            className="nav-link btn-height"
            id="pills-foure-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-foure"
            type="button"
            role="tab"
            aria-controls="pills-foure"
            aria-selected="false"
            style={{ height: "60px" }}
          >
            {" "}
            <img
              className="img-blr"
              src={Whitebw}
              // src={activeButton=== "btn4"? Bw_auto: Whitebw}
              alt="f4"
              style={{ width: "145px", height: "32px" }}
            />
          </button>

          <button
            // onClick={()=> setActiveButton("btn5")}

            className="nav-link btn-height"
            id="pills-fivee-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-fivee"
            type="button"
            role="tab"
            aria-controls="pills-fivee"
            aria-selected="false"
            style={{ height: "60px", padding: "3px 10px 1px 10px" }}
          >
            {" "}
            <img
              className="img-blr"
              src={Whiteemobility}
              // src={activeButton=== "btn5"? Emobility: Whiteemobility}
              alt="f5"
              style={{ width: "150px", height: "1.9rem!important" }}
            />
          </button>

          <button
            // onClick={()=> setActiveButton("btn6")}

            className=" nav-link btn-height"
            id="pills-sixee-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-sixee"
            type="button"
            role="tab"
            aria-controls="pills-sixee"
            aria-selected="false"
            style={{ height: "60px" }}
          >
            {" "}
            <img
              src={Whiteconstruction}
              // src={activeButton=== "btn6"? Construction : Whiteconstruction}
              alt="f6"
              style={{
                width: "95px",
                height: "18px",
                transform: "scale(1.15)",
                // objectFit: "contain",
              }}
            />
          </button>

          <button
            // onClick={()=> setActiveButton("btn7")}

            className=" nav-link btn-height"
            id="pills-sevene-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-sevene"
            type="button"
            role="tab"
            aria-controls="pills-sevene"
            aria-selected="false"
            style={{ height: "60px" }}
          >
            {" "}
            <img
              className="img-blr"
              src={Whitezee}
              // src={activeButton=== "btn7"? Zee: Whitezee}
              alt="f7"
              style={{ width: "80px", height: "28px", transform: "scale(1.3)" }}
            />
          </button>

          {/* <button
              className="nav-link"
              id="pills-eightey-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-eighte"
              type="button"
              role="tab"
              aria-controls="pills-eighte"
              aria-selected="false"
            > <img src={f8} alt='f8' style={{width:"93px" , height:"24px"}}/></button> */}
        </div>

        <div className="tab-content " id="pills-tabContent">
          <div
            className="tab-pills tab-pane fade show active container"
            id="pills-onee"
            role="tabpanel"
            aria-labelledby="pills-onee-tab"
          >
            <Carousel
              responsive={responsive2}
              showDots="true"
              arrows="true"
              removeArrowOnDeviceType={["tablet", "mobile"]}
              className="pb-md-5 container"
              autoPlay={"true"}
              autoPlaySpeed={3000}
              keyBoardControl={true}
              infinite={true}
            >
              <div className=" myCarousel feature-container text-start p-3">
                <div className="row feature-row ">
                  <div className=" feature-content col-sm-12 col-md-5 ">
                    <h2 className="featured-h ">
                      One Electric Motorcycles and Alt Mobility join to deploy
                      50,000 e-bikes for B2B logistics
                    </h2>

                    <p className="featured-p mt-4 mb-4">
                      October 13, 2022 3:21:05 pm
                    </p>
                    <Link
                      target="_blank"
                      to="https://auto.economictimes.indiatimes.com/news/two-wheelers/motorcycles/one-electric-motorcycles-and-alt-mobility-join-to-deploy-50000-e-bikes-for-b2b-logistics/94829099"
                    >
                      {" "}
                      <button
                        type="button"
                        class="btn-feature mt-4 check_btn "
                        style={{ color: "#fff" }}
                      >
                        Checkout the Article{" "}
                        <span className="icons1">
                          <MdOutlineNavigateNext color="white" size={25} />
                        </span>
                      </button>
                    </Link>
                  </div>
                  <div className=" col-sm-12 col-md-7  ">
                    <img
                      src={image1}
                      alt="feature"
                      className="feature-image img-responsive "
                      style={{ float: "right" }}
                    />
                  </div>
                </div>
              </div>
              <div className=" myCarousel feature-container text-start p-3">
                <div className="row feature-row ">
                  <div className=" feature-content col-sm-12 col-md-5 ">
                    <h2 className="featured-h ">
                      Yamaha Motors’ lending arm MBSI partners with Alt Mobility
                      to finance E2Ws <br /> <br />
                    </h2>

                    <p className="featured-p mt-4 mb-4">
                      October 13, 2022 3:21:05 pm
                    </p>
                    <Link
                      target="_blank"
                      to="https://auto.economictimes.indiatimes.com/news/two-wheelers/yamaha-motors-lending-arm-mbsi-partners-with-alt-mobility-to-finance-e2ws/94913716"
                    >
                      <button
                        class="btn-feature mt-4"
                        style={{ color: "#fff" }}
                      >
                        Checkout the Article{" "}
                        <span className="icons1">
                          <MdOutlineNavigateNext color="white" size={25} />
                        </span>
                      </button>
                    </Link>
                  </div>
                  <div className=" col-sm-12 col-md-7  ">
                    <img
                      src={image2}
                      alt="feature"
                      className="feature-image img-responsive "
                      style={{ objectFit: "cover", float: "right" }}
                    />
                  </div>
                </div>
              </div>
            </Carousel>
          </div>

          <div
            className="tab-pills tab-pane fade"
            id="pills-twoe"
            role="tabpanel"
            aria-labelledby="pills-twoe-tab"
          >
            <Carousel
              responsive={responsive2}
              showDots="true"
              arrows="true"
              removeArrowOnDeviceType={["tablet", "mobile"]}
              className="pb-md-5 "
              autoPlay={"true"}
              autoPlaySpeed={3000}
              keyBoardControl={true}
              infinite={true}
            >
              <div className=" myCarousel feature-container text-start p-3">
                <div className="row feature-row ">
                  <div className=" feature-content col-sm-12 col-md-5 ">
                    <h2 className="featured-h ">
                      One Electric Motorcycles partners Alt Mobility, targets
                      deploying 50,000 electric bikes in 2023
                    </h2>
                    <p className="featured-p mt-4 mb-4">
                      October 13, 2022 3:21:05 pm
                    </p>
                    <Link
                      target="_blank"
                      to="https://www.financialexpress.com/express-mobility/one-electric-motorcycles-partners-alt-mobility-targets-deploying-50000-electric-bikes-in-2023/2709306/"
                    >
                      {" "}
                      <button type="button" class="btn-feature mt-4 ">
                        Checkout the Article{" "}
                        <span className="icons1">
                          <MdOutlineNavigateNext color="white" size={25} />
                        </span>
                      </button>
                    </Link>
                  </div>
                  <div className=" col-sm-12 col-md-7  ">
                    <img
                      src={image1}
                      alt="feature"
                      className="feature-image img-responsive "
                      style={{ float: "right" }}
                    />
                  </div>
                </div>
              </div>
              <div className=" myCarousel feature-container text-start p-3">
                <div className="row feature-row ">
                  <div className=" feature-content col-sm-12 col-md-5 ">
                    <h2 className="featured-h ">
                      Yamaha Motors’ finance arm partners Alt Mobility for EV
                      financing <br /> <br />
                    </h2>
                    <p className="featured-p mt-4 mb-4">
                      October 13, 2022 3:21:05 pm
                    </p>
                    <Link
                      target="_blank"
                      to="https://www.financialexpress.com/express-mobility/yamaha-motors-finance-arm-partners-alt-mobility-for-ev-financing/2715777/"
                    >
                      <button type="button" class="btn-feature  mt-4">
                        Checkout the Article{" "}
                        <span className="icons1">
                          <MdOutlineNavigateNext color="white" size={25} />
                        </span>
                      </button>
                    </Link>
                  </div>
                  <div className=" col-sm-12 col-md-7  ">
                    <img
                      src={image2}
                      alt="feature"
                      className="feature-image img-responsive "
                      style={{ objectFit: "cover", float: "right" }}
                    />
                  </div>
                </div>
              </div>
            </Carousel>
          </div>

          <div
            className="tab-pills tab-pane fade"
            id="pills-threee"
            role="tabpanel"
            aria-labelledby="pills-threee-tab"
          >
            {/* <Carousel responsive={responsive2} showDots="true" arrows="true" removeArrowOnDeviceType={['tablet', 'mobile']} className='pb-md-5 ' keyBoardControl={true} infinite={true} > */}
            <div className=" myCarousel feature-container text-start p-3">
              <div className="row feature-row ">
                <div className=" feature-content col-sm-12 col-md-5 ">
                  <h2 className="featured-h ">
                    One Electric, ALT Mobility Partner To Deploy EVs In B2B
                    Logistics <br /> <br />
                  </h2>
                  <p className="featured-p mt-4 mb-4">
                    October 13, 2022 3:21:05 pm
                  </p>
                  <Link
                    target="_blank"
                    to="https://www.mobilityoutlook.com/news/one-electric-alt-mobility-partner-to-deploy-evs-in-b2b-logistics/"
                  >
                    <button type="button" class="btn-feature mt-4 ">
                      Checkout the Article{" "}
                      <span className="icons1">
                        <MdOutlineNavigateNext color="white" size={25} />
                      </span>
                    </button>
                  </Link>
                </div>
                <div className=" col-sm-12 col-md-7  ">
                  <img
                    src={image1}
                    alt="feature"
                    className="feature-image img-responsive "
                    style={{ float: "right" }}
                  />
                </div>
              </div>
            </div>
            {/* <div className=' myCarousel feature-container text-start p-3'>
       <div className='row feature-row '>
        <div className=' feature-content col-sm-12 col-md-5 '>
            <h2 className='featured-h '>onee Electric Motorcycles partners ALT Mobility, targets deploying 50,000 electric bikes in 2023</h2>
            <p className='featured-p mt-3 mb-3'>October 13, 2022 3:21:05 pm</p>
            <button type="button" class="btn-feature ">Checkout the Article <span className='icons1'><GrNext/></span></button>
        </div>
        <div className=' col-sm-12 col-md-7  '>
        <img src={feature} alt='feature' className='feature-image img-responsive '/>
        </div>
       </div>
       </div> */}

            {/* </Carousel> */}
          </div>

          <div
            className="tab-pills tab-pane fade"
            id="pills-foure"
            role="tabpanel"
            aria-labelledby="pills-foure-tab"
          >
            {/* <Carousel responsive={responsive2} showDots="true" arrows="true" removeArrowOnDeviceType={['tablet', 'mobile']} className='pb-md-5 '   keyBoardControl={true} infinite={true} > */}
            <div className=" myCarousel feature-container text-start p-3">
              <div className="row feature-row ">
                <div className=" feature-content col-sm-12 col-md-5 ">
                  <h2 className="featured-h ">
                    Yamaha Motors’ Vehicle Financing Arm MBSI & Alt Mobility To
                    Accelerate EV Adoption
                  </h2>
                  <p className="featured-p mt-4 mb-4">
                    October 13, 2022 3:21:05 pm
                  </p>
                  <Link
                    target="_blabk"
                    to="https://www.google.com/url?q=http://bwautoworld.businessworld.in/article/Yamaha-Motors-Vehicle-Financing-Arm-MBSI-Alt-Mobility-To-Accelerate-EV-Adoption/21-10-2022-451423/&sa=D&source=editors&ust=1682585024101602&usg=AOvVaw2xORCXHXSTf5pDBRB2F7O2"
                  >
                    <button type="button" class="btn-feature  mt-4">
                      Checkout the Article{" "}
                      <span className="icons1">
                        <MdOutlineNavigateNext color="white" size={25} />
                      </span>
                    </button>
                  </Link>
                </div>
                <div className=" col-sm-12 col-md-7  ">
                  <img
                    src={image2}
                    alt="feature"
                    className="feature-image img-responsive "
                    style={{ objectFit: "cover", float: "right" }}
                  />
                </div>
              </div>
            </div>
            {/* <div className=' myCarousel feature-container text-start p-3'>
       <div className='row feature-row '>
        <div className=' feature-content col-sm-12 col-md-5 '>
            <h2 className='featured-h '>onee Electric Motorcycles partners ALT Mobility, targets deploying 50,000 electric bikes in 2023</h2>
            <p className='featured-p mt-3 mb-3'>October 13, 2022 3:21:05 pm</p>
            <button type="button" class="btn-feature ">Checkout the Article <span className='icons1'><GrNext/></span></button>
        </div>
        <div className=' col-sm-12 col-md-7  '>
        <img src={feature} alt='feature' className='feature-image img-responsive '/>
        </div>
       </div>
       </div> */}

            {/* </Carousel> */}
          </div>

          <div
            className="tab-pills tab-pane fade"
            id="pills-fivee"
            role="tabpanel"
            aria-labelledby="pills-fivee-tab"
          >
            {/* <Carousel responsive={responsive2} showDots="true" arrows="true" removeArrowOnDeviceType={['tablet', 'mobile']} className='pb-md-5 '   keyBoardControl={true} infinite={true} > */}
            <div className=" myCarousel feature-container text-start p-3">
              <div className="row feature-row ">
                <div className=" feature-content col-sm-12 col-md-5 ">
                  <h2 className="featured-h ">
                    Moto Business Service India Partners With Alt Mobility To
                    Accelerate EV Adoption
                  </h2>
                  <p className="featured-p mt-4 mb-4">
                    October 13, 2022 3:21:05 pm
                  </p>
                  <Link
                    target="_blank"
                    to="https://emobilityplus.com/2022/10/17/moto-business-service-india-partners-with-alt-mobility-to-accelerate-ev-adoption/"
                  >
                    <button type="button" class="btn-feature mt-5 ">
                      Checkout the Article{" "}
                      <span className="icons1">
                        <MdOutlineNavigateNext color="white" size={25} />
                      </span>
                    </button>
                  </Link>
                </div>
                <div className=" col-sm-12 col-md-7   ">
                  <img
                    src={image2}
                    alt="feature"
                    className="feature-image img-responsive"
                    style={{ objectFit: "cover", float: "right" }}
                  />
                </div>
              </div>
            </div>
            {/* <div className=' myCarousel feature-container text-start p-3'>
       <div className='row feature-row '>
        <div className=' feature-content col-sm-12 col-md-5 '>
            <h2 className='featured-h '>onee Electric Motorcycles partners ALT Mobility, targets deploying 50,000 electric bikes in 2023</h2>
            <p className='featured-p mt-3 mb-3'>October 13, 2022 3:21:05 pm</p>
            <button type="button" class="btn-feature ">Checkout the Article <span className='icons1'><GrNext/></span></button>
        </div>
        <div className=' col-sm-12 col-md-7  '>
        <img src={feature} alt='feature' className='feature-image img-responsive '/>
        </div>
       </div>
       </div> */}

            {/* </Carousel> */}
          </div>

          <div
            className="tab-pills tab-pane fade"
            id="pills-sixee"
            role="tabpanel"
            aria-labelledby="pills-sixee-tab"
          >
            {/* <Carousel responsive={responsive2} showDots="true" arrows="true" removeArrowOnDeviceType={['tablet', 'mobile']} className='pb-md-5 '   keyBoardControl={true} infinite={true} > */}
            <div className=" myCarousel feature-container text-start p-3">
              <div className="row feature-row ">
                <div className=" feature-content col-sm-12 col-md-5 ">
                  <h2 className="featured-h ">
                    Yamaha Motors’ vehicle financing arm MBSI partners with Alt
                    Mobility
                  </h2>
                  <p className="featured-p mt-4 mb-4">
                    October 13, 2022 3:21:05 pm
                  </p>
                  <Link
                    target="_blank"
                    to="https://www.constructionweekonline.in/business/yamaha-motors-vehicle-financing-arm-mbsi-partners-with-alt-mobility"
                  >
                    <button type="button" class="btn-feature  mt-4">
                      Checkout the Article{" "}
                      <span className="icons1">
                        <MdOutlineNavigateNext color="white" size={25} />
                      </span>
                    </button>
                  </Link>
                </div>
                <div className=" col-sm-12 col-md-7  ">
                  <img
                    src={image1}
                    alt="feature"
                    className="feature-image img-responsive "
                    style={{ float: "right" }}
                  />
                </div>
              </div>
            </div>
            {/* <div className=' myCarousel feature-container text-start p-3'>
       <div className='row feature-row '>
        <div className=' feature-content col-sm-12 col-md-5 '>
            <h2 className='featured-h '>onee Electric Motorcycles partners ALT Mobility, targets deploying 50,000 electric bikes in 2023</h2>
            <p className='featured-p mt-3 mb-3'>October 13, 2022 3:21:05 pm</p>
            <button type="button" class="btn-feature ">Checkout the Article <span className='icons1'><GrNext/></span></button>
        </div>
        <div className=' col-sm-12 col-md-7  '>
        <img src={feature} alt='feature' className='feature-image img-responsive '/>
        </div>
       </div>
       </div> */}

            {/* </Carousel> */}
          </div>

          <div
            className="tab-pills tab-pane fade"
            id="pills-sevene"
            role="tabpanel"
            aria-labelledby="pills-sevene-tab"
          >
            {/* <Carousel responsive={responsive2} showDots="true" arrows="true" removeArrowOnDeviceType={['tablet', 'mobile']} className='pb-md-5 ' keyBoardControl={true} infinite={true} > */}
            <div className=" myCarousel feature-container text-start p-3">
              <div className="row feature-row ">
                <div className=" feature-content col-sm-12 col-md-5 ">
                  <h2 className="featured-h ">
                    EV investment in India: Trends to watch in Indian electric
                    vehicle market in 2023
                  </h2>
                  <p className="featured-p mt-4 mb-4">
                    October 13, 2022 3:21:05 pm
                  </p>
                  <Link
                    target="_blank"
                    to="https://www.zeebiz.com/automobile/news-ev-trends-2023-investment-in-india-charging-to-watch-in-indian-electric-vehicle-market-in-2023-213182"
                  >
                    <button type="button" class="btn-feature mt-4">
                      Checkout the Article{" "}
                      <span className="icons1">
                        <MdOutlineNavigateNext color="white" size={25} />
                      </span>
                    </button>
                  </Link>
                </div>
                <div className=" col-sm-12 col-md-7  ">
                  <img
                    src={image1}
                    alt="feature"
                    className="feature-image img-responsive "
                    style={{ float: "right" }}
                  />
                </div>
              </div>
            </div>
            {/* <div className=' myCarousel feature-container text-start p-3'>
       <div className='row feature-row '>
        <div className=' feature-content col-sm-12 col-md-5 '>
            <h2 className='featured-h '>onee Electric Motorcycles partners ALT Mobility, targets deploying 50,000 electric bikes in 2023</h2>
            <p className='featured-p mt-3 mb-3'>October 13, 2022 3:21:05 pm</p>
            <button type="button" class="btn-feature ">Checkout the Article <span className='icons1'><GrNext/></span></button>
        </div>
        <div className=' col-sm-12 col-md-7  '>
        <img src={feature} alt='feature' className='feature-image img-responsive '/>
        </div>
       </div>
       </div> */}

            {/* </Carousel> */}
          </div>

          {/* <div className='tab-pills tab-pane fade' id="pills-sevene"
              role="tabpanel"
              aria-labelledby="pills-sevene-tab">
        <Carousel responsive={responsive2} showDots="true" arrows="true" removeArrowOnDeviceType={['tablet', 'mobile']} className='pb-md-5 ' autoPlay={'true'} autoPlaySpeed={3000} keyBoardControl={true} infinite={true} >
        <div className=' myCarousel feature-container text-start p-3'>
       <div className='row feature-row '>
        <div className=' feature-content col-sm-12 col-md-5 '>
            <h2 className='featured-h '>onee Electric Motorcycles partners ALT Mobility, targets deploying 50,000 electric bikes in 2023</h2>
            <p className='featured-p mt-3 mb-3'>October 13, 2022 3:21:05 pm</p>
            <button type="button" class="btn-feature ">Checkout the Article <span className='icons1'><GrNext/></span></button>
        </div>
        <div className=' col-sm-12 col-md-7  '>
        <img src={image2} alt='feature' className='feature-image img-responsive '/>
        </div>
       </div>
       </div>
       <div className=' myCarousel feature-container text-start p-3'>
       <div className='row feature-row '>
        <div className=' feature-content col-sm-12 col-md-5 '>
            <h2 className='featured-h '>onee Electric Motorcycles partners ALT Mobility, targets deploying 50,000 electric bikes in 2023</h2>
            <p className='featured-p mt-3 mb-3'>October 13, 2022 3:21:05 pm</p>
            <button type="button" class="btn-feature ">Checkout the Article <span className='icons1'><GrNext/></span></button>
        </div>
        <div className=' col-sm-12 col-md-7  '>
        <img src={feature} alt='feature' className='feature-image img-responsive '/>
        </div>
       </div>
       </div>
      
       </Carousel>
       </div> */}
        </div>
      </div>

      <div className="mb-5 mt-5">
        <div className="acce text-center">
          <h1>Know Our Accelerators</h1>
        </div>
        <div className="nsr-main">
          {isMobileOrTablet ? (
            <Carousel
              responsive={responsive}
              showDots={isMobile}
              arrows={false}
              className=" nsr-container d-flex w-100 pe-md-5 ps-md-5 paddingsmall mt-5 text-center ms-0 pb-4"
              autoPlay={isMobile}
              autoPlaySpeed={3000}
              infinite={true}
              style={carouselStyle}
            >
              <div style={itemStyle}>
                <img
                  src={f9}
                  alt="f9"
                  style={{ width: "4rem", height: "3rem" }}
                />
              </div>

              <div style={itemStyle}>
                <img
                  src={f10}
                  alt="f10"
                  style={{ width: "4rem", height: "3rem" }}
                />
              </div>
              <div style={itemStyle}>
                <img
                  src={f11}
                  alt="f11"
                  style={{ width: "4rem", height: "3rem" }}
                />
              </div>
              <div style={itemStyle}>
                <img
                  src={f12}
                  alt="f12"
                  style={{ width: "4rem", height: "3rem" }}
                />
              </div>

              <div style={itemStyle} className="pe-5">
                <img
                  src={f13}
                  alt="f13"
                  style={{ width: "6.5rem", height: "3rem" }}
                />
              </div>
            </Carousel>
          ) : (
            <div className=" nsr-container w-100 d-flex pe-md-5 ps-md-5 paddingsmall mt-5 text-center">
              <div>
                <img
                  src={f9}
                  alt="f9"
                  style={{ width: "6rem", height: "4rem" }}
                />
              </div>
              <div>
                <img
                  src={f10}
                  alt="f10"
                  style={{ width: "6rem", height: "4rem" }}
                />
              </div>
              <div>
                {" "}
                <img
                  src={f11}
                  alt="f11"
                  style={{ width: "6rem", height: "4rem" }}
                />
              </div>
              <div>
                {" "}
                <img
                  src={f12}
                  alt="f12"
                  style={{ width: "6rem", height: "4rem" }}
                />
              </div>
              <div>
                {" "}
                <img
                  src={f13}
                  alt="f13"
                  style={{ width: "8.5rem", height: "4rem" }}
                />
              </div>
            </div>
          )}
        </div>
        
      </div>
    </>
  );
};
export default FeaturedIn;
