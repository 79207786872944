import React from "react";
import { Link } from "react-router-dom";
import { PiPhoneCall } from "react-icons/pi";
import { FaLinkedin } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import FooterLogo from "../../Images/alt mobility transparent.png";
import TopLeftString from "../../Images/footer-top-left-string.png";
import ToRightString from "../../Images/footer-top-right-string.png";
import GooglePlay from "../../Images/google_play.png";
import StringLeft from "../../Images/string_left.png"
import StringRight from "../../Images/string_right.png";
import { footerLinks } from "../../Pages/Home/constants";
import "./Footer.css";

const MobileFooter = () => {
    return (
        <div>
            <div className="mobile-footer">
                <div className="d-flex justify-content-between">
                    {/* left section */}
                    <div className="mobile-footer-links mt-5 mb-5">
                        <img className="mobile-footer-logo" src={FooterLogo} alt="logo" />
                        {/* links */}
                        <div className="mt-3">
                            {footerLinks.map((link) => (
                                <div>
                                    <Link to={link.link} className="mobile-footer-link">
                                        {link.title}
                                    </Link>
                                </div>
                            ))}
                        </div>
                    </div>
                    {/* right section */}
                    <div className="mobile-footer-contact-us pt-5">
                        {/* strings */}
                        <img src={TopLeftString} alt="string1" className="mobile-footer-top-left-string" />
                        <img src={ToRightString} alt="string1" className="mobile-footer-top-right-string" />
                        {/* contact us */}
                        <div className="mobile-contact-us">
                            <div>CALL US</div>
                            <PiPhoneCall />&nbsp;9999303854
                        </div>
                        {/* strings */}
                        <img src={StringLeft} alt="string1" className="mobile-footer-left-string" />
                        <img src={StringRight} alt="string1" className="mobile-footer-right-string" />
                        {/* google play image */}
                        <img className="mobile-google-play-icon" src={GooglePlay} alt="google play" />
                    </div>
                </div>
                {/* social media */}
                <div className="mb-5">
                    <Link to="https://www.linkedin.com/company/altmobility//mycompany/">
                        <FaLinkedin className="mobile-footer-social-links" />
                    </Link>
                    <Link to="https://www.facebook.com/mobilityalt?mibextid=ZbWKwL">
                        <FaFacebook className="mobile-footer-social-links" />
                    </Link>
                    <Link to="https://youtube.com/@AltMobility?si=J9hWiqJRD3-ugqdF">
                        <FaYoutube className="mobile-footer-social-links" />
                    </Link>

                </div>
                {/* copyright */}
                <div className="mobile-footer-copyright">
                    Copyright @2024 ALT NRG PVT LTD
                </div>
            </div>
            {/* page end */}
            <div className="mobile-footer-page-end d-flex justify-content-center align-items-end">
                <div></div>
            </div>
        </div>
    );
};

export default MobileFooter;