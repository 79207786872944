const ROOT_URL = "/";
const ABOUT_URL = "/about";

const INDIVIDUAL_URL = "/individual";
const ENQUIRE_URL = "/enquire";
const LEASING_URL = "/leasing";
const PARTNER_URL = "/partners";
const FLEET_URL = "/fleet";
// const FLEET_LEASING_URL = "/fleet_leasing";
const FLEET_PROTECT_URL = "/fleet_protect";
const FAQS_URL = "/faqs";
const BLOG_URL = "/blog";

export {
  ROOT_URL,
  ABOUT_URL,
  INDIVIDUAL_URL,
  ENQUIRE_URL,
  LEASING_URL,
  PARTNER_URL,
  FLEET_URL,
  // FLEET_LEASING_URL,
  FLEET_PROTECT_URL,
  FAQS_URL,
  BLOG_URL,
};
