import axios from "axios";
import { useFormik } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { BASE_URL_FLEETOS } from "../../repository/api";
import Button from "../CustomComponent/Button";
import CustomDropDown from "../CustomComponent/CustomSelectField";
import InputField from "../CustomComponent/InputField";
import "react-toastify/dist/ReactToastify.css";

const validationSchema = Yup.array().of(
  Yup.object().shape({
    name_of_director: Yup.string().required("Required"),
    qualification: Yup.string().required("Required"),
    other_business_info: Yup.string().required("Required"),
    // Add more validation as needed
  })
);

const Form3 = ({ handleBack, handleNext }) => {
  const { id } = useParams();
  const [data, setData] = useState([
    {
      name_of_director: "",
      qualification: "",
      other_business_info: "",
    },
  ]);
  const [loading, setLoading] = useState(false);
  const Qualification = useMemo(
    () => [
      {
        value:
          "3 or more engineering/management post graduates with 10 yrs or more cumulative experience",
        label:
          "3 or more engineering/management post graduates with 10 yrs or more cumulative experience",
      },
      {
        value:
          "Atleast 2 engineering and management post graduates with 5 yrs or more cumulative experience",
        label:
          "Atleast 2 engineering and management post graduates with 5 yrs or more cumulative experience",
      },

      {
        value:
          "3 or 5 yrs of experience with engineering or management experience",
        label:
          "3 or 5 yrs of experience with engineering or management experience",
      },
      {
        value: "1 to 3 Yrs of Cumulative Experience",
        label: "1 to 3 Yrs of Cumulative Experience",
      },
      {
        value: "No qualification/experience",
        label: "No qualification/experience",
      },
    ],
    []
  );

  const fetchDataFromAPI = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL_FLEETOS}/v1/lease-application/${id}`
      );

      response?.data?.lease_application_stakeholder_details?.length > 0 &&
        setData(response.data.lease_application_stakeholder_details);
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  useEffect(() => {
    fetchDataFromAPI();
  }, [id]);

 
  const formik = useFormik({
    initialValues: data,
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const Form1Data = Object.values(values).map((partner) => ({
          lease_application_id: id,
          prior_experience_in_ev:"false",
          name_of_director: partner.name_of_director,
          qualification: partner.qualification,
          other_business_info: partner.other_business_info,
        }));
        // Loop through additionalFields to add their data to Form1Data

        const response = await axios.post(
          `${BASE_URL_FLEETOS}/v1/lease-application/stakeholder-details`,
          Form1Data
        );

        handleNext();
        setLoading(false);
      } catch (error) {
        console.error("Error:", error);
        // handleErrorResponse(error);
        setLoading(false);
      }
    },
    handleChange: (e) => {
      const { name, value, type } = e.target;

      // Check if the field is a textarea
      if (type === "textarea") {
        // If it is, replace any numbers with an empty string
        const sanitizedValue = value.replace(/[0-9]/g, "");

        // Update the formik values with the sanitized value
        formik.setFieldValue(name, sanitizedValue);
      } else {
        // For other fields, update normally
        formik.setFieldValue(name, value);
      }
    },
  });
  function addAdditionalFields() {
    const newData = {
      name_of_director: "",
      qualification: "",
      other_business_info: "",
    };
    const updatedValues = [...formik.values, newData];
    formik.setValues(updatedValues);
  }

  return (
    <form onSubmit={formik.handleSubmit} className="d-flex flex-column gap-5">
      {formik.values?.map((_, index) => {
        return (
          <div>
            <div className="d-flex flex-column flex-md-row justify-content-between gap-4">
              <InputField
                label="Name of the Director"
                type="text"
                className="w-100"
                id={`${index}.name_of_director`}
                name={`${index}.name_of_director`}
                placeholder="Enter Name of the Director"
                value={formik.values[index]?.name_of_director}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched[index]?.name_of_director &&
                  formik.errors[index]?.name_of_director
                }
              />

              <CustomDropDown
                options={Qualification}
                className="full-width  w-100"
                required
                title="Qualification of the Directors"
                titleClass="custom-dropdown-style"
                value={{
                  label: formik.values[index]?.qualification,
                  value: formik.values[index]?.qualification,
                }}
                onChange={async (option) => {
                  formik.setFieldValue(`${index}.qualification`, option?.value);
                }}
                onBlur={formik.handleBlur(`${index}.qualification`)}
                placeholder={"Select Qualification"}
                error={
                  formik.touched[index]?.qualification &&
                  formik.errors[index]?.qualification
                }
              />
            </div>
            <div className="d-flex flex-column flex-md-row justify-content-between gap-4">
              <div className="d-flex flex-column gap-2 w-100">
                <div>
                  Are there any other businesses of any of the Directors? If
                  yes, please provide details
                </div>
                <div>
                  <label htmlFor={`${index}.other_business_info`}>
                    Kindly provide details about the legal suit.
                  </label>
                  <textarea
                    type="text"
                    className="w-100 p-2"
                    id={`${index}.other_business_info`}
                    name={`${index}.other_business_info`}
                    placeholder="Enter legal suit details"
                    value={formik.values[index]?.other_business_info}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    style={{ height: "100px", outline: "none" }}
                  />
                  {formik.touched[index]?.other_business_info &&
                    formik.errors[index]?.other_business_info && (
                      <div className="text-danger">
                        {formik.errors[index]?.other_business_info}
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        );
      })}
      <Button
        type="button"
        label="Add Directors"
        onClick={() => addAdditionalFields()}
      />
      <div className="d-flex gap-3 justify-content-center">
        <Button label="Back" onClick={handleBack} />
        <Button
          label="Next"
          onClick={formik.handleSubmit}
          loading={loading}
          disabled={!formik.isValid}
        />
      </div>
    </form>
  );
};

export default Form3;
