import React from "react";

const InputField = ({
  label,
  type,
  required,
  className,
  id,
  name,
  placeholder,
  value,
  onChange,
  onBlur,
  error,
}) => {
  return (
    <div
      className={`${className ?? ""}`}
      style={{ display: "flex", flexDirection: "column" }}
    >
      <label style={{ fontSize: "14px" }}>
        {label}
        {required && <span style={{ color: "red" }}>*</span>}
      </label>
      <input
        type={type}
        id={id}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        style={{
          width: "100%",
          borderRadius: "4px",
          padding: "10px",
          border: "1px solid rgba(222, 223, 224, 1)",
        }}
      />
      {error && <div className="error-text">{error}</div>}
    </div>
  );
};

export default InputField;
