import React, { useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useMediaQuery } from "react-responsive";
import "./LeasingGallery.css";
const LeasingGallery = () => {
  const isMobile = useMediaQuery({ maxWidth: 827 });

  const responsive = {
    tablet: {
      breakpoint: { max: 823, min: 500 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 500, min: 0 },
      items: 1,
    },
  };

  const carouselStyle = {
    padding: "0 5px",
    margin: "0 -5px",
  };

  const itemStyle = {
    margin: "0 10px",
  };

  const isMobileOrTablet = window.innerWidth <= 990;
  const [carouselItems, setCarouselItems] = useState([
    {
      title: "High Quality Vehicles",
      description:
        "Our vehicles are vetted on over 100+ data points and inspection tests before delivery.",
      image: "./home_files/affordable.png",
    },
    {
      title: "Smart Ownership",
      description:
        "Save up to 20% on monthly costs with FleetLease compared to bank financing, with an option to own the vehicle at the end of the lease.",
      image: "./home_files/easy_delivery.png",
    },
    {
      title: "Insurace Covers and Bulletproof Warranties",
      description: "With services, maintenance, road tax & registrations.",
      image: "./home_files/management_cost.png",
    },

    {
      title: "Zero Downpayment",
      description:
        "No upfront costs for leasing a brand-new vehicle. You simply pay a small security deposit and one month's advance lease rental to get started.",
      image: "./home_files/affordable2.png",
    },
    {
      title: "Service and Maintenance",
      description:
        "Periodic service and labour costs as per the Service Policy.",
      image: "./home_files/curated.png",
    },
    {
      title: "High Vehicle Uptime",
      description:
        "Ensure optimal fleet performance with our monitoring team and FleetOS sending real-time alerts and recommendations to improve your fleet uptime.",
      image: "./home_files/insurance.png",
    },
  ]);

  // Split the items into two separate lists
  const firstList = carouselItems.slice(0, 3);
  const secondList = carouselItems.slice(3);

  return (
    <section className="leasing-gallery pb-max">
      <div className="container mt-72 lg-pt-0 pt-4">
        <div className=" row">
          <div className="col-lg-5 col-12 col-sm-12 col-md-12 align align-items-lg-start flex-column">
            <h2 className="h2-heading">Lease your fleet today</h2>
            <p className="para mt-lg-4 mt-0 text-center text-lg-start">
              Alt provides a cost-effective solution for electric fleet
              operations with a single source for all your transportation needs.
              Our zero-emission commercial fleets offer everything from leasing
              and insurance to service, telematics, parking, and charging at a
              lower cost than diesel or CNG.
            </p>
            <a href="/leasing" className="leasing-btn">
              <button className="gray-btn green-btn mb-40 mt-sm-3 mt-md-3">
                <span className="d-lg-inline-block d-none">
                  Know More About Leasing vs Financing
                </span>{" "}
                &nbsp;
                <span>
                  <svg
                    width="8"
                    height="12"
                    viewBox="0 0 8 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.49985 0.94165L6.55818 5.99998L1.49985 11.0583"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                </span>
              </button>
            </a>
          </div>
          {isMobileOrTablet ? (
            <div>
              <Carousel
                responsive={responsive}
                arrows={false}
                className="text-start mt-4 mb-4 ms-0 pb-5"
                autoPlay={isMobile}
                showDots={isMobile}
                autoPlaySpeed={3000}
                infinite={true}
                style={carouselStyle}
              >
                {carouselItems.map((item, index) => (
                  <li
                    key={index}
                    className="card-eco-li "
                    style={{
                      width:"100%", 
                      height:"300px !important",
                      margin: "0 15px",
                    }}
                    
                  >
                    <h4 className="h4-heading">{item.title}</h4>
                    <p>{item.description}</p>
                    <img src={item.image} alt="" className="ico-card-img" />
                  </li>
                ))}
              </Carousel>
            </div>
          ) : (
            <div className=" leasing-gallery-desktop col-lg-7  col-sm-12 col-md-12 d-flex align justify-lg-content-between">
              <div className="column">
                <ul className="card-eco-ul d-lg-block d-none">
                  {firstList.map((item, index) => (
                    <div key={index} className="card-eco-li">
                      <h4 className="h4-heading">{item.title}</h4>
                      <p>{item.description}</p>
                      <img src={item.image} alt="" className="ico-card-img" />
                    </div>
                  ))}
                </ul>
              </div>
              <div className="column">
                <ul className="card-eco-ul d-lg-block d-none">
                  {secondList.map((item, index) => (
                    <li key={index} className="card-eco-li eco-li-big">
                      <h4 className="h4-heading">{item.title}</h4>
                      <p>{item.description}</p>
                      <img src={item.image} alt="" className="ico-card-img" />
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default LeasingGallery;
