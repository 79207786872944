import React from "react";
import Select, { components } from "react-select";



const CustomDropDown = ({
  width,
  singleValueStyle = {},
  valueContainerStyle = {},
  indicatorsContainerStyle = {},
  menulist = {},
  containerStyle = {},
  controlStyle = {},
  inputWidth = "auto",
  inputHeight = "auto",
  ...props
}) => {
  // let styling = {
  // };

  return (
    <Select
      components={{ Control: ControlComponent }}
      menuPortalTarget={document.body}
      {...props}
      
    />
  );
};

export default CustomDropDown;

const ControlComponent = (props) => (
  <div className="customDropDown">
    {props.selectProps.title ? (
      <p
        style={{ display: "flex",fontSize:"14px" }}
        className={`dropdownLabel ${props.selectProps.titleClass ?? ""}`}
      >
        {props.selectProps.title}{" "}
        <label
          className="required"
       
          id={`${props.selectProps.name}_required`}
        >
          {props.selectProps.required && <p style={{ color: "red" }}>*</p>}
        </label>

      </p>
    ) : null}
    <components.Control {...props} />
    {props.selectProps.error && <div className="error-text">{props.selectProps.error}</div>}
  </div>
);