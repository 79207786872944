import React from "react";
import "./CSShtml/Home.css";
const Refund = () => {
  return (
    <>
      <div className="home-mainss">
        <div className=" mt-md-5 pt-md-5  mx-auto col-lg-8 col-sm-12 ">
          <div className="container d-flex flex-column gap-4 shadow-sm p-4 mb-5  rounded">
            <h1 className=" text-center main">Cancellation and Refunds Policy </h1>
            <p className="contact-p">
              Thank you for choosing Alt Mobility Drivers. Please review our
              cancellation and refund policy carefully.
            </p>
            <div className="d-flex flex-column gap-1  ">
              <p className="contact-h">Cancellation:</p>
              <p className="contact-p">
                Once a lease agreement is signed, no cancellations are
                permitted. Lessees are committed to fulfilling the entire lease
                term.
              </p>
            </div>
            <div className="d-flex flex-column gap-1 ">
              <p className="contact-h">Refund:</p>
              <p className="contact-p">
                No refunds will be issued for payments made towards leased
                electric vehicles.
              </p>
            </div>
            <div className="d-flex flex-column gap-1 ">
              <p className="contact-h">Modifications:</p>
              <p className="contact-p">
                Alt Mobility reserves the right to modify this policy without
                prior notice.
              </p>
            </div>
            <div className="d-flex flex-column gap-1 ">
              <p className="contact-h">Contact:</p>
              <p className="contact-p">
                For inquiries, contact <span><a href="mailto:support@alt-mobility.com" style={{color:"blue"}}>Support@alt-mobility.com</a></span> By using our
                services, you agree to these terms. Thank you for choosing Alt
                Mobility Services.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Refund;
