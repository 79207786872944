import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import ClockIcon from "../Images/Frame.svg";
import TwitterIcon from "../Images/Group 3.svg";
import LinkedInIcon from "../Images/Group 4.svg";
import { BASE_URL } from "../repository/api";
import "./BlogDetailpage.css";

const BlogDetailPage = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  const [item, setItem] = useState(null);

  const fetchBlogDetails = async () => {
    try {
      const response = await fetch(`${BASE_URL}/blog/${id}`);
      const data = await response.json();
      setItem(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchBlogDetails();
  }, [id]);
  if (!item) {
    return <div>Loading...</div>;
  }

  const options = { month: "short", day: "numeric", year: "numeric" };
  const formattedDate = new Date(item.created_at).toLocaleDateString(
    "en-US",
    options
  );
  console.log(formattedDate);
  return (
    <div className="home-mainss" style={{ paddingTop: "7rem" }}>
      <div className="container main  blog-main-heading text-center">
        <h1>{item?.title}</h1>
      </div>
      <div className="container author-details d-flex m-auto p-auto justify-content-between mb-1">
        <div className="d-flex">
          <div className="d-flex">
            <div className="author-image">
              {/* <img src={item.author_pic} alt="" width="48px" /> */}
              {/* <h6>-</h6> */}
            </div>
            <div className="m-auto ms-2 blog-card-para">
              <p>-{item.author}</p>
            </div>
          </div>
          <div className="d-flex m-auto ms-4">
            <div className="blog-clock-icon">
              <img src={ClockIcon} alt="" width="20px" />
            </div>
            <div className="blog-card-para ms-2">
              <p>
                {new Date(item.created_at).toLocaleDateString("en-US", options)}
              </p>
            </div>
          </div>
        </div>
        <div className="blog-card-para mb-1">
          <p>6 min read</p>
        </div>
      </div>

      <div className=" container">
        <img src={item.image} alt="" className="w-100 rounded" />
      </div>
      <div className="container blog-detail-card card top-90 mb-5">
        <div className="card-body">
          <div className="blog-main-content main">
            <p className="mt-4">{item.top_content}</p>
            {/* <p className="mt-4">
                            However, despite the growing popularity and advancements in EV technology, several myths and misconceptions still surround these vehicles.</p>
                        <p className="mt-4">
                            In this blog, we aim to debunk the top five myths around EVs, providing you with accurate information to make informed decisions about embracing electric mobility.</p> */}
          </div>

          {item.bloglist &&
            item.bloglist.map((elem, id) => (
              <div>
                <div className="myth-title mt-5">
                  <h4>{elem.title}</h4>
                </div>
                <div className="myth-subtitle">
                  <p>{elem.subtitle}</p>
                </div>
                <div className="myth-1-img mt-3">
                  <img src={elem.image} alt="" className="w-100" />
                </div>
                <div className="blog-main-content main">
                  <p className="mt-3" style={{ whiteSpace: "pre-wrap", whiteSpace: 'pre-line' }}>
                    {elem.content}
                  </p>
                </div>

                {/* style= {{whiteSpace:'pre-line'}} is show to show the data with whiteSpace and line */}
                {/* <div className="blog-main-content main">
                                <p className="mt-3" style={{ whiteSpace: 'pre-line' }}>{elem.content}</p>
                            </div> */}

                {/* <p className="mt-4">
                                    Many electric models now offer ranges comparable to or even surpassing those of traditional gasoline- powered cars.</p>
                                <p className="mt-4">
                                    Additionally, the charging infrastructure is expanding rapidly, with fast-charging stations becoming more prevalent, enabling quicker recharging times and alleviating concerns about long-distance travel.</p> */}
                {/* </div> */}
              </div>
            ))}

          {/* <div className="main mt-5">
                        <h4>
                            Myth No. 2
                        </h4>
                    </div>
                    <div className="main">
                        <p>
                            EVs Are Expensive And Their Maintenance Cost Is High.
                        </p>
                    </div>
                    <div className="myth-1-img mt-3">
                        <img src={Rectange7} alt="" className="w-100" />
                    </div>
                    <div className="blog-main-content main">
                        <p className="mt-3">While the initial purchase price of an EV may be higher than that of a conventional car, it’s essential to consider the total cost of ownership.</p>
                        <p className="mt-4">
                            EVs have lower operating costs due to cheaper electricity prices compared to gasoline and reduced maintenance requirements.</p>
                        <p className="mt-4">
                            Moreover, government incentives, tax credits, and lower fuel and maintenance costs over time can neutralize the higher upfront cost of buying an EV making it a cost-effective choice in the long run.</p>
                    </div> */}

          <hr />
          <div className="share-icons d-flex">
            <p className="p-auto">Share</p>
            <Link
              to="https://www.linkedin.com/company/altmobility/"
              target="blank"
            >
              <img src={LinkedInIcon} alt="" className="ms-4" />
            </Link>
            <Link to="https://twitter.com/mobilityalt?s=20" target="blank">
              <img src={TwitterIcon} alt="" className="ms-3" />
            </Link>
          </div>
        </div>
      </div>

      {/* <div className="card container comment-box">
                <div className="card-body">
                    <CommentForm />
                </div>
            </div> */}
    </div>
  );
};

export default BlogDetailPage;
