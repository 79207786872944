import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import { FaChevronLeft } from "react-icons/fa";
import { FaChevronRight } from "react-icons/fa";
import "./MobileCarouselTab.css";
import MobileButton from "../../Components/CustomComponent/MobileButton";

const CustomLeftArrow = ({ setCurrentSlideIndex, onClick, ...rest }) => { 
    const {
        carouselState: { currentSlide }
    } = rest;

    return (
        <div 
            className="mobile-tab-carousel-leftArrow" 
            onClick={() => {
                setCurrentSlideIndex(currentSlide);
                onClick();
            }}
        >
            <FaChevronLeft />
        </div>
    );
};

const CustomRightArrow = ({ setCurrentSlideIndex, onClick, ...rest }) => {
    const {
        carouselState: { currentSlide }
    } = rest;

    return (
        <div 
            className="mobile-tab-carousel-rightArrow" 
            onClick={() => {
                setCurrentSlideIndex(currentSlide);
                onClick();
            }}
        >
            <FaChevronRight />
        </div>
    );
};

const MobileCarouselTab = ({ 
    tabs, selectedTab, setSelectedTab, itemsShowing, carouselContent, handleViewMoreVehicles
}) => {
    // determine the current slide
    const [currentSlideIndex, setCurrentSlideIndex] = useState(1);

    // set the selected tab based on the current slide shown
    useEffect(() => {
        if(currentSlideIndex === 2 || currentSlideIndex === 3) {
            setSelectedTab(carouselContent[currentSlideIndex - 1].type);
        } else {
            setSelectedTab(carouselContent[0].type);
        }
    }, [currentSlideIndex]);

    // config for mobile
    const responsive = {
        mobile: {
            breakpoint: { max: 464, min: 340 },
            items: itemsShowing
        }
    };
    
    return (
        <div>
            {/* carousel tab */}
            <div className="mobile-tab-container">
                <div className="mobile-tab-headers">
                    {tabs.map((tab, i) => (
                        <div className={`mobile-tab ${tab.name === selectedTab ? "activeTab" : ""}`} key={i}>
                            {tab.image
                                ? <img className="mobile-tab-icon" src={tab.image} alt={tab.name} />
                                : null
                            }
                            {tab.name}
                        </div>
                    ))}
                </div>
            </div>
            {/* carousel */}
            <div className="w-100 mt-4">
                <Carousel
                    arrows={true}
                    swipeable={false}
                    draggable={false}
                    showDots={false}
                    responsive={responsive}
                    // ssr={true} // means to render carousel on server-side.
                    infinite={true}
                    autoPlay={false}
                    autoPlaySpeed={1000}
                    customLeftArrow={<CustomLeftArrow setCurrentSlideIndex={(value) => setCurrentSlideIndex(value)} />}
                    customRightArrow={<CustomRightArrow setCurrentSlideIndex={(value) => setCurrentSlideIndex(value)} />}
                >
                    {carouselContent.map((content) => (
                        <img className="mobile-tab-carousel-image" src={content.image} alt={content.type} />
                    ))}
                </Carousel>
            </div>
            <div className="d-flex justify-content-center align-items-center mt-4">
                <MobileButton
                    text="View|More Vehicles"
                    handleClick={handleViewMoreVehicles}
                />
            </div>
        </div>
    )
};

export default MobileCarouselTab;