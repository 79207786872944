// ForgotPasswordModal.js
import React, { useState } from 'react';
import Modal from 'react-modal';
import { useFormik } from 'formik';
import axios from 'axios';

Modal.setAppElement('#root'); // Set the root element for accessibility

const ForgotPasswordModal = ({ isOpen, onRequestClose }) => {
  const [step, setStep] = useState(1);

  const formik = useFormik({
    initialValues: {
      email: '',
      otp: '',
      newPassword: '',
      confirmPassword: '',
    },
    onSubmit: async (values) => {
      try {
        if (step === 1) {
          // Step 1: Request OTP
          await axios.post('http://localhost:4001/v2/auth/forgot-password/request-otp', { email: values.email });
          setStep(2); // Move to next step for OTP verification
        } else if (step === 2) {
          // Step 2: Verify OTP and set new password
          await axios.post('http://localhost:4001/v2/auth/forgot-password/verify-otp', values);
          // Display a success message or redirect to a confirmation page
          console.log('Password reset successful');
          onRequestClose();
        }
      } catch (error) {
        console.error('Error during password reset:', error);
      }
    },
  });

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Forgot Password Modal"
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
        content: {
          maxWidth: '400px',
          margin: 'auto',
          padding: '20px',
          border: '1px solid #ccc',
          borderRadius: '8px',
          boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
        },
      }}
    >
      <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>
        {step === 1 ? 'Forgot Password' : 'Verify OTP and Set New Password'}
      </h2>
      <form style={{ display: 'flex', flexDirection: 'column' }} onSubmit={formik.handleSubmit}>
        {step === 1 && (
          <div className="d-flex flex-column">
            <label>Email:</label>
            <input
              type="email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              style={{ padding: '8px', marginBottom: '15px', borderRadius: '4px', border: '1px solid #ccc', width: '100%' }}
            />
          </div>
        )}
        {step === 2 && (
          <div className="d-flex flex-column">
            <label>OTP:</label>
            <input
              type="text"
              name="otp"
              value={formik.values.otp}
              onChange={formik.handleChange}
              style={{ padding: '8px', marginBottom: '15px', borderRadius: '4px', border: '1px solid #ccc', width: '100%' }}
            />
          </div>
        )}
        <div className="d-flex flex-column">
          <label>New Password:</label>
          <input
            type="password"
            name="newPassword"
            value={formik.values.newPassword}
            onChange={formik.handleChange}
            style={{ padding: '8px', marginBottom: '15px', borderRadius: '4px', border: '1px solid #ccc', width: '100%' }}
          />
        </div>
        <div className="d-flex flex-column">
          <label>Confirm Password:</label>
          <input
            type="password"
            name="confirmPassword"
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            style={{ padding: '8px', marginBottom: '15px', borderRadius: '4px', border: '1px solid #ccc', width: '100%' }}
          />
        </div>
        <button
          className='reset-password-btn'
          type="submit"
          style={{
            color: 'white',
            backgroundColor: 'blue',
            padding: '10px',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
          }}
        >
          {step === 1 ? 'Request OTP' : 'Reset Password'}
        </button>
      </form>
    </Modal>
  );
};

export default ForgotPasswordModal;
