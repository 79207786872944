import React from 'react';
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import BlogDetailPage from '../Components/BlogDetailPage';

const BlogDetails = () => {
    return (
        <>
            <BlogDetailPage />
        </>
    )
}

export default BlogDetails;
