import axios from "axios";
import { useFormik } from "formik";
import moment from "moment";

import React, { useEffect, useMemo, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Fade from "react-reveal/Fade";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import { BASE_URL_FLEETOS } from "../../repository/api";
import Button from "../CustomComponent/Button";
import CustomDropDown from "../CustomComponent/CustomSelectField";
import InputField from "../CustomComponent/InputField";
import "./Form.css";

const validationSchema = Yup.object().shape({
  legalBusinessName: Yup.string().required("Legal Business Name is required"),
  companyPAN: Yup.string()
    .matches(/^([A-Za-z]){5}([0-9]){4}([A-Za-z]){1}$/, "Invalid PAN Number")
    .required("Company PAN is required"),
  registrationNumber: Yup.string().required("Registration Number is required"),
  companyWebsite: Yup.string().required("Company Website is required"),
  officialPhoneNumber: Yup.string()
    .matches(/^[0-9]{10}$/, "Invalid phone number")
    .required("Official Phone Number is required"),
  respondantName: Yup.string().required("Respondant Name is required"),
  respondantEmailID: Yup.string()
    .email("Invalid email")
    .required("Respondant Email ID is required"),
  RespondantDesignation: Yup.string().required(
    "Respondant Designation is required"
  ),
  NatureOfBussiness: Yup.string().required("Nature Of Bussiness is required"),
  EntityType: Yup.string().required("Entity Type is required"),
  RegisteredAddress: Yup.string().required("Registered Address is required"),
  CorporateAddress: Yup.string().required("Corporate Address is required"),
  DateofIncorporation: Yup.mixed().required(
    "Date of Incorporation is required"
  ),
  ComencementofOperations: Yup.string().required(
    "Comencement of Operations is required"
  ),
});

const Form1 = ({ handleBack, handleNext, isValid }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const EntityType = useMemo(
    () => [
      { value: "Proprietorship", label: "Proprietorship" },
      { value: "Partnership", label: "Partnership" },
      { value: "LLP", label: "LLP" },
      { value: "Pvt Ltd", label: "Pvt Ltd" },
      { value: "Others", label: "Others" },
    ],
    []
  );
  const NatureOfBussiness = useMemo(
    () => [
      { value: "B2B Service Provider", label: "B2B Service Provider" },
      { value: "B2C Service Provider", label: "B2C Service Provider" },
      { value: "Others", label: "Others" },
    ],
    []
  );

  const fetchDataFromAPI = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL_FLEETOS}/v1/lease-application/${id}`
      );
      setData(response.data);
    } catch (error) {
      setError(error);
    } finally {
    }
  };

  useEffect(() => {
    if (id) {
      fetchDataFromAPI();
    }
  }, [id]);

  const initialValues = {
    legalBusinessName: data?.business_name ?? "",
    companyPAN: data?.company_pan ?? "",
    registrationNumber: data?.registration_no ?? "",
    companyWebsite: data?.website_link ?? "",
    officialPhoneNumber: data?.phone_number ?? "",
    respondantName: data?.respondant_name ?? "",
    respondantEmailID: data?.respondant_email ?? "",
    EntityType: data?.entity_type ?? "",
    RespondantDesignation: data?.respondant_designation ?? "",
    NatureOfBussiness: data?.nature_of_business ?? "",
    RegisteredAddress: data?.registered_address ?? "",
    CorporateAddress: data?.corporate_address ?? "",
    DateofIncorporation:
      moment(data?.date_of_incorporation).format("YYYY-MM-DD") ?? "",
    ComencementofOperations:
      moment(data?.comencement_of_operations).format("YYYY-MM-DD") ?? "",
    more_than_3_years_status: id ? data?.more_than_3_years_status : false,
    legal_status: data?.legal_status ?? false,
    legal_suit_details: data?.legal_suit_details ?? "",
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        if (values.legal_status === true && values.legal_suit_details === "") {
          toast.error("Legal suit details are required.");
          setLoading(false);
          return;
        }
        const Form1Data = {
          ...(id && { lease_application_id: id }),
          business_name: values.legalBusinessName,
          company_pan: values.companyPAN,
          registration_no: values.registrationNumber,
          website_link: values.companyWebsite,
          phone_number: values.officialPhoneNumber,
          respondant_name: values.respondantName,
          respondant_email: values.respondantEmailID,
          respondant_designation: values.RespondantDesignation,
          nature_of_business: values.NatureOfBussiness,
          entity_type: values.EntityType,
          registered_address: values.RegisteredAddress,
          corporate_address: values.CorporateAddress,
          date_of_incorporation: moment(
            new Date(values.DateofIncorporation)
          ).format("YYYY-MM-DDTHH:mm:ss[Z]"),
          comencement_of_operations: moment(
            new Date(formik.values.ComencementofOperations)
          ).format("YYYY-MM-DDTHH:mm:ss[Z]"),
          more_than_3_years_status: values.more_than_3_years_status,
          legal_status: values.legal_status,
          ...((values.legal_status === true ||
            values.legal_suit_details !== "") && {
            legal_suit_details: values.legal_suit_details,
          }),
        };
        const response = await axios.post(
          `${BASE_URL_FLEETOS}/v1/lease-application`,
          Form1Data
        );
        handleNext(); 
        navigate(`/editleaseapplicationform/${response.data.id}`, {
          state: {
            step: 1, // Update to step 1 for Form2
          },
        });
        setLoading(false);
      } catch (error) {
        console.error("Error:", error.message);
        setLoading(false);
      }
    },
  });
  
  return (
    <div>
      <form
        onSubmit={formik.handleSubmit}
        className="d-flex flex-column gap-5 form1-date-clas"
      >
        <div className="input-width">
          <InputField
            label="Legal Business Name"
            type="text"
            className="w-100 i"
            required
            id="legalBusinessName"
            name="legalBusinessName"
            placeholder="Enter Your Business name"
            value={formik.values.legalBusinessName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.legalBusinessName &&
              formik.errors.legalBusinessName
            }
          />
        </div>
        <div className="d-flex  flex-column flex-md-row justify-content-between gap-4">
          <InputField
            label="Company PAN"
            type="text"
            className="w-100 "
            required
            id="companyPAN"
            name="companyPAN"
            placeholder="Enter Your Company PAN"
            value={formik.values.companyPAN}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.companyPAN && formik.errors.companyPAN}
          />
          <InputField
            label="Registration Number(CIN)"
            type="text"
            className="w-100 "
            required
            id="registrationNumber"
            name="registrationNumber"
            placeholder="Enter Registration Number"
            value={formik.values.registrationNumber}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.registrationNumber &&
              formik.errors.registrationNumber
            }
          />
        </div>
        <div className="d-flex flex-column flex-md-row justify-content-between gap-4 input-width">
          <InputField
            label="Company Website"
            type="text"
            required
            className="w-100 "
            id="companyWebsite"
            name="companyWebsite"
            placeholder="Enter Your Company Website"
            value={formik.values.companyWebsite}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.companyWebsite && formik.errors.companyWebsite
            }
          />
          <InputField
            label="Official Phone Number"
            type="text"
            className="w-100 "
            required
            id="officialPhoneNumber"
            name="officialPhoneNumber"
            placeholder="Enter Your Official Phone Number"
            value={formik.values.officialPhoneNumber}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.officialPhoneNumber &&
              formik.errors.officialPhoneNumber
            }
          />
        </div>
        <div className="d-flex flex-column flex-md-row justify-content-between gap-4 input-width">
          <InputField
            label="Respondant Name"
            type="text"
            className="w-100 "
            required
            id="respondantName"
            name="respondantName"
            placeholder="Enter Respondant Name"
            value={formik.values.respondantName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.respondantName && formik.errors.respondantName
            }
          />

          <InputField
            label="Respondant Email ID"
            type="email"
            className="w-100 "
            id="respondantEmailID"
            required
            name="respondantEmailID"
            placeholder="Enter Respondant Email ID"
            value={formik.values.respondantEmailID}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.respondantEmailID &&
              formik.errors.respondantEmailID
            }
          />
        </div>
        <div className="input-width flex-column flex-md-row">
          <InputField
            label="Respondant Designation"
            type="text"
            className="w-100 "
            id="RespondantDesignation"
            required
            name="RespondantDesignation"
            placeholder="Enter Respondant Designation"
            value={formik.values.RespondantDesignation}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.RespondantDesignation &&
              formik.errors.RespondantDesignation
            }
          />
        </div>
        <div className="d-flex flex-column flex-md-row justify-content-between gap-4 w-100 ">
          <CustomDropDown
            options={NatureOfBussiness}
            className="full-width  w-100"
            required
            value={{
              label: formik.values.NatureOfBussiness,
              value: formik.values.NatureOfBussiness,
            }}
            title="Nature of Business"
            titleClass="custom-dropdown-style"
            onChange={async (option) => {
              formik.setFieldValue("NatureOfBussiness", option?.value);
            }}
            onBlur={formik.handleBlur("NatureOfBussiness")}
            placeholder={"Select Nature of Business"}
            error={
              formik.touched.NatureOfBussiness &&
              formik.errors.NatureOfBussiness
            }
          />
          <CustomDropDown
            options={EntityType}
            className="full-width  w-100"
            required
            value={{
              label: formik.values.EntityType,
              value: formik.values.EntityType,
            }}
            title="Entity Type"
            titleClass="custom-dropdown-style"
            onChange={async (option) => {
              formik.setFieldValue("EntityType", option?.value);
            }}
            onBlur={formik.handleBlur("EntityType")}
            placeholder={"Select Entity Type"}
            error={formik.touched.EntityType && formik.errors.EntityType}
          />
        </div>
        <div className="d-flex flex-column flex-md-row justify-content-between gap-4 input-width">
          <InputField
            label="Registered Address of the company"
            type="text"
            className="w-100"
            required
            id="RegisteredAddress"
            name="RegisteredAddress"
            placeholder="Enter Registered Address"
            value={formik.values.RegisteredAddress}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.RegisteredAddress &&
              formik.errors.RegisteredAddress
            }
          />
          <InputField
            label="Corporate Address of the company"
            required
            type="text"
            className="w-100 "
            id="CorporateAddress"
            name="CorporateAddress"
            placeholder="Enter Corporate Address"
            value={formik.values.CorporateAddress}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.CorporateAddress && formik.errors.CorporateAddress
            }
          />
        </div>
        <div className="d-flex flex-column flex-md-row justify-content-between gap-4 ">
          {/* <InputField
            label="Date of Incorporation"
            required
            type="date"
            className="w-100 "
            id="session-date"
            name="DateofIncorporation"
            placeholder="Enter Date of Incorporation"
            value={formik.values.DateofIncorporation}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.DateofIncorporation &&
              formik.errors.DateofIncorporation
            }
          /> */}

          <div className="w-100">
            <label>Date of Incorporation </label>
            <span style={{ color: "red" }}>*</span>
            <DatePicker
              selected={
                formik.values.DateofIncorporation
                  ? new Date(formik.values.DateofIncorporation)
                  : null
              }
              onChange={(date) =>
                formik.setFieldValue(
                  "DateofIncorporation",
                  date ? date.toISOString() : null
                )
              }
              onBlur={formik.handleBlur("DateofIncorporation")}
              dateFormat="yyyy-MM-dd"
              placeholderText="Select Date of Incorporation"
              className="w-100"
              showYearDropdown
              showMonthDropdown
              dropdownMode="select"
              isClearable
            />
            {formik.touched.DateofIncorporation &&
              formik.errors.DateofIncorporation && (
                <div className="error-text">
                  {formik.errors.DateofIncorporation}
                </div>
              )}
          </div>

          {/* <InputField
            label="Comencement of Operations"
            type="date"
            className="w-100 "
            required
            id="session-date"
            name="ComencementofOperations"
            placeholder="Enter Comencement of Operations"
            value={formik.values.ComencementofOperations}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.ComencementofOperations &&
              formik.errors.ComencementofOperations
            }
          /> */}
          <div className="w-100">
            <label>
              Comencement of Operations<span style={{ color: "red" }}>*</span>
            </label>
            <DatePicker
              selected={
                formik.values.ComencementofOperations
                  ? new Date(formik.values.ComencementofOperations)
                  : null
              }
              onChange={(date) =>
                formik.setFieldValue(
                  "ComencementofOperations",
                  date ? date.toISOString() : null
                )
              }
              onBlur={formik.handleBlur("ComencementofOperations")}
              dateFormat="dd-MM-yyyy"
              placeholderText="Select Comencement of Operations"
              className="w-100"
              showYearDropdown
              showMonthDropdown
              dropdownMode="select"
              isClearable
            />
            {formik.touched.ComencementofOperations &&
              formik.errors.ComencementofOperations && (
                <div className="error-text">
                  {formik.errors.ComencementofOperations}
                </div>
              )}
          </div>
        </div>
        <label>Is the company more than 3 yrs old?</label>

        <div className="d-flex gap-3">
          <input
            type="radio"
            name="Isthecompanyold"
            value="true"
            onChange={() => {
              formik.setValues({
                ...formik.values,
                more_than_3_years_status: true,
              });
            }}
            onBlur={formik.handleBlur}
            checked={formik.values.more_than_3_years_status === true}
          />
          <label htmlFor="Yes">Yes</label>
          <input
            type="radio"
            name="Isthecompanyold"
            value="false"
            onChange={() => {
              formik.setValues({
                ...formik.values,
                more_than_3_years_status: false,
              });
            }}
            onBlur={formik.handleBlur}
            checked={formik.values.more_than_3_years_status === false}
          />
          <label htmlFor="No">No</label>
        </div>
        <div className="d-flex justify-content-between gap-4 ">
          <div className="d-flex flex-column gap-5 w-100">
            <label>
              Are there any legal suits filed by/​against the company?
            </label>
            <div className="d-flex gap-3">
              <input
                type="radio"
                name="legalsuits"
                value="true"
                onChange={() => {
                  formik.setValues({
                    ...formik.values,
                    legal_status: true,
                  });
                }}
                onBlur={formik.handleBlur}
                checked={formik.values.legal_status === true}
              />
              <label htmlFor="Yes">Yes</label>
              <input
                type="radio"
                name="legalsuits"
                value="false"
                onChange={() => {
                  formik.setValues({
                    ...formik.values,
                    legal_status: false,
                  });
                }}
                onBlur={formik.handleBlur}
                checked={formik.values.legal_status === false}
              />
              <label htmlFor="No">No</label>
            </div>
          </div>

          {formik.values.legal_status && (
            <div className="w-100">
              <Fade>
                <textarea
                  label="Kindly provide details about the legal suit."
                  type="text"
                  className="w-100 p-2"
                  required
                  id="legal_suit_details"
                  name="legal_suit_details"
                  placeholder="Enter legal suit details"
                  value={formik.values.legal_suit_details}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.legal_suit_details &&
                    formik.errors.legal_suit_details
                  }
                  style={{ height: "100px", outline: "none" }}
                />
              </Fade>
            </div>
          )}
        </div>

        <div className="d-flex gap-3 justify-content-center">
          <Button
            label="Next"
            type="submit"
           
            disabled={!formik.isValid}
            loading={loading}
          />
        </div>
      </form>
    </div>
  );
};

export default Form1;
