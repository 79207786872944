import React from "react";
import Header from "../Components/Header";
import AboutMain from "../Components/AboutMain";
import Footer from "../Components/Footer";

const About = () => {
  return (
    <div className="home-mainss">
   
      <AboutMain />
   
    </div>
  );
};

export default About;
