import React from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import PartnersPage from "../Components/PartnersPage";

const Partners = () => {
    return (
        <div className="home-mainss" style={{paddingTop:"7rem"}}>
            {/* <Header /> */}
            <PartnersPage />
            {/* <Footer /> */}
        </div>
    );
};

export default Partners;
