import React from 'react';
import "./Process.css";

export default function Process () {
  return (
    <div className='Individual-Process'>
    <section className="mb-max pb-5 pt-max bg-policy">
      <div className="container d-flex flex-column " style={{gap:"60px"}}>
        <h2 className="h2-heading text-center d-block ">Our Process</h2>
        <div className="row">
          <div className="col-lg-4">
            <div className="services-card card">
              <div className="card-img position-relative">
                <img src="./individule_files/1.png" alt="" className="img-fluid" />
                <img src="./individule_files/doument.png" alt="" className="img-fluid count" />
              </div>
              <div>
              <h5 className="h5-heading" style={{fontWeight:"600"}}>Submit your Fleet Requirement &amp; start with your journey</h5>
              <p className="para">
                Select the vehicle make, model, lease tenure and fleet size on the website or FleetOS platform.
              </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="services-card card">
              <div class="card-img position-relative">
                <img src="./individule_files/3.png" alt="" class="img-fluid" />
                <img
                  src="./individule_files/kyc.svg"
                  alt=""
                  class="img-fluid count"
                />
              </div>
              <h5 class="h5-heading" style={{fontWeight:"600"}}>Submit your KYC or Company Information</h5>
              <p class="para">
                Sign in to the FleetOS platform, submit your company information
                in a paperless process, and get approval within 48 hours.
              </p>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="services-card card">
              <div class="card-img position-relative">
                <img src="./individule_files/4.png" alt="" class="img-fluid" />
                <img
                  src="./individule_files/pay.svg"
                  alt=""
                  class="img-fluid count"
                />
              </div>
              <h5 class="h5-heading" style={{fontWeight:"600"}}>
                Pay Security Deposit &amp; Activate Auto-Payment
              </h5>
              <p class="para">
                Sign the Agreement, pay the security deposit, and activate NACH
                for the monthly lease payment.
              </p>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="services-card card">
              <div class="card-img position-relative">
                <img src="./individule_files/5.png" alt="" class="img-fluid" />
                <img
                  src="./individule_files/deliver.svg"
                  alt=""
                  class="img-fluid count"
                />
              </div>
              <h5 class="h5-heading" style={{fontWeight:"600"}}>
                Vehicles Delivered to Nearest Dealership
              </h5>
              <p class="para">
                Your vehicles will be delivered to the nearest authorised dealer
                in your location.
              </p>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="services-card card">
              <div class="card-img position-relative">
                <img
                  src="./individule_files/5 (1).png"
                  alt=""
                  class="img-fluid"
                />
                <img
                  src="./individule_files/maintenance.svg"
                  alt=""
                  class="img-fluid count"
                />
              </div>
              <h5 class="h5-heading" style={{fontWeight:"600"}}>Periodic Service and Maintenance</h5>
              <p class="para">
                Get timely service &amp; recommendations to improve your fleet's
                health and performance.
              </p>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="services-card card">
              <div class="card-img position-relative">
                <img src="./individule_files/6.png" alt="" class="img-fluid" />
                <img
                  src="./individule_files/return_lease.svg"
                  alt=""
                  class="img-fluid count"
                />
              </div>
              <h5 class="h5-heading"style={{fontWeight:"600"}}>
                Return or Extend Lease as per your preference
              </h5>
              <p class="para">
                Select the vehicle make, model, lease tenure and fleet size on
                the website or FleetOS platform.Choose to either return or
                extend your vehicle's lease at the end of the tenure.
              </p>
            </div>
          </div>
          {/* Repeat the above block for other service cards */}
        </div>
      </div>

      {/* <div className="container">
        <img
          src="./individule_files/Group 2.svg"
          className="chatbot-ui"
          alt="Chatbot"
          onClick={redirectWhatsAppGroup}
        />
      </div> */}
    </section>
    </div>
  );
};