export const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

// if the url is not embed then form correct URL
export const handleEmbedVideoURL = (url) => {
    // if YouTube video
    let videoId = "";
    if(url.includes("youtube")) {
        videoId = url.includes("watch") 
                    ? url.split("?v=")?.[1]
                    : url.split("embed/")?.[1];
    }
    return `https://www.youtube.com/embed/${videoId}?autoplay=1&mute=1`; // to make the video autoplay
};

// check whether an object is empty
export const isEmpty = (value) => {
    if(value === "" || !value || value.length === 0 || Object.keys(value).length === 0) {
        return true;
    } else {
        return false;
    }
};