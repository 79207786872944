import React, { useState } from "react";
import Carousel from "react-bootstrap/Carousel";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./HomeSlider.css";

export default function HomeSlider() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     // Cycle through 1, 2, 3, 4, 4, 3, 2, 1
  //     setActiveBox((prevBox) => (prevBox % 4) + 1);
  //   }, 4000);

  //   // Cleanup the interval on component unmount
  //   return () => clearInterval(intervalId);
  // }, []);

  const [activeBox, setActiveBox] = useState(1);

  const handleNextClick = () => {
    setActiveBox((prevBox) => (prevBox % 4) + 1);
  };
  const handlePrevClick = () => {
    setActiveBox((prevBox) => ((prevBox - 2 + 4) % 4) + 1);
  };

  return (
    <>
      <div
        className="container text-center  pt-5  d-lg-block d-none"
        style={{ background: "#E7F8F2" }}
      >
        <div className="home-slider-contant ">
          <h2 className="h2-heading " style={{ lineHeight: "60px!important" }}>
            Your one-stop fleet
            <div>electrification partner</div>
          </h2>
          <p className="para " style={{ marginTop: "15px" }}>
            Our integrated fleet lifecycle management solution enables fleet
            partners to operate reliable, fully managed experience providing
            high fleet uptime at affordable costs.
          </p>
        </div>
        <div className="d-flex justify-content-between ">
          <div
            className="col-lg-6 col-12 col-md-12 col-sm-12 "
            style={{ padding: "5rem 0rem 5rem 5rem" }}
          >
            <div className="col-12 col-lg-6 col-md-12 col-sm-12 pe-5 ">
              <div
                id="square"
                className="d-none"
                style={{
                  transform: "rotate(-180deg)",
                  transformOrigin: "50% 50%",
                }}
              ></div>
              <div className="box-warp-slider" id="layer-1">
                <div
                  className={`box-3 ${activeBox === 1 ? "active" : ""}`}
                  style={{
                    transform:
                      activeBox === 2
                        ? "translate(-200px, -195px)"
                        : activeBox === 3
                        ? "translate(-400px, 0px)"
                        : activeBox === 4
                        ? "translate(-200px, 195px)"
                        : "",
                    transition: "all 0.5s ease 0s",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    viewBox="0 0 25 25"
                    fill="none"
                  >
                    <path
                      opacity="0.4"
                      d="M12.0129 21.7327C17.2596 21.7327 21.5129 17.4794 21.5129 12.2327C21.5129 6.98596 17.2596 2.73267 12.0129 2.73267C6.76623 2.73267 2.51294 6.98596 2.51294 12.2327C2.51294 17.4794 6.76623 21.7327 12.0129 21.7327Z"
                      fill="#4A5863"
                    ></path>
                    <path
                      d="M21.813 22.7327C21.633 22.7327 21.453 22.6627 21.323 22.5327L19.463 20.6727C19.193 20.4027 19.193 19.9627 19.463 19.6827C19.733 19.4127 20.173 19.4127 20.453 19.6827L22.313 21.5427C22.583 21.8127 22.583 22.2527 22.313 22.5327C22.173 22.6627 21.993 22.7327 21.813 22.7327Z"
                      fill="#4A5863"
                    ></path>
                  </svg>
                  <p>Vehicle Selection</p>
                </div>
                <div
                  className={`box-4 ${activeBox === 2 ? "active " : ""}`}
                  style={{
                    transform:
                      activeBox === 2
                        ? "translate(200px, -195px)"
                        : activeBox === 3
                        ? "translate(0px, -400px)"
                        : activeBox === 4
                        ? "translate(-200px, -195px)"
                        : "",
                    transition: "all 0.5s ease 0s",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    viewBox="0 0 25 25"
                    fill="none"
                  >
                    <path
                      opacity="0.4"
                      d="M22.5129 12.1985C22.5129 17.2385 18.7729 21.4085 13.9229 22.0985C13.4629 22.1685 12.9929 22.1985 12.5129 22.1985C9.75294 22.1985 7.25293 21.0785 5.44293 19.2685C3.63293 17.4585 2.51294 14.9585 2.51294 12.1985C2.51294 6.67849 6.99294 2.19849 12.5129 2.19849C15.2729 2.19849 17.7729 3.31849 19.5829 5.12849C21.3929 6.93849 22.5129 9.43849 22.5129 12.1985Z"
                      fill="#4A5863"
                    ></path>
                    <path
                      d="M15.3429 10.4287L12.8629 12.9087L19.3929 19.4387C19.0329 19.7887 18.6428 20.1087 18.2328 20.3987L11.8029 13.9687L9.33289 16.4387L14.4529 21.5687C14.5629 21.6787 14.6329 21.8187 14.6629 21.9587C14.6629 21.9587 14.6629 21.9587 14.6629 21.9687C14.4229 22.0187 14.1729 22.0687 13.9229 22.0987C13.6029 22.1487 13.2829 22.1787 12.9529 22.1887L8.27289 17.4987L5.99286 19.7787C5.80286 19.6187 5.62287 19.4487 5.44287 19.2687C5.26287 19.0887 5.09286 18.9087 4.93286 18.7187L19.0329 4.61865C19.2229 4.77865 19.4029 4.94865 19.5829 5.12865C19.7629 5.30865 19.9329 5.48865 20.0929 5.67865L16.4029 9.36865L22.0929 15.0587C21.9329 15.5887 21.7329 16.0887 21.4929 16.5787L15.3429 10.4287Z"
                      fill="#4A5863"
                    ></path>
                  </svg>
                  <p>Fleet Lease</p>
                </div>
                <div
                  className={`box-1 ${activeBox === 3 ? "active" : ""}`}
                  style={{
                    transform:
                      activeBox === 2
                        ? "translate(200px, 195px)"
                        : activeBox === 3
                        ? "translate(400px, 0px)"
                        : activeBox === 4
                        ? "translate(200px, -195px)"
                        : "",
                    transition: "all 0.5s ease 0s",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    viewBox="0 0 25 25"
                    fill="none"
                  >
                    <path
                      d="M7.51294 8.19849H4.51294C3.41294 8.19849 2.51294 7.29849 2.51294 6.19849V4.19849C2.51294 3.09849 3.41294 2.19849 4.51294 2.19849H7.51294C8.61294 2.19849 9.51294 3.09849 9.51294 4.19849V6.19849C9.51294 7.29849 8.61294 8.19849 7.51294 8.19849Z"
                      fill="#4A5863"
                    ></path>
                    <path
                      d="M21.3129 7.19849H17.7129C17.0529 7.19849 16.5129 6.65847 16.5129 5.99847V4.3985C16.5129 3.7385 17.0529 3.19849 17.7129 3.19849H21.3129C21.9729 3.19849 22.5129 3.7385 22.5129 4.3985V5.99847C22.5129 6.65847 21.9729 7.19849 21.3129 7.19849Z"
                      fill="#4A5863"
                    ></path>
                    <path
                      d="M21.3129 14.6985H17.7129C17.0529 14.6985 16.5129 14.1585 16.5129 13.4985V11.8985C16.5129 11.2385 17.0529 10.6985 17.7129 10.6985H21.3129C21.9729 10.6985 22.5129 11.2385 22.5129 11.8985V13.4985C22.5129 14.1585 21.9729 14.6985 21.3129 14.6985Z"
                      fill="#4A5863"
                    ></path>
                    <path
                      opacity="0.37"
                      d="M16.5129 13.4485C16.9229 13.4485 17.2629 13.1085 17.2629 12.6985C17.2629 12.2885 16.9229 11.9485 16.5129 11.9485H13.7629V5.94849H16.5129C16.9229 5.94849 17.2629 5.60849 17.2629 5.19849C17.2629 4.78849 16.9229 4.44849 16.5129 4.44849H9.51294C9.10294 4.44849 8.76294 4.78849 8.76294 5.19849C8.76294 5.60849 9.10294 5.94849 9.51294 5.94849H12.2629V18.1985C12.2629 19.7185 13.4929 20.9485 15.0129 20.9485H16.5129C16.9229 20.9485 17.2629 20.6085 17.2629 20.1985C17.2629 19.7885 16.9229 19.4485 16.5129 19.4485H15.0129C14.3229 19.4485 13.7629 18.8885 13.7629 18.1985V13.4485H16.5129Z"
                      fill="#4A5863"
                    ></path>
                    <path
                      d="M21.3129 22.1985H17.7129C17.0529 22.1985 16.5129 21.6585 16.5129 20.9985V19.3985C16.5129 18.7385 17.0529 18.1985 17.7129 18.1985H21.3129C21.9729 18.1985 22.5129 18.7385 22.5129 19.3985V20.9985C22.5129 21.6585 21.9729 22.1985 21.3129 22.1985Z"
                      fill="#4A5863"
                    ></path>
                  </svg>
                  <p> Fleet OS</p>
                </div>
                <div
                  className={`box-2 ${activeBox === 4 ? "active" : ""}`}
                  style={{
                    transform:
                      activeBox === 2
                        ? "translate(-200px, 195px)"
                        : activeBox === 3
                        ? "translate(0px, 400px)"
                        : activeBox === 3
                        ? "translate(-400px, 0px)"
                        : activeBox === 4
                        ? "translate(200px, 195px)"
                        : "",
                    transition: "all 0.5s ease 0s",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    viewBox="0 0 25 25"
                    fill="none"
                  >
                    <path
                      opacity="0.4"
                      d="M10.7528 15.4726C10.4228 15.1426 9.98281 14.9526 9.51281 14.9526H4.43281C3.82281 14.9526 3.26281 15.2626 2.94281 15.7826C2.62281 16.2926 2.59281 16.9026 2.85281 17.4426C4.08281 19.9626 6.30281 21.9426 8.94281 22.8726C9.12281 22.9326 9.32281 22.9726 9.51281 22.9726C9.86281 22.9726 10.2128 22.8626 10.5128 22.6526C10.9828 22.3226 11.2628 21.7826 11.2628 21.2126L11.2728 16.7126C11.2628 16.2426 11.0828 15.8026 10.7528 15.4726Z"
                      fill="#4A5863"
                    ></path>
                    <path
                      d="M22.9929 10.3327C21.8729 5.41267 17.5629 1.98267 12.5129 1.98267C7.46287 1.98267 3.15287 5.41267 2.03287 10.3327C1.91287 10.8527 2.03287 11.3827 2.37287 11.8027C2.71287 12.2227 3.21287 12.4627 3.75287 12.4627H21.2829C21.8229 12.4627 22.3229 12.2227 22.6629 11.8027C22.9929 11.3827 23.1129 10.8427 22.9929 10.3327Z"
                      fill="#4A5863"
                    ></path>
                    <path
                      opacity="0.4"
                      d="M22.0729 15.8327C21.7529 15.3127 21.1929 14.9927 20.5729 14.9927L15.5129 14.9827C15.0429 14.9827 14.6029 15.1627 14.2729 15.4927C13.9429 15.8227 13.7629 16.2627 13.7629 16.7327L13.7729 21.2127C13.7729 21.7827 14.0529 22.3227 14.5229 22.6527C14.8229 22.8627 15.1729 22.9727 15.5229 22.9727C15.7129 22.9727 15.9029 22.9427 16.0829 22.8727C18.7029 21.9527 20.9229 19.9827 22.1529 17.4927C22.4129 16.9627 22.3829 16.3427 22.0729 15.8327Z"
                      fill="#4A5863"
                    ></path>
                  </svg>
                  <p> Integrated Service</p>
                </div>
              </div>
            </div>
          </div>
          <div className=" homeSlide-side mt-5 col-lg-6 col-12 col-md-12 col-sm-12 d-flex flex-column">
            <div className="row overflow-hidden d-flex carousel-container">
              <div
                className="carousel-wrapper"
                style={{ transform: `translateX(-${(activeBox - 1) * 100}%)` }}
              >
                <div
                  className="position-relative text-start carousel-slide"
                  style={{ maxWidth: "620px" }}
                >
                  <h3 className="h3-heading fw-bold ">Vehicle Selection</h3>
                  <p className="para homeSlide-side-body">
                    Simplify your vehicle selection by choosing among multiple
                    curated vehicle models evaluated by our experts in 100+
                    parameters. Select the right vehicle for your operations
                    based on on-road range, payload, volume, charging time etc.
                  </p>
                  <img src="./home_files/1.png" alt="" className="number-img" />
                </div>
                <div
                  className="position-relative text-start carousel-slide"
                  style={{ maxWidth: "620px" }}
                >
                  <h3 className="h3-heading fw-bold ">Fleet Lease</h3>
                  <p className="para homeSlide-side-body">
                    Switch your fleet to electric with no upfront cost. Pay a
                    small security deposit and monthly lease payment that covers
                    your entire vehicle cost at 20% lower than bank EMI.
                  </p>
                  <img
                    src="./home_files/image (2).png"
                    alt=""
                    class="number-img"
                  />
                </div>
                <div
                  className="position-relative text-start carousel-slide"
                  style={{ maxWidth: "620px" }}
                >
                  <h3 className="h3-heading fw-bold ">Fleet OS</h3>
                  <p className="para homeSlide-side-body">
                    Monitor fleet and battery health, receive driving
                    recommendations, preventive diagnostics and alerts to
                    improve your vehicle uptime and utilization.
                  </p>
                  <div className="d-flex justify-content-between">
                    <a
                      href="/fleet"
                      class="green-btn text-decoration-none d-inline-block mt-2 "
                    >
                      <span class="d-flex">
                        <span class="learn-more">Learn More </span>{" "}
                        <img
                          class="mt-1 ml-1 learn-icon"
                          src="./images/chevron-right.svg"
                          height="13"
                        />
                      </span>
                    </a>
                    <img
                      src="./home_files/3.png"
                      alt=""
                      class=" number-img-3"
                    />
                  </div>
                </div>
                <div
                  className="position-relative text-start carousel-slide"
                  style={{ maxWidth: "620px" }}
                >
                  <h3 className="h3-heading fw-bold ">Integrated Service</h3>
                  <p className="para homeSlide-side-body">
                    Say goodbye to multiple platforms with our all-in-one fleet
                    management solution. Easily make insurance claims, find
                    service garages, schedule appointments, subscribe to
                    parking/charging hubs, and ease your vehicle management.
                  </p>
                  <img src="./home_files/4.png" alt="" class=" number-img" />
                </div>
              </div>
            </div>
            <div className="Slide-control-button">
              <div className="d-flex gap-3 owl-nav">
                <button
                  type="button"
                  role="presentation"
                  className=" homeSlider-arrow-button"
                  onClick={handlePrevClick}
                >
                  <span aria-label="Previous">
                    <img src="./images/chevron-left_black.svg" height="13" />
                  </span>
                </button>
                <button
                  type="button"
                  role="presentation"
                  className=" homeSlider-arrow-button"
                  onClick={handleNextClick}
                >
                  <span aria-label="Next">
                    <img src="./images/chevron_right_black.svg" height="13" />
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Moile Home Slider */}
      <div
        className="container d-lg-none d-block"
        style={{ background: "#E7F8F2" }}
      >
        <div className="home-slider-contant text-center mt-40 ">
          <h2 className="h2-heading ">
            Your one-stop fleet electrification partner
          </h2>
          <p className="para ">
            Our integrated fleet lifecycle management solution enables fleet
            partners to operate reliable, fully managed experience providing
            high fleet uptime at affordable costs.
          </p>
        </div>
        <div className="home-mobileslider owl-carousel owl-theme owl-loaded owl-drag owl-hidden">
          <div className="owl-stage-outer">
            <div
              className="owl-stage d-flex overflow-hidden"

              // style={{transform: "translate3d(-804px, 0px, 0px)", transition: "all 0s ease 0s", width: "3216px"}}
            >
              <Carousel
                interval={100000000}
                activeIndex={index}
                onSelect={handleSelect}
                prevIcon={
                  <button
                    type="button"
                    role="presentation"
                    className="owl-prev homeSlider-arrow-button"
                  >
                    <span aria-label="Previous">
                      <img src="./images/chevron-left_black.svg" height="13" />
                    </span>
                  </button>
                }
                nextIcon={
                  <button
                    type="button"
                    role="presentation"
                    className="owl-next homeSlider-arrow-button"
                  >
                    <span aria-label="Next">
                      <img src="./images/chevron_right_black.svg" height="13" />
                    </span>
                  </button>
                }
              >
                <Carousel.Item>
                  <div
                    className="owl-item active"
                    //   style={{ width: "387px",
                    //   marginRight: "15px"
                    //  }}
                  >
                    <div className="item align flex-column pb-4">
                      <div className="circle-label"style={{background:"white"}}>
                        <a href="" className="cricle-link">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="25"
                            viewBox="0 0 25 25"
                            fill="none"
                          >
                            <path
                              opacity="0.4"
                              d="M12.0129 21.7327C17.2596 21.7327 21.5129 17.4794 21.5129 12.2327C21.5129 6.98596 17.2596 2.73267 12.0129 2.73267C6.76623 2.73267 2.51294 6.98596 2.51294 12.2327C2.51294 17.4794 6.76623 21.7327 12.0129 21.7327Z"
                              fill="#4A5863"
                            />
                            <path
                              d="M21.813 22.7327C21.633 22.7327 21.453 22.6627 21.323 22.5327L19.463 20.6727C19.193 20.4027 19.193 19.9627 19.463 19.6827C19.733 19.4127 20.173 19.4127 20.453 19.6827L22.313 21.5427C22.583 21.8127 22.583 22.2527 22.313 22.5327C22.173 22.6627 21.993 22.7327 21.813 22.7327Z"
                              fill="#4A5863"
                            />
                          </svg>
                          <p> Vehicle selection</p>
                        </a>
                      </div>
                      <h3 className="h3-heading fw-bold">
                        1. Vehicle selection
                      </h3>
                      <p className="para text-center">
                        Simplify your vehicle selection by choosing among
                        multiple curated vehicle models evaluated by our experts
                        in 100+ parameters. Select the right vehicle for your
                        operations based on on-road range, payload, volume,
                        charging time etc.
                      </p>

                      <a
                        href="/fleet_leasing"
                        className="green-btn text-decoration-none d-inline-block mt-4 "
                      >
                        <span className="learn-more">Learn More </span>
                      </a>
                    </div>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div
                    className="owl-item "
                    // style={{ width: "387px", marginRight: "15px" }}
                  >
                    <div className="item align flex-column pb-4">
                      <div className="circle-label"style={{background:"white"}}>
                        <a href="" className="cricle-link">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="25"
                            viewBox="0 0 25 25"
                            fill="none"
                          >
                            <path
                              d="M7.51294 8.19849H4.51294C3.41294 8.19849 2.51294 7.29849 2.51294 6.19849V4.19849C2.51294 3.09849 3.41294 2.19849 4.51294 2.19849H7.51294C8.61294 2.19849 9.51294 3.09849 9.51294 4.19849V6.19849C9.51294 7.29849 8.61294 8.19849 7.51294 8.19849Z"
                              fill="#4A5863"
                            />
                            <path
                              d="M21.3129 7.19849H17.7129C17.0529 7.19849 16.5129 6.65847 16.5129 5.99847V4.3985C16.5129 3.7385 17.0529 3.19849 17.7129 3.19849H21.3129C21.9729 3.19849 22.5129 3.7385 22.5129 4.3985V5.99847C22.5129 6.65847 21.9729 7.19849 21.3129 7.19849Z"
                              fill="#4A5863"
                            />
                            <path
                              d="M21.3129 14.6985H17.7129C17.0529 14.6985 16.5129 14.1585 16.5129 13.4985V11.8985C16.5129 11.2385 17.0529 10.6985 17.7129 10.6985H21.3129C21.9729 10.6985 22.5129 11.2385 22.5129 11.8985V13.4985C22.5129 14.1585 21.9729 14.6985 21.3129 14.6985Z"
                              fill="#4A5863"
                            />
                            <path
                              opacity="0.37"
                              d="M16.5129 13.4485C16.9229 13.4485 17.2629 13.1085 17.2629 12.6985C17.2629 12.2885 16.9229 11.9485 16.5129 11.9485H13.7629V5.94849H16.5129C16.9229 5.94849 17.2629 5.60849 17.2629 5.19849C17.2629 4.78849 16.9229 4.44849 16.5129 4.44849H9.51294C9.10294 4.44849 8.76294 4.78849 8.76294 5.19849C8.76294 5.60849 9.10294 5.94849 9.51294 5.94849H12.2629V18.1985C12.2629 19.7185 13.4929 20.9485 15.0129 20.9485H16.5129C16.9229 20.9485 17.2629 20.6085 17.2629 20.1985C17.2629 19.7885 16.9229 19.4485 16.5129 19.4485H15.0129C14.3229 19.4485 13.7629 18.8885 13.7629 18.1985V13.4485H16.5129Z"
                              fill="#4A5863"
                            />
                            <path
                              d="M21.3129 22.1985H17.7129C17.0529 22.1985 16.5129 21.6585 16.5129 20.9985V19.3985C16.5129 18.7385 17.0529 18.1985 17.7129 18.1985H21.3129C21.9729 18.1985 22.5129 18.7385 22.5129 19.3985V20.9985C22.5129 21.6585 21.9729 22.1985 21.3129 22.1985Z"
                              fill="#4A5863"
                            />
                          </svg>
                          <p>Fleet OS</p>
                        </a>
                      </div>
                      <h3 className="h3-heading fw-bold">2. Fleet OS</h3>
                      <p className="para text-center">
                        Switch your fleet to electric with no upfront cost. Pay
                        a small security deposit and monthly lease payment that
                        covers your entire vehicle cost at 20% lower than bank
                        EMI.
                      </p>
                      <a
                        href="/fleet"
                        className="green-btn text-decoration-none d-inline-block mt-4 "
                      >
                        <span className="learn-more">Learn More </span>
                      </a>
                    </div>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div
                    className="owl-item "
                    // style={{ width: "387px", marginRight: "15px" }}
                  >
                    <div className="item align flex-column pb-4">
                      <div className="circle-label" style={{background:"white"}}>
                        <a href="" className="cricle-link">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="25"
                            viewBox="0 0 25 25"
                            fill="none"
                          >
                            <path
                              opacity="0.4"
                              d="M22.5129 12.1985C22.5129 17.2385 18.7729 21.4085 13.9229 22.0985C13.4629 22.1685 12.9929 22.1985 12.5129 22.1985C9.75294 22.1985 7.25293 21.0785 5.44293 19.2685C3.63293 17.4585 2.51294 14.9585 2.51294 12.1985C2.51294 6.67849 6.99294 2.19849 12.5129 2.19849C15.2729 2.19849 17.7729 3.31849 19.5829 5.12849C21.3929 6.93849 22.5129 9.43849 22.5129 12.1985Z"
                              fill="#4A5863"
                            />
                            <path
                              d="M15.3429 10.4287L12.8629 12.9087L19.3929 19.4387C19.0329 19.7887 18.6428 20.1087 18.2328 20.3987L11.8029 13.9687L9.33289 16.4387L14.4529 21.5687C14.5629 21.6787 14.6329 21.8187 14.6629 21.9587C14.6629 21.9587 14.6629 21.9587 14.6629 21.9687C14.4229 22.0187 14.1729 22.0687 13.9229 22.0987C13.6029 22.1487 13.2829 22.1787 12.9529 22.1887L8.27289 17.4987L5.99286 19.7787C5.80286 19.6187 5.62287 19.4487 5.44287 19.2687C5.26287 19.0887 5.09286 18.9087 4.93286 18.7187L19.0329 4.61865C19.2229 4.77865 19.4029 4.94865 19.5829 5.12865C19.7629 5.30865 19.9329 5.48865 20.0929 5.67865L16.4029 9.36865L22.0929 15.0587C21.9329 15.5887 21.7329 16.0887 21.4929 16.5787L15.3429 10.4287Z"
                              fill="#4A5863"
                            />
                          </svg>

                          <p> Fleet Lease</p>
                        </a>
                      </div>
                      <h3 className="h3-heading fw-bold">3. Fleet Lease</h3>
                      <p className="para text-center">
                        Monitor fleet and battery health, receive driving
                        recommendations, preventive diagnostics and alerts to
                        improve your vehicle uptime and utilization.
                      </p>
                      <a
                        href="/fleet_leasing"
                        className="green-btn text-decoration-none d-inline-block mt-4 "
                      >
                        <span className="learn-more">Learn More </span>
                      </a>
                    </div>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div
                    className="owl-item "
                    // style={{ width: "387px", marginRight: "15px" }}
                  >
                    <div className="item align flex-column pb-4">
                      <div className="circle-label" style={{background:"white"}}>
                        <a href="" className="cricle-link">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="25"
                            viewBox="0 0 25 25"
                            fill="none"
                          >
                            <path
                              opacity="0.4"
                              d="M10.7528 15.4726C10.4228 15.1426 9.98281 14.9526 9.51281 14.9526H4.43281C3.82281 14.9526 3.26281 15.2626 2.94281 15.7826C2.62281 16.2926 2.59281 16.9026 2.85281 17.4426C4.08281 19.9626 6.30281 21.9426 8.94281 22.8726C9.12281 22.9326 9.32281 22.9726 9.51281 22.9726C9.86281 22.9726 10.2128 22.8626 10.5128 22.6526C10.9828 22.3226 11.2628 21.7826 11.2628 21.2126L11.2728 16.7126C11.2628 16.2426 11.0828 15.8026 10.7528 15.4726Z"
                              fill="#4A5863"
                            />
                            <path
                              d="M22.9929 10.3327C21.8729 5.41267 17.5629 1.98267 12.5129 1.98267C7.46287 1.98267 3.15287 5.41267 2.03287 10.3327C1.91287 10.8527 2.03287 11.3827 2.37287 11.8027C2.71287 12.2227 3.21287 12.4627 3.75287 12.4627H21.2829C21.8229 12.4627 22.3229 12.2227 22.6629 11.8027C22.9929 11.3827 23.1129 10.8427 22.9929 10.3327Z"
                              fill="#4A5863"
                            />
                            <path
                              opacity="0.4"
                              d="M22.0729 15.8327C21.7529 15.3127 21.1929 14.9927 20.5729 14.9927L15.5129 14.9827C15.0429 14.9827 14.6029 15.1627 14.2729 15.4927C13.9429 15.8227 13.7629 16.2627 13.7629 16.7327L13.7729 21.2127C13.7729 21.7827 14.0529 22.3227 14.5229 22.6527C14.8229 22.8627 15.1729 22.9727 15.5229 22.9727C15.7129 22.9727 15.9029 22.9427 16.0829 22.8727C18.7029 21.9527 20.9229 19.9827 22.1529 17.4927C22.4129 16.9627 22.3829 16.3427 22.0729 15.8327Z"
                              fill="#4A5863"
                            />
                          </svg>
                          <p> Integrated Service</p>
                        </a>
                      </div>
                      <h3 className="h3-heading fw-bold">
                        4. Integrated Service
                      </h3>
                      <p className="para text-center">
                        Switch your fleet to electric with no upfront cost. Pay
                        a small security deposit and monthly lease payment that
                        covers your entire vehicle cost at 20% lower than bank
                        EMI.
                      </p>
                      <a
                        href=""
                        className="green-btn text-decoration-none d-inline-block mt-4"
                      >
                        <span className="learn-more">Learn More </span>
                      </a>
                    </div>
                  </div>
                </Carousel.Item>
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
