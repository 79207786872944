import React from "react";
import { GrFormNext } from "react-icons/gr";
import Fleet_manage from "../Images/fleet_management.svg";
import General from "../Images/general.png";
import Integrate from "../Images/integrated_services.png";
import { BASE_URL } from "../repository/api";
import "./FaqPageComponent.css";

import { MdOutlineNavigateNext } from "react-icons/md";
import Warranty from "../Images/warranty.svg";

import { useEffect, useState } from "react";

// const API = `https://alt-backend-dev.alt-mobility.com/api/vehicle/faq`;

const FaqPageComponent = () => {

  const [item, setItem] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const fetchData = async () => {
    try {
      const res = await fetch(`${BASE_URL}/faq`).then((response) => response.json());
      const data = res;
      if (data.results.length > 0) {
        setItem(data.results);

        document.querySelector("#pills-second-tab").click();
        document.querySelector("#pills-first-tab").click();
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    fetchData(`${BASE_URL}/faq`);
  }, []);

  const questionList = (type, idButton) => {
    const filtered_value = item.filter((item) => item.faqType === type);

    return (
      <div
        className={`tab-pane fade show ${activeTab === idButton ? "active" : ""
          }`}
        id={`pills-${idButton}`}
        role="tabpanel"
        aria-labelledby={`pills-${idButton}-tab`}
      >
        <div className="faqs-search pb-md-5  paddingsmall w-100">
          <div className="row">
            <div className="col-md-12 col-lg-8 col-sm-12 text-md-start text-sm-center">
              <h1 className="faq-text text-md-start text-sm-center">FAQs</h1>
              <p className="main">
                Any questions related to {type} process of Alt Mobility can be
                found here.
              </p>
            </div>
            {/*
              <div className='col-md-12 col-lg-4 col-sm-12 text-md-start text-sm-center mt-2'>
                <div class="form-group has-search">
                    <span class="fa fa-search form-control-feedback"></span>
                    <input type="text" class="form-control" placeholder="Search articles by keywords"/>
                </div>
              </div>
              */}
          </div>
        </div>

        <div class=" w-100 mt-2 mb-4 paddingsmall">
          <div
            className="article-2 accordion"
            id="accordionPanelsStayOpenExample"
          >
            <div className="card w-100 ">
              <div className="">
                {/* <div class="accordion-header" id="panelsStayOpen-headingOne">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseOne"
                    aria-expanded="true"
                    aria-controls="panelsStayOpen-collapseOne"
                    style={{ background: "none" }}
                  >
                    <h4 className="article">Articles in this section</h4>
                  </button>
                </div> */}
                <div className="accordion-header" id="panelsStayOpen-headingOne">
                  <button
                    className={`accordion-button ${isCollapsed ? '' : 'collapsed'}`}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseOne"
                    aria-expanded={isCollapsed ? 'false' : 'true'}
                    aria-controls="panelsStayOpen-collapseOne"
                    style={{ background: "none" }}
                    onClick={() => setIsCollapsed(!isCollapsed)}
                  >
                    <h4 className="article">Articles in this section</h4>
                  </button>
                </div>

                <div
                  id="panelsStayOpen-collapseOne"
                  class="accordion-collapse collapse"
                  aria-labelledby="panelsStayOpen-headingOne"
                >
                  <div
                    className="article-btn mt-4 nav nav-pills"
                    id="pills-tab"
                    role="tablist"
                  >
                    {filtered_value.map((elem, id) => (
                      <button
                        className={`gray-btn text-start mb-3 d-flex justify-content-between w-100 nav-link ${activeTab === id ? "active" : ""
                          }`}
                        id={`pills-${id}-tab`}
                        data-bs-toggle="pill"
                        data-bs-target={`#pills-${id}`}
                        type="button"
                        role="tab"
                        aria-controls={`pills-${id}`}
                        aria-selected={activeTab === id}
                        key={id}
                        onClick={() => setActiveTab(id)}
                      >
                        <div>{elem.question}</div>
                        <div>
                          <MdOutlineNavigateNext size="25" />
                        </div>
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="article-section pt-md-5 pb-md-5 paddingsmall w-100">
          <div className="row">
            <div className="article-1 col-md-12 col-lg-5 col-sm-12">
              <div className="card w-100">
                <div className="card-body p-4">
                  <h4 className="article">Articles in this section</h4>

                  <div
                    className="article-btn mt-4 nav nav-pills"
                    id="pills-tab"
                    role="tablist"
                  >
                    {filtered_value.map((elem, id) => (
                      <button
                        className={`gray-btn text-start mb-3 d-flex justify-content-between w-100 nav-link ${activeTab === id ? "active" : ""
                          }`}
                        id={`pills-${id}-tab`}
                        data-bs-toggle="pill"
                        data-bs-target={`#pills-${id}`}
                        type="button"
                        role="tab"
                        aria-controls={`pills-${id}`}
                        aria-selected={activeTab === id}
                        key={id}
                        onClick={() => setActiveTab(id)}
                      >
                        <div>{elem.question}</div>
                        <div>
                          <MdOutlineNavigateNext size="25" />
                        </div>
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-12 col-lg-7 col-sm-12 mt-sm-4 mt-lg-0 mt-md-4">
              <div className="tab-content" id="pills-tabContent">
                {filtered_value.map((elem, id) => {
                  return (
                    <div
                      className={`tab-pane fade show ${activeTab === id ? "active" : ""
                        }`}
                      id={`pills-${id}-tab`}
                      role="tabpanel"
                      aria-labelledby={`pills-${id}-tab`}
                      key={id}
                    >
                      <div className="card w-100">
                        <div className="card-body">
                          <div>
                            <h3 className="faq-text">{elem.question}</h3>
                            <div className="article-p">
                              <p>
                                Updated at:{" "}
                                {new Date(elem.updated_at).toLocaleString()}
                              </p>
                            </div>
                          </div>

                          {elem.answer && (
                            <div className="article-text-p mt-5">
                              {Array.isArray(elem.answer) ? (
                                elem.answer.map((item, innerId) => (
                                  <div
                                    className="article-text-p mt-4"
                                    key={innerId}
                                  >
                                    <p className="main">{item.content}</p>
                                  </div>
                                ))
                              ) : Array.isArray(elem.answer.content) ? (
                                elem.answer.content.map((singleQues, id) => (
                                  <div className="article-text-p mt-4" key={id}>
                                    <h6 className="article-text">
                                      {singleQues.title}
                                    </h6>
                                    <p className="main">{singleQues}</p>
                                  </div>
                                ))
                              ) : (
                                <p className="main">{elem.answer.content}</p>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className=" home-mainss paddingsmall text-center p-5  w-100">
        <p className="page-link ms-3">
          Homepage
          <GrFormNext />
          <span className="span-title">FAQs</span>
        </p>
        <div className="leasing-financing-title mt-3">
          <h1 className="main">Get Help On the Go</h1>
          <p className="faqs-p mt-2">
            Find all the information to utilise our services and offerings
            effectively here. Our <br />
            dedicated team is available to assist you if you require additional
            support.
          </p>
        </div>
      </div>
      <div
        className="faq-image gap-4 pe-lg-5 ps-lg-5 container paddingsmall w-100 nav nav-pills"
        id="pills-tab"
        role="tablist"
      >
        <div
          className="faq-imaged gap-4 pe-lg-5 ps-lg-5 pe-md-5 ps-md-5 pe-sm-3 ps-sm-3 w-100 paddingsmall nav nav-pills"
          id="pills-tab"
          role="tablist"
        >
          <button
            className="alt-cardes p-2 nav-link active text-nowrap gap-3"
            style={{ width: "130px", height: "56px" }}
            id="pills-first-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-first"
            type="button"
            role="tab"
            aria-controls="pills-first"
            aria-selected="true"
          >
            <img src={General} alt="" style={{ marginRight: "4px" }} />
            General
          </button>

          <button
            className="alt-cardes p-2 nav-link text-nowrap"
            style={{ width: "153px", height: "56px" }}
            id="pills-second-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-second"
            type="button"
            role="tab"
            aria-controls="pills-second"
            aria-selected="false"
          >
            <img src={Integrate} alt="" style={{ marginRight: "4px" }} />
            Fleet Lease
          </button>

          <button
            className="alt-cardes p-2 nav-link text-nowrap"
            style={{ width: "130px", height: "56px" }}
            id="pills-third-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-third"
            type="button"
            role="tab"
            aria-controls="pills-third"
            aria-selected="false"
          >
            <img src={Fleet_manage} alt="" style={{ marginRight: "4px" }} />
            Fleet Os
          </button>
          <button
            className="alt-cardes p-2 nav-link text-nowrap"
            style={{ width: "164px", height: "56px" }}
            id="pills-fourth-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-fourth"
            type="button"
            role="tab"
            aria-controls="pills-fourth"
            aria-selected="false"
          >
            <img src={Warranty} alt="" style={{ marginRight: "4px" }} />
            Fleet Protect
          </button>
          {/* <button
            className="alt-cardes p-2 nav-link text-nowrap"
            style={{ width: "130px", height: "56px" }}
            id="pills-fifth-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-fifth"
            type="button"
            role="tab"
            aria-controls="pills-fifth"
            aria-selected="false"
          >
            <img src={PartnerIcons} alt="" />
            Partner
          </button> */}
        </div>
        <div className="tab-content" id="pills-tabContent">
          {questionList("General", "first")}
          {questionList("Fleet Lease", "second")}
          {questionList("Fleet OS", "third")}
          {questionList("Fleet Protect", "fourth")}
          {questionList("Partner", "fifth")}
        </div>
      </div>
    </>
  );
};
export default FaqPageComponent;
