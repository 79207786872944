import React from "react";
import Security from "../Images/security.png";

const SdgCard = (props) => {
  return (
    <>
      <div className="side-bar-content text-start">
        <h1 className="founder-h1 text-start">{props.text1}</h1>
        <p className="mt-1">{props.para1}</p>
        <vr className="hrline mt-5" />
        <div className="d-flex alt-card gap-2 mt-4">
          <img src={Security} alt="" />
          <div className="job-gen">
            <h1>{props.text2}</h1>
            <p className="job-subtitle">{props.para2}</p>
          </div>
        </div>
        {props.img1}
      </div>
    </>
  );
};

export default SdgCard;
