import React from "react";
import Table from "react-bootstrap/Table";
const PolicyTerm = () => {
  return (
    <div>
      <Table striped bordered >
        <tbody>
          <tr>
            <th>S. No.</th>
            <th style={{textAlign:"center"}}>Particulars</th>
            <th style={{textAlign:"center"}}>Frequency</th>
          </tr>

          <tr>
            <th colSpan={3}>EV Operation</th>
          </tr>

          <tr>
            <td style={{ verticalAlign: "middle", textAlign: "center" }}>1</td>
            <td>
              <tr>Customer to provide all the mentioned data:</tr>
              <tr>(a) Number of Vehicles deployed location wise;</tr>
              <tr>(b) Distance covered in a month per vehicle;</tr>
              <tr>(c) Number of the vehicles due for service;</tr>
              <tr>(d) Number of vehicles serviced;</tr>
              <tr>
                (e) Service history along with the service, maintenance and
                parts changed.
              </tr>
            </td>
            <td style={{ verticalAlign: "middle", textAlign: "center" }}>Monthly</td>
          </tr>

          <tr>
            <td>2</td>
            <td>
              Submit to the Lessor the background check and police verification
              of all the users of the vehicle
            </td>
            <td>As and when applicable</td>
          </tr>
          <tr>
            <td>3.</td>
            <td>
              Secure the vehicle and report to the nearest police station, in
              case of any incident/accident of the vehicle{" "}
            </td>
            <td>As and when applicable </td>
          </tr>
          <tr>
            <td>4.</td>
            <td>
              Monthly reports regarding any violation of any Applicable Laws and
              any subsequent imposition of any fines on the Customer{" "}
            </td>
            <td>Monthly</td>
          </tr>
          <tr>
            <td>5.</td>
            <td>Maintenance record/report of the vehicle</td>
            <td>Quarterly</td>
          </tr>
          <tr>
            <th colSpan={3}>Company Financials</th>
          </tr>
          <tr>
            <td>6.</td>
            <td>Audited Financials last two years</td>
            <td>Annually</td>
          </tr>
          <tr>
            <td>7.</td>
            <td>
              Sanction Letters / Lease Agreements of Term Loans /or Vehicles on
              Lease{" "}
            </td>
            <td>Quarterly</td>
          </tr>
          <tr>
            <td>8.</td>
            <td>List of Shareholders as on date</td>
            <td>Annually</td>
          </tr>
          <tr>
            <td>9.</td>
            <td>Details of funds Raised and Infused till date</td>
            <td>Annually</td>
          </tr>
          <tr>
            <td>10.</td>
            <td>Last 12 months GST Return data</td>
            <td>Quarterly</td>
          </tr>
          <tr>
            <td>11.</td>
            <td>Last 1 Year ITR with computation </td>
            <td>Annually</td>
          </tr>
          <tr>
            <td>12.</td>
            <td>Last 12 months Bank Statement</td>
            <td>Annually</td>
          </tr>
          <tr>
            <td>13.</td>
            <td>
              Signed Provisional Financials (in case audited financials not
              available)
            </td>
            <td>Annually</td>
          </tr>
          <tr>
            <td>14.</td>
            <td>
              Furnish Balance confirmation as and when demanded by the
              Lessor/lenders from the Customer
            </td>
            <td>As and when applicable</td>
          </tr>
          <tr>
            <th colSpan={3}>Secretarial Documents</th>
          </tr>
          <tr>
            <td>15.</td>
            <td>Certificate of Incorporation</td>
            <td>One time</td>
          </tr>
          <tr>
            <td>16.</td>
            <td>MOA and AOA</td>
            <td>One time</td>
          </tr>
          <tr>
            <td>17.</td>
            <td>GST Certificate</td>
            <td>One time</td>
          </tr>
          <tr>
            <td>18.</td>
            <td>List of Directors as on date</td>
            <td>Annually</td>
          </tr>
          <tr>
            <th colSpan={3}>Business Related</th>
          </tr>
          <tr>
            <td rowSpan={3} style={{ verticalAlign: "middle", textAlign: "center" }}>19.</td>

            <td>Total Existing Number of Fleets </td>
            <td>Quarterly</td>
          </tr>
          <tr>
            <td>a) Owned</td>
          </tr>
          <tr>
            <td>a) Owned</td>
          </tr>
          <tr>
            <td>20.</td>
            <td>Corporate Profile</td>
            <td>Annually</td>
          </tr>
          <tr>
            <td>21.</td>
            <td>List of Top 5 Customer Contracts / Terms</td>
            <td>Quarterly</td>
          </tr>
          <tr>
            <th colSpan={3}>Quarterly</th>
          </tr>
          <tr>
            <td>22.</td>
            <td>Pan Card</td>
            <td>One time</td>
          </tr>
          <tr>
            <td>23.</td>
            <td>One time</td>
            <td>One time</td>
          </tr>
          <tr>
            <td>24.</td>
            <td>ITR of last 2 Years</td>
            <td>One time</td>
          </tr>
          <tr>
            <th colSpan={3}>CIBIL Data Access rights</th>
          </tr>
          <tr>
            <td>25.</td>
            <td>
              Customer shall provide the rights to access the Corporate and
              Promoters' CIBIL bureau information on annual basis to check the
              credit-worthiness of the customer and its promoters
            </td>
            <td>Annually</td>
          </tr>
          <tr>
            <td>26.</td>
            <td>
              CIBIL Bureau access rights can be provided through signing of the
              physical document signed provided by Lessor or through link{" "}
            </td>
            <td>Annually</td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

export default PolicyTerm;
