import React from 'react';
import IndividualVehicle from '../Components/IndividualVehicle';
import Header from '../Components/Header';
import Parameter from "../Components/Parameter";
import Ecosystem from '../Components/Ecosystem';
import Footer from "../Components/Footer";


const IndividualImg = () => {
  return (
    <>
        <Header/>
        <IndividualVehicle/>
        <Parameter/>
        <Ecosystem/>
        <Footer/>
    </>
  )
}

export default IndividualImg
