import axios from "axios";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import { BASE_URL_FLEETOS, BASE_URL_UAT } from "../repository/api";
import "./Login.css";


const ForgotPasswordModal = ({ closeModal }) => {
  const [otpLoading, setOtpLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email address").required("Required"),
    otp: Yup.string()
      .length(6, "OTP must be exactly 6 characters")
      .required("Required"),
    new_password: Yup.string()
      .min(8, "New password must be at least 8 characters")
      .required("Required"),
  });
  const formik = useFormik({
    initialValues: {
      email: "",
      otp: "",
      new_password: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const response = await axios.post(
          `${BASE_URL_UAT}/v2/auth/customer/change-password`,
          values
        );
        console.log("Forgot Password form submitted:", response);
        setLoading(false);
        toast.success("login successfully");
        closeModal();
      } catch (error) {
        console.error("Error submitting forgot password form:", error);
        toast.error(error?.response?.data?.message);

        setLoading(false);
      }
    },
  });
  const sendOTP = async () => {
    setOtpLoading(true);
    try {
      await axios.post(
        `${BASE_URL_FLEETOS}/v2/auth/customer/forgot-password`,
        { email: formik.values.email }
      );

      toast.success("OTP sent successfully");
      setOtpLoading(false);
    } catch (error) {
      console.error("Error sending OTP:", error);
      setOtpLoading(false);
    }
  };
  return (
    <div
      style={{
        position: "fixed",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          backgroundColor: "#fff",
          padding: "20px",
          borderRadius: "8px",
          width: "400px",
        }}
      >
        <h4 className="text-center">Forgot Password</h4>
        <form className="d-flex flex-column " onSubmit={formik.handleSubmit}>
          <div className="d-flex flex-column  ">
            <label>
              Email<span style={{ color: "red" }}>*</span>{" "}
            </label>
            <input
              type="email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              style={{
                padding: "8px",
                borderRadius: "4px",
                border: "1px solid #ccc",
                width: "100%",
              }}
            />
            <p style={{ fontSize: "12px", color: "red" }}>
              {formik.touched.email && formik.errors.email}
            </p>
            <div className="w-100 d-flex justify-content-end gap-1">
              {otpLoading && (
                <div
                  class="spinner-border spinner-border-sm text-primary"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
              )}
              <div
                onClick={sendOTP}
                style={{ color: "blue", fontSize: "12px", cursor: "pointer" }}
              >
                <p>Send Otp</p>
              </div>
            </div>
          </div>
          <div className="d-flex flex-column  ">
            <label>
              OTP<span style={{ color: "red" }}>*</span>
            </label>
            <input
              type="text"
              name="otp"
              value={formik.values.otp}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              style={{
                padding: "8px",
                borderRadius: "4px",
                border: "1px solid #ccc",
                marginBottom: "10px",
                width: "100%",
              }}
            />
            <p style={{ fontSize: "12px", color: "red" }}>
              {formik.touched.otp && formik.errors.otp}
            </p>
          </div>
          <div className="d-flex flex-column  ">
            <label>
              New Password<span style={{ color: "red" }}>*</span>
            </label>
            <input
              type="password"
              id="new_password"
              name="new_password"
              value={formik.values.new_password}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              style={{
                padding: "8px",
                borderRadius: "4px",
                marginBottom: "10px",
                border: "1px solid #ccc",
                width: "100%",
              }}
            />
            <p style={{ fontSize: "12px", color: "red" }}>
              {formik.touched.new_password && formik.errors.new_password}
            </p>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "1rem",
              marginTop: "10px",
            }}
          >
            <button
              type="button"
              onClick={closeModal}
              style={{
                padding: "10px",
                borderRadius: "4px",
                border: "1px solid #ccc",
                background: "#ddd",
                cursor: "pointer",
              }}
            >
              Cancel
            </button>
            <button
              className="login-btn"
              type="submit"
              onClick={formik.handleSubmit}
              style={{
                padding: "10px",
                borderRadius: "4px",
                border: "none",
                color: "#fff",
                cursor: "pointer",
              }}
            >
              {loading && (
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                />
              )}{" "}
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

const Login = () => {
  const [showModal, setShowModal] = useState(false);
  const [logLoading, setLogLoading] = useState(false);
  const navigate = useNavigate();
  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };
  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email address").required("Required"),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .required("Required"),
  });
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      setLogLoading(true);
      try {
        const response = await axios.post(
          `${BASE_URL_UAT}/v2/auth/customer/login`,
          values
        );
        const authToken = response.data.token;
        formik.resetForm();
        setLogLoading(false);
        toast.success("login successfully");
        navigate("/leaseapplicationform")
      } catch (error) {
        formik.setFieldError(
          "password",
          "Invalid credentials. Please try again."
        );
        setLogLoading(false);
      }
    },
  });
  const handleLoginSubmit = (e) => {
    e.preventDefault();
    formik.handleSubmit();
  };

  return (
    <div>
      <div
        style={{
          maxWidth: "400px",
          margin: "auto",
          padding: "20px",
          border: "1px solid #ccc",
          borderRadius: "8px",
          marginTop: "8rem",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <h2 style={{ textAlign: "center", marginBottom: "20px" }}>Login</h2>
        <form
          style={{ display: "flex", flexDirection: "column", gap: "15px" }}
          onSubmit={handleLoginSubmit}
        >
          <div className="d-flex flex-column  ">
            <label>
              Email<span style={{ color: "red" }}>*</span>
            </label>
            <input
              type="email"
              name="email"
              onBlur={formik.handleBlur}
              value={formik.values.email}
              onChange={formik.handleChange}
              style={{
                padding: "8px",
                borderRadius: "4px",
                border: "1px solid #ccc",
                width: "100%",
              }}
            />
            <p style={{ fontSize: "12px", color: "red" }}>
              {formik.touched.email && formik.errors.email}
            </p>
          </div>
          <div className="d-flex flex-column ">
            <label>
              Password<span style={{ color: "red" }}>*</span>
            </label>
            <input
              type="password"
              name="password"
              value={formik.values.password}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              style={{
                padding: "8px",
                borderRadius: "4px",
                border: "1px solid #ccc",
                width: "100%",
              }}
            />
            <p style={{ fontSize: "12px", color: "red" }}>
              {formik.touched.password && formik.errors.password}
            </p>
            <div className="w-100 justify-content-end d-flex">
              <p
                onClick={openModal}
                style={{
                  marginTop: "10px",
                  cursor: "pointer",
                  color: "#007BFF",
                  border: "none",
                  background: "none",
                  fontSize: "12px",
                }}
              >
                Forgot Password
              </p>
            </div>
          </div>

          <button
            className="login-btn"
            type="submit"
            style={{
              color: "white",
              padding: "10px",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            {logLoading && (
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />
            )}{" "}
            Login
          </button>
        </form>
        {showModal && <ForgotPasswordModal closeModal={closeModal} />}
      </div>
    </div>
  );
};

export default Login;


