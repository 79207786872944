

import React, { useState } from "react";
import "./FleetLeasing.css";
import "./VehicleListComp/VehicleList";
import TwoWheeelerBefore from "../assest/2WheelerBefore.svg"
import TwoWheeelerAfter from "../assest/2WheelerAfter.svg"
import VehicleList from "./VehicleListComp/VehicleList";

function FleetLeasing() {
  const [selectedTab, setSelectedTab] = useState("2 Wheeler");

  return (
    <div className="home-mainss">
      <div className="fleet-leasing-home pt-5">
        <div className="container fleet-leasing-content">
          <div className="row gap-5">
            <div className="col-lg-7 col-12 col-md-12 col-sm-12">
              <h1 className="mt-4 smart_way">
                The smartest way to switch your fleet to electric
              </h1>
              <p className="para font-14">
                With a full-stack fleet electrification solution and services
                designed to increase fleet uptime, we provide the lowest
                operating costs in the industry while helping businesses achieve
                sustainability goals.
              </p>
            </div>
            <div className="col-lg-4 col-12 col-md-12 col-sm-12 justify-content-end">
              <a href="#">
                <img
                  src="./fleet leasing_files/Group 13790.png"
                  alt=""
                  className="img-fluid adjust"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      
      <div class="col-lg-12 wheel-frem wheel-sticky " style={{marginTop:"5rem"}} id="hideonpopup">
      <div
        className="d-flex align-items-center wheel-tab justify-content-md-center justify-content-sm-center  justify-content-xs-start nav nav-pills w-100"
        id="pills-tab"
        role="tablist"
      >
        <a
          href="#"
          className={`align flex-lg-column flex-row wheelr-btn nav-link ${
            selectedTab === "2 Wheeler" ? "active" : ""
          }`}
          onClick={() => setSelectedTab("2 Wheeler")}
          role="tab"
        >
          <span className="cricle-wheelr">
            {selectedTab !== "2 Wheeler" ? (
              <img src={TwoWheeelerBefore} alt="" />
            ):(<img src={TwoWheeelerAfter} alt="" />)}
          </span>
          Two-Wheeler
        </a>
        <a
          href="#"
          className={`align flex-lg-column flex-row wheelr-btn nav-link ${
            selectedTab === "3 Wheeler" ? "active" : ""
          }`}
          onClick={() => setSelectedTab("3 Wheeler")}
          role="tab"
        >
          <span className="cricle-wheelr">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="30"
              viewBox="0 0 24 25"
              fill="none"
            >
              <path
                d="M17.1719 17.7723C17.174 17.7721 17.1758 17.7721 17.178 17.7723H17.2654C17.4327 17.7723 17.5684 17.6167 17.5684 17.4249C17.5684 17.3256 17.5313 17.2288 17.4663 17.1629C17.4013 17.097 17.3142 17.0636 17.2289 17.0763C17.2234 17.0769 17.2178 17.0774 17.212 17.0776H17.178C17.1771 17.0774 17.176 17.0773 17.175 17.0776H17.1718C17.0045 17.0776 16.8688 17.2331 16.8688 17.4249C16.8689 17.6167 17.0046 17.7723 17.1719 17.7723Z"
                fill="black"
              ></path>
              <path
                opacity="0.4"
                d="M3.31488 17.4192C3.31488 18.4997 4.07855 19.375 5.01482 19.375C5.95411 19.375 6.71777 18.4995 6.71777 17.4261C6.71476 16.3457 5.95109 15.4739 5.01482 15.4739C4.08156 15.4737 3.3179 16.3457 3.31488 17.4192Z"
                fill="black"
              ></path>
              <path
                opacity="0.4"
                d="M18.409 18.2493C18.6787 18.9129 19.2696 19.375 19.9514 19.375C20.8879 19.375 21.6514 18.4995 21.6514 17.4261C21.6514 17.3741 21.6483 17.322 21.6453 17.2732C21.5393 17.2698 21.4362 17.2419 21.3393 17.1968C21.0363 17.0544 20.5363 16.8911 20.1333 16.8911C19.9 16.8911 19.7575 16.9397 19.7029 17.0335C19.5938 17.2907 19.2484 17.9193 18.409 18.2493Z"
                fill="black"
              ></path>
              <path
                d="M3.01232 5.37512L16.5908 5.50018C16.906 5.50364 17.2029 5.67732 17.3878 5.96567C17.3938 5.97605 17.3998 5.98643 17.4029 5.99698L19.8361 10.7873C19.9695 11.0547 20.0573 11.3431 20.0907 11.6384L20.3664 13.8965C20.8542 14.1015 22.0723 14.765 21.9966 16.2067C21.9966 16.2274 21.9966 16.2449 21.9936 16.2657C21.9845 16.3769 21.9299 16.4741 21.8451 16.5331C21.7633 16.5886 21.6603 16.5992 21.5725 16.5539C21.5634 16.5504 20.8301 16.1961 20.1331 16.1961C19.6453 16.1961 19.3301 16.3663 19.1665 16.7103C19.1028 16.8701 18.7604 17.5752 17.6574 17.7315C17.5393 17.7523 17.4089 17.7629 17.2726 17.7663C17.2575 17.7663 17.2423 17.7629 17.2272 17.7594C17.2091 17.7629 17.1969 17.7732 17.1788 17.7732H14.588C14.5819 17.7732 14.5759 17.7698 14.5727 17.7698H11.3183C11.3123 17.7698 11.3093 17.7732 11.3032 17.7732H9.01238C9.00332 17.7732 8.99729 17.7663 8.98809 17.7663H7.30324C7.31833 17.6551 7.32451 17.5406 7.32451 17.4259C7.32149 15.9668 6.28517 14.7787 5.01541 14.7787C3.74882 14.7787 2.71235 15.9633 2.70934 17.4153C2.70934 17.5335 2.71537 17.6516 2.73061 17.7661H2.60642C2.2731 17.7661 2.00043 17.4536 2.00043 17.0714L2 6.52483C2 5.88913 2.45149 5.375 3.01218 5.375L3.01232 5.37512ZM11.606 11.6385H12.4696C12.6393 11.6385 12.7726 11.7948 12.7726 11.9858V13.9208H14.5876C14.7544 13.9208 14.8906 14.0772 14.8906 14.2681L14.8908 17.075H16.9847C17.0695 15.6158 17.2967 10.0679 16.0454 7.82042C15.9817 7.70227 15.8545 7.62928 15.7181 7.62928H11.6062L11.606 11.6385ZM9.22734 17.075H11V13.5699H8.16674L9.22734 17.075ZM7.61218 11.7359L7.95758 12.8754H11V7.62989H8.0092C7.79101 7.62989 7.61221 7.83487 7.61221 8.085L7.61218 11.7359Z"
                fill="#4A5863"
              ></path>
              <path
                opacity="0.4"
                d="M19.1727 12.6952H19.8718C20.0391 12.6952 20.1748 12.5396 20.1748 12.3478C20.1748 12.156 20.0391 12.0005 19.8718 12.0005H19.1727C19.0054 12.0005 18.8697 12.156 18.8697 12.3478C18.8697 12.5396 19.0054 12.6952 19.1727 12.6952Z"
                fill="#F3F7F6"
              ></path>
              <path
                opacity="0.4"
                d="M19.3026 12.0697H20.0017C20.169 12.0697 20.3047 11.9142 20.3047 11.7224C20.3047 11.5306 20.169 11.375 20.0017 11.375H19.3026C19.1353 11.375 18.9996 11.5306 18.9996 11.7224C18.9996 11.9142 19.1353 12.0697 19.3026 12.0697Z"
                fill="#black"
              ></path>
            </svg>
          </span>
          Three-Wheeler
        </a>
        <div className="align flex-lg-column flex-row wheelr-btn">
          <span className="cricle-wheelr">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
            >
              <path
                d="M22.334 14.375V17.375C22.334 19.035 20.994 20.375 19.334 20.375H18.334C18.334 19.275 17.434 18.375 16.334 18.375C15.234 18.375 14.334 19.275 14.334 20.375H10.334C10.334 19.275 9.43398 18.375 8.33398 18.375C7.23398 18.375 6.33398 19.275 6.33398 20.375H5.33398C3.67398 20.375 2.33398 19.035 2.33398 17.375V14.375H13.334C14.434 14.375 15.334 13.475 15.334 12.375V5.375H17.174C17.894 5.375 18.554 5.76501 18.914 6.38501L20.624 9.375H19.334C18.784 9.375 18.334 9.825 18.334 10.375V13.375C18.334 13.925 18.784 14.375 19.334 14.375H22.334Z"
                fill="#4A5863"
              />
              <path
                opacity="0.3"
                d="M8.33398 22.375C9.43855 22.375 10.334 21.4796 10.334 20.375C10.334 19.2704 9.43855 18.375 8.33398 18.375C7.22941 18.375 6.33398 19.2704 6.33398 20.375C6.33398 21.4796 7.22941 22.375 8.33398 22.375Z"
                fill="#4A5863"
              />
              <path
                opacity="0.3"
                d="M16.334 22.375C17.4386 22.375 18.334 21.4796 18.334 20.375C18.334 19.2704 17.4386 18.375 16.334 18.375C15.2294 18.375 14.334 19.2704 14.334 20.375C14.334 21.4796 15.2294 22.375 16.334 22.375Z"
                fill="#4A5863"
              />
              <path
                opacity="0.3"
                d="M22.334 12.905V14.375H19.334C18.784 14.375 18.334 13.925 18.334 13.375V10.375C18.334 9.825 18.784 9.375 19.334 9.375H20.624L22.074 11.915C22.244 12.215 22.334 12.555 22.334 12.905Z"
                fill="#4A5863"
              />
              <path
                opacity="0.3"
                d="M15.334 3.375V12.375C15.334 13.475 14.434 14.375 13.334 14.375H2.33398V6.375C2.33398 4.165 4.12398 2.375 6.33398 2.375H14.334C14.884 2.375 15.334 2.825 15.334 3.375Z"
                fill="#4A5863"
              />
            </svg>
          </span>
          Four-Wheeler <br />
          (Coming Soon)
        </div>
      </div>
      </div>
      <div className="mt-5">
      <VehicleList selectedVehicleType={selectedTab} />
      </div>
    </div>
  );
}

export default FleetLeasing;
