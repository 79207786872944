import { Step, StepLabel, Stepper } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import Form1 from "../../Components/FormComponent/Form1";
import Form2 from "../../Components/FormComponent/Form2";
import Form3 from "../../Components/FormComponent/Form3";
import Form4 from "../../Components/FormComponent/Form4";
import Form5 from "../../Components/FormComponent/Form5";
import OtherDocuments from "../../Components/FormComponent/OtherDocuments";
import SecretarialDocuments from "../../Components/FormComponent/SecretarialDocuments";
import "./Stepper.css";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function StepperComponent(props) {
  const theme = useTheme();
  const [value, setValue] = useState(props?.step || 0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    props.refetch?.();
  };

  function handleBack() {
    if (value !== 0) {
      setValue((prev) => prev - 1);
    }
    window.scrollTo(0, 0);
  }

  async function handleNext() {
    if (value !== dataTabs?.length - 1) {
      setValue((prev) => prev + 1);
    }
    window.scrollTo(0, 0);
  }

  let [dataTabs, setDataTabs] = useState(
    [
      { id: "Aboutthecompany", label: "About the company", component: Form1 },
      {
        id: "AdditionalDetails",
        label: "Additional Details",
        component: Form2,
      },
      {
        id: "DirectorandShareholderDetails",
        label: "Director and Shareholder Details",
        component: Form3,
      },
      { id: "GSTDetails", label: "GST Details", component: Form4 },
      {
        id: "companyFinancials",
        label: "Company Financials",
        component: Form5,
      },
      {
        id: "SecretarialDocuments",
        label: "Secretarial Documents",
        component: SecretarialDocuments,
      },
      {
        id: "OtherDocuments",
        label: "Other Documents",
        component: OtherDocuments,
      },
    ],
    []
  );

  useEffect(() => {
    setDataTabs([
      { id: "Aboutthecompany", label: "About the company", component: Form1 },
      {
        id: "AdditionalDetails",
        label: "Additional Details",
        component: Form2,
      },
      {
        id: "DirectorandShareholderDetails",
        label: "Director and Shareholder Details",
        component: Form3,
      },
      { id: "GSTDetails", label: "GST Details", component: Form4 },
      {
        id: "companyFinancials",
        label: "Company Financials",
        component: Form5,
      },
      {
        id: "SecretarialDocuments",
        label: "Secretarial Documents",
        component: SecretarialDocuments,
      },
      {
        id: "OtherDocuments",
        label: "Other Documents",
        component: OtherDocuments,
      },
    ]);
  }, []);

  return (
    <div
      className={`container ${
        props.orientation === "vertical" ? "verticalWrapper" : ""
      }`}
      style={{ paddingTop: "6rem" }}
    >
      <div
        className="remove-scroll"
        style={{
          overflowX: "auto",
          maxWidth: "100%",
          background: "white",
          padding: "1rem 0rem",
          borderRadius: "4px",
        }}
      >
        <Stepper
          nonLinear={true}
          className="StepperClass"
          activeStep={value}
          orientation={props.orientation ?? "horizontal"}
        >
          {dataTabs?.map(({ icon: Icon, ...rest }, index) => {
            const stepProps = {};

            const labelProps = {};

            stepProps.completed = value !== index && rest.isValid;

            return (
              <Step key={rest.label} {...stepProps} className="stepContainer">
                <StepLabel
                  className="stepLabel d-flex"
                  style={{ cursor: "pointer" }}
                  onClick={(e) => handleChange(e, index)}
                  {...labelProps}
                >
                  {rest.label}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </div>

      <div
        className="tabPanel c-form-width "
        style={{
          margin: " 1rem auto ",
          borderRadius: "10px",
          boxShadow: "0 3px 6px #0000001a",
        }}
      >
        {dataTabs?.map(
          ({ component: Component, pageProps = {}, ...rest }, index) => (
            <TabPanel value={value} index={index} dir={theme.direction}>
              <Component
                handleBack={handleBack}
                handleNext={handleNext}
                {...pageProps}
              />
            </TabPanel>
          )
        )}
      </div>
    </div>
  );
}
