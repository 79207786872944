import { Link } from "react-router-dom";
import React, { useState } from "react";
import ABCPolicy from "../../Pdf/ABC_Policy_Final.pdf";
import logo from "../../Images/altlogo.png";
import "./Footer.css";
// import Facebook from "../../Images/facebook.png";
import LinkedIn from "../../Images/linkedin.png";
import Twitter from "../../Images/twitter.png";
// import Instagram from "../../Images/instagram.png";
import CopyRight from "../../Images/copyright.png";
const DeskTopFooter = ({ activeLink, handleLinkClick }) => {
  const [showTermsPopup, setShowTermsPopup] = useState(false);
  const [showPrivacyPopup, setShowPrivacyPopup] = useState(false);

  const handleClick = () => {
   

    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
    setShowTermsPopup(false); 
    setShowPrivacyPopup(false);
  };
  const handleClickenquire = () => {
    window.scrollTo(0, 0);
    setShowTermsPopup(false); 
    setShowPrivacyPopup(false); 
    window.location.href = "/enquire";
  };
  const redirectToHome = () => {
    window.location.href = "/";
  };
const handleClickToShowPdf = () => {
  window.open('https://fleet-driver-app.s3.ap-south-1.amazonaws.com/driver-mandate-photos/financial%20discliosures.pdf?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEFwaCmFwLXNvdXRoLTEiRjBEAiAESdj5DUnw495EHHS9K8rfRFeFm1S8kNs%2BN%2Fa2RoNYPgIgdEDPPf9W0yCQZDmlHY16h4fqIAlAUQUf1w28n40FzmIq%2BwIIdRABGgwzOTQ1ODY4MzM4NzQiDLWY%2BiI%2BmvPt15pC%2ByrYAiEhtpw02f5QTYysyTHSVxk7B2QBCGnyDzmgzbnAK%2BSSWEpIbdUivreDUgihjPCORsS4vRB6Xos37y9eeUz4OYaVHYpLjUqoIuXzBwPIReqmFMdjoTAfAw1OuVCU%2F1vOVu7t%2FVF16BQnBMbj4eHHGFdteutJz87BGoroEIQOJFLJ5vC5QJcFVBZlB21FwUV9D%2FIk39DSDcJo6T4aMjpFkw6f%2BDDnkVtD9OA1IzQr%2Fobdt%2Bb6HNv53lg6zQCITlMmGUVOgQ5c6%2F3SqiOB0oJVKwUQmXZ2RSeJLHqX2k%2B%2BkHuTBq5OzTDby80cZlO5qDCviiyu%2Bhjiln4NOYuCoZISu9JIXsTWhGSYjJVGkx6g7VdGNyeNaL3W%2By9eDN6iTH55RNXfWpby%2F1aZxpiuqbT%2F9EjKull6%2FUiD1DckJ72ZDK%2F%2BJtVA8fP76J%2BEHLrky%2FyUBVKHdaVP4rNrMIisy7YGOrQCCDKszrMApLvSlmMQ%2FnMfeX6ywEgvmTYpqUWlECgCiQAtsgV15vRiCNyXgUCbfsN0x4akPtqaH%2BEJYZ9B3DYCL0L5NQ3HKk4pT7zbqOUd8%2FKtek4sm5oaAjqgIkdja%2FQ1EIqaO7We9O2AJ8ARVKQ7T88UQ6pMUJfZWgZ%2BO%2BIA4ey4pBEVICuZMYuzc1fAK2bqIApu2O70yFTo%2FPPiiFOOaRAMCfcb9OV1bEZtuKFuFA%2FQ57AzDxFxKTb7o3HYI9WQissPup2JtNQfamf7u6Mzc52RwnDKcrEgXWeun6hewd4cAHj8ZGo9L5iJ5Sc7ar7zAtN3g4rPVz3tJ1mO8zROimkSRurCoHy1Wdxg6nObCkke%2FOVRgxSvuI8ZfXylJW71yZ9n0y7I73uI5bCSz2CprkzQJTk%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20240831T121322Z&X-Amz-SignedHeaders=host&X-Amz-Expires=43200&X-Amz-Credential=ASIAVXXZVIPJBGA6OADX%2F20240831%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Signature=af54a1529a2c2883cd789bcc9827cc487ce522f86d578db55976ca298ca75ad3', '_blank');
}
  return (
    <>
      <div className="footer-color">
        <div className="f-footer paddingsmall jumbotron w-100 container">
          <div className="footer row">
            <div className="col-md-5 col-sm-12 pb-5">
              <img
                onClick={redirectToHome}
                src={logo}
                alt="logo"
                className="alt-logo img-responsive"
                style={{ cursor: "pointer" }}
              />

              <p className="logo-txt mt-3">
                Alt Mobility provides a hassle-free and affordable way to switch
                your fleet to electric.
              </p>

              <div className="web mt-4">
                {/* <Link className="soc-link">
                <img
                  src={Facebook}
                  alt=""
                  style={{ width: "32px", height: "32px" }}
                />
              </Link> */}
                {/* &nbsp;&nbsp;&nbsp; */}
                <Link
                  className="soc-link"
                  to="https://www.linkedin.com/company/altmobility/"
                  target="blank"
                >
                  <img
                    src={LinkedIn}
                    alt=""
                    style={{ width: "32px", height: "32px" }}
                  />
                </Link>
                &nbsp;&nbsp;&nbsp;
                {/* <Link className="soc-link">
                <img
                  src={Instagram}
                  alt=""
                  style={{ width: "32px", height: "32px" }}
                />
              </Link>
              &nbsp;&nbsp;&nbsp; */}
                <Link
                  className="soc-link"
                  to="https://twitter.com/mobilityalt?s=20"
                  target="blank"
                >
                  <img
                    src={Twitter}
                    alt=""
                    style={{ width: "32px", height: "32px" }}
                  />
                </Link>
              </div>
            </div>
            <div className="imp-Links col-md-4 col-sm-12">
              <h1>Important Links</h1>
              <Link
                // onClick={handleClick}
                // to="/fleet_leasing"
                style={{ textDecoration: "none", color: "#0A2439" }}
                to="/fleet_leasing"
                className={` link-hover ${activeLink === "/fleet_leasing" ? "active" : ""}`}
                onClick={() => handleLinkClick("/fleet_leasing")}
              >
                <h6 className="mt-4">Fleet Lease</h6>
              </Link>
              <Link
               
                style={{ textDecoration: "none", color: "#0A2439" }}
                to="/fleet"
                className={` link-hover ${activeLink === "/fleet" ? "active" : ""}`}
                onClick={() => handleLinkClick("/fleet")}
              >
                {" "}
                <h6 className="mt-4">Fleet OS</h6>
              </Link>
              <Link
              

                style={{ textDecoration: "none", color: "#0A2439" }}
                to="/about"
                className={` link-hover ${activeLink === "/about" ? "active" : ""}`}
                onClick={() => handleLinkClick("/about")}
              >
                <h6 className="mt-4">About Us</h6>
              </Link>
              <Link
                // onClick={handleClick}
                // to="/partners"
                // style={{ textDecoration: "none", color: "#0A2439" }}
                // className="link-hover"
                style={{ textDecoration: "none", color: "#0A2439" }}
                to="/partners"
                className={` link-hover ${activeLink === "/partners" ? "active" : ""}`}
                onClick={() => handleLinkClick("/partners")}
              >
                <h6 className="mt-4">Partners</h6>
              </Link>
              <Link
                onClick={handleClickenquire}
                to="/enquire"
                style={{ textDecoration: "none", color: "#0A2439" }}
                className="link-hover"
              >
                <h6 className="mt-4">Enquire Now</h6>
              </Link>
              <Link
                to={ABCPolicy}
                target="_blank"
                style={{ textDecoration: "none", color: "#0A2439" }}
                className="link-hover"
              >
              <h6 className="mt-4">ABC Policy</h6>
              </Link>
            </div>
            <div className="warranty-plans col-md-3 col-sm-12">
              <Link
                onClick={handleClick}
                to="/fleetprotect"
                style={{ textDecoration: "none", color: "#0A2439" }}
                className="link-hover"
              >
                <h6 className="">Fleet Protect</h6>
              </Link>
              <Link
                onClick={handleClick}
                to="/faqs"
                style={{ textDecoration: "none", color: "#0A2439" }}
                className="link-hover"
              >
                <h6 className="mt-4">FAQs</h6>
              </Link>
              <Link
                onClick={handleClick}
                to="/leasing"
                style={{ textDecoration: "none", color: "#0A2439" }}
                className="link-hover"
              >
                <h6 className="mt-4">Leasing vs Financing</h6>
              </Link>
              <Link
                onClick={handleClick}
                to="/blog"
                style={{ textDecoration: "none", color: "#0A2439" }}
                className="link-hover"
              >
                <h6 className="mt-4">Blog</h6>
              </Link>
              <Link
                onClick={handleClick}
                to="/Contact-us"
                style={{ textDecoration: "none", color: "#0A2439" }}
                className="link-hover"
              >
                <h6 className="mt-4">Contact Us</h6>
              </Link>
             
              <Link
                onClick={handleClickToShowPdf}
                style={{ textDecoration: "none", color: "#0A2439" }}
                className="link-hover"
              >
                <h6 className="mt-4">Financial disclosures</h6>
              </Link>
              <Link
                onClick={handleClick}
                to="/cancelandrefund"
                style={{ textDecoration: "none", color: "#0A2439" }}
                className="link-hover"
              >
                <h6 className="mt-4">Cancellation and Refund</h6>
              </Link>
            
            </div>
          </div>
          <div className="copyright row">
            <div className="link-hover col-md-8">
              <p className="terms">
                <img
                  src={CopyRight}
                  alt=""
                  style={{ width: "24px", height: "24px" }}
                />{" "}
                2022 Alt Mobility. All rights reserved.
              </p>
            </div>

            <div className="link-hover col-md-4  text-nowrap d-flex ">
              <p
                className="terms ms-5 clk"
                onClick={() => setShowTermsPopup(true)}
              >
                Terms & Conditions
              </p>
              <p
                className="terms ms-5 clk"
                onClick={() => setShowPrivacyPopup(true)}
              >
                Privacy Policy
              </p>
            </div>
            {showTermsPopup && (
              <div className="popup  no-scroll">
                <div className="popupContent">
                  <h2>Terms & Conditions</h2>
                  <div className="popupScroll">
                    <p>
                      {/* Add your terms and conditions content here */}
                      {/* Example: */}
                      These Terms and Conditions (“
                      <span className="fw">User Terms</span>”), as may be
                      modified from time to time, sets forth the terms and
                      conditions that govern the access and use of this website
                      &nbsp;
                      <span className="fw">
                        <a href=" https://alt-mobility.com">
                          {" "}
                          https://alt-mobility.com
                        </a>{" "}
                      </span>{" "}
                      and availing of services on this Website by you, the
                      Customer/User{" "}
                      <span className="fw">(“You/Your/Yours/User”</span>, as the
                      context may require). The Website is owned, managed and
                      operated by{" "}
                      <span className="fw">
                        ALT NRG Private Limited (“We/Our”)
                      </span>
                      , a company incorporated under the provision of the
                      Companies Act, 2013, with Corporate Identity Number{" "}
                      <span className="fw">U40106DL2020PTC364111</span> and
                      having its registered office at 335 DDA Flats, Jai Dev
                      Park East Punjabi Bagh, Punjabi Bagh Sec - III, New
                      Delhi,West Delhi, Delhi, India-110026.
                      <br />
                      <br />
                      These User Terms have been published in terms of the
                      Information Technology (Intermediary Guidelines and
                      Digital Media Ethics Code) Rules, 2021, and if You
                      continue to use/access the website and/or avail our
                      services offered through this Website, you are agreeing to
                      consent to, and abide by the User Terms. In the event You
                      do not agree with the User Terms, you are requested not to
                      use/access the Website and/or avail our services. You are
                      also requested to refer to the Privacy Policy (available
                      on <i> [URL of the website to be mentioned]</i>).
                      <br />
                      <br />
                      We reserve the right to amend/modify these User Terms (in
                      part/full) and/or restrict/amend/discontinue the
                      usage/access to this Website/any features of this Website
                      without assigning any reason and without notification of
                      such amendment/restriction to any User. The Users should
                      check/go through/be familiar with the User Terms at all
                      times and be updated as regards the terms pertaining to
                      this Website for accessing this Website/availing any
                      services on the Website.
                      <br />
                      <br />
                      <h5 className="fw">
                        1. ACKNOWLEDGMENT AND UNDERTAKING BY THE USER
                      </h5>
                      1.1. The User understand, acknowledge, agree and confirm
                      that:
                      <br />
                      <br />
                      a) The User is competent to contract and to avail the
                      services being provided through this Website as per
                      applicable laws. We reserve the right to demand proof of
                      age from You, and pending such proof, Your account maybe
                      suspended/blocked until such proof of age is provided to
                      us to Our satisfaction. Further, We reserve the right to
                      suspend or refuse to offer the services to any User at any
                      time.
                      <br />
                      <br />
                      b) We reserve the right to remove/cease to provide any
                      service from this Website or remove any
                      material/information provided on the Website without any
                      liability towards any User in any manner. You agree and
                      acknowledge that the information provided on this Website
                      is only to assist the User of the Website who are
                      interested in the services provided by Us.
                      <br />
                      <br />
                      c) The User shall comply with all applicable laws while
                      using this Website and/or availing Our services, and not
                      use the Website to encourage or promote or solicit or
                      perform illegal activity or to violate third party rights.
                      <br />
                      <br /> d) Any information submitted by the User on this
                      Website including for availing the services shall be true,
                      valid, updated and correct in all aspects. We reserve the
                      right to suspend/block/terminate use of Website or
                      availment of services by any User if We have reason to
                      believe that the information provided is incorrect,
                      incomplete or false.
                      <br />
                      <br /> e) The User shall be solely liable and responsible
                      for the services that have been availed/will be availed or
                      any transaction that have taken place through this
                      Website, and shall be solely liable for any
                      losses/expenses/cost/claims/damages occurred or incurred
                      by the User/any other third party while using this Website
                      or availing any services, irrespective of whether such
                      losses/expenses/cost/claims/damages have occurred with or
                      without the knowledge of the User. We shall not have any
                      special relationship or fiduciary duty to the User. <br />
                      <br /> f) The User shall not use, save, copy, or reproduce
                      any material/information/image from the Website unless if
                      the same is absolutely necessary for availing the
                      services. <br />
                      <br />
                      g) We shall not be liable for delay in the availing of
                      Services by the User in any manner including for reasons
                      beyond Our control.
                      <br />
                      <br /> h) We have the right to modify the services being
                      provided through this Website at any time without
                      assigning any reason or notifying the User.
                      <br />
                      <br /> 1.2. The User acknowledges that the
                      information/images/data/links/content made available or
                      provided through or described on this Website pertaining
                      to [name of the Company to be mentioned] and/or its
                      associate/subsidiary/group companies and/or services being
                      provided by Us including any marketing or promotional
                      material or any other item or service provided through
                      this Website has been provided on an ‘as is where is’
                      basis, solely at the risk of the User and without any
                      representation or warranty of any kind (express or
                      implied) as regards the quality, fitness for purpose and
                      accuracy in any manner. We do not authorize anyone to make
                      a warranty on Our behalf and the User shall not rely on
                      any statement of warranty being made by any other person
                      or entity. The User acknowledges and agrees that no
                      forward looking statement/information added/captured on
                      the Website should be construed as a guarantee of Our
                      future performance or fitness of services provided, and
                      that the actual result/performance of the product/quality
                      of service can differ materially from the forward looking
                      statements.
                      <br />
                      <br /> 1.3. The Website may contain links of third party
                      websites controlled or operated by such third parties,
                      however, this should not be construed as a representation
                      as regards the correctness/fitness of the
                      content/services/information/product that is available on
                      such third party websites. We shall have no liability in
                      relation to such third party websites or for the
                      content/services/information/product provided through such
                      websites or for any software/information/data/image
                      downloaded by the User from this Website. The User agrees
                      to indemnity and keep indemnified Us and Our
                      affiliates/associate/subsidiary/group company against all
                      liabilities/expenses/costs/damages/claims which may be
                      raised against or incurred by Us as a result of breach of
                      these User Terms and/or Privacy Policy or use of this
                      Website. The User acknowledges that We shall not be
                      responsible for any financial loss or inconvenience caused
                      to the Users in the event of misuse/loss of their
                      registration details of this Website.
                      <br />
                      <br /> 1.4. The User understands and acknowledges that We
                      do not warranty the functionality of the Website,
                      uninterrupted or error free access to the Website. The
                      User understands and acknowledges that we do not warranty
                      that this Website is free of virus/any other harmful
                      element. The User acknowledges that the Website may be
                      inaccessible and/or unavailable due to any reason at any
                      time and We shall not be liable for any
                      damages/liability/action/suit/claim/demand/loss/cost/expenses
                      arising directly or indirectly from or in connection with
                      such inaccessibility and/or unavailability or as a result
                      of any suspension/breakdown/interruption/technical
                      flaw/the presence of virus or other destructive code over
                      the Website or otherwise and/or any consequent delay or
                      failure as a consequence thereof arising from the
                      inability to use the Platform or avail the services.
                      <br />
                      <br /> 1.5. The User understands that the
                      data/material/information submitted by the User for using
                      the Website and/or to avail the services through the
                      Website becomes Our exclusive property. The User agrees to
                      usage and sharing of information shared by the User on the
                      Website as per the Privacy Policy available on{" "}
                      <i>[link of the Privacy Policy]</i>.
                      <br />
                      <br /> 1.6. The User authorizes us to call or email or
                      message the User on the registered mobile number/email
                      address in relation to the services being provided through
                      the Website or for providing assistance/support/quality
                      services. The User understand that they may opt for
                      updates/information in relation to the services being
                      offered by Us. The User also has the option to opt for
                      discontinuation of such updates/information at any point
                      of time by accessing the Website.
                      <br />
                      <br /> 1.7. The User understands that the information,
                      material, suggestions, options in relation to the services
                      being offered by Us on the Website to the User is for
                      general information only and does not constitute advice.
                      We shall not be liable for any damages/losses/cost
                      resulting from the use of or from the inability to use the
                      Website or to avail the services.
                      <br />
                      <br />
                      <h5 className="fw">
                        {" "}
                        2. USE OF THE PLATFORM BY THE USER
                      </h5>
                      <br />
                      2.1. The User agrees not to host, display, upload, modify,
                      publish, transmit, store, update or share any information
                      on the Website that:
                      <br />
                      <br /> a) belongs to another person and to which the User
                      does not have any right;
                      <br />
                      <br /> b) is defamatory, obscene, hateful, disparaging,
                      pornographic, paedophilic, invasive of the privacy of
                      another person, including bodily privacy, insult or
                      harassment on the basis of gender, libellous, racially or
                      ethnically objectionable, relating or encouraging money
                      laundering or gambling, or otherwise inconsistent with or
                      contrary to the applicable laws;
                      <br />
                      <br /> c) is harmful to child;
                      <br />
                      <br /> d) violative of applicable law;
                      <br />
                      <br /> e) deceives or misleads the person to whom such
                      information has been provided about the origin of the
                      message or knowingly and intentionally communicates any
                      information which is patently false or misleading in
                      nature but may reasonably be perceived as a fact;
                      <br />
                      <br /> f) impersonates any other person;
                      <br />
                      <br /> g) threatens the unity, integrity, defence,
                      security or sovereignty of India, friendly relations with
                      foreign states, or public order, or causes incitement to
                      the commission of any cognisable offence or prevents
                      investigation of any offence or is insulting other nation;
                      <br />
                      <br /> h) contains software virus or any other computer
                      code, file or program designed to interrupt, destroy or
                      limit the functionality of any computer resource; or{" "}
                      <br />
                      <br />
                      i) is patently false and untrue, and is written or
                      published in any form, with the intent to mislead or
                      harass a person, entity or agency for financial gain or to
                      cause any injury to any person.
                      <br />
                      <br /> 2.2. The User shall not attempt to decompile or
                      disassemble or reverse engineer any of the software
                      available on the Website. You will not make any attempt to
                      hack into the Website or otherwise attempt to subvert any
                      firewall or any other security measure of the Website and
                      in the event the User becomes aware of any breach of the
                      security on the Website, the User shall forthwith inform
                      Us of such event in writing without any delay.
                      <br />
                      <br /> 2.3. The User shall not use the Website in a manner
                      for any other purpose which circumvents any of Our
                      technical/administrative/security measures or
                      disrupts/degrades the performance of the Website or tests
                      the vulnerability of Our system or network or in a manner
                      that may damage/overburden/block/impair the
                      server/Website.
                      <br />
                      <br />
                      2.4. The User agrees to not use any device or program or
                      process to access, save, download, copy any material or
                      portion of the Website. We reserve the right to block and
                      terminate any such activity as and when detected or deemed
                      appropriate.
                      <br />
                      <br /> 2.5. The User shall not make any defamatory
                      statement about Us or the Website or act in a manner that
                      may tarnish Our reputation or dilute Our intellectual
                      property in any manner.
                      <br />
                      <br /> 2.6. The User acknowledges, agrees and confirms
                      that the User shall not engage in solicitation of other
                      users of this Website in any manner for engaging in
                      activities/promotional tactics in relation to the
                      services/products being offered by any such User including
                      by display of such services/products on the Website.
                      <br />
                      <br /> 2.7. The User understands and acknowledges that in
                      the event of non-compliance with applicable laws, User
                      Terms, privacy policy or breach of any other policy as
                      regards the usage of this Website, We have the right to
                      terminate the User’s access or usage rights of the Website
                      with immediate effect without any notice and/or remove
                      non-compliant information and take necessary actions
                      against such Users who are involved in such
                      non-compliance.
                      <br />
                      <br /> 2.8. The User understands that the information
                      shared by the User may be shared by Us for complying with
                      any applicable law/order/direction of any Court/Tribunal
                      and/or for investigation of any alleged illegal/unlawful
                      activity that may have been carried out on the Website or
                      in relation to the Services. Further, the Users
                      acknowledge that We shall not be liable in the event any
                      User is involuntarily exposed to illegal/obscene/unlawful
                      material transmitted by another user on the Website.
                      <br />
                      <br /> 2.9. The User acknowledges that We may contact
                      third party payment processing service providers to
                      facilitate payment of orders/payment for availing of
                      services that may be placed by You on the Website which
                      includes payment gateway service providers, banks,
                      financial institution etc. You shall be responsible to
                      abide by all rules/regulations/guidelines as issued by
                      such third parties.
                      <br />
                      <br />
                      <h5 className="fw"> 3. INDEMNIFICATION</h5>
                      <br />
                      3.1. The User understands, acknowledges, agrees and
                      confirms that the submission of information, availing of
                      services and use of the Website is done at the sole risk
                      of the User, and We and/or Our directors, employees,
                      agents, partners, affiliates, representatives, officers,
                      contractors shall not be liable for any
                      injury/loss/claim/costs/damages (direct or indirect) that
                      are suffered or may be suffered by the User as a result of
                      or in relation to the aforesaid.
                      <br />
                      <br /> 3.2. The User shall not hold Us and Our directors,
                      employees, agents, partners, affiliates, representatives,
                      officers, contractors responsible for any unforeseen
                      technical glitches including but not limited to Website
                      being down or inaccessible for maintenance, server errors,
                      speed of download or upload from/to the Website, any
                      viruses in the Website etc. that might affect any activity
                      on the Website including any transactions on the Website.
                      <br />
                      <br /> 3.3. The User agrees to indemnify and hold Us and
                      Our directors, employees, agents, partners, affiliates,
                      representatives, officers, contractors harmless from any
                      claim, demand, costs, liabilities, penalties or damage,
                      including reasonable attorney’s fees, asserted by any
                      third party due to or arising out of use of or conduct by
                      the User of the Website. Client hereby further agrees to
                      indemnify and hold Us and our directors, employees,
                      agents, partners, affiliates, representatives, officers or
                      contractors harmless from any actions initiated by any
                      third party on account of any
                      ineffective/inaccurate/misleading/false data or
                      information provided by the User or misrepresentation by
                      the User or otherwise from the submissions made by the
                      User or infringement by the User of any third-party
                      intellectual property rights.
                      <br />
                      <br /> 3.4. The User agrees to keep indemnified and hold
                      Us and Our directors, employees, agents, partners,
                      affiliates, representatives, officers, contractors free
                      and harmless at all times against all monetary and other
                      harm, injury, costs, losses, liabilities, damages,
                      charges, actions, legal proceedings, claims and expenses
                      and consequences including without limitation any
                      attorney’s fees, whether direct or indirect, by reason of
                      using this Website and doing and/or omitting to do
                      anything in breach of or contrary to the terms specified
                      herein or any instructions relating to the online dealing
                      in products/services through the Website including
                      compliance with applicable law.
                      <br />
                      <br /> 3.5. We will not be liable for any direct,
                      indirect, punitive, incidental, special or consequential
                      damages or for any damages whatsoever based on contract,
                      tort, negligence, strict liability or otherwise including
                      damages for loss of use/data/profits, unauthorised access
                      to, or alteration of your data, or Your inability to use
                      the Website or any other loss arising out of or in any way
                      connected with the access, use or performance of the
                      Website.
                      <br />
                      <br /> <h5 className="fw">4. ADVERTISEMENTS AND LINKS</h5>
                      <br />
                      4.1. The User understands and acknowledges that We may
                      allow third party advertisers to advertise on the Website
                      in terms of Our policy in this regard, and the
                      content/material/advertisements posted by such advertisers
                      shall not be reviewed/verified by Us.
                      <br />
                      <br /> 4.2. We shall not be liable for the material
                      advertised by the third-party advertisers, and disclaim
                      all liability arising out of the third-party
                      advertisements on this Website. The Users may rely on the
                      third-party advertisement or the products/services being
                      offered through such advertisements solely on their own
                      risk. We do not represent or provide warranty or statement
                      of fitness or quality in relation to the product/services
                      being offered through such advertisements in any manner.
                      <br />
                      <br /> 4.3. The User understands, agrees and confirms that
                      the Website may contain links to the website of third
                      parties and the User may be redirected to such websites in
                      the event the User clicks on such links. The User may also
                      receive other messages/information/promotional
                      offers/discounts from such third-party advertisers. The
                      User acknowledges that We do not warrant or represent that
                      the content or advertisements of third-party advertisers
                      are accurate, true and correct or complete in any manner
                      and the User may obtain independent professional advice at
                      its own cost in relation thereto. The User acknowledges
                      that we have no control over, and are not liable or
                      responsible for or endorse the services/products,
                      information, offers, material being offered by the third
                      party advertisers, and that the User shall be liable for
                      all activities and/or transactions with such third- party
                      advertisers.
                      <br />
                      <br />
                      4.4. The User understands that We shall not be liable in
                      the event any information/offer/status update is not
                      available to a User. We shall not be liable for incorrect
                      email address or phone number for communication or for any
                      other incorrect information provided by the User.
                      <br />
                      <br />
                      4.5. The User understands, acknowledges, agrees and
                      confirms that any communication or transaction or dealing
                      with the third-party advertisers shall be at the sole risk
                      of the User and We shall not have any liability in any
                      manner in relation thereto. <br />
                      <br />
                      <h5 className="fw">5. USER CONCERN MANAGEMENT</h5>
                      <br />
                      5.1. Any query/feedback/suggestion may be raised by the
                      User on this email address: accounts@alt-mobility.com.
                      <br />
                      <br /> 5.2. The User understands that any discrepancy in
                      the services offered through the Website should be
                      reported latest within 7 (seven) days from the occurrence
                      of the issue/discrepancy. The User agrees and understands
                      that only genuine issues/discrepancies shall be
                      communicated, and no false/hoax claims be raised. We shall
                      not have any liability including to respond in the event
                      such issues/discrepancies are raised through a channel
                      other than as mentioned herein or through any other
                      platform/social media. We shall have no liability as
                      regards wrongful or baseless claims raised by the Users
                      including in relation to the Website or the services being
                      offered through website.
                      <br />
                      <br /> 5.3. We shall endeavour to respond to all
                      issues/discrepancies and resolve the same at the earliest.
                      The Users understand and acknowledge that the issues
                      raised will be resolved basis the severity and 6 urgency
                      involved. However, we disclaim all liability for any
                      damages or losses that may be suffered in the event the
                      User is not satisfied with the time taken in responding to
                      or resolving the issue.
                      <br />
                      <br />{" "}
                      <h5 className="fw">6. GRIEVANCE REDRESSAL MECHANISM </h5>
                      <br />
                      6.1. In the event of any complaint/information/concern as
                      regards breach of the User Terms or any other matter
                      pertaining to the Website, You are requested to file a
                      complaint with Mr. Manas Arora ("Chief Financial Officer")
                      through email at accounts@alt-mobility.com with your
                      electronic signature or in writing at the following
                      address to:
                      <br />
                      <br /> Name and designation: Manas Arora, Chief Financial
                      Officer
                      <br />
                      <br /> Address: 3B5 Research and Innovation park, IIT
                      Delhi, Delhi 110016
                      <br />
                      <br />
                      6.2. The Grievance Offer shall respond to the complaint
                      within 24 (twenty four) hours from the receipt of the
                      complaint, and take all steps (to the extent practicable)
                      and as required in terms of applicable law to resolve the
                      complaint.
                      <br />
                      <br /> <h5 className="fw">7. INTELLECTUAL PROPERTY </h5>
                      <br />
                      7.1. All rights to the Website, the content displayed on
                      the Website including in relation to the services being
                      provided are Our intellectual property. These intellectual
                      property in all material provided on the Website
                      (excluding any applicable third-party materials) is held
                      by Us. Except as expressly permitted herein or on the
                      Website, none of the material provided on the Website may
                      be copied, reproduced, distributed, republished,
                      downloaded, displayed, posted, transferred or transmitted
                      in any form or by any means, including, but not limited
                      to, electronic, mechanical, photocopying, recording, or
                      otherwise, without Our prior written permission. Further,
                      You shall not transfer, reverse engineer, decompile,
                      disassemble, modify or create derivate works based on the
                      material provided on the Website.
                      <br />
                      <br /> 7.2. The User also agrees not to use any
                      information available on the Website for any unlawful
                      purpose, and to comply with all instructions and
                      directions issued under these User Terms.
                      <br />
                      <br />
                      7.3. Nothing contained herein provides the User with any
                      right, title and interests of license, assignment or
                      ownership in the software/Website and all intellectual
                      property rights therein, other than as may be specified
                      allowed.
                      <br />
                      <br /> 7.4. These User Terms do not convey to the User any
                      rights of ownership in or related to the Website or
                      services to be provided by Us or any intellectual property
                      rights owned by Us. The User shall be solely responsible
                      for any violations of any laws and for any infringements
                      of any intellectual property rights caused by use of the
                      services or the Website.
                      <br />
                      <br />
                      <h5 className="fw"> 8. GENERAL TERMS AND CONDITIONS</h5>
                      <br />
                      8.1. We shall not be liable for any failure and/or delay
                      in performance of services or interrupted/delayed access
                      to the Website if that failure and/or delay is due to
                      reasons or circumstances beyond Our control.
                      <br />
                      <br /> 8.2. These User Terms may be amended from time to
                      time. We reserve the right to update the Website including
                      for increasing efficiency, optimising user interface and
                      addition of new features. The User is responsible for
                      remaining updated with the User Terms and privacy policy
                      at all times.
                      <br />
                      <br /> 8.3. In the event any term/provision of the User
                      Terms becomes or is declared illegal, invalid or
                      unenforceable by a court of competent jurisdiction, such
                      illegality, invalidity or unenforceability shall not
                      affect the validity of the other provisions of the User
                      Terms.
                      <br />
                      <br /> 8.4. These User Terms will be governed by the laws
                      of India. The User agrees to irrevocably submit to the
                      exclusive jurisdiction of the courts in New Delhi in the
                      event of any disputes arising as a result of breach of
                      these User Terms or as regards the validity and/or
                      interpretation of these terms of use.
                      <br />
                      <br /> 8.5. All disputes arising out of or in connection
                      with these User Terms shall be first endeavoured to be
                      settled through discussion and negotiation between Our
                      management and the User. In the event any dispute arising
                      between the parties cannot be amicably settled within 30
                      (third) days, the same shall be resolved by arbitration in
                      accordance with the Arbitration and Conciliation Act,
                      1996. The dispute shall be referred to 1 (one) arbitrator
                      mutually agreed to be appointed between the parties. To
                      the extent the parties are unable to agree on a single
                      arbitrator within 30 (thirty) days following submission of
                      the dispute, then the Arbitrator shall be appointed as per
                      the provisions of the Act. The place of arbitration shall
                      be New Delhi. The language of the arbitration will be
                      English. The decision of the arbitrator will be final and
                      binding. The costs of the arbitration shall be borne by
                      the parties to the dispute in such manner as the
                      arbitrator shall direct in their arbitral award.
                      <br />
                      <br /> 8.6. Nothing shall preclude a party from seeking
                      any other remedy in terms of applicable law. The pursuit
                      of equitable or injunctive relief shall not be a waiver of
                      the duty of the parties to pursue any remedy for monetary
                      losses through the arbitration.
                      <br />
                      <br /> 8.7. Any delay in or failure of performance by Us
                      under this Agreement will not be considered as a breach of
                      the User Terms and will be excused to the extent caused by
                      any occurrence or an event beyond Our control, including,
                      but not limited to, Act of God, outbreak of disease, power
                      outages, failures of the internet or relevant technology,
                      failure of banking channel or any other unforeseeable
                      event.
                      <br />
                      <br /> 8.8. Except as expressly provided in any other
                      written agreement with the User, these User Terms
                      constitute the entire agreement with respect to the use of
                      the Website.
                      <br />
                      <br /> 8.9. Any failure by Us to enforce or exercise any
                      provision of the User Terms or any related right, shall
                      not constitute a waiver by Us of that provision or right.
                      <br />
                      <br /> <h5 className="fw"> 9. TERMINATION </h5>
                      <br />
                      9.1. The User acknowledges, agrees and confirms that We
                      may, in our sole discretion and without assigning any
                      reason, terminate or block the access to the Website (in
                      whole or part) or availing of services by the User, and
                      remove and discard all or any part of the account of the
                      User or any information/content uploaded/submitted by the
                      User at any time. The User agrees that pursuant to or as a
                      result of such blockage/termination of access to the
                      Website, We will not be liable to the User or any third
                      party in relation thereto in any manner.
                      <br /> 9.2. We reserve the right to terminate or block the
                      access to the Website (in whole or part) or availing of
                      services by the User for any reason including in the event
                      the User has provided incorrect, false or invalid
                      information or if the User has breached these User Terms
                      or applicable laws. The User acknowledges and agrees that
                      as a result of such termination/blockage, We may
                      immediately deactivate, block access to or delete account
                      created by the User and bar access to all the information
                      submitted by You on the website.
                      <br />
                      <br /> 9.3. This right of termination/blockage/suspension
                      is in addition to any other remedies that We may have in
                      terms of applicable laws. The termination of these User
                      Terms does not prejudice the rights which have accrued
                      prior to such termination.
                      <br />
                      <br /> 9.4. The User has the option to terminate his
                      account or request removal of data/information submitted
                      through the Website itself.
                    </p>
                  </div>
                  {/* <button
                    className="closeButton"
                    onClick={() => setShowTermsPopup(false)}
                    style={{
                      borderRadius: "100%",
                      fontSize: "20px",
                    }}
                  >
                    x
                  </button> */}
                  <button
                    type="button"
                    class="close"
                    aria-label="Close"
                    onClick={() => setShowTermsPopup(false)}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              </div>
            )}

            {showPrivacyPopup && (
              <div className="popup">
                <div className="popupContent">
                  <h2>Privacy Policy</h2>
                  <div className="popupScroll">
                    <p>
                      This Privacy Policy of ALT NRG Private Limited
                      (hereinafter referred to as “
                      <span className="fw">We</span>”, “
                      <span className="fw">Us</span>”, “
                      <span className="fw">Our</span>” or the “
                      <span className="fw">Company</span>” as the context may
                      require, which includes, our affiliates and group
                      companies) is an integral part of terms of use of this
                      website available on{" "}
                      <span className="fw">
                        <a href=" https://alt-mobility.com">
                          {" "}
                          https://alt-mobility.com
                        </a>
                      </span>
                      , and provides information pertaining to the data
                      collected, stored, used, handled and shared by the Company
                      through this website at the time of access of this website
                      by You (hereinafter referred to as “
                      <span className="fw">You</span>” or “
                      <span className="fw">Your</span>” “
                      <span className="fw">User</span>” as the context may
                      require).
                      <br />
                      <br /> <h5 className="fw">1. OBJECTIVE OF THE POLICY</h5>
                      <br /> 1.1. The Company believes in following the highest
                      standards for protecting the privacy of its Users, and is
                      committed to take all steps/measures and follow best
                      practices to secure the data provided to Us. The core
                      objective of this policy is to ensure that the Users
                      understand and are apprised of, the kind/nature of
                      data/information collected by the Company, the purpose of
                      such collection, usage, processing and disclosure of the
                      data/information, the security practices adopted by the
                      Company and the rights of the User in relation thereto.
                      <br />
                      <br /> 1.2. We request all Users to please read this
                      policy for accessing, using, registering on this website
                      or submitting any information/data or availing any
                      products/services/promotions through this website.
                      <br />
                      <br /> 1.3. By using this website or registering on the
                      website or submitting any information/data, the Users
                      unconditionally accept and consent to such use,
                      collection, processing and storage of the information/data
                      by the Company, and agree that no other consent or
                      signature is required in relation to such
                      data/information.
                      <br />
                      <br />{" "}
                      <h5 className="fw">
                        2. WHEN IS INFORMATION COLLECTED BY US?
                      </h5>
                      <br /> 2.1. We collect information at the time of
                      access/use of this website by the Users, at the time You
                      create a user account with Us, availing or subscription to
                      services/goods/promotions by the Users through this
                      website or submission of information (personal or
                      non-personal in an explicit manner or otherwise) on the
                      website, to provide our Users with improved services, and
                      to enhance/develop our processes for providing the
                      services in India and worldwide.
                      <br />
                      <br />
                      <h5 className="fw">
                        3. WHICH INFORMATION IS COLLECTED BY US?{" "}
                      </h5>
                      <br />
                      <span className="fw">
                        <i>Usage related information:</i>
                      </span>
                      <br />
                      <br /> 3.1. Certain information (which may be
                      non-personal) may be automatically collected by the
                      Company during Your access of the website including
                      specific functions/information which may become available
                      on the website using temporary cookies/other technology
                      (to facilitate and optimize the use of this website) by
                      recording/storing in the log files of your system/device,
                      such as information including:
                      <br />
                      <br /> (a) internet protocol address (IP address) of the
                      computer/device,
                      <br /> (b) the browser/search term,
                      <br /> (c) query/website through which this Website has
                      been accessed,
                      <br /> (d) information/analysis of the usage
                      pattern/preference of products/services being offered by
                      the Company,
                      <br /> (e) internet usage,
                      <br /> (f) location of the User (if allowed), or <br />
                      (g) timings of access, etc. <br />
                      <br /> 3.2. Please note that such information does not
                      specifically identify the User or collect any personal
                      information of the User, and collects limited
                      information/data as regards usage of the website by such
                      User.
                      <br />
                      <br />{" "}
                      <span className="fw">
                        <i>Personal information:</i>
                      </span>{" "}
                      <br />
                      <br />
                      3.3. You may provide personal information to the Company
                      for using the website and/or availing the
                      products/services that are being provided by the Company,
                      which pertain specifically to You and can be used to
                      identify You (directly or indirectly), such as
                      <br /> (a) name,
                      <br /> (b) gender,
                      <br /> (c) date of birth,
                      <br /> (d) residence/email address, <br />
                      (e) occupation,
                      <br /> (f) passwords,
                      <br /> (g) financial information relating to bank
                      accounts/credit or debit cards/payment instruments, <br />
                      (h) health condition (physical or mental),
                      <br /> (i) medical information, or
                      <br /> (j) biometric information etc.
                      <br />
                      <br /> 3.4. However, please note that the information
                      which is freely available or accessible in public domain
                      or furnished in terms of the applicable law shall not be
                      construed as sensitive personal data.
                      <br />
                      <br /> 3.5. You hereby agree, acknowledge and confirm that
                      You are unconditionally accepting
                      collection/handling/usage/sharing of such information with
                      the Company. You also acknowledge that such consent can be
                      withdrawn or modified in writing by contacting the
                      Company.
                      <br />
                      <br /> 3.6. You hereby acknowledge and confirm that the
                      personal information has been voluntarily submitted by
                      You, and represent that the information
                      submitted/provided/entered is updated, correct and true in
                      all respects. In the event any information provided is
                      incorrect or outdated, You agree to contact the Company
                      for updating such Information and/or correcting any
                      inaccurate or deficient information. You may contact us in
                      the event you want to review the information submitted by
                      you.
                      <br />
                      <br /> 3.7. You hereby acknowledge that you may use the
                      website without providing such personal information as
                      well, if such information is not specifically required to
                      be provided for the activity/task intended to be
                      performed. In the event any activity/task requires
                      mandatory submission of personal information, kindly
                      abstain from providing/entering any such information if
                      You do not agree.
                      <br />
                      <br /> 3.8. You hereby acknowledge that the submission of
                      personal information by You to the Company means You have
                      consented to and agreed that such information can be
                      processed and used by the Company.
                      <br />
                      <br /> 3.9. This information will be used by the Company
                      at all times in compliance with the applicable law, and to
                      expand/improve/develop Our relationship with You or to
                      make this website more convenient to use and user
                      friendly. The Company endeavours to take all measures to
                      secure/protect the privacy of the User and protect such
                      data/information.
                      <br />
                      <br />
                      <h5 className="fw">
                        4. DO WE SHARE YOUR INFORMATION WITH THIRD PARTIES?
                      </h5>
                      <br /> 4.1. Information is shared with third parties by Us
                      only when it is necessary or to provide the services to
                      the Users or to fulfill the purpose for which such
                      information has been provided by the Users. Unless
                      authorized to disclose personal information by Your
                      consent or by applicable law, we do not share personal
                      information of the Users outside to any other person
                      outside of the Company. Such information disclosed to a
                      third party will not be further disclosed/shared by the
                      third party without Our express consent.
                      <br />
                      <br /> 4.2. However, in the event the information is
                      required to be disclosed pursuant to applicable law or due
                      to a notice/communication from the government authorities
                      or due to an Order/direction/notice by a court/tribunal,
                      we will share/disclose the information to the relevant
                      authority/court/tribunal/any other entity without Your
                      consent.
                      <br />
                      <br />{" "}
                      <h5 className="fw">5. SECURITY MEASURES TAKEN BY US</h5>
                      <br /> 5.1. We are committed to ensure the safety and
                      security of Your data/information that may be shared with
                      or collected by Us against any unauthorized access,
                      disclosure or loss/alteration of data/information. Such
                      data is not retained by us for any longer than is required
                      for providing our services and/or to maintain a
                      relationship with the Users, or as is otherwise required
                      under the applicable law.
                      <br />
                      <br />
                      5.2. We take all appropriate and necessary measures for
                      safe collection and storing of such data and comply with
                      our information security policies and including by
                      ensuring that necessary controls are in place and using
                      encryption technology. We ensure that the data/information
                      provided to us is used in terms of this policy and
                      applicable law, and to enable us to provide the services
                      to our Users.
                      <br />
                      <br /> <h5 className="fw">6. ADDITIONAL POINTS</h5>
                      <br /> 6.1. Please note that this Privacy Policy is only
                      for this website, and for no other third party
                      websites/links/advertisements that you may access through
                      Our website. Such websites may be hosted by the relevant
                      third party, and we will not have any control over its
                      privacy policy.
                      <br />
                      <br /> <h5 className="fw">7. GRIEVANCE MECHANISM</h5>
                      <br /> 7.1. Kindly share any discrepancy or grievance that
                      you may have as regards this policy or
                      sharing/collection/usage of information with the Grievance
                      Officer in writing or through email to
                      accounts@alt-mobility.com: Grievance Officer: Mr. Manas
                      Arora <br />
                      <br />
                      Name: Manas Arora
                      <br /> Address: 3B5 Research and Innovation Park, IIT
                      Delhi, Delhi, 110016
                      <br /> Email: accounts@alt-mobility.com <br />
                      <br />
                      <h5 className="fw">8. CHANGES TO THE POLICY</h5>
                      <br /> 8.1. We request you to please be apprised as
                      regards this policy on a regular basis and/or any
                      changes/amendments that may be made by us to improve our
                      services/business or to comply with the applicable law. We
                      reserve the right to amend this Policy with or without
                      notice.
                    </p>
                  </div>

                  {/* <button
                    className="closeButton"
                    onClick={() => setShowPrivacyPopup(false)}
                    style={{
                      borderRadius: "100%",
                      fontSize: "20px",
                    }}
                  >
                    x
                  </button> */}
                  <button
                    type="button"
                    class="close"
                    aria-label="Close"
                    onClick={() => setShowPrivacyPopup(false)}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              </div>
            )}
          </div>

          <div className="copyright-small ">
            <div className="copyright-small-edit d-flex justify-content-between mt-2">
              <div className="link-hover">
                <p
                  className="terms clk"
                  onClick={() => setShowTermsPopup(true)}
                >
                  Terms & Conditions
                </p>
              </div>
              <div className="link-hover">
                <p
                  className="terms clk"
                  onClick={() => setShowPrivacyPopup(true)}
                >
                  Privacy Policy
                </p>
              </div>
              {showTermsPopup && (
                <div className="popup">
                  <div className="popupContent">
                    <h2>Terms & Conditions</h2>
                    <div className="popupScroll">
                      <p>
                        {/* Add your terms and conditions content here */}
                        {/* Example: */}
                        These Terms and Conditions (“
                        <span className="fw">User Terms</span>”), as may be
                        modified from time to time, sets forth the terms and
                        conditions that govern the access and use of this
                        website &nbsp;
                        <span className="fw">
                          <a href=" https://alt-mobility.com">
                            {" "}
                            https://alt-mobility.com
                          </a>
                        </span>{" "}
                        and availing of services on this Website by you, the
                        Customer/User{" "}
                        <span className="fw">(“You/Your/Yours/User”</span>, as
                        the context may require). The Website is owned, managed
                        and operated by{" "}
                        <span className="fw">
                          ALT NRG Private Limited (“We/Our”)
                        </span>
                        , a company incorporated under the provision of the
                        Companies Act, 2013, with Corporate Identity Number{" "}
                        <span className="fw">U40106DL2020PTC364111</span> and
                        having its registered office at 335 DDA Flats, Jai Dev
                        Park East Punjabi Bagh, Punjabi Bagh Sec - III, New
                        Delhi,West Delhi, Delhi, India-110026.
                        <br />
                        <br />
                        These User Terms have been published in terms of the
                        Information Technology (Intermediary Guidelines and
                        Digital Media Ethics Code) Rules, 2021, and if You
                        continue to use/access the website and/or avail our
                        services offered through this Website, you are agreeing
                        to consent to, and abide by the User Terms. In the event
                        You do not agree with the User Terms, you are requested
                        not to use/access the Website and/or avail our services.
                        You are also requested to refer to the Privacy Policy
                        (available on{" "}
                        <i>[URL of the website to be mentioned]</i>).
                        <br />
                        <br />
                        We reserve the right to amend/modify these User Terms
                        (in part/full) and/or restrict/amend/discontinue the
                        usage/access to this Website/any features of this
                        Website without assigning any reason and without
                        notification of such amendment/restriction to any User.
                        The Users should check/go through/be familiar with the
                        User Terms at all times and be updated as regards the
                        terms pertaining to this Website for accessing this
                        Website/availing any services on the Website.
                        <br />
                        <br />
                        <h5 className="fw">
                          1. ACKNOWLEDGMENT AND UNDERTAKING BY THE USER
                        </h5>
                        1.1. The User understand, acknowledge, agree and confirm
                        that:
                        <br />
                        <br />
                        a) The User is competent to contract and to avail the
                        services being provided through this Website as per
                        applicable laws. We reserve the right to demand proof of
                        age from You, and pending such proof, Your account maybe
                        suspended/blocked until such proof of age is provided to
                        us to Our satisfaction. Further, We reserve the right to
                        suspend or refuse to offer the services to any User at
                        any time.
                        <br />
                        <br />
                        b) We reserve the right to remove/cease to provide any
                        service from this Website or remove any
                        material/information provided on the Website without any
                        liability towards any User in any manner. You agree and
                        acknowledge that the information provided on this
                        Website is only to assist the User of the Website who
                        are interested in the services provided by Us.
                        <br />
                        <br />
                        c) The User shall comply with all applicable laws while
                        using this Website and/or availing Our services, and not
                        use the Website to encourage or promote or solicit or
                        perform illegal activity or to violate third party
                        rights.
                        <br />
                        <br /> d) Any information submitted by the User on this
                        Website including for availing the services shall be
                        true, valid, updated and correct in all aspects. We
                        reserve the right to suspend/block/terminate use of
                        Website or availment of services by any User if We have
                        reason to believe that the information provided is
                        incorrect, incomplete or false.
                        <br />
                        <br /> e) The User shall be solely liable and
                        responsible for the services that have been availed/will
                        be availed or any transaction that have taken place
                        through this Website, and shall be solely liable for any
                        losses/expenses/cost/claims/damages occurred or incurred
                        by the User/any other third party while using this
                        Website or availing any services, irrespective of
                        whether such losses/expenses/cost/claims/damages have
                        occurred with or without the knowledge of the User. We
                        shall not have any special relationship or fiduciary
                        duty to the User. <br />
                        <br /> f) The User shall not use, save, copy, or
                        reproduce any material/information/image from the
                        Website unless if the same is absolutely necessary for
                        availing the services. <br />
                        <br />
                        g) We shall not be liable for delay in the availing of
                        Services by the User in any manner including for reasons
                        beyond Our control.
                        <br />
                        <br /> h) We have the right to modify the services being
                        provided through this Website at any time without
                        assigning any reason or notifying the User.
                        <br />
                        <br /> 1.2. The User acknowledges that the
                        information/images/data/links/content made available or
                        provided through or described on this Website pertaining
                        to [name of the Company to be mentioned] and/or its
                        associate/subsidiary/group companies and/or services
                        being provided by Us including any marketing or
                        promotional material or any other item or service
                        provided through this Website has been provided on an
                        ‘as is where is’ basis, solely at the risk of the User
                        and without any representation or warranty of any kind
                        (express or implied) as regards the quality, fitness for
                        purpose and accuracy in any manner. We do not authorize
                        anyone to make a warranty on Our behalf and the User
                        shall not rely on any statement of warranty being made
                        by any other person or entity. The User acknowledges and
                        agrees that no forward looking statement/information
                        added/captured on the Website should be construed as a
                        guarantee of Our future performance or fitness of
                        services provided, and that the actual
                        result/performance of the product/quality of service can
                        differ materially from the forward looking statements.
                        <br />
                        <br /> 1.3. The Website may contain links of third party
                        websites controlled or operated by such third parties,
                        however, this should not be construed as a
                        representation as regards the correctness/fitness of the
                        content/services/information/product that is available
                        on such third party websites. We shall have no liability
                        in relation to such third party websites or for the
                        content/services/information/product provided through
                        such websites or for any software/information/data/image
                        downloaded by the User from this Website. The User
                        agrees to indemnity and keep indemnified Us and Our
                        affiliates/associate/subsidiary/group company against
                        all liabilities/expenses/costs/damages/claims which may
                        be raised against or incurred by Us as a result of
                        breach of these User Terms and/or Privacy Policy or use
                        of this Website. The User acknowledges that We shall not
                        be responsible for any financial loss or inconvenience
                        caused to the Users in the event of misuse/loss of their
                        registration details of this Website.
                        <br />
                        <br /> 1.4. The User understands and acknowledges that
                        We do not warranty the functionality of the Website,
                        uninterrupted or error free access to the Website. The
                        User understands and acknowledges that we do not
                        warranty that this Website is free of virus/any other
                        harmful element. The User acknowledges that the Website
                        may be inaccessible and/or unavailable due to any reason
                        at any time and We shall not be liable for any
                        damages/liability/action/suit/claim/demand/loss/cost/expenses
                        arising directly or indirectly from or in connection
                        with such inaccessibility and/or unavailability or as a
                        result of any
                        suspension/breakdown/interruption/technical flaw/the
                        presence of virus or other destructive code over the
                        Website or otherwise and/or any consequent delay or
                        failure as a consequence thereof arising from the
                        inability to use the Platform or avail the services.
                        <br />
                        <br /> 1.5. The User understands that the
                        data/material/information submitted by the User for
                        using the Website and/or to avail the services through
                        the Website becomes Our exclusive property. The User
                        agrees to usage and sharing of information shared by the
                        User on the Website as per the Privacy Policy available
                        on <i>[link of the Privacy Policy]</i>.
                        <br />
                        <br /> 1.6. The User authorizes us to call or email or
                        message the User on the registered mobile number/email
                        address in relation to the services being provided
                        through the Website or for providing
                        assistance/support/quality services. The User understand
                        that they may opt for updates/information in relation to
                        the services being offered by Us. The User also has the
                        option to opt for discontinuation of such
                        updates/information at any point of time by accessing
                        the Website.
                        <br />
                        <br /> 1.7. The User understands that the information,
                        material, suggestions, options in relation to the
                        services being offered by Us on the Website to the User
                        is for general information only and does not constitute
                        advice. We shall not be liable for any
                        damages/losses/cost resulting from the use of or from
                        the inability to use the Website or to avail the
                        services.
                        <br />
                        <br />
                        <h5 className="fw">
                          {" "}
                          2. USE OF THE PLATFORM BY THE USER
                        </h5>
                        <br />
                        2.1. The User agrees not to host, display, upload,
                        modify, publish, transmit, store, update or share any
                        information on the Website that:
                        <br />
                        <br /> a) belongs to another person and to which the
                        User does not have any right;
                        <br />
                        <br /> b) is defamatory, obscene, hateful, disparaging,
                        pornographic, paedophilic, invasive of the privacy of
                        another person, including bodily privacy, insult or
                        harassment on the basis of gender, libellous, racially
                        or ethnically objectionable, relating or encouraging
                        money laundering or gambling, or otherwise inconsistent
                        with or contrary to the applicable laws;
                        <br />
                        <br /> c) is harmful to child;
                        <br />
                        <br /> d) violative of applicable law;
                        <br />
                        <br /> e) deceives or misleads the person to whom such
                        information has been provided about the origin of the
                        message or knowingly and intentionally communicates any
                        information which is patently false or misleading in
                        nature but may reasonably be perceived as a fact;
                        <br />
                        <br /> f) impersonates any other person;
                        <br />
                        <br /> g) threatens the unity, integrity, defence,
                        security or sovereignty of India, friendly relations
                        with foreign states, or public order, or causes
                        incitement to the commission of any cognisable offence
                        or prevents investigation of any offence or is insulting
                        other nation;
                        <br />
                        <br /> h) contains software virus or any other computer
                        code, file or program designed to interrupt, destroy or
                        limit the functionality of any computer resource; or{" "}
                        <br />
                        <br />
                        i) is patently false and untrue, and is written or
                        published in any form, with the intent to mislead or
                        harass a person, entity or agency for financial gain or
                        to cause any injury to any person.
                        <br />
                        <br /> 2.2. The User shall not attempt to decompile or
                        disassemble or reverse engineer any of the software
                        available on the Website. You will not make any attempt
                        to hack into the Website or otherwise attempt to subvert
                        any firewall or any other security measure of the
                        Website and in the event the User becomes aware of any
                        breach of the security on the Website, the User shall
                        forthwith inform Us of such event in writing without any
                        delay.
                        <br />
                        <br /> 2.3. The User shall not use the Website in a
                        manner for any other purpose which circumvents any of
                        Our technical/administrative/security measures or
                        disrupts/degrades the performance of the Website or
                        tests the vulnerability of Our system or network or in a
                        manner that may damage/overburden/block/impair the
                        server/Website.
                        <br />
                        <br />
                        2.4. The User agrees to not use any device or program or
                        process to access, save, download, copy any material or
                        portion of the Website. We reserve the right to block
                        and terminate any such activity as and when detected or
                        deemed appropriate.
                        <br />
                        <br /> 2.5. The User shall not make any defamatory
                        statement about Us or the Website or act in a manner
                        that may tarnish Our reputation or dilute Our
                        intellectual property in any manner.
                        <br />
                        <br /> 2.6. The User acknowledges, agrees and confirms
                        that the User shall not engage in solicitation of other
                        users of this Website in any manner for engaging in
                        activities/promotional tactics in relation to the
                        services/products being offered by any such User
                        including by display of such services/products on the
                        Website.
                        <br />
                        <br /> 2.7. The User understands and acknowledges that
                        in the event of non-compliance with applicable laws,
                        User Terms, privacy policy or breach of any other policy
                        as regards the usage of this Website, We have the right
                        to terminate the User’s access or usage rights of the
                        Website with immediate effect without any notice and/or
                        remove non-compliant information and take necessary
                        actions against such Users who are involved in such
                        non-compliance.
                        <br />
                        <br /> 2.8. The User understands that the information
                        shared by the User may be shared by Us for complying
                        with any applicable law/order/direction of any
                        Court/Tribunal and/or for investigation of any alleged
                        illegal/unlawful activity that may have been carried out
                        on the Website or in relation to the Services. Further,
                        the Users acknowledge that We shall not be liable in the
                        event any User is involuntarily exposed to
                        illegal/obscene/unlawful material transmitted by another
                        user on the Website.
                        <br />
                        <br /> 2.9. The User acknowledges that We may contact
                        third party payment processing service providers to
                        facilitate payment of orders/payment for availing of
                        services that may be placed by You on the Website which
                        includes payment gateway service providers, banks,
                        financial institution etc. You shall be responsible to
                        abide by all rules/regulations/guidelines as issued by
                        such third parties.
                        <br />
                        <br />
                        <h5 className="fw"> 3. INDEMNIFICATION</h5>
                        <br />
                        3.1. The User understands, acknowledges, agrees and
                        confirms that the submission of information, availing of
                        services and use of the Website is done at the sole risk
                        of the User, and We and/or Our directors, employees,
                        agents, partners, affiliates, representatives, officers,
                        contractors shall not be liable for any
                        injury/loss/claim/costs/damages (direct or indirect)
                        that are suffered or may be suffered by the User as a
                        result of or in relation to the aforesaid.
                        <br />
                        <br /> 3.2. The User shall not hold Us and Our
                        directors, employees, agents, partners, affiliates,
                        representatives, officers, contractors responsible for
                        any unforeseen technical glitches including but not
                        limited to Website being down or inaccessible for
                        maintenance, server errors, speed of download or upload
                        from/to the Website, any viruses in the Website etc.
                        that might affect any activity on the Website including
                        any transactions on the Website.
                        <br />
                        <br /> 3.3. The User agrees to indemnify and hold Us and
                        Our directors, employees, agents, partners, affiliates,
                        representatives, officers, contractors harmless from any
                        claim, demand, costs, liabilities, penalties or damage,
                        including reasonable attorney’s fees, asserted by any
                        third party due to or arising out of use of or conduct
                        by the User of the Website. Client hereby further agrees
                        to indemnify and hold Us and our directors, employees,
                        agents, partners, affiliates, representatives, officers
                        or contractors harmless from any actions initiated by
                        any third party on account of any
                        ineffective/inaccurate/misleading/false data or
                        information provided by the User or misrepresentation by
                        the User or otherwise from the submissions made by the
                        User or infringement by the User of any third-party
                        intellectual property rights.
                        <br />
                        <br /> 3.4. The User agrees to keep indemnified and hold
                        Us and Our directors, employees, agents, partners,
                        affiliates, representatives, officers, contractors free
                        and harmless at all times against all monetary and other
                        harm, injury, costs, losses, liabilities, damages,
                        charges, actions, legal proceedings, claims and expenses
                        and consequences including without limitation any
                        attorney’s fees, whether direct or indirect, by reason
                        of using this Website and doing and/or omitting to do
                        anything in breach of or contrary to the terms specified
                        herein or any instructions relating to the online
                        dealing in products/services through the Website
                        including compliance with applicable law.
                        <br />
                        <br /> 3.5. We will not be liable for any direct,
                        indirect, punitive, incidental, special or consequential
                        damages or for any damages whatsoever based on contract,
                        tort, negligence, strict liability or otherwise
                        including damages for loss of use/data/profits,
                        unauthorised access to, or alteration of your data, or
                        Your inability to use the Website or any other loss
                        arising out of or in any way connected with the access,
                        use or performance of the Website.
                        <br />
                        <br />{" "}
                        <h5 className="fw">4. ADVERTISEMENTS AND LINKS</h5>
                        <br />
                        4.1. The User understands and acknowledges that We may
                        allow third party advertisers to advertise on the
                        Website in terms of Our policy in this regard, and the
                        content/material/advertisements posted by such
                        advertisers shall not be reviewed/verified by Us.
                        <br />
                        <br /> 4.2. We shall not be liable for the material
                        advertised by the third-party advertisers, and disclaim
                        all liability arising out of the third-party
                        advertisements on this Website. The Users may rely on
                        the third-party advertisement or the products/services
                        being offered through such advertisements solely on
                        their own risk. We do not represent or provide warranty
                        or statement of fitness or quality in relation to the
                        product/services being offered through such
                        advertisements in any manner.
                        <br />
                        <br /> 4.3. The User understands, agrees and confirms
                        that the Website may contain links to the website of
                        third parties and the User may be redirected to such
                        websites in the event the User clicks on such links. The
                        User may also receive other
                        messages/information/promotional offers/discounts from
                        such third-party advertisers. The User acknowledges that
                        We do not warrant or represent that the content or
                        advertisements of third-party advertisers are accurate,
                        true and correct or complete in any manner and the User
                        may obtain independent professional advice at its own
                        cost in relation thereto. The User acknowledges that we
                        have no control over, and are not liable or responsible
                        for or endorse the services/products, information,
                        offers, material being offered by the third party
                        advertisers, and that the User shall be liable for all
                        activities and/or transactions with such third- party
                        advertisers.
                        <br />
                        <br />
                        4.4. The User understands that We shall not be liable in
                        the event any information/offer/status update is not
                        available to a User. We shall not be liable for
                        incorrect email address or phone number for
                        communication or for any other incorrect information
                        provided by the User.
                        <br />
                        <br />
                        4.5. The User understands, acknowledges, agrees and
                        confirms that any communication or transaction or
                        dealing with the third-party advertisers shall be at the
                        sole risk of the User and We shall not have any
                        liability in any manner in relation thereto. <br />
                        <br />
                        <h5 className="fw">5. USER CONCERN MANAGEMENT</h5>
                        <br />
                        5.1. Any query/feedback/suggestion may be raised by the
                        User on this email address: accounts@alt-mobility.com.
                        <br />
                        <br /> 5.2. The User understands that any discrepancy in
                        the services offered through the Website should be
                        reported latest within 7 (seven) days from the
                        occurrence of the issue/discrepancy. The User agrees and
                        understands that only genuine issues/discrepancies shall
                        be communicated, and no false/hoax claims be raised. We
                        shall not have any liability including to respond in the
                        event such issues/discrepancies are raised through a
                        channel other than as mentioned herein or through any
                        other platform/social media. We shall have no liability
                        as regards wrongful or baseless claims raised by the
                        Users including in relation to the Website or the
                        services being offered through website.
                        <br />
                        <br /> 5.3. We shall endeavour to respond to all
                        issues/discrepancies and resolve the same at the
                        earliest. The Users understand and acknowledge that the
                        issues raised will be resolved basis the severity and 6
                        urgency involved. However, we disclaim all liability for
                        any damages or losses that may be suffered in the event
                        the User is not satisfied with the time taken in
                        responding to or resolving the issue.
                        <br />
                        <br />{" "}
                        <h5 className="fw">
                          6. GRIEVANCE REDRESSAL MECHANISM{" "}
                        </h5>
                        <br />
                        6.1. In the event of any complaint/information/concern
                        as regards breach of the User Terms or any other matter
                        pertaining to the Website, You are requested to file a
                        complaint with Mr. Manas Arora ("Chief Financial
                        Officer") through email at accounts@alt-mobility.com
                        with your electronic signature or in writing at the
                        following address to:
                        <br />
                        <br /> Name and designation: Manas Arora, Chief
                        Financial Officer
                        <br />
                        <br /> Address: 3B5 Research and Innovation park, IIT
                        Delhi, Delhi 110016
                        <br />
                        <br />
                        6.2. The Grievance Offer shall respond to the complaint
                        within 24 (twenty four) hours from the receipt of the
                        complaint, and take all steps (to the extent
                        practicable) and as required in terms of applicable law
                        to resolve the complaint.
                        <br />
                        <br /> <h5 className="fw">7. INTELLECTUAL PROPERTY </h5>
                        <br />
                        7.1. All rights to the Website, the content displayed on
                        the Website including in relation to the services being
                        provided are Our intellectual property. These
                        intellectual property in all material provided on the
                        Website (excluding any applicable third-party materials)
                        is held by Us. Except as expressly permitted herein or
                        on the Website, none of the material provided on the
                        Website may be copied, reproduced, distributed,
                        republished, downloaded, displayed, posted, transferred
                        or transmitted in any form or by any means, including,
                        but not limited to, electronic, mechanical,
                        photocopying, recording, or otherwise, without Our prior
                        written permission. Further, You shall not transfer,
                        reverse engineer, decompile, disassemble, modify or
                        create derivate works based on the material provided on
                        the Website.
                        <br />
                        <br /> 7.2. The User also agrees not to use any
                        information available on the Website for any unlawful
                        purpose, and to comply with all instructions and
                        directions issued under these User Terms.
                        <br />
                        <br />
                        7.3. Nothing contained herein provides the User with any
                        right, title and interests of license, assignment or
                        ownership in the software/Website and all intellectual
                        property rights therein, other than as may be specified
                        allowed.
                        <br />
                        <br /> 7.4. These User Terms do not convey to the User
                        any rights of ownership in or related to the Website or
                        services to be provided by Us or any intellectual
                        property rights owned by Us. The User shall be solely
                        responsible for any violations of any laws and for any
                        infringements of any intellectual property rights caused
                        by use of the services or the Website.
                        <br />
                        <br />
                        <h5 className="fw"> 8. GENERAL TERMS AND CONDITIONS</h5>
                        <br />
                        8.1. We shall not be liable for any failure and/or delay
                        in performance of services or interrupted/delayed access
                        to the Website if that failure and/or delay is due to
                        reasons or circumstances beyond Our control.
                        <br />
                        <br /> 8.2. These User Terms may be amended from time to
                        time. We reserve the right to update the Website
                        including for increasing efficiency, optimising user
                        interface and addition of new features. The User is
                        responsible for remaining updated with the User Terms
                        and privacy policy at all times.
                        <br />
                        <br /> 8.3. In the event any term/provision of the User
                        Terms becomes or is declared illegal, invalid or
                        unenforceable by a court of competent jurisdiction, such
                        illegality, invalidity or unenforceability shall not
                        affect the validity of the other provisions of the User
                        Terms.
                        <br />
                        <br /> 8.4. These User Terms will be governed by the
                        laws of India. The User agrees to irrevocably submit to
                        the exclusive jurisdiction of the courts in New Delhi in
                        the event of any disputes arising as a result of breach
                        of these User Terms or as regards the validity and/or
                        interpretation of these terms of use.
                        <br />
                        <br /> 8.5. All disputes arising out of or in connection
                        with these User Terms shall be first endeavoured to be
                        settled through discussion and negotiation between Our
                        management and the User. In the event any dispute
                        arising between the parties cannot be amicably settled
                        within 30 (third) days, the same shall be resolved by
                        arbitration in accordance with the Arbitration and
                        Conciliation Act, 1996. The dispute shall be referred to
                        1 (one) arbitrator mutually agreed to be appointed
                        between the parties. To the extent the parties are
                        unable to agree on a single arbitrator within 30
                        (thirty) days following submission of the dispute, then
                        the Arbitrator shall be appointed as per the provisions
                        of the Act. The place of arbitration shall be New Delhi.
                        The language of the arbitration will be English. The
                        decision of the arbitrator will be final and binding.
                        The costs of the arbitration shall be borne by the
                        parties to the dispute in such manner as the arbitrator
                        shall direct in their arbitral award.
                        <br />
                        <br /> 8.6. Nothing shall preclude a party from seeking
                        any other remedy in terms of applicable law. The pursuit
                        of equitable or injunctive relief shall not be a waiver
                        of the duty of the parties to pursue any remedy for
                        monetary losses through the arbitration.
                        <br />
                        <br /> 8.7. Any delay in or failure of performance by Us
                        under this Agreement will not be considered as a breach
                        of the User Terms and will be excused to the extent
                        caused by any occurrence or an event beyond Our control,
                        including, but not limited to, Act of God, outbreak of
                        disease, power outages, failures of the internet or
                        relevant technology, failure of banking channel or any
                        other unforeseeable event.
                        <br />
                        <br /> 8.8. Except as expressly provided in any other
                        written agreement with the User, these User Terms
                        constitute the entire agreement with respect to the use
                        of the Website.
                        <br />
                        <br /> 8.9. Any failure by Us to enforce or exercise any
                        provision of the User Terms or any related right, shall
                        not constitute a waiver by Us of that provision or
                        right.
                        <br />
                        <br /> <h5 className="fw"> 9. TERMINATION </h5>
                        <br />
                        9.1. The User acknowledges, agrees and confirms that We
                        may, in our sole discretion and without assigning any
                        reason, terminate or block the access to the Website (in
                        whole or part) or availing of services by the User, and
                        remove and discard all or any part of the account of the
                        User or any information/content uploaded/submitted by
                        the User at any time. The User agrees that pursuant to
                        or as a result of such blockage/termination of access to
                        the Website, We will not be liable to the User or any
                        third party in relation thereto in any manner.
                        <br /> 9.2. We reserve the right to terminate or block
                        the access to the Website (in whole or part) or availing
                        of services by the User for any reason including in the
                        event the User has provided incorrect, false or invalid
                        information or if the User has breached these User Terms
                        or applicable laws. The User acknowledges and agrees
                        that as a result of such termination/blockage, We may
                        immediately deactivate, block access to or delete
                        account created by the User and bar access to all the
                        information submitted by You on the website.
                        <br />
                        <br /> 9.3. This right of
                        termination/blockage/suspension is in addition to any
                        other remedies that We may have in terms of applicable
                        laws. The termination of these User Terms does not
                        prejudice the rights which have accrued prior to such
                        termination.
                        <br />
                        <br /> 9.4. The User has the option to terminate his
                        account or request removal of data/information submitted
                        through the Website itself.
                      </p>
                    </div>
                    {/* <button
                      className="closeButton"
                      onClick={() => setShowTermsPopup(false)}
                      style={{
                        borderRadius: "100%",
                        fontSize: "20px",
                      }}
                    >
                      x
                    </button> */}
                    <button
                      type="button"
                      class="close"
                      aria-label="Close"
                      onClick={() => setShowTermsPopup(false)}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                </div>
              )}

              {showPrivacyPopup && (
                <div className="popup">
                  <div className="popupContent">
                    <h2>Privacy Policy</h2>
                    <div className="popupScroll">
                      <p>
                        This Privacy Policy of ALT NRG Private Limited
                        (hereinafter referred to as “
                        <span className="fw">We</span>”, “
                        <span className="fw">Us</span>”, “
                        <span className="fw">Our</span>” or the “
                        <span className="fw">Company</span>” as the context may
                        require, which includes, our affiliates and group
                        companies) is an integral part of terms of use of this
                        website available on{" "}
                        <span className="fw">
                          <a href=" https://alt-mobility.com">
                            {" "}
                            https://alt-mobility.com
                          </a>
                        </span>
                        , and provides information pertaining to the data
                        collected, stored, used, handled and shared by the
                        Company through this website at the time of access of
                        this website by You (hereinafter referred to as “
                        <span className="fw">You</span>” or “
                        <span className="fw">Your</span>” “
                        <span className="fw">User</span>” as the context may
                        require).
                        <br />
                        <br />{" "}
                        <h5 className="fw">1. OBJECTIVE OF THE POLICY</h5>
                        <br /> 1.1. The Company believes in following the
                        highest standards for protecting the privacy of its
                        Users, and is committed to take all steps/measures and
                        follow best practices to secure the data provided to Us.
                        The core objective of this policy is to ensure that the
                        Users understand and are apprised of, the kind/nature of
                        data/information collected by the Company, the purpose
                        of such collection, usage, processing and disclosure of
                        the data/information, the security practices adopted by
                        the Company and the rights of the User in relation
                        thereto.
                        <br />
                        <br /> 1.2. We request all Users to please read this
                        policy for accessing, using, registering on this website
                        or submitting any information/data or availing any
                        products/services/promotions through this website.
                        <br />
                        <br /> 1.3. By using this website or registering on the
                        website or submitting any information/data, the Users
                        unconditionally accept and consent to such use,
                        collection, processing and storage of the
                        information/data by the Company, and agree that no other
                        consent or signature is required in relation to such
                        data/information.
                        <br />
                        <br />{" "}
                        <h5 className="fw">
                          2. WHEN IS INFORMATION COLLECTED BY US?
                        </h5>
                        <br /> 2.1. We collect information at the time of
                        access/use of this website by the Users, at the time You
                        create a user account with Us, availing or subscription
                        to services/goods/promotions by the Users through this
                        website or submission of information (personal or
                        non-personal in an explicit manner or otherwise) on the
                        website, to provide our Users with improved services,
                        and to enhance/develop our processes for providing the
                        services in India and worldwide.
                        <br />
                        <br />
                        <h5 className="fw">
                          3. WHICH INFORMATION IS COLLECTED BY US?{" "}
                        </h5>
                        <br />
                        <span className="fw">
                          <i>Usage related information:</i>
                        </span>
                        <br />
                        <br /> 3.1. Certain information (which may be
                        non-personal) may be automatically collected by the
                        Company during Your access of the website including
                        specific functions/information which may become
                        available on the website using temporary cookies/other
                        technology (to facilitate and optimize the use of this
                        website) by recording/storing in the log files of your
                        system/device, such as information including:
                        <br />
                        <br /> (a) internet protocol address (IP address) of the
                        computer/device,
                        <br /> (b) the browser/search term,
                        <br /> (c) query/website through which this Website has
                        been accessed,
                        <br /> (d) information/analysis of the usage
                        pattern/preference of products/services being offered by
                        the Company,
                        <br /> (e) internet usage,
                        <br /> (f) location of the User (if allowed), or <br />
                        (g) timings of access, etc. <br />
                        <br /> 3.2. Please note that such information does not
                        specifically identify the User or collect any personal
                        information of the User, and collects limited
                        information/data as regards usage of the website by such
                        User.
                        <br />
                        <br />{" "}
                        <span className="fw">
                          <i>Personal information:</i>
                        </span>{" "}
                        <br />
                        <br />
                        3.3. You may provide personal information to the Company
                        for using the website and/or availing the
                        products/services that are being provided by the
                        Company, which pertain specifically to You and can be
                        used to identify You (directly or indirectly), such as
                        <br /> (a) name,
                        <br /> (b) gender,
                        <br /> (c) date of birth,
                        <br /> (d) residence/email address, <br />
                        (e) occupation,
                        <br /> (f) passwords,
                        <br /> (g) financial information relating to bank
                        accounts/credit or debit cards/payment instruments,{" "}
                        <br />
                        (h) health condition (physical or mental),
                        <br /> (i) medical information, or
                        <br /> (j) biometric information etc.
                        <br />
                        <br /> 3.4. However, please note that the information
                        which is freely available or accessible in public domain
                        or furnished in terms of the applicable law shall not be
                        construed as sensitive personal data.
                        <br />
                        <br /> 3.5. You hereby agree, acknowledge and confirm
                        that You are unconditionally accepting
                        collection/handling/usage/sharing of such information
                        with the Company. You also acknowledge that such consent
                        can be withdrawn or modified in writing by contacting
                        the Company.
                        <br />
                        <br /> 3.6. You hereby acknowledge and confirm that the
                        personal information has been voluntarily submitted by
                        You, and represent that the information
                        submitted/provided/entered is updated, correct and true
                        in all respects. In the event any information provided
                        is incorrect or outdated, You agree to contact the
                        Company for updating such Information and/or correcting
                        any inaccurate or deficient information. You may contact
                        us in the event you want to review the information
                        submitted by you.
                        <br />
                        <br /> 3.7. You hereby acknowledge that you may use the
                        website without providing such personal information as
                        well, if such information is not specifically required
                        to be provided for the activity/task intended to be
                        performed. In the event any activity/task requires
                        mandatory submission of personal information, kindly
                        abstain from providing/entering any such information if
                        You do not agree.
                        <br />
                        <br /> 3.8. You hereby acknowledge that the submission
                        of personal information by You to the Company means You
                        have consented to and agreed that such information can
                        be processed and used by the Company.
                        <br />
                        <br /> 3.9. This information will be used by the Company
                        at all times in compliance with the applicable law, and
                        to expand/improve/develop Our relationship with You or
                        to make this website more convenient to use and user
                        friendly. The Company endeavours to take all measures to
                        secure/protect the privacy of the User and protect such
                        data/information.
                        <br />
                        <br />
                        <h5 className="fw">
                          4. DO WE SHARE YOUR INFORMATION WITH THIRD PARTIES?
                        </h5>
                        <br /> 4.1. Information is shared with third parties by
                        Us only when it is necessary or to provide the services
                        to the Users or to fulfill the purpose for which such
                        information has been provided by the Users. Unless
                        authorized to disclose personal information by Your
                        consent or by applicable law, we do not share personal
                        information of the Users outside to any other person
                        outside of the Company. Such information disclosed to a
                        third party will not be further disclosed/shared by the
                        third party without Our express consent.
                        <br />
                        <br /> 4.2. However, in the event the information is
                        required to be disclosed pursuant to applicable law or
                        due to a notice/communication from the government
                        authorities or due to an Order/direction/notice by a
                        court/tribunal, we will share/disclose the information
                        to the relevant authority/court/tribunal/any other
                        entity without Your consent.
                        <br />
                        <br />{" "}
                        <h5 className="fw">5. SECURITY MEASURES TAKEN BY US</h5>
                        <br /> 5.1. We are committed to ensure the safety and
                        security of Your data/information that may be shared
                        with or collected by Us against any unauthorized access,
                        disclosure or loss/alteration of data/information. Such
                        data is not retained by us for any longer than is
                        required for providing our services and/or to maintain a
                        relationship with the Users, or as is otherwise required
                        under the applicable law.
                        <br />
                        <br />
                        5.2. We take all appropriate and necessary measures for
                        safe collection and storing of such data and comply with
                        our information security policies and including by
                        ensuring that necessary controls are in place and using
                        encryption technology. We ensure that the
                        data/information provided to us is used in terms of this
                        policy and applicable law, and to enable us to provide
                        the services to our Users.
                        <br />
                        <br /> <h5 className="fw">6. ADDITIONAL POINTS</h5>
                        <br /> 6.1. Please note that this Privacy Policy is only
                        for this website, and for no other third party
                        websites/links/advertisements that you may access
                        through Our website. Such websites may be hosted by the
                        relevant third party, and we will not have any control
                        over its privacy policy.
                        <br />
                        <br /> <h5 className="fw">7. GRIEVANCE MECHANISM</h5>
                        <br /> 7.1. Kindly share any discrepancy or grievance
                        that you may have as regards this policy or
                        sharing/collection/usage of information with the
                        Grievance Officer in writing or through email to
                        accounts@alt-mobility.com: Grievance Officer: Mr. Manas
                        Arora <br />
                        <br />
                        Name: Manas Arora
                        <br /> Address: 3B5 Research and Innovation Park, IIT
                        Delhi, Delhi, 110016
                        <br /> Email: accounts@alt-mobility.com <br />
                        <br />
                        <h5 className="fw">8. CHANGES TO THE POLICY</h5>
                        <br /> 8.1. We request you to please be apprised as
                        regards this policy on a regular basis and/or any
                        changes/amendments that may be made by us to improve our
                        services/business or to comply with the applicable law.
                        We reserve the right to amend this Policy with or
                        without notice.
                      </p>
                    </div>

                    {/* <button
                      className="closeButton"
                      onClick={() => setShowPrivacyPopup(false)}
                      style={{
                        borderRadius: "100%",

                        fontSize: "20px",
                      }}
                    >
                      x
                    </button> */}
                    <button
                      type="button"
                      class="close"
                      aria-label="Close"
                      onClick={() => setShowPrivacyPopup(false)}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                </div>
              )}
            </div>

            <div className="mt-5 text-center link-hover">
              <p className="terms">
                {" "}
                ©️ 2022 Alt Mobility. All rights reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeskTopFooter;








// import React from "react";
// import { Link } from "react-router-dom";

// const Footer = ({ activeLink, handleLinkClick }) => {
//   return (
//     <footer>
//       <nav>
//         <ul>
//           <li>
//             <Link
//               to="/fleet_leasing"
//               className={activeLink === "/fleet_leasing" ? "active" : ""}
//               onClick={() => handleLinkClick("/fleet_leasing")}
//             >
//               Footer Link 1
//             </Link>
//           </li>
//           <li>
//             <Link
//               to="/footer_link_2"
//               className={activeLink === "/" ? "active" : ""}
//               onClick={() => handleLinkClick("/footer_link_2")}
//             >
//               Footer Link 2
//             </Link>
//           </li>
//           {/* Add more footer links as needed */}
//         </ul>
//       </nav>
//     </footer>
//   );
// };

// export default Footer;

