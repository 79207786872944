import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import road from "../../assest/road.svg";
import roadmap from "../../assest/roodmap.svg";
import "../CSShtml/Custom.css";
import "../CSShtml/Home.css";
import Accelerators from "../HomeComp/Accelerators";
import HomeSlider from "../HomeComp/HomeSlider";
import LeasingGallery from "../HomeComp/LeasingGallery";
import   EVCalculator from "../HomeComp/EVCalculator";
import Altecosystem from "../HomeComp/Altecosystem";
import PressRelease from "../HomeComp/PressRelease";
import map from "../../assest/mapweb.jpg";

const DesktopView = () => {
  window.scrollTo(0, 0);
  const navigate = useNavigate();

  return (
    <div className="home-mainss">
      <div className="home-top-margin ">
        <div className="d-flex home-top justify-content-between container" style={{paddingTop:"4.7rem"}}>
          <div
            className="d-flex  electrify justify-content-center"
            // style={{ paddingTop: "6rem" }}
          >
            <div className="homeTopTitle  d-flex flex-row justify-content-center">
              <div>
                <h1>Electrify your Fleet</h1>
                <p>
                  Affordable, integrated fleet leasing solutions for your <br />{" "}
                  business and the planet.
                </p>
                <div>
                  <Link to ="/fleet_leasing" className="green-btn wide text-decoration-none d-inline-block">
                    Find Right Vehicle
                    <img src="./images/chevron-right.svg" height="13" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="Map-img">
            <img src={map} alt="" className=""/>
            {/* <div className="roadmapImg">
              <img src={roadmap} alt="" />
            </div>
            <div className="roadImg">
              <img src={road} alt="" />
            </div> */}
          </div>
        </div>
      </div>
      <div style={{paddingBottom:"2rem"}}>
      <HomeSlider />
      </div>
      <EVCalculator/>
      <LeasingGallery />
      <Altecosystem/>
      <PressRelease/>
      <Accelerators />

    </div>
  );
};

export default DesktopView;
