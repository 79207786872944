import React from "react";
import "./Card.css";

const Card = (props) => {
  return (
    <>
      <div
        className="card"
        style={{ width: "100%", height: "356px", background: "#fff" }}
      >
        <div className="card-body">
          <div className="icon-1">{props.icons}</div>
          <h5 className="card-title">{props.title}</h5>
          <p className="card-text">{props.text}</p>
        </div>
      </div>
    </>
  );
};

export default Card;
