import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useMediaQuery } from "react-responsive";
import EVCalculator from "../HomeComp/EVCalculator";
import "./LeaseFinance.css";
const LeaseFinance = () => {
  const isMobile = useMediaQuery({ maxWidth: 827 });

  const responsive = {
    tablet: {
      breakpoint: { max: 990, min: 500 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 500, min: 0 },
      items: 1,
    },
  };

  const carouselStyle = {
    padding: "0 5px",
    margin: "0 -5px",
  };

  const itemStyle = {
    margin: "0 10px",
  };

  const isMobileOrTablet = window.innerWidth <= 990;
  return (
    <>
      <div className="container   mt-md-5 pt-5 fleet-leasing-content">
        <div className="row gap-5 mb-5">
          <div className="col-lg-7 col-12 col-md-12 col-sm-12">
            <h1 className="mt-4">
              Fleet Lease - Transform Your Fleet with Economy and Ease
            </h1>
            <p className="para font-14">
              Reduce your monthly vehicle operating costs by up to 20%, minimize
              your financial burden, and improve cash flow with our Fleet
              Vehicle financing options. We will guide you in finding the ideal
              financing solution for your business.
            </p>
          </div>
          <div className="col-lg-4 col-12 col-md-12 col-sm-12 align justify-content-end">
            <img
              src="./home_files/leasing-vehicle.png"
              alt=""
              className="img-fluid adjust w-100"
            />
          </div>
        </div>
      </div>

      <div className="container leasing-compare-box text-center w-100">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <div className="tab2 w-100 text-center">
              <div className="table-title">
                <h3 className="compare-title">Own a Vehicle</h3>
              </div>
              <hr />
              <div className="leasing-box alt-card">
                <img src="./home_files/finance.png" alt="" className="mb-2" />
                <h4>Upfront Cost</h4>
                <p className="main">
                  You pay between 20-30% of the vehicle cost upfront for <br />
                  vehicle downpayment, road tax, etc.
                </p>
              </div>
              <hr />
              <div className="leasing-box alt-card">
                <img
                  src="./home_files/asset_weight.png"
                  alt=""
                  className="mb-2"
                />
                <h4>Asset Heavy</h4>
                <p className="main">
                  You maintain ownership of the vehicle for the complete tenure
                  <br /> of the loan
                </p>
              </div>
              <hr/>
              <div className="leasing-box alt-card">
                <img src="./home_files/cashflow.png" alt="" className="mb-2" />
                <h4>Lower Monthly Cashflow</h4>
                <p className="main">
                  Higher monthly operating costs as entire vehicle cost is
                  <br />
                  typically amortised over 3 years
                </p>
              </div>
              <hr />
              <div className="leasing-box alt-card">
                <img src="./home_files/fleet_management.png" alt="" className="mb-2" />
                <h4>Procure & Manage Fleets</h4>
                <p className="main">
                  You need to be hire your own team for procurement, fleet,
                  <br /> service and insurance. Essentially manage the entirety
                  of
                  <br /> fleets and their health.
                </p>
              </div>
              <hr />
              <div className="leasing-box alt-card">
                <img src="./home_files/predictions.png" alt="" className="mb-2" />
                <h4>Unpredictable Expenses</h4>
                <p className="main">
                  Finance cost is fixed, but you need to pay additional <br />
                  overheads for insurance, maintenance, etc.
                </p>
              </div>
              <hr />
              <div className="leasing-box alt-card">
                <img src="./home_files/tax_benefits.png" alt="" className="mb-2" />
                <h4>Standard Depreciation Benefit</h4>
                <p className="main">
                  Asset depreciation benefits from vehicle ownership are <br />
                  applicable which helps you with the best leasing offers.
                </p>
              </div>
              <hr />
              <div className="leasing-box alt-card">
                <img src="./home_files/experience.png" alt="" className="mb-2" />
                <h4>In-house Fleet Management</h4>
                <p className="main">
                  Build your own dedicated team for complete fleet
                  <br /> management activities
                </p>
              </div>
             
            </div>
          </div>

          <div className="col-md-6 col-sm-12">
            <div className="tabVehicle w-100 text-center">
              <div className="table-title title-vehicle">
                <h3 className="compare-title compare-vehicle">
                  Lease a Vehicle
                </h3>
                <hr />
              </div>

              <div className="leasing-box alt-card">
                <img src="./home_files/finance.png" alt="" className="mb-2" />
                <h4>Capex Light</h4>
                <p className="main">
                  No upfront capex cost. You pay a small refundable <br />
                  security deposit and the vehicle is all yours.
                </p>
              </div>
              <hr />
              <div className="leasing-box alt-card">
                <img
                  src="./home_files/asset_weight.png"
                  alt=""
                  className="mb-2"
                />
                <h4>Asset Light</h4>
                <p className="main">
                  Ownership of vehicles remains with Alt Mobility with the
                  option
                  <br /> to transfer ownership at the end of tenure
                </p>
              </div>
              <hr />
              <div className="leasing-box alt-card">
                <img src="./home_files/cashflow.png" alt="" className="mb-2" />
                <h4>Higher Monthly Cashflow</h4>
                <p className="main">
                  Up to 20% lower monthly vehicle operating costs as cost is
                  <br /> amortized over longer periods
                </p>
              </div>
              <hr />
              <div className="leasing-box alt-card">
                <img src="./home_files/fleet_management.png" alt="" className="mb-2" />
                <h4>Procure & Manage Fleets</h4>
                <p className="main">
                  An expert team provides complete Fleet Procurement and
                  <br /> Management with ongoing vehicle quality and warranty{" "}
                  <br />
                  evaluation.
                </p>
              </div>
              <hr />
              <div className="leasing-box alt-card">
                <img src="./home_files/predictions.png" alt="" className="mb-2" />
                <h4>Predictable Cashflow</h4>
                <p className="main">
                  Fixed monthly rentals covers cost of insurance,
                  <br /> maintenance, breakdowns etc
                </p>
              </div>
              <hr />
              <div className="leasing-box alt-card">
                <img src="./home_files/tax_benefits.png" alt="" className="mb-2" />
                <h4>Higher Tax Benefits</h4>
                <p className="main">
                  Monthly rentals paid for car leasing are tax deductible and{" "}
                  <br />
                  can be treated as an expense.
                </p>
              </div>
              <hr />
              <div className="leasing-box alt-card">
                <img src="./home_files/experience.png" alt="" className="mb-2" />
                <h4>Fully Managed Experience</h4>
                <p className="main">
                  Single technology enabled interface experience for
                  <br /> complete lifecycle management of fleets
                </p>
              </div>
              {/* Repeat the structure for other leasing boxes... */}
            </div>
          </div>
        </div>
      </div>

      <EVCalculator />

      <div className="discover-seamless p-md-5 paddingsmall ms-0 w-100 text-center mb-5">
        <div className="container">
        <div className="discover-heading">
          <h1 className=" seamless">
            Discover Seamless Fleet Management
            <br /> with FleetLease + FleetOS
          </h1>
          <p className="main mt-3">
            Alt makes your transition to EVs affordable, dependable, and
            effortless, enabling your
            <br /> business to become more efficient, profitable, and
            environmentally friendly.
          </p>
        </div>
        {isMobileOrTablet ? (
          <div className=" text-start mt-5 gap-4">
            <Carousel
              responsive={responsive}
              arrows={false}
              className="text-start mt-4 mb-4 ms-0 pb-5"
              autoPlay={true}
              showDots={true}
              autoPlaySpeed={3000}
              infinite={true}
              //   style={carouselStyle}
            >
              <div className="propretary-fleet-box p-3 alt-card d-flex gap-2">
                <img src="./home_files/insurance.png" alt="" />
                <div className="propretary-heading">
                  <h1>Proprietary Fleet Management Platform</h1>
                  <p>
                    Designed to optimize fleet uptime and life to help improve
                    your business productivity.
                  </p>
                </div>
              </div>
              <div className="propretary-fleet-box p-3 alt-card d-flex gap-2">
                <img src="./home_files/predictions.png" alt="" />
                <div className="propretary-heading">
                  <h1>Predictive & Corrective Fleet Analysis</h1>
                  <p>
                    Fleet diagnostic alerts, driving recommendations, service
                    alerts, health optimization for superior uptime.
                  </p>
                </div>
              </div>
              <div className="propretary-fleet-box p-3 alt-card d-flex gap-2">
                <img src="./home_files/asset_weight.png" alt="" />
                <div className="propretary-heading">
                  <h1>Fleet Management Overheads</h1>
                  <p>
                    Reduce your overheads with a dedicated Fleet Operations
                    Centre to manage your fleet health.
                  </p>
                </div>
              </div>
              <div className="propretary-fleet-box p-3 alt-card d-flex gap-2">
                <img src="./home_files/tax_benefits.png" alt="" />
                <div className="propretary-heading">
                  <h1>Data Driven Insights</h1>
                  <p>
                    Insights on vehicle selection, utilization, TCO, savings,
                    carbon offsets, vehicle health, driving behavior.
                  </p>
                </div>
              </div>
            </Carousel>
          </div>
        ) : (
          <div className="propretary-fleet text-start mt-5 gap-4">
            <div className="propretary-fleet-box p-3 alt-card d-flex gap-2">
              <img src="./home_files/insurance.png" alt="" />
              <div className="propretary-heading">
                <h1>Proprietary Fleet Management Platform</h1>
                <p>
                  Designed to optimize fleet uptime and life to help improve
                  your business productivity.
                </p>
              </div>
            </div>
            <div className="propretary-fleet-box p-3 alt-card d-flex gap-2">
              <img src="./home_files/predictions.png" alt="" />
              <div className="propretary-heading">
                <h1>Predictive & Corrective Fleet Analysis</h1>
                <p>
                  Fleet diagnostic alerts, driving recommendations, service
                  alerts, health optimization for superior uptime.
                </p>
              </div>
            </div>
            <div className="propretary-fleet-box p-3 alt-card d-flex gap-2">
              <img src="./home_files/asset_weight.png" alt="" />
              <div className="propretary-heading">
                <h1>Fleet Management Overheads</h1>
                <p>
                  Reduce your overheads with a dedicated Fleet Operations Centre
                  to manage your fleet health.
                </p>
              </div>
            </div>
            <div className="propretary-fleet-box p-3 alt-card d-flex gap-2">
              <img src="./home_files/tax_benefits.png" alt="" />
              <div className="propretary-heading">
                <h1>Data Driven Insights</h1>
                <p>
                  Insights on vehicle selection, utilization, TCO, savings,
                  carbon offsets, vehicle health, driving behavior.
                </p>
              </div>
            </div>
          </div>
        )}
        </div>
      </div>
    </>
  );
};

export default LeaseFinance;
