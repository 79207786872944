import React from "react";
import { useState } from "react";
import { BASE_URL } from "../repository/api";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./EnquiryDriver.css";
import Submitted from "./Submitted";
import { useForm } from "react-hook-form";
import { postDriverEnquiry } from "../repository/enquiry";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { ErrorMessage } from "@hookform/error-message";
import Axios from "axios";
import moment from "moment";

const validationSchema = Yup.object().shape({
  enquiryReason: Yup.string().required("Please select any of the following"),

  firstname: Yup.string()
    .matches(/^[a-zA-Z]+$/, "Please enter a valid first name")
    .min(2, "First name must be at least 2 characters long")
    .max(25, "First name must be no more than 25 characters long")
    .required("Please enter a first name"),

  lastName: Yup.string()
    .matches(/^[a-zA-Z]+$/, "Please enter a valid last name")
    .min(2, "Last name must be at least 2 characters long")
    .max(25, "last name must be no more than 25 characters long")
    .required("Please enter a Last name"),

  phone: Yup.string()
    .matches(/^[0-9]{10}$/, "Please enter a valid 10-digit phone number")
    .required("Please enter a phone number"),

  email: Yup.string()
    .email("Please enter a valid email address")
    .max(60, "Email address must be no more than 60 characters long")
    .required("Please enter an email address"),

  employmentType: Yup.string().required("Please select any of the following"),

  organizationName: Yup.string()
    .min(4, "Organisation name should be at least 4 characters")
    .max(50, "Organisation name should not exceed 50 characters")
    .required("Organisation name is required"),

  pincode: Yup.number()
    .typeError("Please enter a valid pincode")
    .test(
      "len",
      "Pincode must be exactly 6 digits long",
      (val) => val && val.toString().length === 6
    )
    .required("Please enter a pincode"),

  city: Yup.string()
    .min(2, "City name must be at least 2 characters long")
    .max(40, "City name cannot exceed 40 characters")
    .required("City name is required"),

  state: Yup.string().required("Please select a state"),

  vehicleType: Yup.string().required("Please select any of the following"),

  vehicleModel: Yup.string()
    .min(3, "Vehicle model name must be at least 3 characters long")
    .max(50, "Vehicle model name cannot exceed 50 characters")
    .required("Vehicle model name is required"),

  fleetRequirement: Yup.string()
    .min(3, "Fleet requirement must be at least 3 characters long")
    .max(40, "Fleet requirement cannot exceed 40 characters")
    .required("Fleet requirement is required"),

  // requirementDate: Yup.string().required("Please select Date"),

  message: Yup.string().max(500, "Message cannot exceed 500 characters"),
});

const EnquiryDriver = () => {
  const handleKeyDown = (e) => {
    e.preventDefault();
  };
  const [openModal, setOpenModal] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    trigger,
    formState: { errors },
  } = useForm({ resolver: yupResolver(validationSchema) });
  const currentDate = new Date();
  const currentDateString = currentDate.toISOString().split("T")[0];

  const postDriverEnquiry = async ({data,onSuccess}) => {
    console.log(data);
    try {
      data.enquiryType = "Driver";
      data.requirementDate = moment(selectedDate).format("YYYY-MM-DD");
      await Axios.post(`${BASE_URL}/enquire/`, data);
      if (onSuccess) onSuccess();
      setOpenModal(true);
      reset();
    } catch (err) {
      console.error("Error submitting data:", err);
    }
  };
  const [selectedDate, setSelectedDate] = useState(new Date());

  const handleDateChange = (date) => {
    if (date) {
      setSelectedDate(moment(date).format("YYYY-MM-DD"));
    }
  };

  return (
    <>
      <div className="enquire-form-subtitle text-center  " id="one">
        <h1 className="enquiry-form-h1">
          I am a Driver interested in <br />
          Alt Mobility’s fleet services for myself.
        </h1>
        <p className="enquiry-form-p mt-2">
          Please fill out the form below to have an Alt Mobility Fleet
          Management <br />
          sales representative contact you or call 9971007201.
        </p>

        <div className="enquire-form-container p-3 text-start mt-4">
          <form
            onSubmit={handleSubmit((data) => {
              console.log(">>>>")
              postDriverEnquiry({
                data,
                onSuccess: () => {
                  setOpenModal(true);
                  reset();
                },
              });
            })}
          >
            <div className="form-group row">
              <label className="col-md-4 col-form-label">
                What Brings you here?<span className="star-sym">*</span>
              </label>
              <div className="col-md-8">
                <select
                  id="disabledSelect"
                  className="form-select"
                  defaultValue=""
                  {...register("enquiryReason", { required: true })}
                  onBlur={() => trigger("enquiryReason")}
                >
                  <option value="" disabled hidden>
                    Select our Services
                  </option>
                  <option value="Integrated Services">
                    Integrated Services
                  </option>
                  <option value="Our Leasing Services">
                    Our Leasing Services
                  </option>
                  <option value="FleetProtect">FleetProtect</option>
                  <option value="FleetOS">FleetOS</option>
                </select>
                <p className="form-error">
                  <ErrorMessage errors={errors} name="enquiryReason" />
                </p>
              </div>
            </div>

            <div className="form-group row mt-4">
              <label className="col-md-4 col-form-label">
                First Name<span className="star-sym">*</span>
              </label>
              <div className="col-md-8">
                <input
                  type="text"
                  className="form-control"
                  id="Enter first name (eg: Ram)"
                  placeholder="Enter first name (e.g: Ram)"
                  {...register("firstname", { required: true })}
                  onBlur={() => trigger("firstname")}
                />
                <p className="form-error">
                  <ErrorMessage errors={errors} name="firstname" />
                </p>
              </div>
            </div>

            <div className="form-group row mt-4">
              <label className="col-md-4 col-form-label">
                Last Name<span className="star-sym">*</span>
              </label>
              <div className="col-md-8">
                <input
                  type="text"
                  className="form-control"
                  id="Enter last name"
                  placeholder="Enter last name (e.g: Singh)"
                  {...register("lastName", {
                    required: "true",
                  })}
                  onBlur={() => trigger("lastName")}
                />
                <p className="form-error">
                  <ErrorMessage errors={errors} name="lastName" />
                </p>
              </div>
            </div>

            <div className="form-group row mt-4">
              <label className="col-md-4 col-form-label">
                Phone<span className="star-sym">*</span>
              </label>
              <div className="col-md-8">
                <input
                  type="number"
                  name="phone"
                  className="form-control"
                  placeholder="Enter your phone number"
                  {...register("phone", { required: true })}
                  onBlur={() => trigger("phone")}
                />
                <p className="form-error">
                  <ErrorMessage errors={errors} name="phone" />
                </p>
              </div>
            </div>

            <div className="form-group row mt-4">
              <label className="col-md-4 col-form-label">
                Email ID<span className="star-sym">*</span>
              </label>
              <div className="col-md-8">
                <input
                  type="email"
                  className="form-control"
                  id="enter your email"
                  placeholder="Enter email id (e.g: ramsingh01@gmail.com)"
                  {...register("email", { required: true })}
                  onBlur={() => trigger("email")}
                />
                <p className="form-error">
                  <ErrorMessage errors={errors} name="email" />
                </p>
              </div>
            </div>

            <div className="form-group row mt-4">
              <label className="col-md-4 col-form-label">
                Employment<span className="star-sym">*</span>
              </label>
              <div className="col-md-8">
                <select
                  id="disabledSelect"
                  className="form-select"
                  defaultValue=""
                  {...register("employmentType", { required: true })}
                  onBlur={() => trigger("employmentType")}
                >
                  <option value="" disabled hidden>
                    Select employment type
                  </option>
                  <option value="Salaried">Salaried</option>
                  <option value="Self-employed">Self-employed</option>
                  <option value="Unemployed">Unemployed</option>
                </select>
                <p className="form-error">
                  <ErrorMessage errors={errors} name="employmentType" />
                </p>
              </div>
            </div>

            <div className="form-group row mt-4">
              <label className="col-md-4 col-form-label">
                Organisation Name<span className="star-sym">*</span>
              </label>
              <div className="col-md-8">
                <input
                  type="text"
                  className="form-control"
                  id="organizationName"
                  placeholder="Enter organisation name (XYZ Enterprises)"
                  {...register("organizationName", { required: true })}
                  onBlur={() => trigger("organizationName")}
                />
                <p className="form-error">
                  <ErrorMessage errors={errors} name="organizationName" />
                </p>
              </div>
            </div>

            <div className="form-group row mt-4">
              <label className="col-md-4 col-form-label">
                Pincode<span className="star-sym">*</span>
              </label>
              <div className="col-md-8">
                <input
                  type="pincode"
                  className="form-control"
                  id="enter your pincode"
                  placeholder="Enter pincode (eg. 337127)"
                  {...register("pincode", { required: true })}
                  onBlur={() => trigger("pincode")}
                />
                <p className="form-error">
                  <ErrorMessage errors={errors} name="pincode" />
                </p>
              </div>
            </div>

            <div className="form-group row mt-4">
              <label className="col-md-4 col-form-label">
                City<span className="star-sym">*</span>
              </label>
              <div className="col-md-8">
                <input
                  type="text"
                  className="form-control"
                  id="enter your city"
                  placeholder="Enter city (e.g: Delhi)"
                  {...register("city", { required: true })}
                  onBlur={() => trigger("city")}
                />
                <p className="form-error">
                  <ErrorMessage errors={errors} name="city" />
                </p>
              </div>
            </div>

            <div className="form-group row mt-4">
              <label className="col-md-4 col-form-label">
                State<span className="star-sym">*</span>
              </label>
              <div className="col-md-8">
                <select
                  id="disabledSelect"
                  className="form-select"
                  defaultValue=""
                  {...register("state", { required: true })}
                  onBlur={() => trigger("state")}
                >
                  <option value="" disabled hidden>
                    Select State
                  </option>
                  <option value="Karnataka">Karnataka</option>
                  <option value="Tamil Nadu">Tamil Nadu</option>
                  <option value="Jharkhand">Jharkhand</option>
                  <option value="Bihar">Bihar</option>
                  <option value="Assam">Assam</option>
                  <option value="Nagaland">Nagaland</option>
                </select>
                <p className="form-error">
                  <ErrorMessage errors={errors} name="state" />
                </p>
              </div>
            </div>

            <div className="form-group row mt-4">
              <label className="col-md-4 col-form-label">
                Vehicle Type<span className="star-sym">*</span>
              </label>
              <div className="col-md-8">
                <select
                  id="disabledSelect"
                  className="form-select"
                  defaultValue=""
                  {...register("vehicleType", { required: true })}
                  onBlur={() => trigger("vehicleType")}
                >
                  <option value="" disabled hidden>
                    Select Vehicle Type
                  </option>
                  <option value="2 Wheeler">Two Wheelers</option>
                  <option value="3 Wheeler">Three Wheelers</option>
                </select>
                <p className="form-error">
                  <ErrorMessage errors={errors} name="vehicleType" />
                </p>
              </div>
            </div>

            <div className="form-group row mt-4">
              <label className="col-md-4 col-form-label">
                Vehicle Model<span className="star-sym">*</span>
              </label>
              <div className="col-md-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder="enter your vehicle model"
                  {...register("vehicleModel", { required: true })}
                  onBlur={() => trigger("vehicleModel")}
                />
                <p className="form-error">
                  <ErrorMessage errors={errors} name="vehicleModel" />
                </p>
              </div>
            </div>

            <div className="form-group row mt-4">
              <label className="col-md-4 col-form-label">
                Fleet Requirement<span className="star-sym">*</span>
              </label>
              <div className="col-md-8">
                <input
                  type="text"
                  className="form-control"
                  id="enter your fleet requirement"
                  placeholder="Enter fleet requirement"
                  {...register("fleetRequirement", { required: true })}
                  onBlur={() => trigger("fleetRequirement")}
                />
                <p className="form-error">
                  <ErrorMessage errors={errors} name="fleetRequirement" />
                </p>
              </div>
            </div>
            {/*
            <div className="form-group row mt-4">
              <label className="col-md-4 col-form-label">
                Requirement Date<span className="star-sym">*</span>
              </label>
              <div className="col-md-8">
                <input
                  type="date"
                  className="form-control my-input-date"
                  id="enter your requirement"

                min={currentDateString}
                  placeholder="Enter requirement date"
                  {...register("requirementDate", { required: true })}
                  onBlur={() => trigger("requirementDate")}
                  onKeyDown={handleKeyDown}
                />
                <p className="form-error">
                  <ErrorMessage errors={errors} name="requirementDate" />
                </p>
              </div>
            </div> */}

            <div className="form-group row mt-4">
              <label className="col-md-4 col-form-label">
                Requirement Date<span className="star-sym">*</span>
              </label>
              <div className="col-md-8">
                {/* <DatePicker
            selected={selectedDate}
            onChange={handleDateChange}
            className="form-control my-input-date"
            id="enter your requirement"
            // minDate={new Date()}
            placeholderText="Enter requirement date"
            // {...register("requirementDate", { required: true })}
            // onBlur={() => trigger("requirementDate")}
          /> */}
                <DatePicker
                  selected={selectedDate}
                  placeholderText="Enter requirement date"
                  className="form-control my-input-date"
                  dateFormat="dd-MM-yyyy"
                  onChange={(date) => setSelectedDate(date)}
                  minDate={new Date()}
                />
                <p className="form-error">
                  <ErrorMessage errors={errors} name="requirementDate" />
                </p>
              </div>
            </div>

            <div className="form-group row mt-4">
              <label className="col-md-4 col-form-label">
                Additional Message
              </label>
              <div className="col-md-8">
                <textarea
                  type="text"
                  className="form-control"
                  placeholder="Enter additional message if any"
                  id="floatingTextarea2"
                  style={{ height: "180px" }}
                  {...register("additionalMessage")}
                  onBlur={() => trigger("additionalMessage")}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "16px",
              }}
            >
              <button
                type="submit"
                className="green-btn btn-primary mt-5 submit-btn"
              >
                Submit Details & Get Quote
              </button>
            </div>
          </form>
        </div>
        {openModal && <Submitted closeModal={setOpenModal} />}
      </div>
    </>
  );
};

export default EnquiryDriver;
