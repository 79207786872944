import React, { useEffect, useRef, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import { FaAngleDown } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import Logo from "../../Images/mobileAltMobilityLogo.png";
import "./Header.css";
import { vehicleDropdownData } from "./constants";

const MobileHeader = ({ activeLink, handleLinkClick }) => {
    const location = useLocation();
    const currentPath = location.pathname;
    // ref to vehicle dropdown
    const vehicleDropdownRef = useRef(null);

    // states
    const [vehicleDropdown, setVehicleDropdown] = useState(false);

    // once clicked outside the dropdown will close
    const useOutsideAlerterVehicleDropdown = (ref) => {
        useEffect(() => {
            const handleClickOutside = (e) => {
                if (ref.current && !ref.current.contains(e.target)) {
                    setVehicleDropdown(false);
                }
            }
            // Bind the event listener
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, [ref]);
    };
    // the function is called when clicked outside the dropdown
    useOutsideAlerterVehicleDropdown(vehicleDropdownRef);

    return (
        <div>
            <Navbar
                expand="sm"
                fixed="top"
                className="mobile-header"
            >
                <Container>
                    <div className="mobile-header-logo">
                        <Link to="/" onClick={() => handleLinkClick("/")}>
                            <img src={Logo} alt="logo" />
                        </Link>
                    </div>
                    <div className="mobile-menu-options">
                        <div className="mobile-menu-option">
                            <span onClick={() => setVehicleDropdown(true)}>
                                Vehicles&nbsp;<FaAngleDown />
                            </span>
                        </div>
                        <div className="mobile-menu-option">
                            Compare
                        </div>
                    </div>
                    {vehicleDropdown
                            ? (
                                <div className="mobile-menu-dropdown" ref={vehicleDropdownRef}>
                                    <div className="menu-dropdown-header">
                                        <IoMdClose className="mobile-dropdown-close" onClick={() => setVehicleDropdown(false)} />
                                    </div>
                                    <div className="mobile-menu-dropdown-container">
                                        {vehicleDropdownData?.map((data) => (
                                            <div className="mobile-menu-item">
                                                <Link to={data.link} onClick={() => handleLinkClick(data.link)}>
                                                    <img className="mobile-menu-image" src={data.icon} alt="" />
                                                    <div>
                                                        {data.menu}
                                                    </div>
                                                </Link>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )
                            : null
                        }
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav" className="mobile-collapsible-menu">
                        <Nav aria-current="page">
                            <Link to="#" onClick={() => handleLinkClick("/")}>
                                option1
                            </Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    );
};

export default MobileHeader;